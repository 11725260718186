<app-wrapper></app-wrapper>
<div class="ResultWrapper container-fluid">
    <!-- <div class='row'>
      <div class="cols col-sm-12">
          <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
          </div>
      </div>
  </div> -->



    <div style="display:grid;">
        <div class="m-auto spinner-border text-primary" role="status" *ngIf="loading">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <!-- iferror then display eror div -->
    <div *ngIf="ResponseFlightEr">
        <div style="width: 1200px; margin:auto;" *ngFor="let responseEr of ResponseFlightDataEr">

            <div class="Trips" *ngFor="let DataD of responseEr.data; let Da = index">

                <div class=" label label-danger m-auto" *ngFor="let errorMessages of DataD.errorMessages; let Derr = index">

                    <div class="error-message">
                        <i class="fas fa-exclamation-circle"></i> {{errorMessages.Message}}
                    </div>

                </div>

            </div>

        </div>
    </div>


    <div id="FlightResults" *ngIf="ResponseFlight">




        <div class="sideBar">



            <div *ngIf="ResponseFlight">

                <h2>Departure Time</h2>
                <div class="SideBarCardContainer">
                    <div class="SideBarCard" (click)="getFlights('05to12', 'Dep')">
                        05-12
                    </div>
                    <div class="SideBarCard" (click)="getFlights('12to18', 'Dep')">
                        12-18
                    </div>
                    <div class="SideBarCard" (click)="getFlights('18to24', 'Dep')">
                        18-24
                    </div>
                    <div class="SideBarCard" (click)="getFlights('24to05', 'Dep')">
                        24-05
                    </div>
                </div>
                <hr>
                <h2>Arrival Time</h2>
                <div class="SideBarCardContainer">
                    <div class="SideBarCard" (click)="getFlights('05to12', 'Arr')">
                        05-12
                    </div>
                    <div class="SideBarCard" (click)="getFlights('12to18', 'Arr')">
                        12-18
                    </div>
                    <div class="SideBarCard" (click)="getFlights('18to24', 'Arr')">
                        18-24
                    </div>
                    <div class="SideBarCard" (click)="getFlights('24to05', 'Arr')">
                        24-05
                    </div>
                </div>
            </div>



        </div>




        <div class="contentBar">
            <div style="display:grid;" *ngIf="loading">
                <div class="m-auto spinner-border text-primary" role="status" *ngIf="loading">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>


            <!-- if no error then display Flight div -->
            <!--
        [0].FlightTrips
        [1].FlightTrips -->





            <div class="HContainer" *ngFor="let ResponseFlight of ResponseFlightData, let i = index">


                <div class=" vt m-2 inblock" [ngClass]="{'w-98': this.noofSectors==1, 'TripsRows2': this.noofSectors==1,
                'w-48': this.noofSectors==2, 'TripsRows3': this.noofSectors>1,
                'w-40': this.noofSectors>2}" *ngFor="let Data of ResponseFlight.data, let Dataindex=index">
                    <!-- data[0].FlightTrips -->
                    <mat-radio-group>


                        <div class="Trips" *ngFor="let FlightTrips of Data.FlightTrips, let tripindex=index">

                            <div class="mdc-form-field tripHead" *ngIf="this.noofSectors>1">
                                <div class="mdc-radio">
                                    <input class="mdc-radio__native-control" type="radio" id="radio-1" name="radios" checked>
                                    <div class="mdc-radio__background">
                                        <div class="mdc-radio__outer-circle"></div>
                                        <div class="mdc-radio__inner-circle"></div>
                                    </div>
                                    <div class="mdc-radio__ripple"></div>
                                </div>
                                <label class="mb-0" for="radio-1">{{FlightTrips.FlightGroup[0].AirlineName}} </label>
                            </div>


                            <div class="tripContent">
                                <div *ngFor="let flightgroup of FlightTrips.FlightGroup">


                                    <div class="flight">

                                        <div class="ng-star-inserted">
                                            <div class="flights ng-star-inserted">
                                                <div class="DGrid CenterBoth">
                                                    {{flightgroup.AirlineName}}

                                                    <div *ngFor="let imgPathAirlineName of flightgroup.AirlineName | airlineImagePath:'airlines'">

                                                        <img style="width: 60px;" src="{{imgPathAirlineName}}" />
                                                        <!-- <img src="assets\app_store.png" alt=""/> -->
                                                        <!-- <img [src]="assets/airlines/itineraryInfoField.itineraryReservationInfoField.reservationField.companyIdField" /> -->

                                                    </div>


                                                </div>

                                                <div class="DGrid CenterBoth">
                                                    <span class="material-icons"> flight_takeoff </span>
                                                    <span class="CDate ft9">{{flightgroup.DepDate | displayDateTime: "Date"}}</span>

                                                    <span class="CTime ft15">{{flightgroup.DepTime | displayDateTime: "Time"}}</span>
                                                    <span class="ng-star-inserted">{{flightgroup.DepCity}}</span>

                                                </div>
                                                <div class="DGrid CenterBoth">
                                                    <span class="material-icons"> flight_land </span>
                                                    <span class="CDate ft9">{{flightgroup.ArrDate | displayDateTime: "Date"}}</span>
                                                    <span class="CTime ft15">{{flightgroup.ArrTime | displayDateTime: "Time"}}</span>
                                                    <span class="ng-star-inserted">{{flightgroup.ArrCity}}</span>

                                                </div>

                                                <div class="DGrid CenterBoth Price">{{FlightTrips.TripPrice | currency: "INR":"symbol":"1.0-0"}}</div>

                                            </div>

                                        </div>




                                    </div>

                                </div>





                            </div>
                            <div class="tripFooter">

                                <div class="tripFooterL">
                                    <div>Baggage: {{FlightTrips.TripBaggageQty}} {{FlightTrips.TripBaggageUnit}}</div>
                                    <div>Available Seats: {{FlightTrips.AvlSeats}}</div>
                                </div>
                                <div>
                                    <div>{{FlightTrips.FlightGroup[0].Duration | hitsDurationFormat: "hh mm"}}</div>
                                    <div>{{FlightTrips.FlightGroup[0].FlightSector}}</div>




                                </div>

                                <div class="tripFooterR">
                                    <div>
                                        <span href="#" class="pointer FlightDetailsA">+ Flight Details</span>
                                    </div>

                                </div>





                            </div>


                        </div>
                    </mat-radio-group>






                </div>



            </div>







        </div>
    </div>


</div>
