<a class="MenuItems active">
  <span class="material-icons">
    flight
    </span>
<br>Flight</a>

<a class="MenuItems">
  <span class="material-icons">local_hotel</span>
  <br>Hotels</a>

<a class="MenuItems">
  <span class="material-icons">assignment</span>
  <br>Visa</a>
<a class="MenuItems">
  <span class="material-icons">
    card_travel
    </span>
  <br>Holidays</a>
<a class="MenuItems" id="MenuForex">
  <span class="material-icons">
    monetization_on
    </span>
  <br>Forex</a>
<a class="MenuItems" id="MenuBus">
  <span class="material-icons">
    directions_bus
    </span>
  <br>Bus</a>
<a class="MenuItems" id="MenuCruise">
  <span class="material-icons">
    directions_boat
    </span>
  <br>Cruise</a>
