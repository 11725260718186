import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objArray'
})
export class ObjArrayPipe implements PipeTransform {

  transform(obj: Object, args: any[]=null): any {
    let array = [];
  //   Object.keys(obj).forEach(key => {
  //     array.push({
  //         value: obj[key],
  //         key: key
  //     });
  // });
  array.push(obj);
  return array;
  }

}
