import {AfterViewInit, Component, OnInit, ViewChildren, ViewChild, Renderer2, NgModule, HostListener, EventEmitter, Output } from '@angular/core';
import * as moment from 'moment';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

import {FormsModule,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators

} from '@angular/forms'

import { ActivatedRoute, Router } from '@angular/router';
import { FareMasterPricerTravelBoardSearchReplyErProxy } from '../fare-master-pricer-travel-board-search-reply-er';
import { MultiCity } from '../multi-city';
import { JsonPipe } from '@angular/common';
import { PnrName } from '../PnrName';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Contacts } from '../contacts';
import { CookieService } from 'ngx-cookie-service';
import { User } from '../auth/user';

import { FirebaseService } from '../fireservices/firebase.service';
import { AngularFireAuth, PERSISTENCE  } from '@angular/fire/auth';
import { AuthService} from '../auth/auth.service';
import { ErrorMessageService } from '../Services/error-message.service';
import { LoadingService } from '../Services/loading.service';
import { HitCookieService } from '../Services/hit-cookie.service';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-flight-review',
  templateUrl: './flight-review.component.html',
  styleUrls: ['./flight-review.component.css']
})
export class FlightReviewComponent implements OnInit, AfterViewInit  {



  //USER and ORDER Table Fields
  public HitLoginState:boolean;
  public LoggedUser:User;

  public hitVisit:string;
  public hitFUID:string;
  hitIdOrder:string;
  hitToken:string;
  hitEmail:string;
  hitPhoneNumber: string;
  hitOrderAmount:string;
  TotalCost:string;
  ServiceCharge:number=103;
  PhoneNumberLoginChoosen:boolean=false;

  //public apiUrl:string ="https://cosmictours202020210214185330.azurewebsites.net/";
  //public apiUrl="https://localhost:44317/";
  //public apiUrl="https://cosmictours2021.azurewebsites.net/";
  apiUrl = environment.apiUrl;

  // @Output()
  // dateChange:EventEmitter< MatDatepickerInputEvent< any>>;
  //events: string[] = [];

@ViewChild ('ReviewComponentDiv') ReviewComponentDiv;


  errorFound:boolean=false;
  errorFoundData=[];
  errorMessage="Oops! Session expired kindly research your itinerary...";
  sessionError:boolean=false;
  NoCooks:boolean=false;
  PaymentStatusData=[];

  ReviewItineraryDetails=[];

  public ReviewComponent=false;
  NoofPAX: number;
  PaxChildren: number;
  PaxAdults: number;
  PaxInfant: number;
  NoofTravellers: number;
  public DirectFlights: boolean = false;
  public DomOrInt="D";
  public TrackerID="";
  public SectorsItinerary="";
  SimpleSearch="One Way";
  MultiCityArray=[{"FromCityCode":"DEL","ToCityCode":"BOM","DepartDate":"", "ReturnDate":"","DepDorI":"","RetDorI":""}];
  ResponseFlightData=[];
  public ResponseFlight=false;
  ResponseFlightDataEr=[];
  public FlightIndex=[];
  public ResponseFlightEr=false;
  obj: any = null;
  public TripType="";
  Cabin: string;
  public ResponseFlightDetailsDataEr=[];
  public ResponseFlightDetailsData=[];
  public ResponseFlightDetails: boolean;
  public ResponseFlightDetailsEr: boolean;
  public FlightInfoNoPNR: [];

  public FlightInfo=false;
  public FlightInfoData=[];
  public FareRules=false;
  public FareRulesData=[];

  public PNR_locator="";
  public ContactDetails:[{Email:string,Mobile:string}];
  public ShowPaymentDiv= false;
  public ShowPaymentDivData=[];
  public SequenceNo:number=0;
  public ShowTicketDiv=false;
  public ShowTicketDivData=[];


  public PNRNames=[{"Adults":{"surname":"","firstname":"","DOB":""}},
                   {"Infants":{"surname":"","firstname":"","DOB":""}},
                   {"Child":{"surname":"","firstname":"","DOB":""}} ];

  // public PNRADTNames=[{"surname":"Ansari","firstname":"Imran","DOB":""}];
  // public PNRINFNames=[{"surname":"Ansari","firstname":"","DOB":""}];
  // public PNRCHDNames=[{"surname":"Ansari","firstname":"","DOB":""}];

  public PNRADTNames=[];
  public PNRINFNames=[];
  public PNRCHDNames=[];
  public PNRContact=[];
  public Token=[];
  minDateInf: Date;
  maxDateInf: Date;


  minDateChd: Date;
  maxDateChd: Date;

  currentdate:Date;


  public AdultsLists;
  public ChildrenList;
  public INfantsList;
  public AdtPax=true;
  public InfPax=true;
  public ChPax=false;
  InfantDOB="";
  public BookingFailedComponent = false;

  Nmask = [ /[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

  public FormValid=false;

  @ViewChild('RightBars') RightBars;


  //Adt
  @ViewChildren('AdtTitle') AdtTitle;
  @ViewChildren('AdTFirstName') AdTFirstName;
  @ViewChildren('AdTSurName') AdTSurName;
  @ViewChildren('adtTErr') adtTErr;
  @ViewChildren('adtFNErr') adtFNErr;
  @ViewChildren('adtSNErr') adtSNErr;



  //INF
  @ViewChildren('InfTitle') InfTitle;
  @ViewChildren('InfFirstName') InfFirstName;
  @ViewChildren('InfSurName') InfSurName;
  @ViewChildren('InfDOB') InfDOB;
  @ViewChildren('infTErr') infTErr;
  @ViewChildren('infFNErr') infFNErr;
  @ViewChildren('infSNErr') infSNErr;
  @ViewChildren('infDOBErr') infDOBErr;

  //CHD
  @ViewChildren('ChTitle') ChTitle;
  @ViewChildren('ChFirstName') ChFirstName;
  @ViewChildren('ChSurName') ChSurName;
  @ViewChildren('ChDOB') ChDOB;
  @ViewChildren('chdTErr') chdTErr;
  @ViewChildren('chdFNErr') chdFNErr;
  @ViewChildren('chdSNErr') chdSNErr;
  @ViewChildren('chdDOBErr') chdDOBErr;
  @ViewChild('EmailErr') EmailErr;
  @ViewChild('MobileErr')MobileErr;
  loading: boolean;
  PaymentStatus: boolean;
  PhoneNumberLogin: boolean=false;
  PaymentSuccess: boolean=false;








  constructor(private renderer: Renderer2,
    private fb:FormBuilder,
    private httpClient:HttpClient,
    private router: Router,
    private route:ActivatedRoute,
    private cookieService: CookieService,
    public firebaseService: FirebaseService,
    public afAuth: AngularFireAuth,
    public HitAuth: AuthService,
    public errorMessageService:ErrorMessageService,
    public loadingService:LoadingService,
    public hitCookieService:HitCookieService
    ) {
      this.route.params.subscribe(  params=>{
          if(params['Task']=="Review"){
            this.AirSell(params['TrackerID']);
        // this.searchFromURL(params['Task'],params['Sectors'],params['DepDates'],params['Travellers'],
        // params['Cabin'],params['TripType'],params['DomOrInt'],params['DirectFlights'],params['UTT'])
      }else if(params['Task']=="Ticket"){
        this.CheckPayment();
      }


    }

    );

    const currentYear = new Date().getFullYear();

    //  this.currentdate = new Date();
    // let momentVariable = moment("10/10/2020", 'DD-MM-YYYY').toDate();

     this.currentdate =moment("10/10/2020", 'DD-MM-YYYY').toDate();

     var pastYear = this.currentdate.getFullYear() - 2;

    //this.minDateInf = new Date(currentYear - 1, 0, -1);

    this.minDateInf = new Date(this.currentdate.setFullYear(pastYear));
    this.minDateInf.setDate( this.minDateInf.getDate() + 1 );

    //this.maxDateInf = new Date();
    let dt = localStorage.getItem("depDate");
    let dtYear=dt.substr(0,4);
    let dtMonth=dt.substr(5,2);
    let dtDay=dt.substr(8,2);
    let dateString = dtYear +"/" +dtMonth+"/"+dtDay;

    moment.locale('en-GB');
    //var date = moment(Y+"-"+M+"-"+D);

     //let maxDateInfV =  moment(dtYear+"-"+dtMonth+"-"+dtDay);

     this.currentdate = moment(dtYear+"-"+dtMonth+"-"+dtDay).toDate();
    //  //console.log("dt: " + dt);
    //  //console.log("dtDay: " + dtDay);
    //  //console.log("dtMonth: " + dtMonth);
    //  //console.log("dtYear: " + dtYear);
    //  //console.log("MaxInfDate:"+maxDateInf);

    let maxDateInfV =  moment(dtYear+"-"+dtMonth+"-"+dtDay);

    let maxDateInf = maxDateInfV.format('DD-MM-YYYY');
    // //console.log("MaxInfDate" + maxDateInf);

    //let currentdate = maxDateInfV.format('DD/MM-YYYY');

    var pastYearChd = this.currentdate.getFullYear() - 12;
    this.minDateChd = new Date(this.currentdate.setFullYear(pastYearChd));

    // this.maxDateChd = new Date();
    // this.maxDateChd.setDate(this.minDateInf.getDate()-3);

    this.currentdate = moment(dtYear+"-"+dtMonth+"-"+dtDay).toDate(); // max Inf Date
    this.minDateInf = moment(this.currentdate).add(-2,"years").toDate();  // Min Inf Date
    this.minDateInf = moment(this.minDateInf).add(1,"day").toDate();
    this.maxDateChd = moment(this.currentdate).add(-2,"years").toDate();
    // this.maxDateChd = moment(this.maxDateChd).add(-1,"day").toDate();

    this.minDateChd = moment(this.currentdate).add(-12,"years").toDate();
    this.minDateChd = moment(this.minDateChd).add(1,"day").toDate();



     this.currentdate = new Date();
    this.setPNRObj();

    }
    public setPNRObj(){

      let Adults = localStorage.getItem("Adults");
      let Children = localStorage.getItem("Children");
      let Infants = localStorage.getItem("Infants");

      this.PaxAdults = +Adults;
      this.PaxChildren = +Children;
      this.PaxInfant = +Infants;

      for(let i=0; i<this.PaxAdults;i++){
        // let PnrN = new PnrName("","","","");
        let PnrN = new PnrName("","","","");
        this.PNRADTNames.push(PnrN);
      }


      for(let i=0; i<this.PaxInfant;i++){
        // let PnrN = new PnrName("","","","");
        let PnrN = new PnrName("","","","");
        this.PNRINFNames.push(PnrN);
      }

      for(let i=0; i<this.PaxChildren;i++){
        // let PnrN = new PnrName("","","","");
        let PnrN = new PnrName("","","","");
        this.PNRCHDNames.push(PnrN);
      }
      // let Cont = new Contacts("","");
      let Cont = new Contacts("","");
      this.PNRContact.push(Cont);
      // //console.log(this.PNRContact);


      }

    setTitle(Pax,num){


    }

    onTitleSelected(Pax, val, num){
      ////console.log (Pax+ "-" + val +"-" + num);
      this.FormValid=false;
      let entryVal=false;
      if(Pax=="A"){
          this.PNRADTNames[num].PaxTitle = val;
        if(val.length>0){
          this.renderer.setStyle(this.adtTErr.toArray()[num].nativeElement,'display','none');
        }else{
          this.renderer.setStyle(this.adtTErr.toArray()[num].nativeElement,'display','block');
        }
 //       //console.log(this.PNRADTNames);
      }
      if(Pax=="I"){
        this.PNRINFNames[num].PaxTitle = val;
        if(val.length>0){
          this.renderer.setStyle(this.infTErr.toArray()[num].nativeElement,'display','none');
        }else{
          this.renderer.setStyle(this.infTErr.toArray()[num].nativeElement,'display','block');
        }

  //      //console.log(this.PNRINFNames);
      }

      if(Pax=="C"){
        this.PNRCHDNames[num].PaxTitle = val;
        if(val.length>0){
          this.renderer.setStyle(this.chdTErr.toArray()[num].nativeElement,'display','none');
        }else{
          this.renderer.setStyle(this.chdTErr.toArray()[num].nativeElement,'display','block');
        }

    //    //console.log(this.PNRCHDNames);
      }







    }

  onFirstNameChange(Pax, val, num){
      ////console.log (Pax+ "-" + val +"-" + num);
      //PaxFirstName: "1", PaxSurName: "", PaxDOB: ""
      if(Pax=="A"){

        this.PNRADTNames[num].PaxFirstName = val;
        if(val.length>2){
          this.renderer.setStyle(this.adtFNErr.toArray()[num].nativeElement,'display','none');
        }else{
          this.renderer.setStyle(this.adtFNErr.toArray()[num].nativeElement,'display','block');
        }
    //    //console.log(this.PNRADTNames);

      }
      if(Pax=="I"){
        this.PNRINFNames[num].PaxFirstName = val;
        if(val.length>2){
          this.renderer.setStyle(this.infFNErr.toArray()[num].nativeElement,'display','none');
        }else{
          this.renderer.setStyle(this.infFNErr.toArray()[num].nativeElement,'display','block');
        }
    //    //console.log(this.PNRINFNames);
      }

      if(Pax=="C"){
        this.PNRCHDNames[num].PaxFirstName = val;
        if(val.length>2){
          this.renderer.setStyle(this.chdFNErr.toArray()[num].nativeElement,'display','none');
        }else{
          this.renderer.setStyle(this.chdFNErr.toArray()[num].nativeElement,'display','block');
        }
     //   //console.log(this.PNRCHDNames);
      }




  }
  onEmailChange(val){
      if(val.length>0){
        this.PNRContact[0].Email=val;
        //console.log(this.PNRContact);
        this.renderer.setStyle(this.EmailErr.nativeElement,'display','none');
      }else{
        this.renderer.setStyle(this.EmailErr.nativeElement,'display','none');
      }

  }

  onMobileChange(val){
      if(val.length==10){

      this.PNRContact[0].Mobile=val;
      this.renderer.setStyle(this.MobileErr.nativeElement,'display','none');
      console.log(this.PNRContact[0].Mobile);
      this.PhoneNumberLoginChoosen=true;
      sessionStorage.setItem("PhoneLoginNumber",this.PNRContact[0].Mobile);



      }else{
        this.renderer.setStyle(this.MobileErr.nativeElement,'display','block');
        console.log("NNNNN");
        this.PhoneNumberLoginChoosen=false;
      sessionStorage.removeItem("PhoneLoginNumber");
      this.PhoneNumberLogin=false;

      }

  }


  onSurNameChange(Pax, val, num){
      ////console.log (Pax+ "-" + val +"-" + num);
      //PaxFirstName: "1", PaxSurName: "", PaxDOB: ""
      if(Pax=="A"){
        this.PNRADTNames[num].PaxSurName = val;
        if(val.length>2){
          this.renderer.setStyle(this.adtSNErr.toArray()[num].nativeElement,'display','none');
        }else{
          this.renderer.setStyle(this.adtSNErr.toArray()[num].nativeElement,'display','block');
        }
  //      //console.log(this.PNRADTNames);


      }
      if(Pax=="I"){
        this.PNRINFNames[num].PaxSurName = val;
        if(val.length>2){
          this.renderer.setStyle(this.infSNErr.toArray()[num].nativeElement,'display','none');
        }else{
          this.renderer.setStyle(this.infSNErr.toArray()[num].nativeElement,'display','block');
        }
 //       //console.log(this.PNRCHDNames);
      }

      if(Pax=="C"){
        this.PNRCHDNames[num].PaxSurName = val;
        if(val.length>2){
          this.renderer.setStyle(this.chdSNErr.toArray()[num].nativeElement,'display','none');
        }else{
          this.renderer.setStyle(this.chdSNErr.toArray()[num].nativeElement,'display','block');
        }
   //     //console.log(this.PNRCHDNames);
      }


  }

  onDOBChange(Pax, val, num){
      ////console.log (Pax+ "-" + val +"-" + num);
      //PaxFirstName: "1", PaxSurName: "", PaxDOB: ""
      if(Pax=="A"){
        this.PNRADTNames[num].PaxDOB = val;
      }
      if(Pax=="I"){
        this.PNRINFNames[num].PaxDOB = val;
      }

      if(Pax=="C"){
        this.PNRCHDNames[num].PaxDOB = val;
      }


  }


  ngAfterViewInit() {  }

  ngOnInit(): void {
    this.PhoneNumberLoginChoosen=false;

    let Adults = localStorage.getItem("Adults");
    let Children = localStorage.getItem("Children");
    let Infants = localStorage.getItem("Infants");

    this.PaxAdults = +Adults;
    this.PaxChildren = +Children;
    this.PaxInfant = +Infants;

    this.loadingService.loadingUpated.subscribe((boolean)=>{
      this.loading = this.loadingService.getloading();}
    );


    this.HitAuth.authUpdated.subscribe(
      (data)=>{
        this.HitLoginState = this.HitAuth.getLoginState();
        console.log("JUST got state from review component", this.HitLoginState);
        if(this.HitLoginState){
          this.PhoneNumberLogin=false;
        }
      }


    );
    this.HitAuth.userUpdated.subscribe(
      (user)=>{
        if(user){
          console.log("User from review component", user);
          this.LoggedUser=this.HitAuth.getLoggedUser();
          this.hitFUID=this.LoggedUser.uid;
        }

      }
    );
    this.HitAuth.emailUpdated.subscribe(
      (email)=>{
        this.hitEmail= this.HitAuth.getemail();
      }
    )
    this.HitAuth.phonenumberUpdated.subscribe(
      (phonenumber)=>{
        this.hitPhoneNumber=this.HitAuth.getphonenumber();
      }
    )

    this.HitAuth.hitTokenUpated.subscribe(
      (hittoken)=>{
        this.hitToken=this.HitAuth.gethitToken();
        console.log("ThisHitToken", this.hitToken);
      }
    );

      this.hitCookieService.Hit_CVisitIDUpated.subscribe(
        (idVisit)=>{
          this.hitVisit=this.hitCookieService.getHit_CVisitID();
        }
      );



  }



  AirSell(TrackerID){


    this.HitLoginState = this.HitAuth.getLoginState();
    //console.log("JUST got state from review component", this.HitLoginState);
    //this.loading=true;
    this.loadingService.setloading(true);
    let Adults = localStorage.getItem("Adults");
    let Children = localStorage.getItem("Children");
    let Infants = localStorage.getItem("Infants");

    this.PaxAdults = +Adults;
    this.PaxChildren = +Children;
    this.PaxInfant = +Infants;

    if(this.PaxAdults>0){
      this.AdtPax=true;
    }else{
      this.AdtPax=false;
    }

    if(this.PaxChildren>0){
      this.ChPax=true;
    }else{
      this.ChPax=false;
    }

    if(this.PaxInfant>0){
      this.InfPax=true;
    }else{
      this.InfPax=false;
    }


    this.NoofPAX = this.PaxAdults+this.PaxChildren;
    this.NoofTravellers=this.PaxAdults+this.PaxChildren + this.PaxInfant;

    this.AdultsLists=new Array(this.PaxAdults);
    this.ChildrenList=new Array(this.PaxChildren);
    this.INfantsList=new Array(this.PaxInfant);

    this.TrackerID=TrackerID;
    this.ResponseFlight= false;




    let Sectors=localStorage.getItem("Sectors");
    let Segments = localStorage.getItem("Segments");

    let s = '{"Sectors":"'+ Sectors + '","Segments":"'+ Segments + '"}';


    //  window.alert(s);
    //let headers = new HttpHeaders().append('Content-Type','application/json; charset=utf-8');
    let params = new HttpParams().set('TrackerID', this.TrackerID); // create params object
  const httOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true,
    observe: 'response' as 'response',
    params: params
  };

    this.httpClient.post(this.apiUrl + "api/CheckFlight",s, httOptions).subscribe(
          (data:any)=> {
            //console.log(data.body);
            // let headers = data.headers;
            // //console.log(data.headers.get('ASP.NET_SessionId'))
            // //console.log(data.headers);
            // //console.log(data.Cookies);
            ////console.log(data.headers.get('authorization'));

            if(data.body=="No") {

                this.ReviewComponent=false;
                this.BookingFailedComponent=true;

            }else{

              localStorage.setItem("Tracker", data.body);

              this.ReviewComponent=true;
              this.BookingFailedComponent=false;
              // this.ReviewComponentDiv.scrollIntoView();
              // this.renderer.
              // this.renderer.scrollIntoView(this.ReviewComponentDiv);
              this.GetFareInfo();

            }
            //this.loading=false;
            this.loadingService.setloading(false);

            },
            (err)=>{
              //this.loading=false;
              this.loadingService.setloading(false);
              this.errorMessageService.seterrorMessage("Oops!! Some thing went wrong... Kindly restart searching your itinerary.")
              this.NoCooks=true;
            });

  }

  GetFareInfo(){

    let PassengerGroup = localStorage.getItem("Adults") + "-" + localStorage.getItem("Children")+"-" + localStorage.getItem("Infants");
    let SegmentGroup = localStorage.getItem("Flights");
    let Cabin = localStorage.getItem("Cabin");
    let TripType=""; let DomOrInt="";
    let BookingClass = localStorage.getItem("BookingClass");
    let DirectFlights = "false";

    let load = '{"Token":"' + localStorage.getItem('Tracker') +'"}';

    let headers = new HttpHeaders({'Authorization':'Bearer ' + localStorage.getItem('Tracker')});
    headers = headers.append('Content-Type','application/json; charset=utf-8');

    let params = new HttpParams().set('TrackerID', this.TrackerID); // create params object
    params = params.append('PassengersGroup', PassengerGroup); // add a new param, creating a new object
    params = params.append('SegmentGroup', SegmentGroup); // add a new param, creating a new object

    params = params.append('Cabin', Cabin); // add a new param, creating a new object
    params = params.append('TripType', TripType); // add a new param, creating a new object
    params = params.append('DomOrInt', DomOrInt); // add a new param, creating a new object
    //params = params.append('BookingClass', BookingClass); // add a new param, creating a new object
    params = params.append('DirectFlights', DirectFlights); // add a new param, creating a new object


    const httOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true,
      observe: 'response' as 'response',
      params: params
    };
    ////this.httpClient.post(this.apiUrl + "api/BookPNR",load, {headers:headers, params:params}).subscribe(
      //this.httpClient.post(this.apiUrl + "api/FlightInfo",load, httOptions).subscribe(

        this.httpClient.post(this.apiUrl + "api/FlightInfo",load, {headers:headers, params:params}).subscribe
        (
            (data:any)=> {
              //errorGroupField.errorWarningDescriptionField.freeTextField[0]
              if(data.errorGroupField==null){

                console.log("No Errors");

                this.FlightInfo=true;
                // console.log("FlightInfo", this.FlightInfo);
                this.FlightInfoData.push(data);
                this.getTotalCost();
                this.getFareRules();
                }else{
                  console.log("Errors");
                  this.errorMessageService.seterrorMessage(data.errorGroupField.errorWarningDescriptionField.freeTextField[0])
                  this.NoCooks=true;
                }
              //console.log(data);


              },
              (error:any) =>{
                  this.errorMessageService.seterrorMessage("No agreement on destination")
                  this.NoCooks=true;
              }
              );

  }
  getTotalCost(){
    console.log("Getting TC");

    let tc:number=0;
    for(let i=0; i<this.FlightInfoData.length;i++){
      for(let n=0;n<this.FlightInfoData[i].mainGroupField.pricingGroupLevelGroupField.length;n++){

        // mainGroupField.pricingGroupLevelGroupField[0].fareInfoGroupField.fareAmountField.otherMonetaryDetailsField[0].amountField
        let amt = this.FlightInfoData[i].mainGroupField.pricingGroupLevelGroupField[n].fareInfoGroupField.fareAmountField.otherMonetaryDetailsField[0].amountField * 1 + this.ServiceCharge;
        console.log("amt>"+n,amt);

        //mainGroupField.pricingGroupLevelGroupField[0].numberOfPaxField[0].numberOfUnitsField
        let noofpax =this.FlightInfoData[i].mainGroupField.pricingGroupLevelGroupField[n].numberOfPaxField[0].numberOfUnitsField;
        console.log("noofPAx>"+n,noofpax);
        let Tamt = amt * noofpax;
        tc = tc + Tamt;

      }

    }
    this.TotalCost=tc+"";
    this.hitOrderAmount= this.TotalCost;
  }

  getFareRules(){
    let load = '{"Token":"' + localStorage.getItem('Tracker') +'"}';
    let headers = new HttpHeaders().append('Content-Type','application/json; charset=utf-8');

    // let params = new HttpParams().set('PassengersGroup', Travellers); // create params object
    //     params = params.append('SegmentGroup', segmentInformation); // add a new param, creating a new object
    //     params = params.append('Cabin', Cabin);
    //     params = params.append('TripType', this.TripType);
    //     params = params.append('DomOrInt', this.DomOrInt);
    //     params = params.append('BookingClass', bookingClass);
    //     params = params.append('DirectFlights', this.DirectFlights.toString());

    //let newparams = new HttpParams().set('PassengersGroup', new_s);

    this.httpClient.post(this.apiUrl + "api/FlightFareRules",load, {headers: headers}).subscribe(
      //this.httpClient.post("api/Test",new_s, {headers: headers, params:params}).subscribe(
          (data:any)=>

          {
            console.log (data);
            this.FareRules=true;
            this.FareRulesData.push(data);

          },
          (err)=>{
            this.errorMessageService.seterrorMessage("Oops!! Some thing went wrong... Kindly restart searching your itinerary.")
            this.NoCooks=true;
          }
          );


  }

  prepareNames(){
    let Adults = localStorage.getItem("Adults");
    let Children = localStorage.getItem("Children");
    let Infants = localStorage.getItem("Infants");

    this.PaxAdults = +Adults;
    this.PaxChildren = +Children;
    this.PaxInfant = +Infants;
    // this.PNRADTNames=[];


    for(let i=0;i<=this.PaxAdults-1;i++){
      let a;

      a = this.AdTFirstName.toArray()[i];


    }

  }

  BookFirstNValidateNSubmit(){
    //this.loading=true;
    this.loadingService.setloading(true);
    if (!this.cookieService.get('cosmicHits')) {
      console.log('No cookie');
      this.errorMessageService.seterrorMessage("Oops!! Your session Expired... Kindly restart searching your itinerary.")
      this.NoCooks=true;
      //this.loading=false;
      this.loadingService.setloading(false);
    }else{





    let formTrue= true;
      // //console.log(this.PNRADTNames[0]);

        for(let i=0; i<this.PaxAdults;i++){

          var val = this.PNRADTNames[i].PaxTitle;

          if(val.length==0){
            this.renderer.setStyle(this.adtTErr.toArray()[i].nativeElement,'display','block');
            //console.log("V: "+val.length);
            formTrue=false;
          }

          var val = this.PNRADTNames[i].PaxFirstName;
          if(val.length<2){
            this.renderer.setStyle(this.adtFNErr.toArray()[i].nativeElement,'display','block');
            formTrue=false;
          }

          var val = this.PNRADTNames[i].PaxFirstName;
          if(val.length<2){
            this.renderer.setStyle(this.adtSNErr.toArray()[i].nativeElement,'display','block');
            formTrue=false;
          }

        }

        for(let i=0; i<this.PaxInfant;i++){

          var val = this.PNRINFNames[i].PaxTitle;

          if(val.length==0){
            this.renderer.setStyle(this.infTErr.toArray()[i].nativeElement,'display','block');

            formTrue=false;
          }

          var val = this.PNRINFNames[i].PaxFirstName;
          if(val.length<2){
            this.renderer.setStyle(this.infFNErr.toArray()[i].nativeElement,'display','block');
            formTrue=false;
          }

          var val = this.PNRINFNames[i].PaxSurName;
          if(val.length<2){
            this.renderer.setStyle(this.infSNErr.toArray()[i].nativeElement,'display','block');
            formTrue=false;
          }

          var val = this.PNRINFNames[i].PaxDOB;
          if(val.length==0){
            this.renderer.setStyle(this.infDOBErr.toArray()[i].nativeElement,'display','block');
            formTrue=false;
          }

        }


        for(let i=0; i<this.PaxChildren;i++){

   //       var val = this.PNRCHDNames[i].PaxTitle;
          //console.log("Val: "+val);
          if(val.length==0){
            this.renderer.setStyle(this.chdTErr.toArray()[i].nativeElement,'display','block');
            //console.log("V: "+val.length);
            formTrue=false;
          }

          var val = this.PNRCHDNames[i].PaxFirstName;
          if(val.length<2){
            this.renderer.setStyle(this.chdFNErr.toArray()[i].nativeElement,'display','block');
            formTrue=false;
          }

          var val = this.PNRCHDNames[i].PaxSurName;
          if(val.length<2){
            this.renderer.setStyle(this.chdSNErr.toArray()[i].nativeElement,'display','block');
            formTrue=false;
          }

          var val = this.PNRCHDNames[i].PaxDOB;
          if(val.length<2){
            this.renderer.setStyle(this.chdDOBErr.toArray()[i].nativeElement,'display','block');
            formTrue=false;
          }
        }

      var val = this.PNRContact[0].Email;
        if(val.length==0){
          formTrue=false
          this.renderer.setStyle(this.EmailErr.nativeElement,'display','block');
        }

        var val = this.PNRContact[0].Mobile;
        if(val.length<10){
          formTrue=false
          this.renderer.setStyle(this.MobileErr.nativeElement,'display','block');
        }

        if(formTrue==true){

          this.hitEmail = this.PNRContact[0].Email;
          this.hitPhoneNumber = this.PNRContact[0].Mobile;
          this.hitToken= this.PNRContact[0].Email;
          this.HitLoginState=true;

          // this.LoggedUser.uid= this.PNRContact[0].Email;;
          // this.LoggedUser.email = this.PNRContact[0].Email;;

          // this.LoggedUser.phoneNumber= this.PNRContact[0].Mobile;
          // this.LoggedUser.displayName= this.PNRContact[0].Name;
          // this.LoggedUser.photoURL="";
          // this.LoggedUser.emailVerified = false;
          // this.LoggedUser.refreshToken="";

          // let u={'user':{"uid":this.PNRContact[0].Email, "email":this.PNRContact[0].Email,
          //  "phoneNumber":this.PNRContact[0].Mobile, "displayName":this.PNRContact[0].Name
          //  , "photoURL":"", "emailVerified":false, "refreshToken":""}};

          let u={"uid":this.PNRContact[0].Email, "email":this.PNRContact[0].Email,
          "phoneNumber":this.PNRContact[0].Mobile, "displayName":this.PNRContact[0].Name
          , "photoURL":"", "emailVerified":false,"password":"", "providerId":"","Hit":this.cookieService.get('cosmicHits'),
          "cosmicVisits": this.cookieService.get('cosmicVisits'),
          "stsTokenManager":{"accessToken":"","refreshToken":""}}

          //console.log("U",this.LoggedUser);
          //localStorage.setItem("User",JSON.stringify(u));
          sessionStorage.setItem("user",JSON.stringify(u));
          this.HitAuth.registerUser();
          //this.loading=false;
          this.loadingService.setloading(false);

     //     this.submitForm();
        }
      }
      // this.loading=false;
  }


  checkSession(){
    if (!this.cookieService.get('cosmicHits')) {
      console.log('No cookie');
      this.errorMessageService.seterrorMessage("Oops!! Your session Expired... Kindly restart searching your itinerary.")
      this.NoCooks=true;
    }else{
      this.NoCooks=false;
      this.ValidateNSubmit();
    }
  }

  ValidateNSubmit(){

    let formTrue= true;
      // //console.log(this.PNRADTNames[0]);

        for(let i=0; i<this.PaxAdults;i++){

          var val = this.PNRADTNames[i].PaxTitle;

          if(val.length==0){
            this.renderer.setStyle(this.adtTErr.toArray()[i].nativeElement,'display','block');
            //console.log("V: "+val.length);
            formTrue=false;
          }

          var val = this.PNRADTNames[i].PaxFirstName;
          if(val.length<2){
            this.renderer.setStyle(this.adtFNErr.toArray()[i].nativeElement,'display','block');
            formTrue=false;
          }

          var val = this.PNRADTNames[i].PaxFirstName;
          if(val.length<2){
            this.renderer.setStyle(this.adtSNErr.toArray()[i].nativeElement,'display','block');
            formTrue=false;
          }

        }

        for(let i=0; i<this.PaxInfant;i++){

          var val = this.PNRINFNames[i].PaxTitle;

          if(val.length==0){
            this.renderer.setStyle(this.infTErr.toArray()[i].nativeElement,'display','block');

            formTrue=false;
          }

          var val = this.PNRINFNames[i].PaxFirstName;
          if(val.length<2){
            this.renderer.setStyle(this.infFNErr.toArray()[i].nativeElement,'display','block');
            formTrue=false;
          }

          var val = this.PNRINFNames[i].PaxSurName;
          if(val.length<2){
            this.renderer.setStyle(this.infSNErr.toArray()[i].nativeElement,'display','block');
            formTrue=false;
          }

          var val = this.PNRINFNames[i].PaxDOB;
          if(val.length==0){
            this.renderer.setStyle(this.infDOBErr.toArray()[i].nativeElement,'display','block');
            formTrue=false;
          }

        }


        for(let i=0; i<this.PaxChildren;i++){

   //       var val = this.PNRCHDNames[i].PaxTitle;
          //console.log("Val: "+val);
          if(val.length==0){
            this.renderer.setStyle(this.chdTErr.toArray()[i].nativeElement,'display','block');
            //console.log("V: "+val.length);
            formTrue=false;
          }

          var val = this.PNRCHDNames[i].PaxFirstName;
          if(val.length<2){
            this.renderer.setStyle(this.chdFNErr.toArray()[i].nativeElement,'display','block');
            formTrue=false;
          }

          var val = this.PNRCHDNames[i].PaxSurName;
          if(val.length<2){
            this.renderer.setStyle(this.chdSNErr.toArray()[i].nativeElement,'display','block');
            formTrue=false;
          }

          var val = this.PNRCHDNames[i].PaxDOB;
          if(val.length<2){
            this.renderer.setStyle(this.chdDOBErr.toArray()[i].nativeElement,'display','block');
            formTrue=false;
          }
        }

      var val = this.PNRContact[0].Email;
        if(val.length==0){
          formTrue=false
          this.renderer.setStyle(this.EmailErr.nativeElement,'display','block');
        }

        var val = this.PNRContact[0].Mobile;
        if(val.length<10){
          formTrue=false
          this.renderer.setStyle(this.MobileErr.nativeElement,'display','block');
        }


        //console.log("formTrue: " + formTrue);
        if(formTrue==true){
          this.submitForm();
        }


  }

  createOrder(){
    //this.loading=true;
    this.loadingService.setloading(true);
    if (!this.cookieService.get('cosmicHits')) {
      console.log('No cookie');
      this.errorMessageService.seterrorMessage("Oops!! Your session Expired... Kindly restart searching your itinerary.")
      this.NoCooks=true;
      //this.loading=false;
      this.loadingService.setloading(false);
    }else{

    //first create order
//    this.hitOrderAmount="5956";


    let load = '{"Hit":"' + this.cookieService.get('cosmicHits') +'","HitVisit": "' + this.cookieService.get('cosmicVisits')
    + '","hitToken": "' + this.hitToken + '","Email": "' + this.hitEmail + '","PhoneNumber":"' + this.hitPhoneNumber
    + '","OrderAmount": "'+ this.hitOrderAmount+ '"}';
        ////console.log(load);

    let headers = new HttpHeaders({'Authorization':'Bearer ' + localStorage.getItem('Tracker')});
                    headers = headers.append('Content-Type','application/json; charset=utf-8');

      let params = new HttpParams().set('TrackerID', this.TrackerID); // create params object
      const httOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        withCredentials: true,
        observe: 'response' as 'response',
        //params: params
      };

    this.httpClient.post(this.apiUrl + "api/TicketOrder",load, {headers:headers, params:params}).subscribe(
      (data:any)=>
      {
        //this.loading=false;
        this.loadingService.setloading(false);
        console.log(data);
        this.hitIdOrder = data;
        this.hitVisit = this.hitCookieService.getHit_CVisitID();
        this.submitForm()
       // let err="N";
      //  this.FarePrice();
      },
      (err)=>{
        //this.loading=false;
        this.loadingService.setloading(false);

        this.errorMessageService.seterrorMessage("Oops!! Some thing went wrong... Kindly restart searching your itinerary.")
        this.NoCooks=true;
      }
      );
    }


    //this.submitform();
  }
  submitForm(){
    ////console.log("Submitted");

    //console.log(this.PNRADTNames);
    let ADTDetails="";
    for(let i=0; i<this.PNRADTNames.length; i++){
      if(i==0){
        ADTDetails =  this.PNRADTNames[i].PaxTitle + "," + this.PNRADTNames[i].PaxFirstName + "," + this.PNRADTNames[i].PaxSurName ;
      }else{
        ADTDetails = ADTDetails+"|" + this.PNRADTNames[i].PaxTitle + "," + this.PNRADTNames[i].PaxFirstName + "," + this.PNRADTNames[i].PaxSurName ;
      }

    }

    let INFDetails="";
    for(let i=0; i<this.PNRINFNames.length; i++){
      if(i==0){
        INFDetails =  this.PNRINFNames[i].PaxTitle + "," + this.PNRINFNames[i].PaxFirstName + "," + this.PNRINFNames[i].PaxSurName  + "," + this.PNRINFNames[i].PaxDOB ;
      }else{
        INFDetails = INFDetails+"|" + this.PNRINFNames[i].PaxTitle + "," + this.PNRINFNames[i].PaxFirstName + "," + this.PNRINFNames[i].PaxSurName  + "," + this.PNRINFNames[i].PaxDOB ;
      }

    }


    let CHDDetails="";
    for(let i=0; i<this.PNRCHDNames.length; i++){
      if(i==0){
        CHDDetails =  this.PNRCHDNames[i].PaxTitle + "," + this.PNRCHDNames[i].PaxFirstName + "," + this.PNRCHDNames[i].PaxSurName  + "," + this.PNRCHDNames[i].PaxDOB ;
      }else{
        CHDDetails = CHDDetails+"|" + this.PNRCHDNames[i].PaxTitle + "," + this.PNRCHDNames[i].PaxFirstName + "," + this.PNRCHDNames[i].PaxSurName  + "," + this.PNRCHDNames[i].PaxDOB ;
      }

    }

    let ContactDetails="";
    ContactDetails= this.PNRContact[0].Email + "," + this.PNRContact[0].Mobile;




    let load = '{"Token":"' + localStorage.getItem('Tracker') +'","ADTDetails": "' + ADTDetails + '","CHDDetails": "'
    + CHDDetails + '","INFDetails": "' + INFDetails + '","ContactDetails":"' +  ContactDetails + '"}';


    //let load=  '{"Token":"' + localStorage.getItem('Tracker') +'"}' ;
    //this.Token.push(new_s);
    //load.push(this.Token);
    ////console.log(load);

    let headers = new HttpHeaders({'Authorization':'Bearer ' + localStorage.getItem('Tracker')});
                    headers = headers.append('Content-Type','application/json; charset=utf-8');

      let params = new HttpParams().set('TrackerID', this.TrackerID); // create params object
      const httOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        withCredentials: true,
        observe: 'response' as 'response',
        //params: params
      };


      this.httpClient.post(this.apiUrl + "api/BookPNR",load, {headers:headers, params:params}).subscribe(
            (data:any)=>
            {
              //console.log(data);
              let err="N";
              this.FarePrice();
            },
            (err)=>{
              this.errorMessageService.seterrorMessage("Oops!! Some thing went wrong... Kindly restart searching your itinerary.")
              this.NoCooks=true;
            }
            );

  }

  FarePrice(){
    ////console.log("FarePricing");
    let load = '{"Token":"' + localStorage.getItem('Tracker') +'", "Carrier":"' + localStorage.getItem('Carrier') + '" }';
    let headers = new HttpHeaders({'Authorization':'Bearer ' + localStorage.getItem('Tracker')});
    headers = headers.append('Content-Type','application/json; charset=utf-8');
    let params = new HttpParams().set('TrackerID', this.TrackerID); // create params object
    const httOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true,
      observe: 'response' as 'response',
      //params: params
      };


      this.httpClient.post(this.apiUrl + "api/FarePricePNR",load, {headers:headers, params:params}).subscribe(
      (data:any)=>
      {

          //console.log(data);
          this.ShowPaymentDiv=true;
          this.ReviewComponent=false;
          this.ShowPaymentDivData.push(data);
          this.getTotalCostFinal();
          //let err="N";
          // this.savePNR();

      },
      (err)=>{
        this.errorMessageService.seterrorMessage("Oops!! Some thing went wrong... Kindly restart searching your itinerary.")
        this.NoCooks=true;
      }
      );

  }

  getTotalCostFinal(){
    let tc:number=0;
    console.log("Getting");

    for(let i=0; i<this.ShowPaymentDivData.length;i++){
      for(let n=0;n<this.ShowPaymentDivData[i].fareListField.length;n++){

        // fareListField.fareDataInformationField.fareDataSupInformationField[1].fareAmountField
        //fareListField[0].fareDataInformationField.fareDataSupInformationField[1].fareAmountField
        let amt = this.ShowPaymentDivData[i].fareListField[n].fareDataInformationField.fareDataSupInformationField[1].fareAmountField * 1 + this.ServiceCharge;


        //mainGroupField.pricingGroupLevelGroupField[0].numberOfPaxField[0].numberOfUnitsField
        let noofpax =0;
        if(n==0){
          noofpax = this.PaxAdults;
        }else if (n==1){
          noofpax = this.PaxChildren;
        }else{
          noofpax = this.PaxInfant;
        }

        let Tamt = amt * noofpax;
        tc = tc + Tamt;

      }

    }
    this.TotalCost=tc+"";
    this.hitOrderAmount= this.TotalCost;

  }

  PNRCheck(sequence){
    let load = '{"Token":"' + localStorage.getItem('Tracker') +'", "PNR":"'+ this.PNR_locator +'"}';

    let headers = new HttpHeaders({'Authorization':'Bearer ' + localStorage.getItem('Tracker')});
    headers = headers.append('Content-Type','application/json; charset=utf-8');
    let params = new HttpParams().set('TrackerID', this.TrackerID); // create params object
    const httOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true,
      observe: 'response' as 'response',
      //params: params
      };


      this.httpClient.post(this.apiUrl + "api/PNR",load, {headers:headers, params:params}).subscribe(
      (data:any)=>
      {
          //console.log(data);
          if(sequence=="1"){
          //let err="N";
          if(data.originDestinationDetailsField[0].itineraryInfoField[0].relatedProductField.statusField=="HK"){
            this.IssueTicket();

          }else{
            //console.log ("Segment Not OK");
          }
        }else if (sequence==2){
          this.ShowPaymentDiv=false;
          this.ShowTicketDiv=true;
          this.ShowTicketDivData.push(data);
          console.log ("PNR>>>",this.ShowPaymentDivData);
        }

      },
      (err)=>{
        this.errorMessageService.seterrorMessage("Oops!! Some thing went wrong... Kindly restart searching your itinerary.")
        this.NoCooks=true;
      }
      );


  }

  IssueTicket(){

    let load = '{"Token":"' + localStorage.getItem('Tracker') +'", "PNR":"'+ this.PNR_locator +'","Pax":"'+ this.PaxAdults + '-'
    + this.PaxChildren + '-' + this.PaxInfant + '"}';

    let headers = new HttpHeaders({'Authorization':'Bearer ' + localStorage.getItem('Tracker')});
    headers = headers.append('Content-Type','application/json; charset=utf-8');
    let params = new HttpParams().set('TrackerID', this.TrackerID); // create params object
    const httOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true,
      observe: 'response' as 'response',
      //params: params
      };


      this.httpClient.post(this.apiUrl + "api/Ticketing",load, {headers:headers, params:params}).subscribe(
      (data:any)=>
      {
          //console.log(data);
          //let err="N";
          //if(data.originDestinationDetailsField[0].itineraryInfoField[0].relatedProductField.statusField=="HK"){
            //this.IssueTicket();

          //}else{
          //   //console.log ("Segment Not OK");
          // }
          this.PNRCheck(2);

      },
      (err)=>{
        this.errorMessageService.seterrorMessage("Oops!! Some thing went wrong... Kindly restart searching your itinerary.")
        this.NoCooks=true;
      }
      );
  }
  savePNR(){
    let load = '{"Token":"' + localStorage.getItem('Tracker') +'","HitVisit":"'+this.hitCookieService.getHit_CVisitID()+'"}';

    let headers = new HttpHeaders({'Authorization':'Bearer ' + localStorage.getItem('Tracker')});
    headers = headers.append('Content-Type','application/json; charset=utf-8');
    let params = new HttpParams().set('TrackerID', this.TrackerID); // create params object
    const httOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true,
      observe: 'response' as 'response',
      //params: params
      };


      this.httpClient.post(this.apiUrl + "api/PNRValidation",load, {headers:headers, params:params}).subscribe(
      (data:any)=>
      {
          //console.log(data);
          this.PNR_locator=this.PNR_locator=data.pnrHeaderField[0].reservationInfoField[0].controlNumberField;
          //let err="N";

          setTimeout(function(){
            //console.log("Ready")
            //retrieve PNR here after 10 seconds
          //console.log(this.PNR_locator=data.pnrHeaderField[0].reservationInfoField[0].controlNumberField);


        }, 10000);
        this.PNRCheck(1);
      },
      (err)=>{
        this.errorMessageService.seterrorMessage("Oops!! Some thing went wrong... Kindly restart searching your itinerary.")
        this.NoCooks=true;
      }
      );
  }

  getPhoneLogin(){
    //this.PhoneNumberLoginChoosen=true;
    if(this.PNRContact[0].Mobile.length==10){
      console.log("10");
      this.PhoneNumberLoginChoosen=true;
      sessionStorage.setItem("PhoneLoginNumber",this.PNRContact[0].Mobile);
    this.PhoneNumberLogin=true;
    }else{
      console.log("No10");
      this.PhoneNumberLoginChoosen=false;
      sessionStorage.removeItem("PhoneLoginNumber");
    this.PhoneNumberLogin=false;
    }

  }

  makePayment(){
    if (!this.cookieService.get('cosmicHits')) {
      console.log('No cookie');
      this.errorMessageService.seterrorMessage("Oops!! Your session Expired... Kindly restart searching your itinerary.")
      this.NoCooks=true;
    }else{

    //first create order
    this.hitOrderAmount="5956";


    let load = '{"Hit":"' + this.cookieService.get('cosmicHits') +'","HitVisit": "' + this.cookieService.get('cosmicVisits')
    + '","hitToken": "' + this.hitToken + '","Email": "' + this.hitEmail + '","PhoneNumber":"' + this.hitPhoneNumber
    + '","OrderAmount": "'+ this.hitOrderAmount+ '"}';
        ////console.log(load);

    let headers = new HttpHeaders({'Authorization':'Bearer ' + localStorage.getItem('Tracker')});
                    headers = headers.append('Content-Type','application/json; charset=utf-8');

      let params = new HttpParams().set('TrackerID', this.TrackerID); // create params object
      const httOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        withCredentials: true,
        observe: 'response' as 'response',
        //params: params
      };
//https://localhost:44317/ccAve/ccavRequestHandler.aspx
    this.httpClient.post(this.apiUrl + "ccAve/ccavRequestHandler.aspx",load, {headers:headers, params:params}).subscribe(
      (data:any)=>
      {
        console.log(data);
        this.submitForm()
       // let err="N";
      //  this.FarePrice();
      },
      (err)=>{
        this.errorMessageService.seterrorMessage("Oops!! Some thing went wrong... Kindly restart searching your itinerary.")
        this.NoCooks=true;
      }
      );
    }


  }

  CheckPayment(){
    console.log("HitEmail:>>", this.HitAuth.getemail());
    console.log("HitVisit", this.cookieService.get('cosmicVisits'));
    console.log("HHitVisit", this.hitCookieService.getHit_CVisitID());

    //,"HitVisit","'+this.cookieService.get('cosmicVisits')+'"
    let load = '{"Hit":"' + this.cookieService.get('cosmicHits') +'" ,"HitVisit":"'+this.hitCookieService.getHit_CVisitID()+'"}';

    let headers = new HttpHeaders({'Authorization':'Bearer ' + localStorage.getItem('Tracker')});
    headers = headers.append('Content-Type','application/json; charset=utf-8');
    let params = new HttpParams().set('TrackerID', this.TrackerID); // create params object
    const httOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true,
      observe: 'response' as 'response',
      //params: params
      };


      this.httpClient.post(this.apiUrl + "api/GenReceipt",load, {headers:headers}).subscribe(
      (data:any)=>
      {

        console.log("Data",data);
        this.PaymentStatus=true;
        this.PaymentStatusData.push(data);
        if(data.Status=="Success"){
          this.PaymentSuccess=true;

        }else{
          this.PaymentSuccess=false;
        }


        // if(data=="Success"){
        //   console.log("Success");
        //   this.CreateTicket();
        // }else{
        //   console.log("False Success");
        // }
      },
      (err)=>{
        this.errorMessageService.seterrorMessage("Oops!! Some thing went wrong... Kindly restart searching your itinerary.")
        this.NoCooks=true;
      }
      );

  }

  checkClick(){
     //clear payment first
    //console.log("Create Ticket");
    let load = '{"Token":"' + localStorage.getItem('Tracker') +'" ,"Pax":"'+ this.PaxAdults + '-'
    + this.PaxChildren + '-' + this.PaxInfant + '"}';

    let headers = new HttpHeaders({'Authorization':'Bearer ' + localStorage.getItem('Tracker')});
    headers = headers.append('Content-Type','application/json; charset=utf-8');
    let params = new HttpParams().set('TrackerID', this.TrackerID); // create params object
    const httOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true,
      observe: 'response' as 'response',
      //params: params
      };


      this.httpClient.post(this.apiUrl + "api/Ticket",load, {headers:headers, params:params}).subscribe(
      (data:any)=>
      {
          //console.log(data);
          //let err="N";
          this.savePNR();
      },
      (err)=>{
        this.errorMessageService.seterrorMessage("Oops!! Some thing went wrong... Kindly restart searching your itinerary.")
        this.NoCooks=true;
      }
      );
  }

  CreateTicket(){

    //clear payment first
    //console.log("Create Ticket");
    let load = '{"Token":"' + localStorage.getItem('Tracker') +'" ,"Pax":"'+ this.PaxAdults + '-'
    + this.PaxChildren + '-' + this.PaxInfant + '"}';

    let headers = new HttpHeaders({'Authorization':'Bearer ' + localStorage.getItem('Tracker')});
    headers = headers.append('Content-Type','application/json; charset=utf-8');
    let params = new HttpParams().set('TrackerID', this.TrackerID); // create params object
    const httOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true,
      observe: 'response' as 'response',
      //params: params
      };


      this.httpClient.post(this.apiUrl + "api/Ticket",load, {headers:headers, params:params}).subscribe(
      (data:any)=>
      {
          //console.log(data);
          //let err="N";
          this.savePNR();
      },
      (err)=>{
        this.errorMessageService.seterrorMessage("Oops!! Some thing went wrong... Kindly restart searching your itinerary.")
        this.NoCooks=true;
      }
      );
  }

  AddPNR(){
    let new_s=  '{"Token":"' + localStorage.getItem('Tracker') +'"}' ;
    let new_ss=   localStorage.getItem('Tracker') ;
    ////console.log (new_s);

    let newparams = new HttpParams().set('PassengersGroup', new_s);
    let headers = new HttpHeaders({'Authorization':'Bearer ' + localStorage.getItem('Tracker')});
                  headers = headers.append('Content-Type','application/json; charset=utf-8');

    let params = new HttpParams().set('TrackerID', this.TrackerID); // create params object
    const httOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true,
      observe: 'response' as 'response',
      //params: params
    };


    this.httpClient.post(this.apiUrl + "api/BookPNR",new_s, {headers:headers, params:params}).subscribe(
          (data:any)=>
          {
            //console.log(data);
            let err="N";
          },
          (err)=>{
            this.errorMessageService.seterrorMessage("Oops!! Some thing went wrong... Kindly restart searching your itinerary.")
            this.NoCooks=true;
          }
          );


  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>,pax,num,FD) {
    if(event.value!=null){


      //console.log(event.value);
      FD = event.value.toString();
      let dtDay = "";
      let dtMonth = "";
      let dtYearN = "";


      //var D = event.value.substr(0, 2);

      dtDay = FD.substr(8, 2) ;
    //  //console.log(dtDay);
      dtMonth = FD.substr(4, 3) ;
//      //console.log(dtMonth);
      dtYearN = FD.substr(13,2);
      ////console.log(dtYearN);
      let dt = dtDay +"" + dtMonth + "" + dtYearN;


      if(pax=="C"){
        ////console.log(this.minDateChd);
        this.PNRCHDNames[num].PaxDOB = dt;
          //console.log(this.PNRCHDNames);
          this.renderer.setStyle(this.chdDOBErr.toArray()[num].nativeElement,'display','none');

      }
      if(pax=="I"){
        ////console.log(this.minDateChd);
        this.PNRINFNames[num].PaxDOB = dt;
        //console.log(this.PNRINFNames);
        this.renderer.setStyle(this.infDOBErr.toArray()[num].nativeElement,'display','none');

      }
    }

  }

  ValidateDate(pax,val, num){
    //this.renderer.setStyle(this.errorSelectCityF.toArray()[i].nativeElement,'display','block');
    // //console.log ("Pax: "+pax +"dt: "+val);

    var D = val.substr(0, 2);
    var M = val.substr(3, 2);
    var Y = val.substr(6, 4);
    var f = new Date(Y, M-1 , D)


    moment.locale('en-GB');
    var date = moment(Y+"-"+M+"-"+D);
    // //console.log(date);
    var check = date.isValid();

     if(check==true){ // if date is valid then
     let minDate;
     let maxDate;
     let minchk;
     let maxchk;
     let DtOk;
      let dt="";
      if(M=="01"){
        dt=D + "Jan"+Y;
      }
      if(M=="02"){
        dt=D + "Feb"+Y;
      }
      if(M=="03"){
        dt=D + "Mar"+Y;
      }
      if(M=="04"){
        dt=D + "Apr"+Y;
      }
      if(M=="05"){
        dt=D + "May"+Y;
      }
      if(M=="06"){
        dt=D + "Jun"+Y;
      }
      if(M=="07"){
        dt=D + "Jul"+Y;
      }
      if(M=="08"){
        dt=D + "Aug"+Y;
      }
      if(M=="09"){
        dt=D + "Sep"+Y;
      }
      if(M=="10"){
        dt=D + "Oct"+Y;
      }
      if(M=="11"){
        dt=D + "Nov"+Y;
      }

      if(M=="12"){
        dt=D + "Dec"+Y;
      }



        if (pax=="I"){  //if infant
          minchk  = date.diff(this.minDateInf, 'days');
          maxchk = date.diff(this.currentdate, 'days');
          if(minchk>=0 && maxchk<=0){
            DtOk="OK";
            this.PNRINFNames[num].PaxDOB = dt;
            this.renderer.setStyle(this.infDOBErr.toArray()[num].nativeElement,'display','none');


          }else{
            DtOk="NOTOK";
            this.renderer.setStyle(this.infDOBErr.toArray()[num].nativeElement,'display','block');
          }
        }
        if (pax=="C"){
          minchk  = date.diff(this.minDateChd, 'days');
          maxchk = date.diff(this.maxDateChd, 'days');
          // //console.log("Min: " +minchk);
          // //console.log("Max: " +maxchk);

          if(minchk>=0 && maxchk<=0){
            DtOk="OK";
            this.renderer.setStyle(this.chdDOBErr.toArray()[num].nativeElement,'display','none');

          }else{
            DtOk="NOTOK";
            this.renderer.setStyle(this.chdDOBErr.toArray()[num].nativeElement,'display','block');
          }
        }

            if(DtOk=="OK"){
              //console.log("Valid");
            }else{
              //console.log("Invalid Date");
            }

      }else{
       //console.log("Invalid Date");
     }


// //console.log(num);

  }
}
