import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getTotalTaxFromFareInfo'
})
export class GetTotalTaxFromFareInfoPipe implements PipeTransform {
//transform(value: any, flightIndex: number, GroupNo:number): any {

  transform(value: any, ...args: any[]): string {
    let TotalTaxAmount:number=0;

    //fareListField[0].taxInformationField[0].amountDetailsField.fareDataMainInformationField.fareAmountField



    for(let i=0; i<=value.length-1; i++){
      for (let n=0; n<value[i].taxInformationField.length;n++){
        let amount=value[i].taxInformationField[n].amountDetailsField.fareDataMainInformationField.fareAmountField;
        let amt =parseInt(amount, 10);
        // console.log("TaxAmount:" + amount);
        TotalTaxAmount = TotalTaxAmount+amt;
      }
      // TotalTaxAmount = value[i].taxInformationField[0].amountDetailsField.fareDataMainInformationField.fareAmountField

    }
    let TotalTaxAmounttodisplay:string= TotalTaxAmount.toString();
    return TotalTaxAmounttodisplay;
  }

}
