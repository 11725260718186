<!-- <app-wrapper></app-wrapper> -->
<app-head-section></app-head-section>
<div class="ResultWrapper container-fluid">
    <div #ReviewComponentDiv id="ReviewComponent">
        <div class="contentBar">
            <div style="display: grid">
                <div class="m-auto spinner-border text-primary" role="status" *ngIf="loading">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div id="ReviewComponenta" *ngIf="ReviewComponent">
                <!-- Showing Review Details -->
                <div *ngIf="BookingFailedComponent == true">
                    <div class="card">
                        <div class="AdtPax" *ngIf="AdtPax">
                            Booking failed. kindly mofify the search or search again.
                        </div>
                        <div class="card" *ngIf="sessionError">
                            Session expired. kindly restart the search.
                        </div>
                    </div>
                </div>



                <div *ngIf="FlightInfo == true">
                    <div class="AdtPax">
                        <mat-tab-group>
                            <mat-tab label="Flight Info">
                                <div *ngFor="let FlightInfo of FlightInfoData">
                                    <div *ngFor="
                      let pricingGroupLevelGroupField of FlightInfo
                        .mainGroupField.pricingGroupLevelGroupField
                        | slice: 0:1;
                      let priceIndex = index
                    ">
                                        <div class="card FlightDetails m-2" *ngFor="
                        let segmentLevelGroupField of pricingGroupLevelGroupField
                          .fareInfoGroupField.segmentLevelGroupField
                      ">
                                            <div>
                                                <div class="row InfoFlight">
                                                    <div class="cols col-sm-12 col-md-10">
                                                        <img style="width: 100px" src="../../../assets/airlines/{{
                                segmentLevelGroupField.segmentInformationField
                                  .companyDetailsField.marketingCompanyField
                              }}.png" /><br/> {{ segmentLevelGroupField.segmentInformationField .companyDetailsField.marketingCompanyField }} -{{ segmentLevelGroupField.segmentInformationField .flightIdentificationField.flightNumberField
                                                        }}
                                                    </div>

                                                    <div class="cols col-sm-12 col-md-2">
                                                        {{ segmentLevelGroupField.segmentInformationField .flightIdentificationField.bookingClassField }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="row">
                                                    <div class="cols col-sm-4 al-r">
                                                        <div class="HType1">
                                                            {{ segmentLevelGroupField.segmentInformationField .boardPointDetailsField.trueLocationIdField }}
                                                        </div>

                                                        <!-- Dep. Date: {{pricingGroupLevelGroupField.fareInfoGroupField.pricingIndicatorsField.productDateTimeDetailsField.departureDateField}}<br> -->
                                                        <!-- Dep. Time: {{pricingGroupLevelGroupField.fareInfoGroupField.pricingIndicatorsField.productDateTimeDetailsField.departureTimeField}}<br> -->

                                                        Departure Date: {{ segmentLevelGroupField.segmentInformationField .flightDateField.departureDateField | displayDateTime: "Date"}}
                                                        <br /> Departure Time: {{ segmentLevelGroupField.segmentInformationField .flightDateField.departureTimeField | displayDateTime: "Time" }}
                                                        <br />
                                                    </div>
                                                    <div class="cols col-sm-4">
                                                        <!-- Baggage: -->
                                                        <div class="HType1">
                                                            {{ segmentLevelGroupField.segmentInformationField .boardPointDetailsField.trueLocationIdField }}- {{ segmentLevelGroupField.segmentInformationField .offpointDetailsField.trueLocationIdField }}
                                                        </div>
                                                        {{ segmentLevelGroupField.baggageAllowanceField .baggageDetailsField.freeAllowanceField }} {{ segmentLevelGroupField.baggageAllowanceField.baggageDetailsField.quantityCodeField | getBaggageUnit}}

                                                        <br /> Pax Type: {{ segmentLevelGroupField.ptcSegmentField .quantityDetailsField.numberOfUnitField }} {{ segmentLevelGroupField.ptcSegmentField .quantityDetailsField.unitQualifierField }}
                                                        <br />
                                                    </div>
                                                    <div class="cols col-sm-4 al-l">
                                                        <div class="HType1">
                                                            {{ segmentLevelGroupField.segmentInformationField .offpointDetailsField.trueLocationIdField }}
                                                        </div>

                                                        <!-- Arrival On: {{segmentLevelGroupField.additionalInformationField.productDateTimeDetailsField.arrivalDateField}}<br>
                          Arrival Time: {{segmentLevelGroupField.additionalInformationField.productDateTimeDetailsField.arrivalDateField}}<br> -->
                                                        Arrival Date: {{ segmentLevelGroupField.segmentInformationField .flightDateField.arrivalDateField | displayDateTime: "Date"}}
                                                        <br /> Arrival Time: {{ segmentLevelGroupField.segmentInformationField .flightDateField.arrivalTimeField | displayDateTime: "Time"}}
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>

                                            <br />
                                        </div>
                                        <div class="card m-2 ">
                                            <div class="InfoFlight">Other Details</div>
                                            <ul>
                                                <li>
                                                    Unique Ref: {{ pricingGroupLevelGroupField.fareInfoGroupField .offerReferencesField.offerIdentifierField .uniqueOfferReferenceField }}
                                                </li>


                                                <li *ngFor="let textDataField of pricingGroupLevelGroupField
                                                    .fareInfoGroupField.textDataField
                          ">
                                                    {{ textDataField.freeTextField[0] }}<br />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </mat-tab>




                            <mat-tab label="Fares">
                                <div style="display: grid;
                                grid-template-columns: 1fr 1fr 1fr 1fr;
                                padding: 8px;
                                grid-gap: 5px;" *ngFor="let FlightInfo of FlightInfoData">
                                    <div *ngFor="
                      let pricingGroupLevelGroupField of FlightInfo
                        .mainGroupField.pricingGroupLevelGroupField; let PaxType = index
                    ">
                                        <h2>{{ PaxType == '0' ? 'Adult': PaxType=='1' ? 'Child':'Infant'}} </h2>
                                        <!-- | slice: 0:1 -->
                                        <!-- pricingGroupLevelGroupField[0] is adult  -->
                                        <!-- pricingGroupLevelGroupField[1] is children  -->
                                        <!-- pricingGroupLevelGroupField[2] is Infant  -->



                                        Base Fare: {{ pricingGroupLevelGroupField.fareInfoGroupField .fareAmountField.monetaryDetailsField.amountField }}

                                        <!-- <br> Total Fare: {{ pricingGroupLevelGroupField.fareInfoGroupField .fareAmountField.otherMonetaryDetailsField[0] .amountField }} -->


                                        <div *ngFor="
                        let fareComponentDetailsGroupField of pricingGroupLevelGroupField
                          .fareInfoGroupField.fareComponentDetailsGroupField
                            ">


                                            <!-- Monetary: {{ fareComponentDetailsGroupField.monetaryInformationField .monetaryDetailsField.amountField }} -->
                                            <!-- <br /> -->
                                        </div>
                                        <!-- Unique Ref: {{pricingGroupLevelGroupField.fareInfoGroupField.offerReferencesField.offerIdentifierField.uniqueOfferReferenceField}}<br> -->
                                        <!-- Dep. Date: {{pricingGroupLevelGroupField.fareInfoGroupField.pricingIndicatorsField.productDateTimeDetailsField.departureDateField}}<br>
                  Dep. Time: {{pricingGroupLevelGroupField.fareInfoGroupField.pricingIndicatorsField.productDateTimeDetailsField.departureTimeField}}<br> -->

                                        Surcharges:<br />
                                        <div *ngFor="
                        let taxDetailsField of pricingGroupLevelGroupField
                          .fareInfoGroupField.surchargesGroupField
                          .taxesAmountField.taxDetailsField
                      ">
                                            {{ taxDetailsField.typeField[0] }}-- {{ taxDetailsField.rateField }}<br />
                                        </div>
                                        <div>Sc--{{ServiceCharge}}
                                            <br> Total Fare: {{ pricingGroupLevelGroupField.fareInfoGroupField.fareAmountField.otherMonetaryDetailsField[0].amountField * 1 + ServiceCharge}} X Nooof Pax {{ pricingGroupLevelGroupField.numberOfPaxField[0].numberOfUnitsField}}
                                        </div>

                                        <div>Total: {{ pricingGroupLevelGroupField.fareInfoGroupField .fareAmountField.otherMonetaryDetailsField[0] .amountField * pricingGroupLevelGroupField.numberOfPaxField[0].numberOfUnitsField + ServiceCharge * pricingGroupLevelGroupField.numberOfPaxField[0].numberOfUnitsField
                                            }} </div>
                                    </div>
                                    <div><strong>Total Amount Payable</strong> {{TotalCost}}</div>
                                </div>

                            </mat-tab>

                            <mat-tab label="Free Baggage">
                                <div *ngFor="let FlightInfo of FlightInfoData">
                                    <div *ngFor="
                      let pricingGroupLevelGroupField of FlightInfo
                        .mainGroupField.pricingGroupLevelGroupField
                    ">
                                        <!-- | slice: 0:1 -->
                                        <div class="card FlightDetails InfoFlight" *ngFor="
                        let segmentLevelGroupField of pricingGroupLevelGroupField
                          .fareInfoGroupField.segmentLevelGroupField
                      ">
                                            Baggage: {{ segmentLevelGroupField.baggageAllowanceField .baggageDetailsField.freeAllowanceField }} {{ segmentLevelGroupField.baggageAllowanceField .baggageDetailsField.quantityCodeField | getBaggageUnit}}
                                            <br /> Pax Type: {{ segmentLevelGroupField.ptcSegmentField .quantityDetailsField.numberOfUnitField }} {{ segmentLevelGroupField.ptcSegmentField .quantityDetailsField.unitQualifierField }}
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </mat-tab>
                        </mat-tab-group>
                    </div>
                </div>

                <div *ngIf="ReviewComponent">
                    <!-- Adults: {{PaxAdults}} <br>
        Children: {{PaxChildren}} <br>
        Infants: {{InfPax}} <br> -->
                    <form #PNRForm="ngForm">
                        <div class="card">
                            <div class="AdtPax" *ngIf="AdtPax">
                                <div class="form-group Pax" *ngFor="let item of AdultsLists; let a = index">
                                    Adult {{ a + 1 }}
                                    <div>
                                        <mat-form-field class="example-full-width" appearance="fill">
                                            <mat-label>Title</mat-label>
                                            <select matNativeControl required #AdtTitle (change)="onTitleSelected('A', AdtTitle.value, a)" class="">
                        <option value="">Title</option>
                        <option value="Mr">Mr</option>
                        <option value="Ms">Ms</option>
                        <option value="Mstr">Mrs</option>
                      </select>
                                        </mat-form-field>
                                        <div #adtTErr class="err">Select Title</div>
                                    </div>
                                    <div>
                                        <mat-form-field class="example-full-width" appearance="fill">
                                            <mat-label>First Name</mat-label>
                                            <input matInput required appHitsAlphabetOnly class="" placeholder="First Name/ Given Name" type="text" #AdtFirstName (change)="onFirstNameChange('A', AdtFirstName.value, a)" />
                                        </mat-form-field>
                                        <div #adtFNErr class="err">Enter First Name</div>
                                    </div>
                                    <div>
                                        <mat-form-field class="example-full-width" appearance="fill">
                                            <mat-label>Surname</mat-label>
                                            <input matInput required class="" placeholder="Last Name/ Surname" appHitsAlphabetOnly type="text" #AdtSurName (change)="onSurNameChange('A', AdtSurName.value, a)" />
                                        </mat-form-field>
                                        <div #adtSNErr class="err">Enter Last Name</div>
                                    </div>
                                </div>
                            </div>

                            <div class="InfPax" *ngIf="InfPax">
                                <div class="Pax" *ngFor="let item of INfantsList; let Inf = index">
                                    Infant {{ Inf + 1 }}
                                    <div>
                                        <mat-form-field class="example-full-width" appearance="fill">
                                            <select matNativeControl required class="" #InfTitle (change)="onTitleSelected('I', InfTitle.value, Inf)">
                        <option value="">Title</option>
                        <option value="Mstr">Mstr</option>
                        <option value="Ms">Ms</option>
                      </select>
                                        </mat-form-field>
                                        <div #infTErr class="err">Select Title</div>
                                    </div>
                                    <div>
                                        <mat-form-field class="example-full-width" appearance="fill">
                                            <mat-label>First Name</mat-label>
                                            <input matInput required class="" placeholder="First Name/ Given Name" appHitsAlphabetOnly type="text" #InfFirstName (change)="
                          onFirstNameChange('I', InfFirstName.value, Inf)
                        " />
                                        </mat-form-field>
                                        <div #infFNErr class="err">Enter First Name</div>
                                    </div>
                                    <div>
                                        <mat-form-field class="example-full-width" appearance="fill">
                                            <mat-label>Sur Name</mat-label>
                                            <input matInput required class="" placeholder="Last Name/ Surname" appHitsAlphabetOnly type="text" #InfSurName (change)="onSurNameChange('I', InfSurName.value, Inf)" />
                                        </mat-form-field>
                                        <div #infSNErr class="err">Enter Sur Name</div>
                                    </div>
                                    <div>
                                        <mat-form-field class="example-full-width" appearance="fill">
                                            <mat-label>Date of Birtth</mat-label>
                                            <input #InfDOB (change)="ValidateDate('I', InfDOB.value, Inf)" placeholder="dd/mm/yyyy" [textMask]="{ mask: Nmask }" matInput [min]="minDateInf" [max]="currentdate" [matDatepicker]="picker" (dateInput)="
                          addEvent('input', $event, 'I', Inf, InfDOB.value)
                        " />
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                        <!-- <div #infDOBErr class="err">Invalid Date</div> -->
                                        <div #infDOBErr class="err">Invalid Date</div>

                                        <!-- <input matInput [min]="minDateInf" [max]="maxDateInf"  [matDatepicker]="picker">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker> -->
                                    </div>
                                    <!-- ngModel="InfantDOB" -->
                                    <!-- <div>

                <input  ng-Change="onDOBChange('sss')" class="" placeholder="Date of birth" type="text" #InfDOB >

              </div> -->
                                </div>
                            </div>
                            <div class="ChPax" *ngIf="ChPax">
                                <div class="form-group Pax" *ngFor="let item of ChildrenList; let Ch = index">
                                    Child {{ Ch + 1 }}
                                    <div>
                                        <mat-form-field class="example-full-width" appearance="fill">
                                            <mat-label>First Name</mat-label>
                                            <select matNativeControl class="" #ChTitle (change)="onTitleSelected('C', ChTitle.value, Ch)">
                        <option value="">Title</option>
                        <option value="Mstr">Mstr</option>
                        <option value="Ms">Ms</option>
                      </select>
                                        </mat-form-field>
                                        <div #chdTErr class="err">Select Title</div>
                                    </div>
                                    <div>
                                        <mat-form-field class="example-full-width" appearance="fill">
                                            <mat-label>First Name</mat-label>
                                            <input matInput class="" placeholder="First Name/ Given Name" appHitsAlphabetOnly type="text" #ChFirstName (change)="onFirstNameChange('C', ChFirstName.value, Ch)" />
                                        </mat-form-field>
                                        <div #chdFNErr class="err">Enter First Name</div>
                                    </div>
                                    <div>
                                        <mat-form-field class="example-full-width" appearance="fill">
                                            <mat-label>Sur Name</mat-label>
                                            <input matInput class="" placeholder="Sur Name" appHitsAlphabetOnly type="text" #ChSurName (change)="onSurNameChange('C', ChSurName.value, Ch)" />
                                        </mat-form-field>
                                        <div #chdSNErr class="err">Enter Sur Name</div>
                                    </div>

                                    <div>
                                        <mat-form-field class="example-full-width" appearance="fill">
                                            <mat-label>Date of Birth</mat-label>
                                            <!-- (dateChange)="addEvent('change', $event,'C',Ch)" -->
                                            <input placeholder="dd/mm/yyyy" [textMask]="{ mask: Nmask }" matInput [min]="minDateChd" [max]="maxDateChd" [matDatepicker]="Chpicker" #chDOB (change)="ValidateDate('C', chDOB.value, Ch)" (dateInput)="
                          addEvent('input', $event, 'C', Ch, chDOB.value)
                        " />
                                            <mat-datepicker-toggle matSuffix [for]="Chpicker"></mat-datepicker-toggle>
                                            <mat-datepicker #Chpicker></mat-datepicker>
                                        </mat-form-field>
                                        <div #chdDOBErr class="err">Invalid Date</div>
                                    </div>
                                </div>
                            </div>
                            <div class="ChPax">
                                <div class="ContactPax">
                                    <div>Contact Details</div>
                                    <div>
                                        <mat-form-field class="example-full-width" appearance="fill">
                                            <mat-label>Email</mat-label>
                                            <input matInput class="" placeholder="Email" type="text" #Email (change)="onEmailChange(Email.value)" />
                                        </mat-form-field>
                                        <div #EmailErr class="err">Enter Email</div>
                                    </div>
                                    <div>
                                        <mat-form-field class="example-full-width" appearance="fill">
                                            <mat-label>Mobile</mat-label>
                                            <input matInput class="" placeholder="Mobile" type="text" #Mobile (change)="onMobileChange(Mobile.value)" />
                                        </mat-form-field>
                                        <div #MobileErr class="err">Enter Mobile</div>
                                    </div>
                                </div>
                            </div>

                            <div class="ChPax submit " *ngIf="HitLoginState">

                                <!-- <app-ccave-pay></app-ccave-pay> -->


                                <!-- checkSession() -->
                                <button [disabled]="FormValid" class="btn btn-danger" (click)="createOrder()">
                                Book
                              </button>
                                <!-- <table>
                                    <tr>
                                        <td>
                                            {{HitLoginState}}
                                            <br> {{hitEmail}}
                                            <br> {{hitToken}}
                                            <br> {{hitPhoneNumber}}


                                            <br>

                                        </td>
                                    </tr>
                                </table> -->




                                <!-- (click)="AddPNR()" -->
                            </div>
                            <div style="display: grid">
                                <div class="m-auto spinner-border text-primary" role="status" *ngIf="loading">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>

                            <div class="ChPax submit" *ngIf="!HitLoginState">
                                <!-- {{HitLoginState}} -->
                                <!-- <br> {{hitEmail}} -->
                                <button [disabled]="!PhoneNumberLoginChoosen" class="btn btn-primary" *ngIf="(afAuth.authState | async) === null" (click)="getPhoneLogin()">
                                  Book as First Passenger
                                </button>



                                <!-- <button [disabled]="FormValid" class="btn btn-primary" *ngIf="(afAuth.authState | async) === null" (click)="BookFirstNValidateNSubmit()">
                              Book as First Passenger
                            </button> -->
                                <!-- (click)="AddPNR()" -->
                                <button *ngIf="(afAuth.authState | async) === null" type="button" class=" ml-2 btn btn-danger" data-toggle="modal" data-target="#LoginModalCenter">
                            Login
                          </button>
                            </div>
                            <div class="ChPax w-50" *ngIf="PhoneNumberLogin">
                                <app-phone-login></app-phone-login>
                            </div>


                            <!-- <div class="ChPax" *ngIf="ShowPaymentDiv">
          <div class="SubHead">Base Fare</div>
           <div class="GD">
            <div class="form-group Pax" *ngFor="let item of AdultsLists | slice:AdultsLists.length-1 let i=index">
              <div>Adult ({{i+1}} X 5000) </div><div>5000</div>
            </div>
            <div class="form-group Pax" *ngFor="let item of ChildrenList | slice:AdultsLists.length-1 let i=index">
              <div>Child ({{i+1}} X 5000) </div><div>5000</div>
            </div>
            <div class="form-group Pax" *ngFor="let item of INfantsList  | slice:INfantsList.length-1 let i=index">
              <div>Infant ({{i+1}} X 1500) </div><div>1500</div>
            </div>

            <div class="form-group Pax" >
              <div>Tax & Charges </div><div>1500 </div>
            </div>



           </div>

        </div> -->
                        </div>
                    </form>
                </div>
            </div>

            <div #PaymentContainer class="ChPax" *ngIf="ShowPaymentDiv">
                <span class="SubHead">Fare Details</span>

                <div *ngFor="let FareDetails of ShowPaymentDivData; let responseNo = index">

                    <div *ngFor="
              let fareListField of FareDetails.fareListField;
              let FareListNo = index
            ">
                        <div class="form-group PayDivFare">
                            <div>
                                <h2>{{ FareListNo == '0' ? 'Adult': FareListNo=='1' ? 'Child':'Infant'}}</h2>
                                <!-- fareListField[0].otherPricingInfoField[0].attributeDetailsField OTHER Notices-->
                                <!-- fareListField[0].warningInformationField (Warnings) -->
                                <table width="100%">
                                    <tr>
                                        <th>Base Fare</th>
                                        <td>{{fareListField.fareDataInformationField.fareDataSupInformationField[0].fareAmountField | currency: "INR":"symbol":"1.0-0"}} </td>
                                    </tr>
                                    <tr>
                                        <th>Surcharges</th>
                                        <td> {{fareListField.fareDataInformationField.fareDataSupInformationField[1].fareAmountField - fareListField.fareDataInformationField.fareDataSupInformationField[0].fareAmountField + this.ServiceCharge | currency: "INR":"symbol":"1.0-0"}}</td>
                                    </tr>
                                    <tr>
                                        <th>Total Fare</th>
                                        <td>{{fareListField.fareDataInformationField.fareDataSupInformationField[1].fareAmountField * 1 + this.ServiceCharge | currency: "INR":"symbol":"1.0-0"}}</td>
                                    </tr>

                                </table>

                                <!-- <strong>Base Fare</strong> -->
                                <!-- Amount -->
                                <!-- {{fareListField.fareDataInformationField.fareDataSupInformationField[0].fareAmountField}} <br> -->

                                <!--  Tax-->
                                <!-- <strong>Surcharges</strong> {{fareListField.fareDataInformationField.fareDataSupInformationField[1].fareAmountField - fareListField.fareDataInformationField.fareDataSupInformationField[0].fareAmountField + this.ServiceCharge}}<br> -->

                                <!-- Amount with Tax-->
                                <!-- <strong>Total Fare</strong> {{fareListField.fareDataInformationField.fareDataSupInformationField[1].fareAmountField * 1 + this.ServiceCharge}} -->


                                <!-- {{ PaxType == '0' ? 'Adult': PaxType=='1' ? 'Child':'Infant'}} -->

                                <!-- <hr> -->
                                <!-- {{ fareListField.paxSegReferenceField[0].refQualifierField }}  -->
                                <!-- {{ FareListNo == '0' ? 'Adult': FareListNo=='1' ? 'Child':'Infant'}} ({{ FareListNo == '0' ? PaxAdults : FareListNo =='1' ? PaxChildren : PaxInfant }} X {{ fareListField.fareComponentDetailsGroupField[0] .monetaryInformationField.monetaryDetailsField.amountField
                                }}) => {{ (fareListField.fareDataInformationField.fareDataSupInformationField[1].fareAmountField * 1 + this.ServiceCharge) * (FareListNo == '0' ? PaxAdults : FareListNo =='1' ? PaxChildren : PaxInfant)}} -->

                            </div>
                            <div class="m-auto">
                                {{ FareListNo == '0' ? 'Adult': FareListNo=='1' ? 'Child':'Infant'}} ({{ FareListNo == '0' ? PaxAdults : FareListNo =='1' ? PaxChildren : PaxInfant }} X {{ fareListField.fareDataInformationField.fareDataSupInformationField[1].fareAmountField * 1 + this.ServiceCharge
                                | currency: "INR":"symbol":"1.0-0" }})
                            </div>
                            <div class="m-auto">
                                {{ (fareListField.fareDataInformationField.fareDataSupInformationField[1].fareAmountField * 1 + this.ServiceCharge) * (FareListNo == '0' ? PaxAdults : FareListNo =='1' ? PaxChildren : PaxInfant)| currency: "INR":"symbol":"1.0-0" }}
                            </div>
                        </div>
                    </div>

                    <!-- <div class="form-group Pax">
                        <div>Tax & Charges</div>
                        <div>
                            {{ FareDetails.fareListField | getTotalTaxFromFareInfo: FareDetails.fareListField | currency: "INR":"symbol":"1.0-0" }}
                        </div>
                    </div> -->
                    <div class="form-group PayDivFareSummary">
                        <div>Total Charges</div>
                        <div>
                            <!-- {{ FareDetails.fareListField | getTotalPayableFromFareInfo: FareDetails.fareListField | currency: "INR":"symbol":"1.0-0" }} -->
                            {{ TotalCost | currency: "INR":"symbol":"1.0-0" }}
                        </div>
                    </div>
                </div>
                <div class="ChPax submit" *ngIf="HitLoginState">
                    <!-- CreateTicket() -->

                    <!-- <app-ccave-pay></app-ccave-pay> -->
                    <!-- action="https://cosmictours2021.azurewebsites.net/ccAve/ccavRequestHandler.aspx" -->
                    <!-- <form #form ngNoForm id="nonseamless" method="post" name="redirect" action="https://localhost:44317/ccAve/ccavRequestHandler.aspx"> -->
                    <form #form ngNoForm id="nonseamless" method="post" name="redirect" action="https://cosmictours2021.azurewebsites.net/ccAve/ccavRequestHandler.aspx">

                        <input type="hidden" name="tid" id="tid" readonly />
                        <input type="hidden" name="merchant_id" id="merchant_id" value="600134" /> Order ID: {{hitIdOrder}}

                        <input type="hidden" name="currency" value="INR" />
                        <input type="hidden" name="redirect_url" value="https://cosmictours2021.azurewebsites.net/ccAve/ccavResponseHandler.aspx" />
                        <input type="hidden" name="cancel_url" value="https://cosmictours2021.azurewebsites.net/ccAve/ccavResponseHandler.aspx" />
                        <input type="hidden" name="merchant_param1" value="{{hitVisit}}" />
                        <input type="hidden" name="merchant_param2" value="additional Info." />
                        <input type="hidden" name="merchant_param3" value="additional Info." />
                        <input type="hidden" name="merchant_param4" value="additional Info." />
                        <input type="hidden" name="merchant_param5" value="additional Info." />
                        <input type="hidden" name="promo_code" />
                        <input type="hidden" name="customer_identifier" value="{{hitFUID}}" />
                        <input class="btn btn-danger" type="submit" value="Checkout" />

                    </form>
                    <!-- <button [disabled]="FormValid" class="btn btn-danger" (click)="makePayment()">
                      Pay Now
                    </button> -->
                    <!-- (click)="AddPNR()" -->
                </div>







            </div>
            <div #CreateTicket class="ChPax" *ngIf="PaymentStatus">
                <div [ngClass]="{'alert alert-success':PaymentSuccess==true, 'alert alert-danger':PaymentSuccess==false}" *ngFor="let PaymentData of PaymentStatusData">
                    <h2>Transaction {{PaymentData.Status}}</h2>
                    <strong>Order ID:</strong> {{PaymentData.idOrder}}<br>
                    <strong>Amount:</strong> {{PaymentData.Amount}}<br>
                    <button *ngIf="PaymentSuccess" class="btn btn-success" (click)="checkClick()">Print your Ticket</button>
                    <div class="alert role-alert alert-danger">Kindly restart searching</div>

                </div>

            </div>

            <div #PaymentContainer class="ChPax" *ngIf="ShowTicketDiv">
                <div class="container-fluid"></div>


                <div *ngIf="ShowTicketDiv">
                    <div *ngFor="let pnrData of ShowTicketDivData">
                        <table align="center" style="
                    border: 0px solid gray;
                    font-size: 15px;
                    width: 100%;
                    padding: 8px;
                    color: black;
                  ">
                            <tr>
                                <td>

                                    <table align="center" style="
                          border: 0px solid gray;
                          font-size: 15px;
                          width: 100%;
                          padding: 8px;
                          color: black;
                        ">
                                        <tr>
                                            <td style="width: 150px">
                                                <div *ngFor="
                                let originDestinationDetailsField of pnrData.originDestinationDetailsField
                              ">
                                                    <div *ngFor="
                                  let itineraryInfoField of originDestinationDetailsField.itineraryInfoField
                                    | imgPath
                                ">
                                                        <img style="width: 100px" src="{{ itineraryInfoField }}" />
                                                        <!-- <img src="assets\app_store.png" alt=""/> -->
                                                        <!-- <img [src]="assets/airlines/itineraryInfoField.itineraryReservationInfoField.reservationField.companyIdField" /> -->
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Cosmic Tours and Travels</td>
                                            <td>
                                                Address<br /> Lehri House Shop Number 1Ground Floor,<br /> Sir JJ Road, Off, Sofia Zubair Road, <br />Nagpada, Mumbai, Maharashtra 400008
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>

                            <tr>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <br />
                                    <table align="center" width="100%" border="1">
                                        <tr align="center" style="background-color: gray">
                                            <th style="padding: 15px">Segment</th>
                                            <th>Passengers</th>
                                            <th>Ticket Ref no.</th>
                                        </tr>

                                        <tr>
                                            <td>
                                                <table align="center" style="
                                border: 0px solid gray;
                                font-size: 15px;
                                width: 100%;
                                padding: 8px;
                                color: black;
                              ">
                                                    <tr>
                                                        <td>
                                                            <div *ngFor="
                                      let originDestinationDetailsField of pnrData.originDestinationDetailsField
                                    ">
                                                                <div *ngFor="
                                        let itineraryInfoField of originDestinationDetailsField.itineraryInfoField
                                      ">
                                                                    {{ itineraryInfoField.travelProductField .boardpointDetailField.cityCodeField }}=> {{ itineraryInfoField.travelProductField .offpointDetailField.cityCodeField }} {{ itineraryInfoField.travelProductField .offpointDetailField.cityNameField }}
                                                                </div>
                                                            </div>
                                                        </td>



                                                    </tr>
                                                </table>
                                            </td>
                                            <td>
                                                <!-- travellerInfoField[0].passengerDataField[0].travellerInformationField.passengerField[0].typeField
                        travellerInfoField[1].passengerDataField[0].travellerInformationField.passengerField[0].typeField -->
                                                <div *ngFor="
                                let travellerInfoField of pnrData.travellerInfoField
                              ">
                                                    <ol *ngFor="
                                  let passengerDataField of travellerInfoField.passengerDataField
                                ">
                                                        <li *ngFor="
                                    let passengerField of passengerDataField
                                      .travellerInformationField.passengerField
                                  ">
                                                            {{ passengerField.typeField }} {{ passengerField.firstNameField }} {{ passengerDataField.travellerInformationField .travellerField.surnameField }}
                                                        </li>
                                                        <!-- <li>Mr. Imran Ansari</li> -->
                                                        <!-- <li>Mr. Rizwan Ansari</li> -->
                                                    </ol>
                                                </div>
                                            </td>

                                            <td>
                                                <span innerHtml="{{pnrData.dataElementsMasterField.dataElementsIndivField | ticketRefNo:''}}"></span>
                                                <!-- {{pnrData.dataElementsMasterField.dataElementsIndivField | ticketRefNo:''}} -->


                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <table align="center" width="100%" border="0">
                                        <tr align="center">
                                            <th><br />Flight Details</th>
                                        </tr>
                                    </table>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <table align="center" width="100%" border="1">
                                        <tr align="center">
                                            <th>Date</th>
                                            <th>Flight</th>
                                            <th>From / Terminal</th>
                                            <th>To / Terminal</th>
                                            <th>Stops</th>
                                            <th>Departs</th>
                                            <th>Arrives</th>
                                            <th>Baggage Allowance</th>
                                            <th>Class</th>
                                        </tr>
                                        <tr align="center">
                                            <td>
                                                <div *ngFor="
                                let originDestinationDetailsField of pnrData.originDestinationDetailsField
                              ">
                                                    <div *ngFor="
                                  let itineraryInfoField of originDestinationDetailsField.itineraryInfoField
                                ">
                                                        {{ itineraryInfoField.travelProductField .productField.depDateField | displayDateTime: "Date" }}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div *ngFor="
                                let originDestinationDetailsField of pnrData.originDestinationDetailsField
                              ">
                                                    <div *ngFor="
                                  let itineraryInfoField of originDestinationDetailsField.itineraryInfoField
                                ">
                                                        {{ itineraryInfoField.travelProductField .productDetailsField.identificationField }}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div *ngFor="
                                let originDestinationDetailsField of pnrData.originDestinationDetailsField
                              ">
                                                    <div *ngFor="
                                  let itineraryInfoField of originDestinationDetailsField.itineraryInfoField
                                ">
                                                        {{ itineraryInfoField.flightDetailField .departureInformationField .departTerminalField }}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div *ngFor="
                                let originDestinationDetailsField of pnrData.originDestinationDetailsField
                              ">
                                                    <div *ngFor="
                                  let itineraryInfoField of originDestinationDetailsField.itineraryInfoField
                                ">
                                                        {{ itineraryInfoField.flightDetailField .arrivalStationInfoField.terminalField }}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <!-- originDestinationDetailsField[0].itineraryInfoField[0].flightDetailField.productDetailsField.numOfStopsField -->
                                                <div *ngFor="
                                let originDestinationDetailsField of pnrData.originDestinationDetailsField
                              ">
                                                    <div *ngFor="
                                  let itineraryInfoField of originDestinationDetailsField.itineraryInfoField
                                ">
                                                        {{ itineraryInfoField.flightDetailField .productDetailsField.numOfStopsField }}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div *ngFor="
                                let originDestinationDetailsField of pnrData.originDestinationDetailsField
                              ">
                                                    <div *ngFor="
                                  let itineraryInfoField of originDestinationDetailsField.itineraryInfoField
                                ">
                                                        {{ itineraryInfoField.travelProductField .productField.depTimeField | displayDateTime: "Time" }}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div *ngFor="
                                let originDestinationDetailsField of pnrData.originDestinationDetailsField
                              ">
                                                    <div *ngFor="
                                  let itineraryInfoField of originDestinationDetailsField.itineraryInfoField
                                ">
                                                        {{ itineraryInfoField.travelProductField .productField.dayChangeIndicatorField }} {{ itineraryInfoField.travelProductField .productField.arrTimeField | displayDateTime: "Time" }}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div *ngFor="let tstDataField of pnrData.tstDataField">
                                                    <div *ngFor="
                                  let fareBasisInfoField of tstDataField.fareBasisInfoField
                                ">
                                                        {{ fareBasisInfoField.baggageAllowanceField }}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div *ngFor="
                                let originDestinationDetailsField of pnrData.originDestinationDetailsField
                              ">
                                                    <div *ngFor="
                                  let itineraryInfoField of originDestinationDetailsField.itineraryInfoField
                                ">
                                                        <!-- {{itineraryInfoField.travelProductField.productDetailsField.classOfServiceField}} -->
                                                        {{ itineraryInfoField.cabinDetailsField .cabinDetailsField.classDesignatorField | classDesignator }}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Note: Check-in counters closes strictlt 60 minutes prior to departure.
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <br />

                                    <table align="center" width="100%" border="1">
                                        <tr align="center">
                                            <th>Booking Refrence</th>
                                            <th>Status</th>
                                            <th>Date of Booking</th>
                                            <th>Payment Status</th>
                                            <th>Promo Code</th>
                                            <th>PTC</th>
                                        </tr>
                                        <tr align="center">
                                            <td>
                                                <div *ngFor="
                                let originDestinationDetailsField of pnrData.originDestinationDetailsField
                              ">
                                                    <div *ngFor="
                                  let itineraryInfoField of originDestinationDetailsField.itineraryInfoField
                                ">
                                                        {{ itineraryInfoField .itineraryReservationInfoField .reservationField.controlNumberField }}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div *ngFor="
                                let originDestinationDetailsField of pnrData.originDestinationDetailsField
                              ">
                                                    <div *ngFor="
                                  let itineraryInfoField of originDestinationDetailsField.itineraryInfoField
                                ">
                                                        {{ itineraryInfoField.relatedProductField .statusField[0] | statusText: itineraryInfoField.relatedProductField .statusField[0] }}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div *ngFor="
                                let pnrHeaderField of pnrData.pnrHeaderField
                              ">
                                                    <div *ngFor="
                                  let reservationInfoField of pnrHeaderField.reservationInfoField
                                ">
                                                        {{ reservationInfoField.dateField | displayDateTime: "Date" }} {{ reservationInfoField.timeField | displayDateTime: "Time" }}
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Paid</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>

                            <tr>
                                <td></td>
                            </tr>
                            <tr>
                                <td>
                                    <table align="center" width="100%" border="0">
                                        <tr>
                                            <td valign="top">
                                                <table align="center" style="
                                border: 1px solid gray;
                                font-size: 15px;
                                width: 100%;
                                padding: 8px;
                                color: black;
                              ">
                                                    <tr>
                                                        <th colspan="2" style="text-align: center">
                                                            Passenger
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <th>Name and Address</th>
                                                        <td>Agent Name</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Mobile</th>
                                                        <td>9696969666</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Email</th>
                                                        <td>connect@gmail.com</td>
                                                    </tr>

                                                    <tr>
                                                        <th colspan="2">Payment Details</th>
                                                    </tr>
                                                </table>
                                            </td>
                                            <td>
                                                <table align="center" style="
                                border: 1px solid gray;
                                font-size: 15px;
                                width: 100%;
                                padding: 8px;
                                color: black;
                              ">
                                                    <tr>
                                                        <th colspan="2">Fare Description</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Airfare Charges</th>
                                                        <!-- <td>
                              <div *ngFor="let tstDataField of pnrData.tstDataField">
                                <div *ngFor="let monetaryInfoField of tstDataField.fareDataField.monetaryInfoField">
                                  {{monetaryInfoField.currencyCodeField}}
                                </div>
                              </div>
                            </td> -->
                                                        <td>
                                                            <div *ngFor="let productPricingQuotationRecordField  of pnrData.pricingRecordGroupField.productPricingQuotationRecordField">
                                                                <div *ngFor="let fareComponentDetailsGroupField of productPricingQuotationRecordField.documentDetailsGroupField">

                                                                    {{fareComponentDetailsGroupField.monetaryInformationField.monetaryDetailsField.amountField}}
                                                                </div>
                                                                <!-- pricingRecordGroupField.productPricingQuotationRecordField[2].documentDetailsGroupField.fareComponentDetailsGroupField[0].monetaryInformationField.monetaryDetailsField.amountField -->
                                                            </div>

                                                            <div *ngFor="
                                      let tstDataField of pnrData.tstDataField;
                                      let i = index
                                    ">
                                                                {{ tstDataField | getTotalFareFromPNR: tstDataField | currency: "INR":"symbol":"1.0-0" }}
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th>Fee and Surcharges</th>

                                                        <td>
                                                            <div *ngFor="
                                      let tstDataField of pnrData.tstDataField
                                    ">
                                                                {{ tstDataField | getTotalTaxFromPNR: tstDataField | currency: "INR":"symbol":"1.0-0" }}
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th>CGST</th>
                                                        <td>
                                                            <div *ngFor="
                                      let tstDataField of pnrData.tstDataField
                                    ">
                                                                {{ tstDataField | getGSTFromPNR: "TotalCGST" | currency: "INR":"symbol":"1.0-0" }}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>SGST</th>
                                                        <td>
                                                            <div *ngFor="
                                      let tstDataField of pnrData.tstDataField
                                    ">
                                                                {{ tstDataField | getGSTFromPNR: "TotalSGST" | currency: "INR":"symbol":"1.0-0" }}
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <th>Total Fare</th>
                                                        <td>
                                                            <div *ngFor="
                                      let tstDataField of pnrData.tstDataField
                                    ">
                                                                {{ tstDataField | getGSTFromPNR: "PayableAmount" | currency: "INR":"symbol":"1.0-0" }}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>




            </div>
        </div>

        <div class="sideBar">
            <div style="display: grid">
                <div class="m-auto spinner-border text-primary" role="status" *ngIf="loading">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div *ngIf="FareRules">
                <h3>FareRules</h3>
                <ol *ngFor="let fareRules of FareRulesData">
                    <li *ngFor="let tariffInfoField of fareRules.tariffInfoField">
                        <span *ngFor="
                let fareRuleTextField of tariffInfoField.fareRuleTextField
              ">
              {{ fareRuleTextField.freeTextField[0] }}
            </span>
                    </li>
                </ol>
            </div>
            <!-- Side Bar Old -->
            <!-- <button (click)="ReviewOff()">Off</button> -->
            <div *ngFor="let ItineraryDetails of ReviewItineraryDetails">
                <div class="card p-2" *ngFor="
            let segmentInformationField of ItineraryDetails.segmentInformationField
          ">
                    <h4>
                        {{ segmentInformationField.flightDetailsField.boardPointDetailsField .trueLocationIdField }} - {{ segmentInformationField.flightDetailsField.offpointDetailsField .trueLocationIdField }}
                    </h4>

                    {{ segmentInformationField.flightDetailsField.companyDetailsField .marketingCompanyField }}- {{ segmentInformationField.flightDetailsField.flightIdentificationField .flightNumberField }}
                    <hr />

                    <h5 class="CityStyle">
                        {{ segmentInformationField.flightDetailsField.boardPointDetailsField .trueLocationIdField }}
                    </h5>

                    <span class="CTime">{{
            segmentInformationField.flightDetailsField.flightDateField
              .departureTimeField | displayDateTime: "Time"
          }}</span>

                    <br />
                    <span class="CTime">{{
            segmentInformationField.flightDetailsField.flightDateField
              .departureDateField | displayDateTime: "Date"
          }}</span>
                    <br />
                    <span class="title">
            Arriving on Terminal No.
            {{
              segmentInformationField.apdSegmentField.arrivalStationInfoField
                .terminalField
            }}<br
          /></span>

                    <br />
                    <h5 class="CityStyle">
                        {{ segmentInformationField.flightDetailsField.offpointDetailsField .trueLocationIdField }}
                    </h5>
                    <span class="CTime">
            {{
              segmentInformationField.flightDetailsField.flightDateField
                .arrivalTimeField | displayDateTime: "Time"
            }}</span
          ><br />
          <span class="CTime">{{
            segmentInformationField.flightDetailsField.flightDateField
              .arrivalDateField | displayDateTime: "Date"
          }}</span>
                    <br />
                    <span class="title">
            Departing from Terminal No.
            {{
              segmentInformationField.apdSegmentField.departureStationInfoField
                .terminalField
            }}<br
          /></span>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="NoCooks">
    <app-session-modal></app-session-modal>
</div>
<script>
    $(document).ready(function() {
        $("#ReviewComponent").scrollIntoView();
    })
</script>