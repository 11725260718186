import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getTotalFareFromPNR'
})
export class GetTotalFareFromPNRPipe implements PipeTransform {

  //tstDataField[0].fareDataField.monetaryInfoField[0].amountField

  transform(value: any, ...args: unknown[]): string {
    let TotalFare:number=0;
    let valueData=[];
    valueData.push(value);

    for(let i=0; i<=valueData.length-1; i++){

       for (let n=0; n<valueData[i].fareDataField.monetaryInfoField.length;n++){
         let amount=valueData[i].fareDataField.monetaryInfoField[n].amountField;
         let amt=parseInt(amount,10);
         TotalFare=TotalFare+amt;
       }



    }
    return TotalFare.toString();
  }

}
