import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})



export class FirebaseService {
  isLoggedIn=false;
  constructor(public firebaseAuth : AngularFireAuth) {


  }

// async SignInFB(){
//   await this.firebaseAuth.fac
// }

//async GetSignedinUser(){
  //await this.firebaseAuth.
//}

  async SignInEP(email: string, password: string)  {
    await this.firebaseAuth.signInWithEmailAndPassword(email,password)
    .then(res=>{
      this.isLoggedIn=true;
      sessionStorage.setItem('user', JSON.stringify(res.user));
    })
  }


  async SignUpEP(email: string, password: string)  {
    await this.firebaseAuth.createUserWithEmailAndPassword(email,password)
    .then(res=>{
      this.isLoggedIn=true;
      sessionStorage.setItem('user', JSON.stringify(res.user));
    })
  }

  logout(){
    sessionStorage.removeItem('user');
    this.firebaseAuth.signOut()

  }





}
