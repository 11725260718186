import { Component, OnInit } from '@angular/core';
import { GetIpServiceService } from '../get-ip-service.service';
// import { Event } from '@angular/router';
import {AuthService} from '../auth/auth.service';
import { User } from '../auth/user';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  ipAddress:string;
  public HitLoginState:boolean;
  public LoggedUser:User;
  // ResponseFlight=true;
  // ReviewComponent=false;
  // ResponseFlightDataEr=false;
  // loading=false;
  // RecommendationFilterType='Full';
  // RecommendationFilterValue='Full';
  // GotAirlines=[];

  selectedAirlines = [];



  constructor(private ip:GetIpServiceService,public HitAuth: AuthService) { }

  ngOnInit(): void {
 //   console.log("Inittttt");
  //  this.getUser();
    //this.getIP();
  //console.log("AuthFrom Home>>", this.HitAuth.getLoginState());
  //this.HitLoginState = this.HitAuth.getLoginState();

  // this.HitAuth.authUpdated.subscribe(
  //   (data)=>{
  //     this.HitLoginState = this.HitAuth.getLoginState();
  //     //console.log("JUST got state");
  //   }


  // );
  // this.HitAuth.userUpdated.subscribe(
  //   (user)=>{
  //     this.LoggedUser=this.HitAuth.getLoggedUser();
  //   }
  // );

  }



  // getIP()
  // {
  //   this.ip.getIPAddress().subscribe((res:any)=>{
  //     this.ipAddress=res.ip;
  //   });
  // }






}
