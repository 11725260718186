import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'depArrCities'
})
export class DepArrCitiesPipe implements PipeTransform {

  transform(value: any, DepArr: string): unknown {

    let da = new Set();
    for (let i=0; i<value.length; i++) {
      //console.log("I>>",i);
      //flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.locationField[0].locationIdField
      for(let j=0; j<value[i].groupOfFlightsField.length; j++) {
        //console.log("J>>",j);
      //  for(let k=0; k<value[i].groupOfFlightsField[j].flightDetailsField.length;k++){
          let  c =value[i].groupOfFlightsField[j].flightDetailsField.length;
          c=c-1;
// console.log("CCCCCCCC>>>", c);
          if (DepArr == 'Dep'){
           da.add(value[i].groupOfFlightsField[j].flightDetailsField[0].flightInformationField.locationField[0].locationIdField);

          }else{
            da.add(value[i].groupOfFlightsField[j].flightDetailsField[c].flightInformationField.locationField[1].locationIdField);

          }

        //}


      }
    }
// console.log("DA>>", da);
    return da;
  }

}
