import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getFlightsBag'
})
export class GetFlightsBagPipe implements PipeTransform {

  transform(value: any, SorB: string): any {
    const FilteredArray:any[] =[];

    // recommendationField[0].segmentFlightRefField[0].referencingDetailField[0].refQualifierField
    for(let i=0; i<=value.length-1; i++){

      let nrefQualifierField:string=value[i].refQualifierField;

        nrefQualifierField = nrefQualifierField.toUpperCase();

          if(nrefQualifierField===SorB){
            FilteredArray.push(value[i]);
            }

  }
  //console.log(FilteredArray);
  return FilteredArray;

  }
}
