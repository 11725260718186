<!-- <button class="btn btn-lg btn-outline-primary" (click)="open(mymodal)">Open My Modal</button> -->
<!--
<ng-template #mymodal let-modal>
    <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalCenterTitle">Modal title</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
                </div>
                <div class="modal-body">
                    {{ errorMessage }}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                </div>
            </div>
        </div>
    </div>

</ng-template> -->

<ng-template #mymodal let-modal class="modal-dialog-centered">

    <!-- <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Bootstrap Modal</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    </div> -->
    <div class="modal-body">
        <div style="display:grid;
      grid-template-rows: 4fr 1fr;">
            {{ errorMessage }}
            <!-- Oops! Session expired kindly research your itinerary... -->

            <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Ok</button>
        </div>
    </div>
    <!-- <div class="modal-footer">

    </div> -->

</ng-template>
