import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'hitsDateFormat'
})
export class HitsDateFormatPipe implements PipeTransform {

  transform(value: string, dateFormat: string): unknown {
    var d = value.substr(0, 2);
    var m = value.substr(2, 2);
    var y = value.substr(4,2);
    var dd ="20"+y+"/"+ m+"/"+d;
    console.log(dd);
    return moment(dd).format(dateFormat);

  }

}
