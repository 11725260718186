import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterAirportByCode'
})
export class FilterAirportByCodePipe implements PipeTransform {

  transform(value:any, nAirport:string): any {

    if(nAirport===""){
      return null;
    }

    const FilteredSirportArray:any[] =[];

    if(nAirport==="Select City"){

      FilteredSirportArray.push({"Code":"Select City","Name":"","Alias":"","CityName":"Select City","CityCode":"","Type":"","LogoPath":""});
      return FilteredSirportArray;
    }
    for(let i=0; i<=value.length-1; i++){
      //console.log(value[i].Code);

      let aairportname:string=value[i].CityName;
      aairportname = aairportname.toUpperCase();

      let aairportcode:string=value[i].Code;
      aairportcode= aairportcode.toUpperCase();


      nAirport = nAirport.toUpperCase();

       if(aairportcode===nAirport  ){
        FilteredSirportArray.push(value[i]);
       }
    }
    return FilteredSirportArray;

  }

}
