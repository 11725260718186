import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'getBaggage'
})
export class GetBaggagePipe implements PipeTransform {

  transform(value: any, segRefNo: number): any {
    // console.log("s1="+segRefNo)
    let servicegrpno = segRefNo-1;
    // console.log("servicegrpno="+servicegrpno)
    // serviceFeesGrpField[0].serviceCoverageInfoGrpField[6].itemNumberInfoField.itemNumberField.numberField must tally with segment Baggage 6
    // serviceFeesGrpField[0].serviceCoverageInfoGrpField[6].serviceCovInfoGrpField[0].refInfoField[0].refQualifierField="F"
    // serviceFeesGrpField[0].serviceCoverageInfoGrpField[6].serviceCovInfoGrpField[0].refInfoField[0].refNumberField = 2

    const FilteredArray:any[] =[];

    let serviceCoverageInfoGrpField:string="";
    let str:number=0;
    let Baggage:string="";
    for(let i=0; i<=value.length-1; i++){


   // serviceCoverageInfoGrpField=value[i].serviceCoverageInfoGrpField[servicegrpno].itemNumberInfoField.itemNumberField.numberField;
   // serviceFeesGrpField[0].freeBagAllowanceGrpField[4].freeBagAllownceInfoField.baggageDetailsField.quantityCodeField
        str = value[i].serviceCoverageInfoGrpField[servicegrpno].serviceCovInfoGrpField[0].refInfoField[0].refNumberField ;
        str=str-1;
        // console.log("str="+str);
        let quantityCodeField:string= value[i].freeBagAllowanceGrpField[str].freeBagAllownceInfoField.baggageDetailsField.quantityCodeField
        // console.log("str="+str);
        if(quantityCodeField==="N"){
          Baggage = value[i].freeBagAllowanceGrpField[str].freeBagAllownceInfoField.baggageDetailsField.freeAllowanceField + " pieces";
        }else{
          Baggage = value[i].freeBagAllowanceGrpField[str].freeBagAllownceInfoField.baggageDetailsField.freeAllowanceField + " kgs";
        }




  }
  return Baggage;
  // return FilteredArray;
  }

}
