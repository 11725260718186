
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment';
import { MultiCity } from '../multi-city';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { FlightListError } from '../interfaces/flight-list-error';



@Component({
  selector: 'app-flight-list',
  templateUrl: './flight-list.component.html',
  styleUrls: ['./flight-list.component.css']
})
export class FlightListComponent implements OnInit {


  public apiUrl = 'https://localhost:44317/';
  //public apiUrl="https://cosmictours2021.azurewebsites.net/";

  public ResponseFlight = false;

  ResponseFlightData = [];
  ResponseFlightDataFull = [];
  ResponseFlightDataEr = [];
  ResponseFlightEr = false;
  obj: any = null;
  public loading = false;


  cookieValue: string = '';
  public idVisit = '';

  noofSectors:number;
   DirectFlights: boolean = false;
   NoofPAX: number;
    PaxAdults: number;
    PaxChildren: number;
    PaxInfant: number;
    NoofTravellers: number;
    Cabin: string;

    DomOrInt: string;
    TrackerID: string;
    SectorsItinerary: string;
    SimpleSearch = 'One Way';

    MultiCityArray = [
      {
        FromCityCode: 'DEL',
        ToCityCode: 'BOM',
        DepartDate: '',
        ReturnDate: '',
        DepDorI: '',
        RetDorI: '',
      },
    ];




  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
    private route: ActivatedRoute,
  ) {

    this.route.params.subscribe((params) => {
      if (params['Task'] == 'Search') {
        console.log("Route: "+this.route);
        this.getVisitID(
          params['Task'],
          params['Sectors'],
          params['DepDates'],
          params['Travellers'],
          params['Cabin'],
          params['TripType'],
          params['DomOrInt'],
          params['DirectFlights'],
          params['UTT']
        );
      }
    });

  }

  ngOnInit(): void {
    this.ResponseFlight=true;
    this.loading=false;
  }


  getVisitID(Task: string,
    Sectors: string,
    DepDates: string,
    Travellers: string,
    Cabin: string,
    TripType: string,
    DomOrInt: string,
    DirectFlights: string,
    TrackerID: string) {


    let headersa = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    this.cookieValue = this.cookieService.get('cosmicHits');

    let ma = '{"Hit":"' + this.cookieValue + '"}';

    this.httpClient
      .post(this.apiUrl + 'api/HitSessions', ma, { headers: headersa })
      .subscribe((data: any) => {
        //console.log(data);
        this.idVisit = data;

        this.cookieService.set('cosmicVisits',this.idVisit);
        this.searchFromURL(Task,
          Sectors,
          DepDates,
          Travellers,
          Cabin,
          TripType,
          DomOrInt,
          DirectFlights,
          TrackerID)

      });
  }



  searchFromURL(
    Task: string,
    Sectors: string,
    DepDates: string,
    Travellers: string,
    Cabin: string,
    TripType: string,
    DomOrInt: string,
    DirectFlights: string,
    TrackerID: string
  ) {

this.loading =true;
this.ResponseFlight = false;
    console.log(this.idVisit);
    //   let s = '{"FlightDataParams":{"Sectors":"'+ Sectors + '","DepDates":"'+ DepDates + '","Travellers":"'+ Travellers
    //  + '","Cabin":"' + Cabin + '","TripType":"' + TripType + '","DomOrInt":"' + this.DomOrInt + '","DirectFlights":"' + this.DirectFlights +'"}}';
    let Adults: number = 0;
    let Children: number = 0;
    let Infants: number = 0;

    var paxss = Travellers.split('-');
    Adults = +paxss[0];
    Children = +paxss[1];
    Infants = +paxss[2];
    this.NoofPAX = Adults + Children;
    this.PaxAdults = Adults;
    this.PaxChildren = Children;
    this.PaxInfant = Infants;
    this.NoofTravellers = Adults + Children + Infants;
    this.Cabin = Cabin;

    this.DomOrInt = DomOrInt;
    this.TrackerID = TrackerID;
    this.SectorsItinerary = Sectors;
    var ll = Sectors.split('_');
    this.noofSectors= ll.length;
    console.log("Noof sectors", this.noofSectors);
    var Datess = DepDates.split('_');
    ////console.log(Datess[0]);

    if (TripType == 'O') {
      this.SimpleSearch = 'One Way';
      this.MultiCityArray.length = 1;
      var cities = ll[0].split('-');
      this.MultiCityArray[0].FromCityCode = cities[0];
      this.MultiCityArray[0].ToCityCode = cities[1];

      var FDate =
        '20' +
        Datess[0].substring(4, 6) +
        '-' +
        Datess[0].substring(2, 4) +
        '-' +
        Datess[0].substring(0, 2);
      this.MultiCityArray[0].DepartDate = FDate;
      this.MultiCityArray[0].ReturnDate = '';
      this.MultiCityArray[0].DepDorI = DomOrInt;
      this.MultiCityArray[0].RetDorI = DomOrInt;
      ////console.log(this.MultiCityArray);

      // this.SearchFlights('S');
    } else if (TripType == 'R') {
      this.SimpleSearch = 'Round Trip';

      this.MultiCityArray.length = 1;
      var cities = ll[0].split('-');
      this.MultiCityArray[0].FromCityCode = cities[0];
      this.MultiCityArray[0].ToCityCode = cities[1];
      var FDate =
        '20' +
        Datess[0].substring(4, 6) +
        '-' +
        Datess[0].substring(2, 4) +
        '-' +
        Datess[0].substring(0, 2);
      this.MultiCityArray[0].DepartDate = FDate;
      var TDate =
        '20' +
        Datess[1].substring(4, 6) +
        '-' +
        Datess[1].substring(2, 4) +
        '-' +
        Datess[1].substring(0, 2);
      this.MultiCityArray[0].ReturnDate = TDate;
      this.MultiCityArray[0].DepDorI = DomOrInt;
      this.MultiCityArray[0].RetDorI = DomOrInt;
      ////console.log(this.MultiCityArray);
      //    this.SearchFlights('S');
    } else if (TripType == 'M') {
      this.SimpleSearch = 'Multi City';

      ////console.log(ll.length);
      this.MultiCityArray.length = 0;

      for (var i = 0; i < ll.length; i++) {
        var cities = ll[i].split('-');

        var FDate =
          '20' +
          Datess[i].substring(4, 6) +
          '-' +
          Datess[i].substring(2, 4) +
          '-' +
          Datess[i].substring(0, 2);

        let m = new MultiCity(
          cities[0],
          cities[1],
          FDate,
          '',
          DomOrInt,
          DomOrInt
        );
        this.MultiCityArray.push(m);
      }
      ////console.log(this.MultiCityArray);
    }

    let ma = '{"Tracker":"' + this.TrackerID + '","Merchantid":"7123"}';
    //GetTracker here and then Navigate
    let headersa = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=utf-8'
    );

    this.cookieValue = this.cookieService.get('cosmicHits');


    this.httpClient
      .post(this.apiUrl + 'api/HitsTracker', ma, { headers: headersa })
      .subscribe((data: any) => {
        if (this.TrackerID != 'll') {
          this.TrackerID = data;
          //    //console.log("Match no found still moving");
          //console.log(this.TrackerID);
          ///FromURL
          let s =
            '{"HitVisit":"' +
            this.idVisit +
            '","FlightDataParams":{"Sectors":"' +
            Sectors +
            '","DepDates":"' +
            DepDates +
            '","Travellers":"' +
            Travellers +
            '","Cabin":"' +
            Cabin +
            '","TripType":"' +
            TripType +
            '","DomOrInt":"' +
            this.DomOrInt +
            '","DirectFlights":"' +
            this.DirectFlights +
            '"}}';

          //  window.alert(s);
          let headers = new HttpHeaders().append(
            'Content-Type',
            'application/json; charset=utf-8'
          );

          let params = new HttpParams().set('Sectors', Sectors); // create params object
          params = params.append('DepDates', DepDates); // add a new param, creating a new object
          params = params.append('Travellers', Travellers);
          params = params.append('Cabin', Cabin);
          params = params.append('TripType', TripType);
          params = params.append('DomOrInt', this.DomOrInt);
          params = params.append(
            'DirectFlights',
            this.DirectFlights.toString()
          );
//AirFareFeatured
          this.httpClient
            .post(this.apiUrl + 'api/FlightList', s, {
              headers: headers,
              params: params,
            })
            .subscribe((data: any) => {

              let err = 'N';
              console.log('NewData', data);
              this.ResponseFlightData.push(data);

              const result = this.ResponseFlightData.map(item => Object.values(item)[1]);
              console.log("ResultL>>",result.length);
              console.log("Result>>",result[0]);
              if(result[0]=='false'){
                console.log("Yes false");
                 err='N';
              }else{
                console.log("true");
                err = 'Y';
              }
          console.log("err>>",err);






              this.ResponseFlightData = [];
              this.ResponseFlightDataEr = [];
              this.ResponseFlight = true;
              this.ResponseFlightEr = false;
              this.ResponseFlightDataFull = [];
              //this.ResponseFlightData.push(data);
              // console.log("err>>>", err);
              // console.log("ResponseErr>>>",this.ResponseFlightDataEr);

              if (err === 'Y') {
                this.ResponseFlight=false;
                this.ResponseFlightEr = true;
                //this.ResponseFlightDataEr = [];
                this.ResponseFlightDataEr.push(data);
                console.log("ResponseFlightErr>>", this.ResponseFlightEr);


              } else if (err === 'N') {
                this.ResponseFlightEr = false;
                this.ResponseFlight = true;
                this.ResponseFlightData.push(data);
                this.ResponseFlightDataFull.push(data);
                console.log("ResponseFlightData>>", this.ResponseFlightData);

              }
              this.loading=false;
            });
        } else {
          //this.onSearchFlights();
          //navigate with new TrackerID
          //console.log ("LLLLLLL");
          this.loading=false;
        }
      });

    //  //console.log("Response is " + this.obj);
    //console.log("FlightIndex is " + this.FlightIndex);
  }

  getFlights(schedule, deparr) {
    //this.FilterFlightData();
    //05-12
    if (schedule == '05to12') {
      //Filter Departure
      if (deparr == 'Dep') {
      } else if (deparr == 'Dep') {
        //Filter Arrival
      }
      //console.log("MorningFlight");

      // this.ResponseFlightData=[];
    }
  }
}
