<div class="ChPax m-auto" [hidden]="user">
    <h1>Get OTP On Your Phone Number</h1>

    <!-- <label for="phone">Phone Number</label><br>
    <input type="text" [(ngModel)]="phoneNumber.country" class="input" placeholder="1" maxlength="2">
    <input type="text" [(ngModel)]="phoneNumber.area" class="input" placeholder="949" maxlength="3">
    <input type="text" [(ngModel)]="phoneNumber.prefix" class="input" placeholder="555" maxlength="3">
    <input type="text" [(ngModel)]="phoneNumber.line" class="input" placeholder="5555" maxlength="4"> -->
    <input type="text" [(ngModel)]="phoneNumber.fullNo" #mobile class="form-control mb-2" placeholder="Mobile" required />
    <div id="recaptcha-container"></div>

    <button class="btn btn-lg btn-primary btn-block mb-1" (click)="sendLoginCode()">SMS Text Login Code</button>

    <div *ngIf="windowRef.confirmationResult">
        <hr>
        <label for="code">Enter your Verification Code Here</label><br>
        <input class="form-control mb-2" type="text" name="code" [(ngModel)]="verificationCode">

        <button data-dismiss="modal" data-target="#LoginModalCenter" class="btn btn-lg btn-primary btn-block mb-1" (click)="verifyLoginCode()">Verify</button>
    </div>

</div>

<div *ngIf="user">
    You have successfully logged in with your phone number! UserId: {{ user?.uid }}

</div>