import { Injectable,EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  loading:boolean=false;
  loadingUpated:EventEmitter<boolean> = new EventEmitter();

  getloading(){
    return this.loading;
  }

  setloading(val){
    this.loading=val;
    this.loadingUpated.emit(this.loading);
  }
  constructor() { }
}
