import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullToString'
})
export class NullToStringPipe implements PipeTransform {

  transform(value: any,convertTo: string): any {
    let a:string ="";
    if(value==null){
      a=convertTo;
    }
    return a;
  }

}
