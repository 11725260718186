import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { of } from 'rxjs';

@Component({
  selector: 'app-ccave-pay',
  templateUrl: './ccave-pay.component.html',
  styleUrls: ['./ccave-pay.component.css']
})
export class CcavePayComponent implements OnInit {
  // checkoutService: any;

  constructor() { }

  getEnc(formData: any) {
    const nodeCCAvenue = require("node-ccavenue")
    const ccav = new nodeCCAvenue.Configure({
      merchant_id: '[Your Merchant ID]',
      working_key: '[Your Working Key]'
    });
    const encryptedData = ccav.getEncryptedOrder(formData);
    return of({encRequest : encryptedData});
}

  @ViewChild('form') form: ElementRef;

  encRequest: String;
  accessCode: String;


  ngOnInit() {
    this.accessCode = 'YOURACCESSCODEGOESHERE';

  }




  pay() {

    // this.cartValue contains all the order information which is sent to the server
    // // You can use this package to encrypt - https://www.npmjs.com/package/node-ccavenue/
    // this.checkoutService.getEnc(this.orderInformation).subscribe((response: any) => {
    //   this.encRequest = response.encRequest;
    //   setTimeout(_ => this.form.nativeElement.submit());
    // }, error => {
    //   console.log(error);
    // });
  }
  orderInformation(orderInformation: any) {
    throw new Error('Method not implemented.');
  }

}
