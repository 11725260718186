<app-wrapper></app-wrapper>
<div class="container-fluid">
  <div class="CContainer">
    <div class="contentBar">
      <div *ngIf="PNRExists">
        <div *ngFor="let pnrData of PNRData">



        <table
        align="center"
        style="
          border: 0px solid gray;
          font-size: 15px;
          width: 100%;
          padding: 8px;
          color:black;
        "
      >
        <tr>
          <td >
            <table
        align="center"
        style="
          border: 0px solid gray;
          font-size: 15px;
          width: 100%;
          padding: 8px;
          color:black;
        "
      >
              <tr>
                <td style="width:150px;">
                  <div *ngFor="let originDestinationDetailsField of pnrData.originDestinationDetailsField">
                    <div *ngFor="let itineraryInfoField of originDestinationDetailsField.itineraryInfoField | imgPath">

                       <img style="width: 100px;" src="{{itineraryInfoField}}"/>
                      <!-- <img src="assets\app_store.png" alt=""/> -->
                      <!-- <img [src]="assets/airlines/itineraryInfoField.itineraryReservationInfoField.reservationField.companyIdField" /> -->

                    </div>
                  </div>



                </td>
                <td>Cosmic Tours and Travels</td>
                <td>
                  Address<br />
                  Lehri House Shop Number 1Ground Floor,<br />
                  Sir JJ Road, Off, Sofia Zubair Road, <br />Nagpada, Mumbai,
                  Maharashtra 400008
                </td>
              </tr>
            </table>
          </td>
        </tr>

        <tr>
          <td>



          </td>

        </tr>
        <tr>
          <td>
            <br />
            <table align="center" width="100%" border="1">
              <tr style="background-color: gray">
                <th style="padding: 15px">Segment</th>
                <th>Passengers</th>
                <th>Booking Ref No.</th>
              </tr>

              <tr>
                <td>
                  <table
        align="center"
        style="
          border: 0px solid gray;
          font-size: 15px;
          width: 100%;
          padding: 8px;
          color:black;
        "
      >
                    <tr>
                      <td>
                        <div *ngFor="let originDestinationDetailsField of pnrData.originDestinationDetailsField">
                          <div *ngFor="let itineraryInfoField of originDestinationDetailsField.itineraryInfoField">
                            {{itineraryInfoField.travelProductField.boardpointDetailField.cityCodeField}}=>
                            {{itineraryInfoField.travelProductField.offpointDetailField.cityCodeField}}
                            {{itineraryInfoField.travelProductField.offpointDetailField.cityNameField}}
                          </div>
                        </div>

                      </td>
                      </tr>
                  </table>

                </td>
                <td>
                  <!-- travellerInfoField[0].passengerDataField[0].travellerInformationField.passengerField[0].typeField
                  travellerInfoField[1].passengerDataField[0].travellerInformationField.passengerField[0].typeField -->
                  <div *ngFor="let travellerInfoField of pnrData.travellerInfoField">
                    <ol *ngFor="let passengerDataField of travellerInfoField.passengerDataField">
                     <li *ngFor="let passengerField of passengerDataField.travellerInformationField.passengerField">
                      {{passengerField.typeField}} {{passengerField.firstNameField}}
                       {{passengerDataField.travellerInformationField.travellerField.surnameField}}

                    </li>
                    <!-- <li>Mr. Imran Ansari</li> -->
                    <!-- <li>Mr. Rizwan Ansari</li> -->
                  </ol>
                  </div>
                </td>
                <td>
                  <div >
                    <span innerHtml="{{pnrData.dataElementsMasterField.dataElementsIndivField | ticketRefNo:''}}"></span>
                    <!-- {{pnrData.dataElementsMasterField.dataElementsIndivField | ticketRefNo:''}} -->
                    <!-- {{dataElementsIndivField.elementManagementDataField.segmentNameField}}<br>
                    {{dataElementsIndivField.otherDataFreetextField[0].longFreetextField}} -->
                  </div>

                  <!-- [0].dataElementsMasterField.dataElementsIndivField[1].elementManagementDataField.segmentNameField -->

                </td>


              </tr>
            </table>
          </td>
        </tr>

        <tr>
          <td>
            <table align="center" width="100%" border="0">
              <tr align="center">
                <th>
                  <br />Flight Details</th>
              </tr>
            </table>
          </td>
        </tr>



        <tr>
          <td>
            <table align="center" width="100%" border="1">
              <tr align="center">
                <th>Date</th>
                <th>Flight</th>
                <th>From / Terminal</th>
                <th>To / Terminal</th>
                <th>Stops</th>
                <th>Departs</th>
                <th>Arrives</th>
                <th>Baggage Allowance</th>
                <th>Class</th>
              </tr>
              <tr align="center">
                <td>
                  <div *ngFor="let originDestinationDetailsField of pnrData.originDestinationDetailsField">
                    <div *ngFor="let itineraryInfoField of originDestinationDetailsField.itineraryInfoField">
                      {{itineraryInfoField.travelProductField.productField.depDateField  | displayDateTime: "Date"}}
                    </div>
                  </div>

                </td>
                <td>
                  <div *ngFor="let originDestinationDetailsField of pnrData.originDestinationDetailsField">
                    <div *ngFor="let itineraryInfoField of originDestinationDetailsField.itineraryInfoField">
                      {{itineraryInfoField.travelProductField.productDetailsField.identificationField}}
                    </div>
                  </div>


                </td>
                <td>
                  <div *ngFor="let originDestinationDetailsField of pnrData.originDestinationDetailsField">
                    <div *ngFor="let itineraryInfoField of originDestinationDetailsField.itineraryInfoField">
                      {{itineraryInfoField.flightDetailField.departureInformationField.departTerminalField}}
                    </div>
                  </div>
                </td>
                <td>
                  <div *ngFor="let originDestinationDetailsField of pnrData.originDestinationDetailsField">
                    <div *ngFor="let itineraryInfoField of originDestinationDetailsField.itineraryInfoField">
                      {{itineraryInfoField.flightDetailField.arrivalStationInfoField.terminalField}}
                    </div>
                  </div>


                  </td>
                <td>
                  <!-- originDestinationDetailsField[0].itineraryInfoField[0].flightDetailField.productDetailsField.numOfStopsField -->
                  <div *ngFor="let originDestinationDetailsField of pnrData.originDestinationDetailsField">
                    <div *ngFor="let itineraryInfoField of originDestinationDetailsField.itineraryInfoField">
                      {{itineraryInfoField.flightDetailField.productDetailsField.numOfStopsField}}
                    </div>
                  </div>


                </td>
                <td>


                  <div *ngFor="let originDestinationDetailsField of pnrData.originDestinationDetailsField">
                    <div *ngFor="let itineraryInfoField of originDestinationDetailsField.itineraryInfoField">
                      {{itineraryInfoField.travelProductField.productField.depTimeField  | displayDateTime: "Time"}}
                      </div>
                   </div>
                </td>
                <td>
                  <div *ngFor="let originDestinationDetailsField of pnrData.originDestinationDetailsField">
                    <div *ngFor="let itineraryInfoField of originDestinationDetailsField.itineraryInfoField">
                      {{itineraryInfoField.travelProductField.productField.dayChangeIndicatorField}}
                      {{itineraryInfoField.travelProductField.productField.arrTimeField  | displayDateTime: "Time"}}
                    </div>
                  </div>

                </td>
                <td>
                  <div *ngFor="let tstDataField of pnrData.tstDataField">
                    <div *ngFor="let fareBasisInfoField of tstDataField.fareBasisInfoField">
                      {{fareBasisInfoField.baggageAllowanceField}}
                    </div>

                  </div>
                </td>
                <td>
                  <div *ngFor="let originDestinationDetailsField of pnrData.originDestinationDetailsField">
                    <div *ngFor="let itineraryInfoField of originDestinationDetailsField.itineraryInfoField">
                      <!-- {{itineraryInfoField.travelProductField.productDetailsField.classOfServiceField}} -->
                      {{itineraryInfoField.cabinDetailsField.cabinDetailsField.classDesignatorField | classDesignator}}
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            Note: Check-in counters closes strictlt 60 minutes prior to departure.
          </td>
        </tr>

        <tr>
          <td>
            <br />

            <table align="center" width="100%" border="1">
              <tr align="center">
                <th>Booking Refrence</th>
                <th>Status</th>
                <th>Date of Booking</th>
                <th>Payment Status</th>
                <th>Promo Code</th>
                <th>PTC</th>
              </tr>
              <tr align="center">
                <td>
                  <div *ngFor="let originDestinationDetailsField of pnrData.originDestinationDetailsField">
                    <div *ngFor="let itineraryInfoField of originDestinationDetailsField.itineraryInfoField">
                      {{itineraryInfoField.itineraryReservationInfoField.reservationField.controlNumberField}}
                    </div>
                  </div>

                </td>
                <td>

                  <div *ngFor="let originDestinationDetailsField of pnrData.originDestinationDetailsField">
                    <div *ngFor="let itineraryInfoField of originDestinationDetailsField.itineraryInfoField">
                      {{itineraryInfoField.relatedProductField.statusField[0] | statusText:itineraryInfoField.relatedProductField.statusField[0] }}
                    </div>
                  </div>


                </td>
                <td>
                  <div *ngFor="let pnrHeaderField of pnrData.pnrHeaderField">
                    <div *ngFor="let reservationInfoField of pnrHeaderField.reservationInfoField">
                      {{reservationInfoField.dateField  | displayDateTime: "Date"}} {{reservationInfoField.timeField  | displayDateTime: "Time"}}
                    </div>
                  </div>


                </td>
                <td>Paid</td>
                <td></td>
                <td></td>
              </tr>
            </table>
          </td>
        </tr>

        <tr>
          <td></td>
        </tr>
        <tr>
          <td>
            <table align="center" width="100%" border="0">
              <tr>
                <td valign="top">
                  <table
        align="center"
        style="
          border: 1px solid gray;
          font-size: 15px;
          width: 100%;
          padding: 8px;
          color:black;
        "
      >
                    <tr>
                      <th colspan="2" style="text-align: center;">Passenger</th>
                    </tr>
                    <tr>
                      <th>Name and Address</th>
                      <td>Agent Name</td>
                    </tr>
                    <tr>
                      <th>Mobile</th>
                      <td>9696969666</td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>connect@gmail.com</td>
                    </tr>

                    <tr>
                      <th colspan="2">Payment Details</th>
                    </tr>
                  </table>
                </td>
                <td>
                  <table
        align="center"
        style="
          border: 1px solid gray;
          font-size: 15px;
          width: 100%;
          padding: 8px;
          color:black;
        "
      >
                    <tr>
                      <th colspan="2">Fare Description</th>
                    </tr>
                    <tr>
                      <th>Airfare Charges</th>
                      <!-- <td>
                        <div *ngFor="let tstDataField of pnrData.tstDataField">
                          <div *ngFor="let monetaryInfoField of tstDataField.fareDataField.monetaryInfoField">
                            {{monetaryInfoField.currencyCodeField}}
                          </div>
                        </div>
                      </td> -->
                      <td>

                        <div *ngFor="let tstDataField of pnrData.tstDataField let i= index">

                          {{tstDataField | getTotalFareFromPNR:tstDataField | currency: "INR":"symbol":"1.0-0"}}

                        </div>

                      </td>
                    </tr>

                    <tr>
                      <th>Fee and  Surcharges</th>

                      <td>
                        <div *ngFor="let tstDataField of pnrData.tstDataField">
                          {{tstDataField | getTotalTaxFromPNR:tstDataField | currency: "INR":"symbol":"1.0-0"}}

                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th>CGST</th>
                      <td>

                        <div *ngFor="let tstDataField of pnrData.tstDataField">
                          {{tstDataField | getGSTFromPNR:"TotalCGST" | currency: "INR":"symbol":"1.0-0"}}
                        </div>


                      </td>
                    </tr>
                    <tr>
                      <th>SGST</th>
                      <td><div *ngFor="let tstDataField of pnrData.tstDataField">
                        {{tstDataField | getGSTFromPNR:"TotalSGST" | currency: "INR":"symbol":"1.0-0"}}
                      </div></td>
                    </tr>

                    <tr>
                      <th>Total Fare</th>
                      <td><div *ngFor="let tstDataField of pnrData.tstDataField">
                        {{tstDataField | getGSTFromPNR:"PayableAmount" | currency: "INR":"symbol":"1.0-0"}}
                      </div></td>
                    </tr>



                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      </div>
      </div>

    </div>

    <div class="sideBar"></div>

  </div>
</div>
