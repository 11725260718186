<div class="container pt-3">
  <div class="row justify-content-sm-center">
    <div class="col-sm-10 col-md-6">
      <div class="card border-info">
        <div class="card-header">Login</div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4 text-center">
              <img src="https://placeimg.com/128/128/nature">
            </div>
            <div class="col-md-8">
              <form>
                <input type="text" #email class="form-control mb-2" placeholder="Email" required autofocus>
                <input type="password" #password class="form-control mb-2" placeholder="Password" required>
                <!-- <button (click)="onSignInEP(email.value,password.value)" class="btn btn-lg btn-primary btn-block mb-1" type="submit">Login</button> -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
