import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRoute } from '@angular/router';
import { FlightListComponent } from './flight-list/flight-list.component';
import { FlightResultsComponent } from './flight-results/flight-results.component';
import { FlightReviewComponent } from './flight-review/flight-review.component';
import { FlightSearchWrapperComponent } from './flight-search-wrapper/flight-search-wrapper.component';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { TicketComponent } from './ticket/ticket.component';
import { ForgotPasswordComponent } from './travellers/forgot-password/forgot-password.component';
import { LoginComponent } from './travellers/login/login.component';
import { RegisterComponent } from './travellers/register/register.component';
import { VerifyEmailComponent } from './travellers/verify-email/verify-email.component';
import { WrapperComponent } from './wrapper/wrapper.component';





const routes: Routes = [
  {
    path:'Register',
    component:RegisterComponent
  },
  {
    path:'Login',
    component:LoginComponent
  },{
    path:'VerifyEmail',
    component:VerifyEmailComponent
  },{
    path:'ForgotPassword',
    component:ForgotPasswordComponent
  },
  {
    path:'Bookings',
    component:FlightSearchWrapperComponent
  },
  {
    ///FlightSearch/DEL-BOM/181120/1-0-0/E/O/D/false
    ///FlightSearch/:sector/:DepDates/:Travellers/:Class/:TripType/:DOrI/:DirectFlights/:UTT
    path:'Flight/:Task/:Sectors/:DepDates/:Travellers/:Cabin/:TripType/:DomOrInt/:DirectFlights/:TrackerID',
    // component:WrapperComponent
    component:FlightResultsComponent
    //component:FlightListComponent
  },
  {
    path:'FlightReview/:Task/:TrackerID',
    component:FlightReviewComponent
  },
  {
    path:'FlightReview/:Task',
    component:FlightReviewComponent
  },
  {


    path:'Flight/:Task/:TrackerID',
    component:WrapperComponent
  },
  {
    path:'FlightSearch/:Task/:Sectors/:DepDates/:Travellers/:Cabin/:TripType/:DomOrInt/:DirectFlights/:TrackerID',
    component:FlightResultsComponent
    // component:FlightListComponent
  },

  {
    path:'Flight',
    component:WrapperComponent
  },
  {
    path:'Ticket',
    component:TicketComponent
  },
  {
    path:'',
    component:HomeComponent
  },
  {
    path:'**',
    component:NotFoundComponent
  }

  ];

@NgModule({
   imports: [
      RouterModule.forRoot(routes)
   ],
   exports: [
      RouterModule
   ]

  //  ,
  //  declarations: [

  //  ]
})
export class AppRoutingModule { }
