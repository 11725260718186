import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getBaggageUnit'
})
export class GetBaggageUnitPipe implements PipeTransform {

  transform(value: string): string {
    let bu:string="";
    if(value=="K"){
      bu=" KG"
    }else if(value=="N"){
      bu=" Pieces"
    }else{
      bu="";
    }

    return bu;
  }

}
