import { Component, OnInit, ViewChild,ViewChildren, Renderer2, AfterViewInit, TemplateRef } from '@angular/core';
//import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { ErrorMessageService } from 'src/app/Services/error-message.service';


@Component({
  selector: 'app-session-modal',
  templateUrl: './session-modal.component.html',
  styleUrls: ['./session-modal.component.css']
})
export class SessionModalComponent implements OnInit, AfterViewInit {

  errorMessage:string='';
  title = 'ng-bootstrap-modal-demo';
  closeResult: string;
  modalOptions:NgbModalOptions;
  @ViewChild('mymodal') mymodal;
  constructor(
    private errorMessageService: ErrorMessageService,
    private modalService: NgbModal
  ){
    this.modalOptions = {
      backdrop:'static',
      backdropClass:'customBackdrop'
    }
  }
  ngAfterViewInit(): void {


    this.errorMessage = this.errorMessageService.geterrorMessage();
    this.errorMessageService.errorMessageUpated.subscribe(
      (data)=>{
        this.errorMessage = this.errorMessageService.geterrorMessage();
        console.log("JUST got error message");
      }


    );

   // this.errorMessage = this.errorMessageService.geterrorMessage();
    //throw new Error('Method not implemented.');
    //console.log("1111111111111");
    //open(this.mymodal);
    this.modalService.open(this.mymodal);
    //console.log("222222222222");
  }
  ngOnInit(): void {


   // throw new Error('Method not implemented.');
  }

  open(content) {
    this.modalService.open(content, this.modalOptions).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
}
