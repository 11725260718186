import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { GetIpServiceService } from './get-ip-service.service';
import { FirebaseService } from 'src/app/fireservices/firebase.service';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';

import { AuthService } from './auth/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { HitCookieService } from './Services/hit-cookie.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'Cosmic Client';
  ipAddress: string;
  // public apiUrl = 'https://localhost:44317/';
  //public apiUrl= "https://cosmictours2021.azurewebsites.net/";
  apiUrl = environment.apiUrl;


  isSignedIn = false;
  cosmicHits: string;
  cosmicVisits:string;
  errorMessageService: any;
  NoCooks: boolean;
  cookieValue: string;

  Hit_CID:string;
  Hit_CVisitID:string;

  constructor(
    private ip: GetIpServiceService,
    private cookieService: CookieService,
    // public firebaseService: FirebaseService,
    private httpClient: HttpClient,
    // public afAuth: AngularFireAuth,
    public HitAuth: AuthService,
    public hitCookieService: HitCookieService,
  ) {
    this.cosmicHits = this.cookieService.get('cosmicHits');
    this.cosmicVisits = this.cookieService.get('cosmicVisits');

    this.Hit_CID=this.hitCookieService.getHit_CID();
    this.Hit_CVisitID=this.hitCookieService.getHit_CVisitID();
    // console.log("Hit_CID", this.Hit_CID);
    // console.log("Hit_CVisitID", this.Hit_CVisitID);

  }

  ngOnInit() {
    //this.getIP();
    // console.log("ThisAuth>>", this.HitAuth.getLoginState());

    // if (sessionStorage.getItem('user') != null) {
    //   this.HitAuth.setLoginState(true);
    //   this.isSignedIn = true;
    //   this.registerUser();
    // } else {
    //   this.HitAuth.setLoginState(false);
    //   this.isSignedIn = false;
    // //  console.log('Not signed');
    // }

    // this.hitCookieService.Hit_CIDUpated.subscribe((d)=>{
    //   this.Hit_CID=this.hitCookieService.getHit_CID();
    // });

    // this.hitCookieService.Hit_CVisitIDUpated.subscribe((d)=>{
    //   this.Hit_CVisitID=this.hitCookieService.getHit_CVisitID();
    // });

    // //  console.log("FIsLoggedin>>", this.isSignedIn );

    // this.HitAuth.userUpdated.subscribe((user) => {
    //   console.log('UserUpdates>>', user);
    //   //let LoggedUser=this.HitAuth.getLoggedUser();
    //   if (user) {
    //     //      console.log("UserFound>", user);

    //     if (!this.cookieService.get('cosmicHits')) {
    //       console.log('No cookie');

    //       let headersa = new HttpHeaders().append('Content-Type','application/json; charset=utf-8');
    //             this.httpClient.post(this.apiUrl + "api/Hits", {headers: headersa}).subscribe(
    //             (data:any)=> {
    //               //console.log(data);
    //               const dateNow = new Date();
    //                     dateNow.setMinutes(dateNow.getMinutes() + 10);
    //                     this.cookieService.set('cosmicHits', data, dateNow);
    //                     this.cookieValue = this.cookieService.get('cosmicHits');
    //                     this.hitCookieService.setHit_CID(data);
    //                     console.log("NowHCID", this.Hit_CID);

    //                     this.registerUser();

    //             },
    //             (err)=>{
    //               this.errorMessageService.seterrorMessage("Oops!! Your session Expired... Kindly restart searching your itinerary.")
    //               this.NoCooks=true;
    //             });

    //       //this.cookieValue = this.cookieService.get('cosmicHits');
    //     } else {



    //     // this.registerUser();
    //     }
    //   }
    // });
  }

  // getIP() {
  //   this.ip.getIPAddress().subscribe((res: any) => {
  //     this.ipAddress = res.ip;
  //   //  console.log(this.ipAddress);
  //   });
  // }

  // registerUser() {
  //   this.cosmicHits = this.cookieService.get('cosmicHits');
  //   this.cosmicVisits = this.cookieService.get('cosmicVisits');

  //   console.log('Registration Initiated from app component');
  //   let data = sessionStorage.getItem('user');
  //   let obj = JSON.parse(data);
  //   console.log("OBJ App component>>", obj);
  //   // displayname:string,
  //   // providerid:string, photourl:string, Hit:string
  //   if (obj.email != undefined) {
  //     //stsTokenManager.refreshToken
  //     let s =
  //       '{"FUid":"' +
  //       obj.uid +
  //       '","email":"' +
  //       obj.email +
  //       '","emailVerified":"' +
  //       obj.emailVerified +
  //       '","password":"' +
  //       '' +
  //       '","accessToken":"' +
  //       obj.stsTokenManager.accessToken +
  //       '","refreshToken":"' +
  //       obj.stsTokenManager.refreshToken +
  //       '","displayName":"' +
  //       obj.displayName +
  //       '","phoneNumber":"' +
  //       obj.phoneNumber +
  //       '","providerId":"' +
  //       obj.providerData[0].providerId +
  //       '","photourl":"' +
  //       obj.photourl +
  //       '","Hit":"' +
  //       this.cosmicHits +
  //       '","cosmicVisits":"' +
  //       this.cosmicVisits +
  //       '"}';

  //     //let headers = new HttpHeaders().append('Content-Type','application/json; charset=utf-8');
  //     //let params = new HttpParams().set('TrackerID', ""); // create params object

  //     let headersa = new HttpHeaders().append(
  //       'Content-Type',
  //       'application/json; charset=utf-8'
  //     );

  //     const httOptions = {
  //       headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  //       withCredentials: true,
  //       observe: 'response' as 'response',
  //       // params: params
  //     };

  //     this.httpClient

  //       .post(this.apiUrl + 'api/Auth', s, { headers: headersa })
  //       .subscribe((data: any) => {
  //         console.log('RegisterResponse App component', data);

  //         if (data.body == 'No') {
  //         } else {
  //           console.log("Check here App component");
  //         }
  //       });
  //   }
  // }
}
