import { Pipe, PipeTransform } from '@angular/core';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
//

@Pipe({
  name: 'filterRecommendationsSegment'
})
export class FilterRecommendationsSegmentPipe implements PipeTransform {

  transform(value: any, TripType:string, FlightIndexNo:number, nrefQualifierField:string, nrefNumberField:string, PaxAdults:number, PaxChildren:number, PaxInfant:number): any {


  //FlightIndexNo == SEgment
  //nrefQualifierField == GroupofFlight
  //flightIndexField[0].groupOfFlightsField[0].propFlightGrDetailField.flightProposalField[0].refField


    const FilteredArray:any[] =[];
   //  console.log("Tosearch: "+ TripType  + "; Flight Index No: " + FlightIndexNo + "; QF: " + nrefQualifierField +"-"+ nrefNumberField );




    //  if(TripType=="O"){

       for (let k=0;k<=value.length-1;k++)
        {
          {
            for(let i=0;i<=value[k].segmentFlightRefField.length-1;i++){
              for(let n=0;n<=value[k].segmentFlightRefField[i].referencingDetailField.length-1;n++){

                if (value[k].segmentFlightRefField[i].referencingDetailField[n].refQualifierField==nrefQualifierField
                  && value[k].segmentFlightRefField[i].referencingDetailField[n].refNumberField==nrefNumberField)
                  {
                //    console.log("Found at k: " + k+ "; TripType: " + TripType  + "; Flight Index No: " + FlightIndexNo + "; QF: " + nrefQualifierField +"-"+ nrefNumberField );
                  FilteredArray.push(value[k]);
                  }
              }
            }
          }

        }



      // }
      // else {
      //   for (let k=0;k<=value.length-1;k++)
      //    {
      //      if(k==FlightIndexNo)
      //      {

      //            for(let i=0;i<=value[k].segmentFlightRefField.length-1;i++){
      //              for(let n=0;n<=value[k].segmentFlightRefField[i].referencingDetailField.length-1;n++){

      //              if (value[k].segmentFlightRefField[i].referencingDetailField[n].refQualifierField==nrefQualifierField
      //                && value[k].segmentFlightRefField[i].referencingDetailField[n].refNumberField==nrefNumberField)
      //                {

      //           //      console.log("Found at k: " + k+ "; TripType: " + TripType  + "; Flight Index No: " + FlightIndexNo + "; QF: " + nrefQualifierField +"-"+ nrefNumberField );
      //                  FilteredArray.push(value[k]);
      //                }
      //          }
      //        }
      //      }

      //    }
      //  }





    return FilteredArray;
  }

}
