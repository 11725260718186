import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterSegments'
})
export class FilterSegmentsPipe implements PipeTransform {

  transform(value: any, nrefQualifier: string): any {


    const FilteredArray:any[] =[];

    for(let i=0; i<=value.length-1; i++){
      //console.log(value[i].Code);
      // window.alert(nrefQualifier);
      // window.alert(value[i].Value);
      let arefQualifier:string=value[i].refQualifierField;
       arefQualifier = arefQualifier.toUpperCase();




        if(arefQualifier===nrefQualifier  ){
         FilteredArray.push(value[i]);
        }
    }
    return FilteredArray;
  }

}
