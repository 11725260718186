import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getTotalTaxFromPNR'
})
export class GetTotalTaxFromPNRPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): string {

    let TotalTax:number=0;
    let valueData=[];
    valueData.push(value);
    //tstDataField[0].fareDataField.taxFieldsField[0].taxAmountField

    for(let i=0; i<=valueData.length-1; i++){

      for (let n=0; n<valueData[i].fareDataField.taxFieldsField.length;n++){
        let amount=valueData[i].fareDataField.taxFieldsField[n].taxAmountField;
        let amt=parseInt(amount,10);
        TotalTax=TotalTax+amt;
      }

   }
   return TotalTax.toString();

  }

}
