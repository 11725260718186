import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterAirports'
})
export class FilterAirportsPipe implements PipeTransform {

  transform(value:any, nAirport:string): any {


    if(nAirport===""){
      return value;
    }

    const FilteredSirportArray:any[] =[];

    for(let i=0; i<=value.length-1; i++){
      //console.log(value[i].Code);

      let aairportname:string=value[i].CityName;
      aairportname = aairportname.toUpperCase();

      let aairportcode:string=value[i].Code;
      aairportcode= aairportcode.toUpperCase();


      nAirport = nAirport.toUpperCase();

       if(aairportname.startsWith(nAirport) || aairportcode===nAirport  ){
        FilteredSirportArray.push(value[i]);
       }
    }
    return FilteredSirportArray;
  }

}
