export class PnrName {
PaxTitle:string="";
PaxFirstName:string="";
PaxSurName:string="";
PaxDOB:string="";

constructor(PaxTitle,PaxFirstName,PaxSurName,PaxDOB) {
  this.PaxTitle=PaxTitle;
  this.PaxFirstName=PaxFirstName;
  this.PaxSurName=PaxSurName;
  this.PaxDOB=PaxDOB;

}


}
