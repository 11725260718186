import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'hitsDurationFormat'
})
export class HitsDurationFormatPipe implements PipeTransform {

  transform(value: string, TimeFormat: string): unknown {
    var H = value.substr(0, 2);
      if(H.substr(0,1)=="0"){
        H=H.substr(1,1);
      }

      H = H + " Hr ";

    var M = value.substr(2, 2);
      if(M=="00"){
        M="";
      }else{
        M = M + " Min.";
      }



    return (H + M );

  }


}
