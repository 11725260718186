import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getAirlinesFromResponse'
})
export class GetAirlinesFromResponsePipe implements PipeTransform {

  transform(value: any): unknown {
//    const gotAirlines:any[]=[];
let gotAirlines= new Set();

//console.log("Value>>>", value);
    //flightIndexField[0].groupOfFlightsField[14].flightDetailsField[0].flightInformationField.companyIdField.operatingCarrierField

    for (let i=0;i<value.length;i++){ //flightindex
  //    console.log("i>>", i);

      for (let n=0; n<value[i].groupOfFlightsField.length;n++){ //groupOfFlightsField
    //    console.log("n>>", n);

        for (let o=0;o<value[i].groupOfFlightsField[n].flightDetailsField.length;o++){ //flightDetailsField
        // console.log("o>>", o);
        // gotAirlines.add("New");
          gotAirlines.add(value[i].groupOfFlightsField[n].flightDetailsField[o].flightInformationField.companyIdField.operatingCarrierField.toString());
        }
      }

    }
//console.log("GotAirlines>>>", gotAirlines);

    return gotAirlines;
  }

}
