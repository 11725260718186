import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'journeyGraphicArray'
})
export class JourneyGraphicArrayPipe implements PipeTransform {

  transform(value: any, nn: string): any {

    const FilteredArray:any[] =[];


    for (let h=0;h<=value.length-1;h++){

      if(value[h].hasOwnProperty("flightInformationField"))
      {

        let ValArrar:any[]=[];

        ValArrar.push(value[h].flightInformationField); //informationfields
          for(let i=0;i<=ValArrar.length-1;i++)
          {
            let newItem: object ={};

              for (let n=0; n<=ValArrar[i].locationField.length-1;n++)
                {
                  let Location =ValArrar[i].locationField[n].locationIdField;
                  if(FilteredArray.indexOf(Location) === -1){
                    FilteredArray.push(Location);
                  }
                    // FilteredArray.indexOf(newItem) === -1 ? FilteredArray.push(newItem) : console.log("This item already exists");
                }

            // newItem.indexOf(Location) === -1 ? newItem.push(Location) : console.log("This item already exists");
            //FilteredArray
            // FilteredArray.indexOf(newItem) === -1 ? FilteredArray.push(newItem) : console.log("This item already exists");
        }

      }
    }
    return FilteredArray;

    //OLD
    // transform(value: any, nn: string): any {
    //   const ValArrar:any[]=[];
    //   ValArrar.push(value);
    //   const FilteredArray:any[] =[];


    //   let Journey:string ='';

    //   for(let i=0;i<=ValArrar.length-1;i++){
    //     for (let n=0; n<=ValArrar[i].locationField.length-1;n++){
    //       let newItem: object ={"locationIdField": ValArrar[i].locationField[n].locationIdField};
    //       FilteredArray.indexOf(newItem) === -1 ? FilteredArray.push(newItem) : console.log("This item already exists");
    //     }
    //   }


    // return FilteredArray;
  }

}
