export class Contacts {
  Email:string="";
  Mobile:string="";

  constructor(Email, Mobile){
    this.Email = Email;
    this.Mobile = Mobile;
  }



}


