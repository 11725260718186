import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ticketRefNo',
})
export class TicketRefNoPipe implements PipeTransform {
  // transform(value: any, Segment: string): any {
  transform(value: any, Segment: string): string {
    //dataElementsMasterField.dataElementsIndivField[3].elementManagementDataField.segmentNameField
    let RetData = [];
    let str: string = '';

    for (let i = 0; i < value.length; i++) {
      if (value[i].elementManagementDataField.segmentNameField == 'FA') {
        //RetData.push(value[i]);
        console.log("Found at "+i);
        console.log(value[i].otherDataFreetextField[0].longFreetextField);

        var strsplitted = value[i].otherDataFreetextField[0].longFreetextField.split('/', 1);
        // strsplitted[0];


        if(str.length>0){
          str =str + "<br/>"+ strsplitted[0];
        }else{
          str = strsplitted[0]
        }
        console.log(str);

      }
    }

    //str = str.replace('PAX ', '');
    //var strsplitted = str.split('/', 1);
    return str;
    // return RetData;
  }
}
