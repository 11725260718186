import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { WrapperComponent } from './wrapper/wrapper.component';
import { HeadSectionComponent } from './head-section/head-section.component';
import { HeadNoticeComponent } from './head-notice/head-notice.component';
import { NavbarTopComponent } from './navbar-top/navbar-top.component';
import { TopMenusComponent } from './top-menus/top-menus.component';
import { BrandLogoComponent } from './brand-logo/brand-logo.component';
import { HeadTopBrandComponent } from './head-top-brand/head-top-brand.component';
import { HeadMenusComponent } from './head-menus/head-menus.component';
import { SearchSectionComponent } from './search-section/search-section.component';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { FlightSearchFormComponent } from './flight-search-form/flight-search-form.component';
import { FilterAirportsPipe } from './filter-airports.pipe';
import { HitsDateFormatPipe } from './hits-date-format.pipe';
import { HitsTimeFormatPipe } from './hits-time-format.pipe';
import { FilterSegmentsPipe } from './filter-segments.pipe';
import { FilterFlightProposalPipe } from './filter-flight-proposal.pipe';
import { HitsDurationFormatPipe } from './hits-duration-format.pipe';
import { FilterRecommendationsSegmentPipe } from './filter-recommendations-segment.pipe';
import { NullToStringPipe } from './null-to-string.pipe';
import { FlightSearchWrapperComponent } from './flight-search-wrapper/flight-search-wrapper.component';
import { FlightListComponent } from './flight-list/flight-list.component';


import {ReactiveFormsModule} from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { FilterAirportByCodePipe } from './filter-airport-by-code.pipe';
import {HttpClientModule} from '@angular/common/http';
import {InterceptorModule} from './interceptor.module';
import { ObjArrayPipe } from './obj-array.pipe';
import { JourneyGraphicArrayPipe } from './journey-graphic-array.pipe';
import { DisplayDateTimePipe } from './display-date-time.pipe';
import { GetFareToDisplayPipe } from './Pipes/get-fare-to-display.pipe';
import {GetNoofStopsPipe} from './Pipes/get-noof-stops.pipe';
import {GetFlightsBagPipe} from './Pipes/get-flights-bag.pipe';
import { GetFlightsFromFlightIndexPipe } from './Pipes/get-flights-from-flight-index.pipe';
import { GetBaggagePipe } from './Pipes/get-baggage.pipe';
import { FlightResultsComponent } from './flight-results/flight-results.component';
import { FlightReviewComponent } from './flight-review/flight-review.component';
import { TextMaskModule } from 'angular2-text-mask';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { HomeComponent } from './home/home.component';
import { GetTotalTaxFromFareInfoPipe } from './Pipes/get-total-tax-from-fare-info.pipe';
import { GetTotalPayableFromFareInfoPipe } from './Pipes/get-total-payable-from-fare-info.pipe';
import { TicketComponent } from './ticket/ticket.component';
import { ClassDesignatorPipe } from './Pipes/class-designator.pipe';
import { GetTotalTaxFromPNRPipe } from './Pipes/get-total-tax-from-pnr.pipe';
import { GetTotalFareFromPNRPipe } from './Pipes/get-total-fare-from-pnr.pipe';
import { GetTotalPayableFromPNRPipe } from './Pipes/get-total-payable-from-pnr.pipe';
import { GetGSTFromPNRPipe } from './Pipes/get-gstfrom-pnr.pipe';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { ImgPathPipe } from './Pipes/img-path.pipe';

import { AirlineImagePathPipe } from './Pipes/airline-image-path.pipe';
import { HitsAlphabetOnlyDirective } from './Directives/hits-alphabet-only.directive';
import { TicketRefNoPipe } from './Pipes/ticket-ref-no.pipe';
import { StatusTextPipe } from './Pipes/status-text.pipe';

import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { FirebaseService } from './fireservices/firebase.service';
import { PERSISTENCE } from '@angular/fire/auth';
import { FilterRecommendationsPipe } from './Pipes/filter-recommendations.pipe';
import { GetAirlinesFromResponsePipe } from './Pipes/get-airlines-from-response.pipe';
import { DepArrCitiesPipe } from './Pipes/dep-arr-cities.pipe';
import { RecommendationFilterPipe } from './Pipes/recommendation-filter.pipe';
import { GetBaggageUnitPipe } from './Pipes/get-baggage-unit.pipe';
import { SessionModalComponent } from './Components/session-modal/session-modal.component';
import { CcavePayComponent } from './Components/ccave-pay/ccave-pay.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingComponent } from './Components/loading/loading.component';
import { UserProfileComponent } from './Components/user-profile/user-profile.component';
import { PhoneLoginComponent } from './Components/phone-login/phone-login.component';



// var firebaseConfig = {
//   apiKey: "AIzaSyBWqlNuotq-fkFIiLCay3ixcZEHY-NATb4",
//   authDomain: "cosmictours-708bb.firebaseapp.com",
//   projectId: "cosmictours-708bb",
//   storageBucket: "cosmictours-708bb.appspot.com",
//   messagingSenderId: "102944536643",
//   appId: "1:102944536643:web:c36900b555af46836d3cf6",
//   measurementId: "G-DQ3JTWCJ14"
// };


@NgModule({
  declarations: [
    AppComponent,
    // TaskDialogComponent,
    // TaskComponent,
    WrapperComponent,
    HeadSectionComponent,
    HeadNoticeComponent,
    NavbarTopComponent,
    TopMenusComponent,
    BrandLogoComponent,
    HeadTopBrandComponent,
    HeadMenusComponent,
    SearchSectionComponent,
    RadioGroupComponent,
    FlightSearchFormComponent,
    FilterAirportsPipe,
    FilterAirportByCodePipe,
    HitsDateFormatPipe,
    HitsTimeFormatPipe,
    ObjArrayPipe,
    FilterSegmentsPipe,
    FilterFlightProposalPipe,
    HitsDurationFormatPipe,
    FilterRecommendationsSegmentPipe,
    NullToStringPipe,
    JourneyGraphicArrayPipe,
    DisplayDateTimePipe,
    GetFareToDisplayPipe,
    GetFlightsBagPipe,
    GetFlightsFromFlightIndexPipe,
    GetBaggagePipe,
    FlightSearchWrapperComponent,
    FlightListComponent,
    FlightResultsComponent,
    FlightReviewComponent,
    GetTotalTaxFromFareInfoPipe,
    GetTotalPayableFromFareInfoPipe,
    HomeComponent,
    TicketComponent,
    ClassDesignatorPipe,
    FilterRecommendationsPipe,
    GetAirlinesFromResponsePipe,
    DepArrCitiesPipe,
    GetTotalTaxFromPNRPipe,
    GetTotalFareFromPNRPipe,
    GetTotalPayableFromPNRPipe,
    GetGSTFromPNRPipe,
    LoginModalComponent,
    ImgPathPipe,
    HitsAlphabetOnlyDirective,
    TicketRefNoPipe,
    StatusTextPipe,
    AirlineImagePathPipe,
    GetNoofStopsPipe,
    RecommendationFilterPipe,
    GetBaggageUnitPipe,
    SessionModalComponent,
    CcavePayComponent,
    LoadingComponent,
    UserProfileComponent,
    PhoneLoginComponent






  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    // AngularFireModule.initializeApp(firebaseConfig),
    // AngularFireAuthModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,

    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    InterceptorModule,
    TextMaskModule,
    NgbModule



  ],
  providers: [CookieService ,
    FirebaseService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: PERSISTENCE, useValue: 'session' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

