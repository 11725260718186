import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'hitsTimeFormat'
})
export class HitsTimeFormatPipe implements PipeTransform {

  transform(value: string, TimeFormat: string): unknown {
    var H = value.substr(0, 2);
    var M = value.substr(2, 2);

    var dd ="2020"+"/01/01 "+ H +":" + M;

    //return H +":"+ M ;

    return moment(dd,).format(TimeFormat);

  }

}
