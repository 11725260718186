<app-head-section></app-head-section>

<div class="ResultWrapper container-fluid">
    <!-- <div class='row'>
        <div class="cols col-sm-12">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div> -->

    <div id="FlightResults" *ngIf="!NoCooks">
        <div class="sideBar">
            <div style="display: grid">
                <div class="m-auto spinner-border text-primary" role="status" *ngIf="loading">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div *ngIf="LoggedUser">
                <!-- {{ LoggedUser.email }} -->
            </div>
            <!-- <br /> {{ HitLoginState }} -->
            <div *ngIf="ResponseFlightResult">
                <h2>No of Stops</h2>
                <div *ngFor="let m of this.selectedNoofStops">
                    <h3>{{ m }}</h3>
                </div>
                <!-- flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.companyIdField.operatingCarrierField -->
                <div class="SideBarCardContainer" *ngFor="let response of ResponseFlightData; let i = index">
                    <div class="" *ngFor="let NoofStops of response.flightIndexField | getNoofStops">
                        <mat-checkbox (change)="onChangeCombinedCode($event, 'NoofStops')" value="{{ NoofStops }}" color="primary" class="example-margin">{{ NoofStops }}</mat-checkbox><br />
                    </div>
                </div>
                <hr />

                <h2>Departure Time</h2>
                <div *ngFor="let m of this.selectedDepTimes">
                    <h3>{{ m }}</h3>
                </div>
                <!-- flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.companyIdField.operatingCarrierField -->
                <div class="mb-1" *ngFor="let response of ResponseFlightData; let i = index">
                    <div class="mb-4" *ngFor="
              let DepLocation of response.flightIndexField
                | depArrCities: 'Dep';
              let ii = index
            ">
                        <h3 classs="CTime">{{ DepLocation }}</h3>
                        <!-- <mat-checkbox (change)='onChangeDep($event)' value="{{DepLocation}}" color='primary' class="example-margin">{{DepLocation}}</mat-checkbox> -->
                        <div class="SideBarCardContainer">
                            <div class="" (click)="getFlights('05to12', 'Dep')">
                                <mat-checkbox (change)="onChangeCombinedCode($event, 'Dep')" value="05-12_{{ ii }}" color="primary" class="example-margin">05-12
                                </mat-checkbox>
                            </div>
                            <div class="" (click)="getFlights('12to18', 'Dep')">
                                <mat-checkbox (change)="onChangeCombinedCode($event, 'Dep')" value="12-18_{{ ii }}" color="primary" class="example-margin">12-18
                                </mat-checkbox>
                            </div>
                            <div class="" (click)="getFlights('18to24', 'Dep')">
                                <mat-checkbox (change)="onChangeCombinedCode($event, 'Dep')" value="18-24_{{ ii }}" color="primary" class="example-margin">18-24
                                </mat-checkbox>
                            </div>
                            <div class="" (click)="getFlights('24to05', 'Dep')">
                                <mat-checkbox (change)="onChangeCombinedCode($event, 'Dep')" value="24-05_{{ ii }}" color="primary" class="example-margin">24-05
                                </mat-checkbox>
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>

                <h2>Arrival Time</h2>

                <div *ngFor="let m of this.selectedArrTimes">
                    <h3>{{ m }}</h3>
                </div>
                <!-- flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.companyIdField.operatingCarrierField -->
                <div class="mb-1" *ngFor="let response of ResponseFlightData; let i = index">
                    <div class="mb-4" *ngFor="
              let DepLocation of response.flightIndexField
                | depArrCities: 'Arr';
              let ii = index
            ">
                        <h3 classs="CTime">{{ DepLocation }}</h3>
                        <!-- <mat-checkbox (change)='onChangeDep($event)' value="{{DepLocation}}" color='primary' class="example-margin">{{DepLocation}}</mat-checkbox> -->
                        <div class="SideBarCardContainer">
                            <div class="" (click)="getFlights('05to12', 'Arr')">
                                <mat-checkbox (change)="onChangeCombinedCode($event, 'Arr')" value="05-12_{{ ii }}" color="primary" class="example-margin">05-12</mat-checkbox>
                            </div>
                            <div class="" (click)="getFlights('12to18', 'Arr')">
                                <mat-checkbox (change)="onChangeCombinedCode($event, 'Arr')" value="12-18_{{ ii }}" color="primary" class="example-margin">12-18</mat-checkbox>
                            </div>
                            <div class="" (click)="getFlights('18to24', 'Arr')">
                                <mat-checkbox (change)="onChangeCombinedCode($event, 'Arr')" value="18-24_{{ ii }}" color="primary" class="example-margin">18-24</mat-checkbox>
                            </div>
                            <div class="" (click)="getFlights('24to05', 'Arr')">
                                <mat-checkbox (change)="onChangeCombinedCode($event, 'Arr')" value="24-05_{{ ii }}" color="primary" class="example-margin">24-05</mat-checkbox>
                            </div>
                        </div>
                        <hr />
                    </div>
                </div>

                <h2>Price Range</h2>
                <mat-slider thumbLabel [displayWith]="formatLabel" (input)="onChangeCombinedCode($event, 'Price')" tickInterval="1000" step="1000" min="{{ this.minPrice }}" max="{{ this.maxPrice }}" aria-label="units" [(value)]="selectedMaxPrice"></mat-slider>
                {{ selectedMaxPrice }}<br /> {{ minPrice }}-{{ selectedMaxPrice }}

                <!--<hr>
                 <div class="SideBarCardContainer">
                    <div class="SideBarCard" (click)="getFlights('05to12', 'Dep')">
                        05-12
                    </div>
                    <div class="SideBarCard" (click)="getFlights('12to18', 'Dep')">
                        12-18
                    </div>
                    <div class="SideBarCard" (click)="getFlights('18to24', 'Dep')">
                        18-24
                    </div>
                    <div class="SideBarCard" (click)="getFlights('24to05', 'Dep')">
                        24-05
                    </div>
                </div> -->

                <h2>Airlines</h2>
                <div *ngFor="let m of this.selectedAirlines">
                    <h3>{{ m }}</h3>
                </div>

                <div class="SideBarCardContainer" *ngFor="let response of ResponseFlightData; let i = index">
                    <div class="" *ngFor="
              let Airlines of response.flightIndexField
                | getAirlinesFromResponse
            ">
                        <mat-checkbox (change)="onChangeCombinedCode($event, 'Airlines')" value="{{ Airlines }}" color="primary" class="example-margin">{{ Airlines }}</mat-checkbox><br />
                    </div>
                </div>
            </div>
        </div>
        <div class="contentBar">
            <div style="display: grid" *ngIf="loading">
                <div class="m-auto spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="card mb-2 bg-success p-2" *ngIf="isCosmicAirInventory">
                <h1>Cosmic Air Inventory</h1>
                <hr>

            </div>

            <div *ngIf="ResponseFlight">
                <div *ngIf="ReviewComponent == false">
                    <!-- if Error  in Flight search Reponse-->
                    <div *ngFor="let responseEr of ResponseFlightDataEr">
                        Error {{ responseEr.errorMessageField.errorMessageTextField .descriptionField }}
                    </div>

                    <!-- if No Error in Flight search Reponse-->
                    <!-- <div *ngFor="
              let response of ResponseFlightDataFull
                | recommendationFilter
                  : this.selectedArrTimes
                  : this.selectedDepTimes
                  : this.selectedAirlines
                  : this.selectedNoofStops
                  : this.selectedMaxPrice;
              let responseNo = index
            "> -->

                    <div *ngFor="
              let response of ResponseFlightDataFull;
              let responseNo = index
            ">
                        <div class="" *ngFor="
                let recommendationField of response.recommendationField;
                let recommendationFieldNo = index
              ">
                            <!-- Recommendation # {{ recommendationFieldNo }} -->
                            <!-- recommendationField[0].segmentFlightRefField[0] -->
                            <div class="Recommendationss" *ngFor="
                  let segmentFlightRefField of recommendationField.segmentFlightRefField;
                  let segmentFlightRefFieldNo = index
                ">
                                <!-- segmentFlightRefFieldNo # {{ segmentFlightRefFieldNo }} -->
                                <!-- recommendationField[0].segmentFlightRefField[0].referencingDetailField -->

                                <div class="Trips">
                                    <div class="flightGroupContainer">
                                        <!-- <div class="FlightIndividualss" *ngFor="let referencingDetailField of segmentFlightRefField.referencingDetailField |
              getFlightsBag:'S' let referencingDetailFieldNo=index "> -->
                                        <div class="FlightIndividualss" *ngFor="
                        let referencingDetailField of segmentFlightRefField.referencingDetailField
                          | getFlightsBag: 'S';
                        let referencingDetailFieldNo = index
                      ">
                                            <!-- referencingDetailFieldNo # = {{referencingDetailFieldNo}}; -->

                                            <!-- Flights: {{referencingDetailField.refQualifierField}}-{{referencingDetailField.refNumberField}}-Findex-{{referencingDetailFieldNo}} -->

                                            <div class="flightsGroup" *ngFor="
                          let groupOfFlightsField of response.flightIndexField
                            | getFlightsFromFlightIndex
                              : referencingDetailFieldNo
                              : referencingDetailField.refNumberField;
                          let groupOfFlightsFieldNo = index
                        ">
                                                <div class="N">
                                                    <div *ngFor="
                              let flightDetailsField of groupOfFlightsField.flightDetailsField;
                              let r = index
                            ">
                                                        <!-- Group of Flights -->

                                                        <div class="flights" *ngFor="
                                let flightInformationField of flightDetailsField.flightInformationField
                                  | objArray
                                    : flightDetailsField.flightInformationField
                              ">
                                                            <!-- {{flightInformationField.flightOrtrainNumberField}} -->
                                                            <!--  <div *ngFor="let companyIdField of flightInformationField.companyIdField">
                              {{companyIdField.marketingCarrierField}}<br>
                              {{companyIdField.operatingCarrierField}}<br>
                              <br>
                            </div>-->

                                                            <div class="DGrid CenterBoth">
                                                                <!-- flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.companyIdField.marketingCarrierField -->
                                                                <div *ngFor="
                                    let imgPathAirlineName of flightDetailsField
                                      .flightInformationField.companyIdField
                                      .marketingCarrierField | airlineImagePath:'airlines'
                                  ">
                                                                    <img style="width: 80%; margin-top: 10%" src="{{ imgPathAirlineName }}" />
                                                                </div>
                                                                {{ flightDetailsField.flightInformationField .flightOrtrainNumberField }}
                                                            </div>

                                                            <div class="DGrid CenterBoth">
                                                                <span class="material-icons">
                                  flight_takeoff
                                </span>
                                                                <span class="CTime">{{
                                  flightDetailsField.flightInformationField
                                    .productDateTimeField.timeOfDepartureField
                                    | displayDateTime: "Time"
                                }}</span>
                                                                <!-- flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.productDateTimeField.timeOfDepartureField -->
                                                                <!-- flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.locationField[0].locationIdField-->
                                                                <span *ngFor="
                                    let DepartureLocation of flightDetailsField
                                      .flightInformationField.locationField
                                      | slice: 0:1
                                  ">{{ DepartureLocation.locationIdField }}</span
                                >
                              </div>

                              <div class="DGrid CenterBoth">
                                <span class="material-icons">
                                  flight_land
                                </span>
                                                                <span class="CTime">{{
                                    flightDetailsField.flightInformationField
                                      .productDateTimeField.timeOfArrivalField
                                      | displayDateTime: "Time"
                                  }}
                                  <!-- <br>
                            {{flightDetailsField.flightInformationField.productDateTimeField.timeOfArrivalField }} -->
                                </span>
                                                                <!-- flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.productDateTimeField.timeOfDepartureField -->
                                                                <!-- flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.locationField[0].locationIdField-->
                                                                <span *ngFor="
                                    let ArrivalLocation of flightDetailsField
                                      .flightInformationField.locationField
                                      | slice: 1:2
                                  ">{{ ArrivalLocation.locationIdField }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="DGrid CenterBoth">
                          <!-- flightIndexField[0].groupOfFlightsField[0].propFlightGrDetailField.flightProposalField[1].refField -->
                          <div class="CenterBoth">
                            <!-- <span class="material-icons">
                          timer
                        </span><br> -->

                                                                <span *ngFor="
                                let flightProposalField of groupOfFlightsField
                                  .propFlightGrDetailField.flightProposalField
                                  | filterFlightProposal: 'EFT';
                                let k = index
                              ">
                              {{
                                flightProposalField.refField
                                  | hitsDurationFormat: "hh mm"
                              }} </span
                            ><br />

                            <span
                              *ngFor="
                                let ArrivalLocation of groupOfFlightsField.flightDetailsField
                                  | journeyGraphicArray
                                    : groupOfFlightsField.flightDetailsField
                              "
                            >
                              <!-- Array=  {{ArrivalLocation | json}} -->
                              {{ ArrivalLocation }}
                              <!-- {{ ArrivalLocation | slice:1:ArrivalLocation.length-1 }} --> </span
                            ><br />
                          </div>
                          <div class="DGrid CenterBoth">
                            <span class="FlightDetailsA">
                              <!-- GetFlightsInfoNew -->
                              <!-- <span
                              (click)="
                              AirSellInfo(
                                  groupOfFlightsField,
                                  groupOfFlightsFieldNo
                                )
                              "
                              href="#"
                              class="pointer FlightDetailsA"
                              >+ Flight Details</span> -->
                                                                </span>
                                                                <br />

                                                                <span (click)="
                                BookClickedForInfo(
                                  segmentFlightRefField.referencingDetailField,
                                  recommendationField.paxFareProductField[0]
                                    .fareDetailsField,
                                  response.flightIndexField
                                )
                              " href="#" class="pointer FlightDetailsA">+ Flight Details</span
                            >
                          </div>
                        </div>
                      </div>
                      <!--flightsGroup-->
                      <!-- <div class="FlightDetailsDes"></div> -->
                    </div>
                    <!--Flight Individual-->
                  </div>
                  <!--div-->

                  <div
                    class="DGrid GGap-5 CenterBoth"
                    *ngFor="
                      let referencingDetailField of segmentFlightRefField.referencingDetailField
                        | getFlightsBag: 'B';
                      let referencingDetailFieldNo = index
                    "
                  >
                    <!-- Baggage: {{referencingDetailField.refQualifierField}}-{{referencingDetailField.refNumberField}}-Findex-{{referencingDetailFieldNo}} -->
                    <div>
                      <span class="material-icons"> shopping_bag </span><br />{{ response.serviceFeesGrpField | getBaggage: referencingDetailField.refNumberField }}
                                                            </div>
                                                        </div>

                                                        <div class="DGrid GGap-5 CenterBoth">
                                                            <div>
                                                                <span class="material-icons">
                        airline_seat_legroom_normal </span
                      ><br />
                      {{
                        recommendationField.paxFareProductField[0]
                          .fareDetailsField[0].groupOfFaresField[0]
                          .productInformationField.cabinProductField
                          .avlStatusField
                      }}
                    </div>
                  </div>

                  <div class="Price DGrid GGap-5 CenterBoth">
                    <span class="Price">
                      {{
                        recommendationField.recPriceInfoField
                          | getFareToDisplay
                            : recommendationField.recPriceInfoField
                          | currency: "INR":"symbol":"1.0-0"
                      }}
                    </span>
                                                                <div>
                                                                    <div style="display: grid" *ngIf="loading">
                                                                        <div class="m-auto spinner-border text-primary" role="status">
                                                                            <span class="sr-only">Loading...</span>
                                                                        </div>
                                                                    </div>
                                                                    <button (click)="
                          checkSession(
                            segmentFlightRefField.referencingDetailField,
                            recommendationField.paxFareProductField[0]
                              .fareDetailsField,
                            response.flightIndexField
                          )
                        " class="btn btn-danger">
                        Book Now</button
                      ><br />
                    </div>
                  </div>
                </div>
                <!--Trips-->
              </div>
              <!--segmentFlightRefField-->
            </div>
            <!--recommendations-->
          </div>
          <!--response-->
        </div>
      </div>
    </div>
    <!--Content Bar-->
  </div>
  <!--FlightResults-->
</div>
<!--container-->
<div #RightBars id="RightBarstyle">
  <button class="btn btn-danger" (click)="hideInfo()">X</button>
                                                                    <div *ngIf="FlightInfo == true">
                                                                        <div class="AdtPax">
                                                                            <mat-tab-group>
                                                                                <mat-tab label="Flight Info">
                                                                                    <div *ngFor="let FlightInfo of FlightInfoData">
                                                                                        <div *ngFor="
                let pricingGroupLevelGroupField of FlightInfo.mainGroupField
                  .pricingGroupLevelGroupField | slice: 0:1;
                let priceIndex = index
              ">
                                                                                            <div class="card FlightDetails m-2" *ngFor="
                  let segmentLevelGroupField of pricingGroupLevelGroupField
                    .fareInfoGroupField.segmentLevelGroupField
                ">
                                                                                                <div>
                                                                                                    <div class="row InfoFlight">
                                                                                                        <div class="cols col-sm-12 col-md-10">
                                                                                                            <img style="width: 100px" src="Airlines/{{
                          segmentLevelGroupField.segmentInformationField
                            .companyDetailsField.marketingCompanyField
                        }}.png" /> {{ segmentLevelGroupField.segmentInformationField .companyDetailsField.marketingCompanyField }}
                                                                                                            <br /> {{ segmentLevelGroupField.segmentInformationField .flightIdentificationField.flightNumberField }}
                                                                                                        </div>

                                                                                                        <div class="cols col-sm-12 col-md-2">
                                                                                                            {{ segmentLevelGroupField.segmentInformationField .flightIdentificationField.bookingClassField }}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <div class="row">
                                                                                                        <div class="cols col-sm-4 al-r">
                                                                                                            <div class="HType1">
                                                                                                                {{ segmentLevelGroupField.segmentInformationField .boardPointDetailsField.trueLocationIdField }}
                                                                                                            </div>

                                                                                                            <!-- Dep. Date: {{pricingGroupLevelGroupField.fareInfoGroupField.pricingIndicatorsField.productDateTimeDetailsField.departureDateField}}<br> -->
                                                                                                            <!-- Dep. Time: {{pricingGroupLevelGroupField.fareInfoGroupField.pricingIndicatorsField.productDateTimeDetailsField.departureTimeField}}<br> -->

                                                                                                            Departure Date: {{ segmentLevelGroupField.segmentInformationField .flightDateField.departureDateField }}
                                                                                                            <br /> Departure Time: {{ segmentLevelGroupField.segmentInformationField .flightDateField.departureTimeField }}
                                                                                                            <br />
                                                                                                        </div>
                                                                                                        <div class="cols col-sm-4">
                                                                                                            Baggage:
                                                                                                            <div class="HType1">
                                                                                                                {{ segmentLevelGroupField.segmentInformationField .boardPointDetailsField.trueLocationIdField }}- {{ segmentLevelGroupField.segmentInformationField .offpointDetailsField.trueLocationIdField }}
                                                                                                            </div>
                                                                                                            {{ segmentLevelGroupField.baggageAllowanceField .baggageDetailsField.freeAllowanceField }} {{ segmentLevelGroupField.baggageAllowanceField .baggageDetailsField.quantityCodeField }}
                                                                                                            <br /> Pax Type: {{ segmentLevelGroupField.ptcSegmentField .quantityDetailsField.numberOfUnitField }} {{ segmentLevelGroupField.ptcSegmentField
                                                                                                            .quantityDetailsField.unitQualifierField }}
                                                                                                            <br />
                                                                                                        </div>
                                                                                                        <div class="cols col-sm-4 al-l">
                                                                                                            <h2>
                                                                                                                {{ segmentLevelGroupField.segmentInformationField .offpointDetailsField.trueLocationIdField }}
                                                                                                            </h2>

                                                                                                            <!-- Arrival On: {{segmentLevelGroupField.additionalInformationField.productDateTimeDetailsField.arrivalDateField}}<br>
                    Arrival Time: {{segmentLevelGroupField.additionalInformationField.productDateTimeDetailsField.arrivalDateField}}<br> -->
                                                                                                            Arrival Date: {{ segmentLevelGroupField.segmentInformationField .flightDateField.arrivalDateField }}
                                                                                                            <br /> Arrival Time: {{ segmentLevelGroupField.segmentInformationField .flightDateField.arrivalTimeField }}
                                                                                                            <br />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <br />
                                                                                            </div>
                                                                                            <div class="card m-2">
                                                                                                <div class="InfoFlight">Other Details</div>
                                                                                                <ul>
                                                                                                    <li>
                                                                                                        Unique Ref: {{ pricingGroupLevelGroupField.fareInfoGroupField .offerReferencesField.offerIdentifierField .uniqueOfferReferenceField }}
                                                                                                    </li>

                                                                                                    <li *ngFor="
                      let textDataField of pricingGroupLevelGroupField
                        .fareInfoGroupField.textDataField
                    ">
                                                                                                        {{ textDataField.freeTextField[0] }}<br />
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </mat-tab>

                                                                                <mat-tab label="Fares">
                                                                                    <div *ngFor="let FlightInfo of FlightInfoData">
                                                                                        <div *ngFor="
                let pricingGroupLevelGroupField of FlightInfo.mainGroupField
                  .pricingGroupLevelGroupField | slice: 0:1
              ">
                                                                                            Base Fare: {{ pricingGroupLevelGroupField.fareInfoGroupField.fareAmountField .monetaryDetailsField.amountField }} Total Fare: {{ pricingGroupLevelGroupField.fareInfoGroupField.fareAmountField .otherMonetaryDetailsField[0].amountField }}
                                                                                            <div *ngFor="
                  let fareComponentDetailsGroupField of pricingGroupLevelGroupField
                    .fareInfoGroupField.fareComponentDetailsGroupField
                ">
                                                                                                Monetary: {{ fareComponentDetailsGroupField.monetaryInformationField .monetaryDetailsField.amountField }}
                                                                                                <br />
                                                                                            </div>
                                                                                            <!-- Unique Ref: {{pricingGroupLevelGroupField.fareInfoGroupField.offerReferencesField.offerIdentifierField.uniqueOfferReferenceField}}<br> -->
                                                                                            <!-- Dep. Date: {{pricingGroupLevelGroupField.fareInfoGroupField.pricingIndicatorsField.productDateTimeDetailsField.departureDateField}}<br>
            Dep. Time: {{pricingGroupLevelGroupField.fareInfoGroupField.pricingIndicatorsField.productDateTimeDetailsField.departureTimeField}}<br> -->

                                                                                            Surcharges:<br />
                                                                                            <div *ngFor="
                  let taxDetailsField of pricingGroupLevelGroupField
                    .fareInfoGroupField.surchargesGroupField.taxesAmountField
                    .taxDetailsField
                ">
                                                                                                {{ taxDetailsField.typeField[0] }}-- {{ taxDetailsField.rateField }}<br />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </mat-tab>

                                                                                <mat-tab label="Free Baggage">
                                                                                    <div *ngFor="let FlightInfo of FlightInfoData">
                                                                                        <div *ngFor="
                let pricingGroupLevelGroupField of FlightInfo.mainGroupField
                  .pricingGroupLevelGroupField | slice: 0:1
              ">
                                                                                            <div class="card FlightDetails InfoFlight" *ngFor="
                  let segmentLevelGroupField of pricingGroupLevelGroupField
                    .fareInfoGroupField.segmentLevelGroupField
                ">
                                                                                                Baggage: {{ segmentLevelGroupField.baggageAllowanceField .baggageDetailsField.freeAllowanceField }} {{ segmentLevelGroupField.baggageAllowanceField .baggageDetailsField.quantityCodeField }}
                                                                                                <br /> Pax Type: {{ segmentLevelGroupField.ptcSegmentField.quantityDetailsField .numberOfUnitField }} {{ segmentLevelGroupField.ptcSegmentField.quantityDetailsField
                                                                                                .unitQualifierField }}
                                                                                                <br />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </mat-tab>
                                                                            </mat-tab-group>
                                                                        </div>
                                                                    </div>

                                                                    <!--
  <div *ngIf="FareRules">
    <h3>FareRules</h3>
    <ol *ngFor="let fareRules of FareRulesData">
      <li *ngFor="let tariffInfoField of fareRules.tariffInfoField">
        <span
          *ngFor="
            let fareRuleTextField of tariffInfoField.fareRuleTextField
          "
        >
          {{ fareRuleTextField.freeTextField[0] }}
        </span>
      </li>
    </ol>
  </div> -->
                                                                </div>
                                                                <div *ngIf="NoCooks">
                                                                    <app-session-modal></app-session-modal>
                                                                </div>