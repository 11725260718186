import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'airlineImagePath'
})
export class AirlineImagePathPipe implements PipeTransform {

  transform(value: any, type: string): unknown {
    let path: any=[];
    let nm: string="";
    let d:string="";
    if(type.length>0){
      d=type+"/";
      nm = "assets/"+d+value+".png";
    }
    if(type=="profile"){
      nm=value;
    }




    path.push(nm);
//console.log(path);
  return path;
  }

}
