import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'classDesignator'
})
export class ClassDesignatorPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    let ClassValue="";
    if(value=="M"){
      ClassValue="Economy";
    }
    if(value=="W"){
      ClassValue="Economy Premium";
    }
    if(value=="C"){
      ClassValue="Business Club";
    }
    if(value=="F"){
      ClassValue="First";
    }
    if(value=="Y"){
      ClassValue="Economy All";
    }




    return ClassValue;
  }

}
