import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/fireservices/firebase.service';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';

import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';
import { CdkNoDataRow } from '@angular/cdk/table';
import {AuthService} from '../auth/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { ErrorMessageService } from '../Services/error-message.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.css'],
})

export class LoginModalComponent implements OnInit {
  // public apiUrl = 'https://localhost:44317/';
  //public apiUrl= "https://cosmictours2021.azurewebsites.net/";
  apiUrl = environment.apiUrl;
  isSignedIn = false;
  private cosmicHits:string;
  public cosmicVisits:string;
  NoCooks: boolean;
  PhoneNumberLogin: boolean;


  constructor(
    private cookieService: CookieService,
    public firebaseService: FirebaseService,
    private httpClient: HttpClient,
    public afAuth: AngularFireAuth,
    public HitAuth:AuthService,
    public errorMessageService: ErrorMessageService
  ) {

    this.cosmicHits = this.cookieService.get('cosmicHits');
  }


  GoogleSignIn() {
    const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
    this.afAuth.signInWithPopup(googleAuthProvider).then(async(res)=>{
      await sessionStorage.setItem('user',JSON.stringify(res));
  //    console.log('Token', res.user.refreshToken);
  //    this.registerUser( res);
    });
  }

  GoogleSignOut() {
    this.afAuth.signOut();
    this.HitAuth.setLoginState(false);
  }

  ngOnInit(): void {


  }

  async onFacebookSignin() {}
  async onSignInEP(email: string, password: string) {


    if (!this.cookieService.get('cosmicHits')) {
    //  console.log('No cookie');
      this.errorMessageService.seterrorMessage("Oops!! Your session Expired... Kindly restart searching your itinerary.")
      this.NoCooks=true;
    //  this.loading=false;
    }else{



    console.log('I got here');

    await this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then( async (res) => {
        await sessionStorage.setItem('user',JSON.stringify(res));
        // this.HitAuth.hitloginStatus=true;
        this.HitAuth.setLoginState(true);
        //console.log('Email>>', res.user.email);
        //console.log('RefToken>>', res.user.refreshToken);
   //     this.registerUser();

      })
      .catch((err) => {
        if (err.code) return { isValid: false, message: err.message };
      });
    }
  }

  async onSignUpEP(email: string, password: string) {
    console.log("Hey SignUpEP");
    try{
      await this.firebaseService.SignUpEP(email, password).then(async(data) => {
        await sessionStorage.setItem('user',JSON.stringify(data));
     //   console.log('Data >>  ', data);
    //     this.registerUser();
      });

    }catch(err){console.log (err)}







    if (this.firebaseService.isLoggedIn) {
      let token = '';

      //this.registerUser(email, password, token);
      this.isSignedIn = true;
      this.HitAuth.setLoginState(true);
    //  this.registerUser();
    } else {
      this.HitAuth.setLoginState(false);
      this.isSignedIn = false;
    }


  }

  // registerUser() {
  //   this.cosmicHits = this.cookieService.get('cosmicHits');
  //   this.cosmicVisits = this.cookieService.get('cosmicVisits');

  //   console.log('Registration Initiated');
  //   let data = sessionStorage.getItem('user');
  //   let obj = JSON.parse(data);
  //   console.log(obj);
  //   // displayname:string,
  //   // providerid:string, photourl:string, Hit:string
  //   if (obj.user.email != undefined) {
  //     //stsTokenManager.refreshToken
  //     let s =
  //       '{"FUid":"' +
  //       obj.user.uid +
  //       '","email":"' +
  //       obj.user.email +
  //       '","emailVerified":"' +
  //       obj.user.emailVerified +
  //       '","password":"' +
  //       '' +
  //       '","accessToken":"' +
  //       obj.user.stsTokenManager.accessToken +
  //       '","refreshToken":"' +
  //       obj.user.stsTokenManager.refreshToken +
  //       '","displayName":"' +
  //       obj.user.displayName +
  //       '","phoneNumber":"' +
  //       obj.user.phoneNumber +
  //       '","providerId":"' +
  //       obj.user.providerData[0].providerId +
  //       '","photourl":"' +
  //       obj.user.photourl +
  //       '","Hit":"' +
  //       this.cosmicHits +
  //       '","cosmicVisits":"' +
  //       this.cosmicVisits +
  //       '"}';

  //     //let headers = new HttpHeaders().append('Content-Type','application/json; charset=utf-8');
  //     //let params = new HttpParams().set('TrackerID', ""); // create params object

  //     let headersa = new HttpHeaders().append(
  //       'Content-Type',
  //       'application/json; charset=utf-8'
  //     );

  //     const httOptions = {
  //       headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  //       withCredentials: true,
  //       observe: 'response' as 'response',
  //       // params: params
  //     };

  //     this.httpClient

  //       .post(this.apiUrl + 'api/Auth', s, { headers: headersa })
  //       .subscribe((data: any) => {
  //         console.log('RegisterResponse', data);

  //         if (data.body == 'No') {
  //         } else {
  //           console.log("Check here");
  //         }
  //       });
  //   }
  // }

  getPhoneLogin(){
    this.PhoneNumberLogin=true;
  }

}
