import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../fireservices/firebase.service';
import { AngularFireAuth, PERSISTENCE  } from '@angular/fire/auth';
import { AuthService} from '../auth/auth.service';


//import { getAuth, setPersistence, signInWithEmailAndPassword, browserSessionPersistence } from "firebase@A";

import firebase from 'firebase/app';
import { User } from '../auth/user';



@Component({
  selector: 'app-head-notice',
  templateUrl: './head-notice.component.html',
  styleUrls: ['./head-notice.component.css'],
})
export class HeadNoticeComponent implements OnInit {
  public LoginStatus = false;
  public LoggedUser:User;
  isSignedIn = false;
  HitLoginState: boolean;
  constructor(
    public firebaseService: FirebaseService,
    public afAuth: AngularFireAuth,
    public HitAuth: AuthService
  ) {}

  // GoogleSignIn() {
  //   const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
  //   this.afAuth.signInWithPopup(googleAuthProvider);
  // }




  GoogleSignOut() {
    sessionStorage.removeItem('user');
    this.HitAuth.setLoginState(false);
    this.afAuth.signOut();

  }

  // getUser(){
  //   return this.afAuth.user;
  // }

  ngOnInit(): void {

    // this.HitAuth.authUpdated.subscribe(
    //   (data)=>{
    //     this.HitLoginState = this.HitAuth.getLoginState();
    //     //console.log("JUST got state");
    //   }


    // );
    // this.HitAuth.userUpdated.subscribe(
    //   (user)=>{
    //     this.LoggedUser=this.HitAuth.getLoggedUser();
    //     console.log("UUU.....,", this.LoggedUser)
    //   }
    // );
  }
}
