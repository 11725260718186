<div class="card border-info">
    <div class="card-header">Login</div>
    <div class="card-body">
        <div class="row">
            <!-- <div *ngIf="isSignedIn==true"> -->

            <!-- <div class="col-md-4 text-center">
                <img src="https://placeimg.com/128/128/nature" />
            </div> -->
            <div class="col-md-12">
                <form>
                    <input type="text" #email class="form-control mb-2" placeholder="Email" required autofocus />
                    <input type="password" #password class="form-control mb-2" placeholder="Password" required />
                    <div class="btn-container">
                        <button (click)="onSignInEP(email.value, password.value)" class="btn btn-lg btn-primary btn-block mb-1" data-toggle="modal" data-target="#LoginModalCenter">
                        Login Here
                      </button>

                        <button (click)="onSignUpEP(email.value, password.value)" class="btn btn-lg btn-primary btn-block mb-1" data-toggle="modal" data-target="#LoginModalCenter">
                        Register
                      </button>
                    </div>

                    <hr>

                    <button class="btn btn-lg btn-primary btn-block mb-1" data-toggle="modal" data-target="#LoginModalCenter" *ngIf="(afAuth.authState | async) === null" (click)="GoogleSignIn()">
                    Sign In with google
                    </button>


                    <hr>
                    <!-- <h3>Get OTP on your mobile to sign in</h3> -->
                    <!-- <input type="text" #mobile class="form-control mb-2" placeholder="Mobile" required /> -->
                    <!-- data-toggle="modal" data-target="#LoginModalCenter"  -->
                    <button class="btn btn-lg btn-primary btn-block mb-1" *ngIf="(afAuth.authState | async) === null" (click)="getPhoneLogin()">
          Phone Login
        </button>
                </form>
            </div>
            <div class="col-md-12">
                <div *ngIf="PhoneNumberLogin">
                    <app-phone-login></app-phone-login>
                </div>

            </div>
            <!-- </div> -->
        </div>
    </div>
</div>


<div *ngIf="NoCooks">
    <app-session-modal></app-session-modal>
</div>
