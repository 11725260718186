import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChildren,
  ViewChild,
  Renderer2,
  NgModule,
  HostListener,
} from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';

import {
  FormsModule,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { FareMasterPricerTravelBoardSearchReplyErProxy } from '../fare-master-pricer-travel-board-search-reply-er';
import { MultiCity } from '../multi-city';
import { JsonPipe } from '@angular/common';
import { FlightIndexFieldEntity, FlightSearch, GroupOfFlightsFieldEntity, ItemNumberField, RecommendationFieldEntity, SegmentFlightRefFieldEntity, ServiceFeesGrpFieldEntity } from './../interfaces/flight-search';
import { SegmentFlightRefFieldEntityProxy } from '../fare-master-pricer-travel-board-search-reply';
import { User } from '../auth/user';
import { AuthService } from '../auth/auth.service';
import { CosmicAirInventory } from '../interfaces/cosmic-air-inventory';
import { ErrorMessageService } from '../Services/error-message.service';
import { HitCookieService } from '../Services/hit-cookie.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-flight-results',
  templateUrl: './flight-results.component.html',
  styleUrls: ['./flight-results.component.css'],
})
export class FlightResultsComponent implements OnInit, AfterViewInit {
  public HitLoginState:boolean;
  public LoggedUser:User;
  hitToken:string="";
  NoCooks:boolean=false;

  //public apiUrl:string ="https://cosmictours202020210214185330.azurewebsites.net/";
  //public apiUrl = 'https://localhost:44317/';
  //public apiUrl="https://cosmictours2021.azurewebsites.net/";
  apiUrl = environment.apiUrl;
  cookieValue: string = '';
  public ReviewComponent = false;
  NoofPAX: number;
  PaxChildren: number;
  PaxAdults: number;
  PaxInfant: number;
  NoofTravellers: number;
  public DirectFlights: boolean = false;
  public DomOrInt = 'D';
  public TrackerID = '';
  public SectorsItinerary = '';
  SimpleSearch = 'One Way';
  MultiCityArray = [
    {
      FromCityCode: 'DEL',
      ToCityCode: 'BOM',
      DepartDate: '',
      ReturnDate: '',
      DepDorI: '',
      RetDorI: '',
    },
  ];
  ResponseFlightData: FlightSearch[];
  ResponseFlightDataFull: FlightSearch[];
  isCosmicAirInventory:boolean=false;
  CosmicAirInventoryList: CosmicAirInventory[];




  // public file = require('file-system');
  // public fs = require('fs');


  public loading = false;

  public ResponseFlight = false;
  public ResponseFlightResult = false;
  ResponseFlightDataEr = [];
  public FlightIndex = [];
  public ResponseFlightEr = false;
  obj: any = null;
  public TripType = '';
  Cabin: string;
  public ResponseFlightDetailsDataEr = [];
  public ResponseFlightDetailsData = [];
  public ResponseFlightDetails: boolean;
  public ResponseFlightDetailsEr: boolean;
  public FlightInfoNoPNR = [];

  //For FareInfo & Rules
  public BookingFailedComponent = false;
  public AdultsLists;
  public ChildrenList;
  public INfantsList;
  public AdtPax = true;
  public InfPax = true;
  public ChPax = false;

  public FlightInfo = false;
  public FlightInfoData = [];
  public FareRules = false;
  public FareRulesData = [];
  public idVisit = '';

  Hit_CID:string;
  Hit_CVisitID:string;

  GotAirlines=[];

  selectedAirlines = [];
  selectedNoofStops=[];
  selectedDepTimes=[]
  selectedArrTimes=[];

  AirlinesArrayChanged=false;
  NoofStopsArrayChanged=false;
  DepTimesArrayChanged=false;
  ArrTimesArrayChanged=false;
  MaxPriceArrayChanged=false;

  selectedPriceRange=[10000,12000];
  selectedMinPrice=0;
  selectedMaxPrice=0;


  minPrice=1000;
  maxPrice=8000;


  onChangeDep(event:Event){}
  onChangeArr(event:Event){}


  // onChange(event:Event){
  //   //source.value
  //   let airline = ((<any>event).source).value;
  //   if((<any>event).checked==true){
  //     this.selectedAirlines.push(airline);
  //   }else{
  //     this.selectedAirlines.forEach((element,index)=>{
  //       if(element==airline) delete this.selectedAirlines[index];
  //     });
  //     console.log("SAirlines>>>", this.selectedAirlines);
  //     console.log("Airline>>>", airline);
  //     console.log(event);
  //   }

  // }


  //For FareInfo & Rules

  // public DepDates="";
  // public DepTimes="";
  // public TruelocFs="";
  // public TrueLocTs="";
  // public MarketingCs="";
  // public OperatingCs="";
  // public FlightNumbers="";
  // public BookinClass="";
  // public FlightIndicator="";
  // public ItemNumber ="";
  public FlightGroup = '';

  @ViewChild('RightBars') RightBars;

  constructor(
    private errorMessageService: ErrorMessageService,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    public HitAuth: AuthService,
    public hitCookieService:HitCookieService
  ) {

    this.route.params.subscribe((params) => {
      if (params['Task'] == 'Search') {
//        console.log("Route: "+this.route);
        this.checkCookie(
          params['Task'],
          params['Sectors'],
          params['DepDates'],
          params['Travellers'],
          params['Cabin'],
          params['TripType'],
          params['DomOrInt'],
          params['DirectFlights'],
          params['UTT']
        );


        // this.getVisitID(
        //   params['Task'],
        //   params['Sectors'],
        //   params['DepDates'],
        //   params['Travellers'],
        //   params['Cabin'],
        //   params['TripType'],
        //   params['DomOrInt'],
        //   params['DirectFlights'],
        //   params['UTT']
        // );
      }
    });
  }



  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }


    return value;
  }




  ngAfterViewInit() {}

  ngOnInit(): void {

    if(sessionStorage.getItem('user')){


    let data = sessionStorage.getItem('user');
    let obj = JSON.parse(data);
    this.LoggedUser=obj;

    this.hitToken = this.LoggedUser.uid;
//    console.log("GotTokenfromSessio",this.hitToken);
    }

  this.HitAuth.authUpdated.subscribe(
    (data)=>{
      this.HitLoginState = this.HitAuth.getLoginState();
      //console.log("JUST got state");
    }




  );


  this.HitAuth.userUpdated.subscribe(
    (user)=>{
      this.LoggedUser=this.HitAuth.getLoggedUser();
    }
  );

  this.HitAuth.hitTokenUpated.subscribe(
    (hittoken)=>{
      this.hitToken=this.HitAuth.gethitToken();
      console.log("ThisHitToken", this.hitToken);
    }
  );

  this.hitCookieService.Hit_CIDUpated.subscribe((d)=>{
    this.Hit_CID=this.hitCookieService.getHit_CID();

  });
  this.hitCookieService.Hit_CVisitIDUpated.subscribe((d)=>{
    this.Hit_CVisitID=this.hitCookieService.getHit_CVisitID();
  });

  }

  hideInfo() {
    this.renderer.setStyle(this.RightBars.nativeElement, 'right', '-60%');
  }

  searchFromURL(
    Task: string,
    Sectors: string,
    DepDates: string,
    Travellers: string,
    Cabin: string,
    TripType: string,
    DomOrInt: string,
    DirectFlights: string,
    TrackerID: string
  ) {

    if(TripType=="R"){
      this.isCosmicAirInventory=true;
    }
  this.loading =true;
  this.ResponseFlight = false;
  this.ResponseFlightResult=false;
 //     console.log(this.idVisit);
      //   let s = '{"FlightDataParams":{"Sectors":"'+ Sectors + '","DepDates":"'+ DepDates + '","Travellers":"'+ Travellers
      //  + '","Cabin":"' + Cabin + '","TripType":"' + TripType + '","DomOrInt":"' + this.DomOrInt + '","DirectFlights":"' + this.DirectFlights +'"}}';
      let Adults: number = 0;
      let Children: number = 0;
      let Infants: number = 0;

      var paxss = Travellers.split('-');
      Adults = +paxss[0];
      Children = +paxss[1];
      Infants = +paxss[2];
      this.NoofPAX = Adults + Children;
      this.PaxAdults = Adults;
      this.PaxChildren = Children;
      this.PaxInfant = Infants;
      this.NoofTravellers = Adults + Children + Infants;
      this.Cabin = Cabin;

      this.DomOrInt = DomOrInt;
      this.TrackerID = TrackerID;
      this.SectorsItinerary = Sectors;
      var ll = Sectors.split('_');
      var Datess = DepDates.split('_');
      ////console.log(Datess[0]);

      if (TripType == 'O') {
        this.SimpleSearch = 'One Way';
        this.MultiCityArray.length = 1;
        var cities = ll[0].split('-');
        this.MultiCityArray[0].FromCityCode = cities[0];
        this.MultiCityArray[0].ToCityCode = cities[1];

        var FDate =
          '20' +
          Datess[0].substring(4, 6) +
          '-' +
          Datess[0].substring(2, 4) +
          '-' +
          Datess[0].substring(0, 2);
        this.MultiCityArray[0].DepartDate = FDate;
        this.MultiCityArray[0].ReturnDate = '';
        this.MultiCityArray[0].DepDorI = DomOrInt;
        this.MultiCityArray[0].RetDorI = DomOrInt;
        ////console.log(this.MultiCityArray);

        // this.SearchFlights('S');
      } else if (TripType == 'R') {
        this.SimpleSearch = 'Round Trip';

        this.MultiCityArray.length = 1;
        var cities = ll[0].split('-');
        this.MultiCityArray[0].FromCityCode = cities[0];
        this.MultiCityArray[0].ToCityCode = cities[1];
        var FDate =
          '20' +
          Datess[0].substring(4, 6) +
          '-' +
          Datess[0].substring(2, 4) +
          '-' +
          Datess[0].substring(0, 2);
        this.MultiCityArray[0].DepartDate = FDate;
        var TDate =
          '20' +
          Datess[1].substring(4, 6) +
          '-' +
          Datess[1].substring(2, 4) +
          '-' +
          Datess[1].substring(0, 2);
        this.MultiCityArray[0].ReturnDate = TDate;
        this.MultiCityArray[0].DepDorI = DomOrInt;
        this.MultiCityArray[0].RetDorI = DomOrInt;
        ////console.log(this.MultiCityArray);
        //    this.SearchFlights('S');
      } else if (TripType == 'M') {
        this.SimpleSearch = 'Multi City';

        ////console.log(ll.length);
        this.MultiCityArray.length = 0;

        for (var i = 0; i < ll.length; i++) {
          var cities = ll[i].split('-');

          var FDate =
            '20' +
            Datess[i].substring(4, 6) +
            '-' +
            Datess[i].substring(2, 4) +
            '-' +
            Datess[i].substring(0, 2);

          let m = new MultiCity(
            cities[0],
            cities[1],
            FDate,
            '',
            DomOrInt,
            DomOrInt
          );
          this.MultiCityArray.push(m);
        }
        ////console.log(this.MultiCityArray);
      }

      let ma = '{"Tracker":"' + this.TrackerID + '","Merchantid":"7123"}';
      //GetTracker here and then Navigate
      let headersa = new HttpHeaders().append(
        'Content-Type',
        'application/json; charset=utf-8'
      );

      this.cookieValue = this.cookieService.get('cosmicHits');


      this.httpClient
        .post(this.apiUrl + 'api/HitsTracker', ma, { headers: headersa })
        .subscribe((data: any) => {
          if (this.TrackerID != 'll') {
            this.TrackerID = data;
            //    //console.log("Match no found still moving");
            //console.log(this.TrackerID);
            ///FromURL
            let s =
              '{"HitVisit":"' +
              this.idVisit +
              '","hitToken":"' +
              this.hitToken +
              '","FlightDataParams":{"Sectors":"' +
              Sectors +
              '","DepDates":"' +
              DepDates +
              '","Travellers":"' +
              Travellers +
              '","Cabin":"' +
              Cabin +
              '","TripType":"' +
              TripType +
              '","DomOrInt":"' +
              this.DomOrInt +
              '","DirectFlights":"' +
              this.DirectFlights +
              '"}}';

            //  window.alert(s);
            let headers = new HttpHeaders().append(
              'Content-Type',
              'application/json; charset=utf-8'
            );

            let params = new HttpParams().set('Sectors', Sectors); // create params object
            params = params.append('DepDates', DepDates); // add a new param, creating a new object
            params = params.append('Travellers', Travellers);
            params = params.append('Cabin', Cabin);
            params = params.append('TripType', TripType);
            params = params.append('DomOrInt', this.DomOrInt);
            params = params.append('DirectFlights',this.DirectFlights.toString()

            );
  //AirFareFeatured
            this.httpClient
            .post(this.apiUrl + 'api/FlightSearch', s, {
              headers: headers,
              params: params,
            })
            .subscribe((data: any) => {
              var DataD = JSON.stringify(data, null, 2);
              // var file = require('file-system');
              // var fs = require('fs');
              // fs.writeFile('FileSearch.json',DataD);
              //console.log(data);
                              let err = 'N';

              for (let key in data) {
                // //console.log("key="+key);
                if (data.hasOwnProperty(key) && key === 'errorMessageField') {
                  for (let skey in data[key]) {
                    if (
                      data[key].hasOwnProperty(skey) &&
                      skey === 'applicationErrorField'
                    ) {
                      err = 'F';
                    }
                  }
                }
              }
              //             window.alert("err: "+err);

              this.ResponseFlightData = [];
              this.ResponseFlightDataFull = [];

              this.ResponseFlightDataEr = [];
              this.ResponseFlight = true;
              this.ResponseFlightResult=true;

              this.ResponseFlightEr = false;

              if (err === 'F') {
                this.ResponseFlightEr = true;
                this.obj = FareMasterPricerTravelBoardSearchReplyErProxy.Create(
                  data
                );
                this.ResponseFlightDataEr = [];
                this.ResponseFlightDataEr.push(this.obj);
              } else if (err === 'N') {
                this.ResponseFlight = true;
                this.ResponseFlightResult=true;
                this.NoCooks=false;

                this.ResponseFlightData.push(data);
            //    this.ResponseFlightDataFull.push(data);
            for(let i=0;i<data.flightIndexField.length;i++){
              this.selectedArrTimes.push([]);
              this.selectedDepTimes.push([]);
            }

                this.SetMinMaxPrice(data.recommendationField);
                // this.FilterFlightData();
                this.NewFilterFlightData();
              }
              this.loading=false;
            },
            (err)=>{
              this.errorMessageService.seterrorMessage("Oops!! Some thing went wrong... Kindly restart searching your itinerary.")
              this.NoCooks=true;
            });
        } else {
          //this.onSearchFlights();
          //navigate with new TrackerID
          //console.log ("LLLLLLL");
          this.loading=false;
        }
      },
      (err)=>{
        this.errorMessageService.seterrorMessage("Oops!! Some thing went wrong... Kindly restart searching your itinerary.")
        this.NoCooks=true;
      });

    //  //console.log("Response is " + this.obj);
    //console.log("FlightIndex is " + this.FlightIndex);
  }

  SetMinMaxPrice(data:any[]){
    // console.log("Data>>>",data);
    // console.log("DataLength>>", data.length);
    let DataLength = data.length-1;
    //[0].recPriceInfoField[0].amountField
    let mv = data[0].recPriceInfoField[0].amountField;
      if(mv<1000){
          mv = 1000;
        }else{
            mv = Math.round(mv/1000)*1000;
          }
    this.minPrice=mv;

    mv = data[DataLength].recPriceInfoField[0].amountField;
    if(mv<1000){
        mv = 1000;
      }else{
          mv = Math.round(mv/1000)*1000;
        }
  this.maxPrice=mv;
  this.selectedMaxPrice=this.maxPrice;
  this.MaxPriceArrayChanged=true;
  // console.log("MaxPrice>>",this.maxPrice);


  }

  checkCookie(Task: string,
    Sectors: string,
    DepDates: string,
    Travellers: string,
    Cabin: string,
    TripType: string,
    DomOrInt: string,
    DirectFlights: string,
    TrackerID: string){

      if (!this.cookieService.get('cosmicHits')) {
   //     console.log('No cookie');

        let headersa = new HttpHeaders().append('Content-Type','application/json; charset=utf-8');
              this.httpClient.post(this.apiUrl + "api/Hits", {headers: headersa}).subscribe(
              (data:any)=> {
                //console.log(data);
                const dateNow = new Date();
                      dateNow.setMinutes(dateNow.getMinutes() + 100);
                      this.cookieService.set('cosmicHits', data, dateNow);
                      this.hitCookieService.setHit_CID(this.idVisit);
                      this.cookieValue = this.cookieService.get('cosmicHits');
                          //console.log("User is:" + this.cookieValue);
                          this.getVisitID(
                            Task,Sectors,DepDates,Travellers,Cabin,TripType,DomOrInt,DirectFlights,TrackerID
                          );

              },
              (err)=>{
                this.errorMessageService.seterrorMessage("Oops!! Your session Expired... Kindly restart searching your itinerary.")
                this.NoCooks=true;
              });

        //this.cookieValue = this.cookieService.get('cosmicHits');
      } else {

        this.cookieValue = this.cookieService.get('cosmicHits');
     ///   console.log("Founnnnd", this.cookieValue);
        this.getVisitID(
          Task,Sectors,DepDates,Travellers,Cabin,TripType,DomOrInt,DirectFlights,TrackerID
        );

      }

  }

  getVisitID(Task: string,
    Sectors: string,
    DepDates: string,
    Travellers: string,
    Cabin: string,
    TripType: string,
    DomOrInt: string,
    DirectFlights: string,
    TrackerID: string) {

      this.cookieValue = this.cookieService.get('cosmicHits');
      // if(this.cookieValue==""){



      // }

    let headersa = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=utf-8'
    );


    let ma = '{"Hit":"' + this.cookieValue + '"}';

    this.httpClient
      .post(this.apiUrl + 'api/HitSessions', ma, { headers: headersa })
      .subscribe((data: any) => {
        //console.log(data);
        this.idVisit = data;

        this.cookieService.set('cosmicVisits',this.idVisit);
        this.hitCookieService.setHit_CVisitID(this.idVisit);

        this.searchFromURL(Task,
          Sectors,
          DepDates,
          Travellers,
          Cabin,
          TripType,
          DomOrInt,
          DirectFlights,
          TrackerID)

      },
      (err)=>{
        this.errorMessageService.seterrorMessage("Oops!! Some thing went wrong... Kindly restart searching your itinerary.")
        this.NoCooks=true;
      });
  }
  BookClickedForInfo(referencingDetailField, fareDetailsField, flightIndex) {

    this.FlightInfoData = [];
    this.FareRulesData = [];
    let Sectors = ''; //Origin-Destination|Origin-Destination
    let Segments = ''; //DepDate-TrueBoard-TrueOffPOint-marketingCompany-FlightNo-BookingClass-TotalPax
    this.FlightGroup = '';

    for (let i = 0; i < referencingDetailField.length - 1; i++) {
      let DepDate = '';
      let DepTime = '';
      let TrueFromLoctions = '';
      let TrueToLoctions = '';
      let marketingCompany = '';
      let FlightNo = '';
      let BookingClass = '';

      //recommendationField[0].paxFareProductField[0].fareDetailsField[0].groupOfFaresField[0].productInformationField.cabinProductField.rbdField (RBD)(Class) ="O"
      // //console.log("i="+ i + "No: "+ referencingDetailField[i].refNumberField);
      for (
        let n = 0;
        n <
        flightIndex[i].groupOfFlightsField[
          referencingDetailField[i].refNumberField - 1
        ].flightDetailsField.length;
        n++
      ) {
        //[0].groupOfFaresField[0].productInformationField.cabinProductField.rbdField
        if (BookingClass.length == 0) {
          //flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.locationField[0].locationIdField
          BookingClass =
            fareDetailsField[i].groupOfFaresField[n].productInformationField
              .cabinProductField.rbdField;
        } else {
          BookingClass =
            BookingClass +
            '-' +
            fareDetailsField[i].groupOfFaresField[n].productInformationField
              .cabinProductField.rbdField;
        }
        let F_BookingClass =
          fareDetailsField[i].groupOfFaresField[n].productInformationField
            .cabinProductField.rbdField;

        if (DepDate.length == 0) {
          DepDate =
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField.productDateTimeField
              .dateOfDepartureField;
        } else {
          DepDate =
            DepDate +
            '-' +
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField.productDateTimeField
              .dateOfDepartureField;
        }
        let F_depDate =
          flightIndex[i].groupOfFlightsField[
            referencingDetailField[i].refNumberField - 1
          ].flightDetailsField[n].flightInformationField.productDateTimeField
            .dateOfDepartureField;
        //flightIndexField[0].groupOfFlightsField[5].flightDetailsField[0].flightInformationField.productDateTimeField.timeOfDepartureField
        let F_depTime =
          flightIndex[i].groupOfFlightsField[
            referencingDetailField[i].refNumberField - 1
          ].flightDetailsField[n].flightInformationField.productDateTimeField
            .timeOfDepartureField;

        //flightIndexField[0].groupOfFlightsField[58].flightDetailsField[0].flightInformationField.locationField[1]
        if (TrueFromLoctions.length == 0) {
          //flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.locationField[0].locationIdField
          TrueFromLoctions =
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField.locationField[0]
              .locationIdField;
        } else {
          TrueFromLoctions =
            TrueFromLoctions +
            '-' +
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField.locationField[0]
              .locationIdField;
        }
        let F_TruLocF =
          flightIndex[i].groupOfFlightsField[
            referencingDetailField[i].refNumberField - 1
          ].flightDetailsField[n].flightInformationField.locationField[0]
            .locationIdField;
        let F_TruLocT =
          flightIndex[i].groupOfFlightsField[
            referencingDetailField[i].refNumberField - 1
          ].flightDetailsField[n].flightInformationField.locationField[1]
            .locationIdField;

        if (TrueToLoctions.length == 0) {
          //flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.locationField[0].locationIdField
          TrueToLoctions =
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField.locationField[1]
              .locationIdField;
        } else {
          TrueToLoctions =
            TrueToLoctions +
            '-' +
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField.locationField[1]
              .locationIdField;
        }

        if (marketingCompany.length == 0) {
          //flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.locationField[0].locationIdField
          //flightIndexField[0].groupOfFlightsField[58].flightDetailsField[0].flightInformationField.companyIdField.marketingCarrierField
          marketingCompany =
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField.companyIdField
              .marketingCarrierField;
        } else {
          marketingCompany =
            marketingCompany +
            '-' +
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField.companyIdField
              .marketingCarrierField;
        }

        let F_MarketingC =
          flightIndex[i].groupOfFlightsField[
            referencingDetailField[i].refNumberField - 1
          ].flightDetailsField[n].flightInformationField.companyIdField
            .marketingCarrierField;
        let F_OperatingC =
          flightIndex[i].groupOfFlightsField[
            referencingDetailField[i].refNumberField - 1
          ].flightDetailsField[n].flightInformationField.companyIdField
            .operatingCarrierField;

        if (FlightNo.length == 0) {
          //flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.locationField[0].locationIdField
          //flightIndexField[0].groupOfFlightsField[58].flightDetailsField[0].flightInformationField.companyIdField.marketingCarrierField
          FlightNo =
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField
              .flightOrtrainNumberField;
        } else {
          FlightNo =
            FlightNo +
            '-' +
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField
              .flightOrtrainNumberField;
        }

        let F_FlightNo =
          flightIndex[i].groupOfFlightsField[
            referencingDetailField[i].refNumberField - 1
          ].flightDetailsField[n].flightInformationField
            .flightOrtrainNumberField;

        ////console.log ("FlightNo: " + flightIndex[i].groupOfFlightsField[referencingDetailField[i].refNumberField-1].flightDetailsField[n].flightInformationField.flightOrtrainNumberField);

        if (this.FlightGroup.length == 0) {
          this.FlightGroup =
            F_depDate +
            '-' +
            F_depTime +
            '-' +
            F_TruLocF +
            '-' +
            F_TruLocT +
            '-' +
            F_MarketingC +
            '-' +
            F_OperatingC +
            '-' +
            F_FlightNo +
            '-' +
            F_BookingClass +
            '-' +
            (n + 1).toString() +
            '-' +
            '0';
        } else {
          this.FlightGroup =
            this.FlightGroup +
            '~' +
            F_depDate +
            '-' +
            F_depTime +
            '-' +
            F_TruLocF +
            '-' +
            F_TruLocT +
            '-' +
            F_MarketingC +
            '-' +
            F_OperatingC +
            '-' +
            F_FlightNo +
            '-' +
            F_BookingClass +
            '-' +
            (n + 1).toString() +
            '-' +
            '0';
        }
        localStorage.setItem('BookingClass', F_BookingClass);
      }

      //console.log(this.FlightGroup);

      if (Segments.length == 0) {
        Segments =
          DepDate +
          '|' +
          TrueFromLoctions +
          '|' +
          TrueToLoctions +
          '|' +
          marketingCompany +
          '|' +
          FlightNo +
          '|' +
          BookingClass +
          '|' +
          this.NoofPAX;
      } else {
        Segments =
          Segments +
          '_' +
          DepDate +
          '|' +
          TrueFromLoctions +
          '|' +
          TrueToLoctions +
          '|' +
          marketingCompany +
          '|' +
          FlightNo +
          '|' +
          BookingClass +
          '|' +
          this.NoofPAX;
      }
      localStorage.setItem('Carrier', marketingCompany);

      localStorage.setItem('Cabin', this.Cabin);
    }

    localStorage.setItem('Flights', this.FlightGroup);
    //localStorage.setItem("BC", )
    localStorage.setItem('Sectors', this.SectorsItinerary);
    localStorage.setItem('Segments', Segments);
    localStorage.setItem('Adults', this.PaxAdults.toString());
    localStorage.setItem('Children', this.PaxChildren.toString());
    localStorage.setItem('Infants', this.PaxInfant.toString());

    ////console.log(this.SectorsItinerary);
    ////console.log (Segments);
    //this.router.navigate(['FlightReview', 'Review', this.TrackerID]);
    this.AirSellInfo(this.TrackerID);
  }

  AirSellInfo(TrackerID) {
    // generate TrackerID first

    let Adults = localStorage.getItem('Adults');
    let Children = localStorage.getItem('Children');
    let Infants = localStorage.getItem('Infants');

    this.PaxAdults = +Adults;
    this.PaxChildren = +Children;
    this.PaxInfant = +Infants;

    if (this.PaxAdults > 0) {
      this.AdtPax = true;
    } else {
      this.AdtPax = false;
    }

    if (this.PaxChildren > 0) {
      this.ChPax = true;
    } else {
      this.ChPax = false;
    }

    if (this.PaxInfant > 0) {
      this.InfPax = true;
    } else {
      this.InfPax = false;
    }

    this.NoofPAX = this.PaxAdults + this.PaxChildren;
    this.NoofTravellers = this.PaxAdults + this.PaxChildren + this.PaxInfant;

    this.AdultsLists = new Array(this.PaxAdults);
    this.ChildrenList = new Array(this.PaxChildren);
    this.INfantsList = new Array(this.PaxInfant);

    //this.TrackerID=TrackerID;
    //this.ResponseFlight= false;
    this.ReviewComponent = false;

    let Sectors = localStorage.getItem('Sectors');
    let Segments = localStorage.getItem('Segments');

    let s = '{"Sectors":"' + Sectors + '","Segments":"' + Segments + '"}';

    //  window.alert(s);
    //let headers = new HttpHeaders().append('Content-Type','application/json; charset=utf-8');
    let params = new HttpParams().set('TrackerID', this.TrackerID); // create params object
    const httOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true,
      observe: 'response' as 'response',
      params: params,
    };

    this.httpClient
      .post(this.apiUrl + 'api/CheckFlight', s, httOptions)
      .subscribe((data: any) => {
        //console.log(data.body);
        // let headers = data.headers;
        // //console.log(data.headers.get('ASP.NET_SessionId'))
        // //console.log(data.headers);
        // //console.log(data.Cookies);
        ////console.log(data.headers.get('authorization'));

        if (data.body == 'No') {
          this.ReviewComponent = false;
          this.BookingFailedComponent = true;
        } else {
          localStorage.setItem('Tracker', data.body);

          //this.ReviewComponent=true;
          this.BookingFailedComponent = false;
          this.GetFareInfo();
        }
      });
  }

  GetFareInfo() {
    let PassengerGroup =
      localStorage.getItem('Adults') +
      '-' +
      localStorage.getItem('Children') +
      '-' +
      localStorage.getItem('Infants');
    let SegmentGroup = localStorage.getItem('Flights');
    let Cabin = localStorage.getItem('Cabin');
    let TripType = '';
    let DomOrInt = '';
    let BookingClass = localStorage.getItem('BookingClass');
    let DirectFlights = 'false';

    let load = '{"Token":"' + localStorage.getItem('Tracker') + '"}';

    let headers = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('Tracker'),
    });
    headers = headers.append('Content-Type', 'application/json; charset=utf-8');

    let params = new HttpParams().set('TrackerID', this.TrackerID); // create params object
    params = params.append('PassengersGroup', PassengerGroup); // add a new param, creating a new object
    params = params.append('SegmentGroup', SegmentGroup); // add a new param, creating a new object

    params = params.append('Cabin', Cabin); // add a new param, creating a new object
    params = params.append('TripType', TripType); // add a new param, creating a new object
    params = params.append('DomOrInt', DomOrInt); // add a new param, creating a new object
    //params = params.append('BookingClass', BookingClass); // add a new param, creating a new object
    params = params.append('DirectFlights', DirectFlights); // add a new param, creating a new object

    const httOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true,
      observe: 'response' as 'response',
      params: params,
    };
    ////this.httpClient.post(this.apiUrl + "api/BookPNR",load, {headers:headers, params:params}).subscribe(
    //this.httpClient.post(this.apiUrl + "api/FlightInfo",load, httOptions).subscribe(

    this.httpClient
      .post(this.apiUrl + 'api/FlightInfo', load, {
        headers: headers,
        params: params,
      })
      .subscribe((data: any) => {
        //console.log(data);
        this.FlightInfo = true;
        console.log(this.FlightInfo);
        this.FlightInfoData.push(data);

        this.renderer.setStyle(this.RightBars.nativeElement, 'right', '0px');
        // this.getFareRules();
      });
  }

  getFareRules() {
    let load = '{"Token":"' + localStorage.getItem('Tracker') + '"}';
    let headers = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=utf-8'
    );

    // let params = new HttpParams().set('PassengersGroup', Travellers); // create params object
    //     params = params.append('SegmentGroup', segmentInformation); // add a new param, creating a new object
    //     params = params.append('Cabin', Cabin);
    //     params = params.append('TripType', this.TripType);
    //     params = params.append('DomOrInt', this.DomOrInt);
    //     params = params.append('BookingClass', bookingClass);
    //     params = params.append('DirectFlights', this.DirectFlights.toString());

    //let newparams = new HttpParams().set('PassengersGroup', new_s);

    this.httpClient
      .post(this.apiUrl + 'api/FlightFareRules', load, { headers: headers })
      .subscribe(
        //this.httpClient.post("api/Test",new_s, {headers: headers, params:params}).subscribe(
        (data: any) => {
          console.log(data);
          this.FareRules = true;
          this.FareRulesData.push(data);
        }
      );
  }

  GetFlightsInfoNewOLL(GroupofFlight, linenumber) {
    ////console.log(GroupofFlight.flightDetailsField);
    // //console.log("New here");
    var locator = '';

    var segmentInformation = '';
    var countGroupofFlight = Object.keys(GroupofFlight.flightDetailsField)
      .length;

    const Travellers =
      this.PaxAdults + '-' + this.PaxChildren + '-' + this.PaxInfant;
    var bookingClass = '';
    //console.log("Cabin: " + this.Cabin);

    let Cabin = '';
    if (this.Cabin === 'E') {
      Cabin = 'E';
      bookingClass = 'Y';
    } else if (this.Cabin === 'PE') {
      Cabin = 'PE';
      bookingClass = 'F';
    } else if (this.Cabin === 'B') {
      Cabin = 'B';
      bookingClass = 'C';
    }

    for (let i = 0; i <= countGroupofFlight - 1; i++) {
      var countflightInformationField = Object.keys(
        GroupofFlight.flightDetailsField[i].flightInformationField
      ).length;
      //[0].flightInformationField.productDateTimeField.dateOfDepartureField
      var departureDate =
        GroupofFlight.flightDetailsField[i].flightInformationField
          .productDateTimeField.dateOfDepartureField;

      //[0].flightInformationField.productDateTimeField.timeOfDepartureField
      var departureTime =
        GroupofFlight.flightDetailsField[i].flightInformationField
          .productDateTimeField.timeOfDepartureField;

      //[0].flightInformationField.locationField[0].locationIdField
      var trueLocationIdF =
        GroupofFlight.flightDetailsField[i].flightInformationField
          .locationField[0].locationIdField;
      //[0].flightInformationField.locationField[1].locationIdField
      var trueLocationIdT =
        GroupofFlight.flightDetailsField[i].flightInformationField
          .locationField[1].locationIdField;

      //[0].flightInformationField.companyIdField.marketingCarrierField
      var marketingCompany =
        GroupofFlight.flightDetailsField[i].flightInformationField
          .companyIdField.marketingCarrierField;

      //[0].flightInformationField.companyIdField.operatingCarrierField
      var operatingCompany =
        GroupofFlight.flightDetailsField[i].flightInformationField
          .companyIdField.operatingCarrierField;

      //[0].flightInformationField.flightOrtrainNumberField
      //flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.flightOrtrainNumberField
      var flightNumber =
        GroupofFlight.flightDetailsField[i].flightInformationField
          .flightOrtrainNumberField;
      //i+1;

      var flightIndicator = i + 1;
      //GroupofFlight.flightDetailsField[i].flightInformationField.companyIdField.marketingCarrierField;
      var itemNumber = linenumber;

      //departureDate;departureTime;trueLocationIdF;trueLocationIdT;marketingCompany;operatingCompany;flightNumber;bookingClass;flightIndicator;itemNumber
      if (segmentInformation.length == 0) {
        segmentInformation =
          departureDate +
          '-' +
          departureTime +
          '-' +
          trueLocationIdF +
          '-' +
          trueLocationIdT +
          '-' +
          marketingCompany +
          '-' +
          operatingCompany +
          '-' +
          flightNumber +
          '-' +
          bookingClass +
          '-' +
          flightIndicator +
          '-' +
          itemNumber;
      } else {
        segmentInformation =
          segmentInformation +
          '~' +
          departureDate +
          '-' +
          departureTime +
          '-' +
          trueLocationIdF +
          '-' +
          trueLocationIdT +
          '-' +
          marketingCompany +
          '-' +
          operatingCompany +
          '-' +
          flightNumber +
          '-' +
          bookingClass +
          '-' +
          flightIndicator +
          '-' +
          itemNumber;
      }
    }
    // //console.log(Cabin);
    // //console.log(Travellers);
    // //console.log(segmentInformation);

    //send to server

    let PassengersGroup =
      '{"FlightInfoDataParams":{"PassengersGroup":"' +
      Travellers +
      '","SegmentGroup":"' +
      segmentInformation +
      '","Cabin":"' +
      Cabin +
      '","TripType":"' +
      this.TripType +
      '","DomOrInt":"' +
      this.DomOrInt +
      '","DirectFlights":"' +
      this.DirectFlights +
      '"}}';

    let new_s =
      Travellers +
      '/' +
      segmentInformation +
      '/' +
      this.Cabin +
      '/' +
      this.TripType +
      '/' +
      this.DomOrInt +
      '/' +
      this.DirectFlights;

    let headers = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=utf-8'
    );

    let params = new HttpParams().set('TrackerID', this.TrackerID); // create params object
    params = params.append('PassengersGroup', Travellers); // add a new param, creating a new object
    params = params.append('SegmentGroup', segmentInformation); // add a new param, creating a new object
    params = params.append('Cabin', Cabin);
    params = params.append('TripType', this.TripType);
    params = params.append('DomOrInt', this.DomOrInt);
    params = params.append('BookingClass', bookingClass);
    params = params.append('DirectFlights', this.DirectFlights.toString());

    let newparams = new HttpParams().set('PassengersGroup', new_s);

    this.httpClient
      .post(this.apiUrl + 'api/FlightInfo', new_s, {
        headers: headers,
        params: params,
      })
      .subscribe(
        //this.httpClient.post("api/Test",new_s, {headers: headers, params:params}).subscribe(
        (data: any) => {
          //console.log(data);

          let err = 'N';

          for (let key in data) {
            // //console.log("key="+key);
            if (data.hasOwnProperty(key) && key === 'errorGroupField') {
              for (let skey in data[key]) {
                if (
                  data[key].hasOwnProperty(skey) &&
                  skey === 'errorWarningDescription'
                ) {
                  err = 'F';
                }
              }
            }
          }
          //             window.alert("err: "+err);

          this.ResponseFlightDetailsData = [];
          this.ResponseFlightDetailsDataEr = [];
          this.ResponseFlightDetails = true;
          this.ResponseFlightDetailsEr = false;

          if (err === 'F') {
            this.ResponseFlightEr = true;
            this.obj = FareMasterPricerTravelBoardSearchReplyErProxy.Create(
              data
            );
            this.ResponseFlightDataEr = [];
            this.ResponseFlightDataEr.push(this.obj);
          } else if (err === 'N') {
            this.ResponseFlightDetails = true;

            this.ResponseFlightDetailsData.push(data);

            for (let key in data) {
              if (data.hasOwnProperty(key) && key === 'mainGroupField') {
                this.FlightInfoNoPNR.length = 0;
                //console.log("MainFound");
                //this.FlightInfoNoPNR.push(data.mainGroupField);
              } else if (
                data.hasOwnProperty(key) &&
                key === 'paxFareProductField'
              ) {
                //    this.paxFareProductField.push(data[key])
              }
            }

            this.renderer.setStyle(
              this.RightBars.nativeElement,
              'right',
              '0px'
            );
            //console.log("MainGroup: " + this.FlightInfoNoPNR);
          }
          this.getFareRules();
        }
      );
  }
  getFareRulesOLD() {
    let headers = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=utf-8'
    );

    // let params = new HttpParams().set('PassengersGroup', Travellers); // create params object
    //     params = params.append('SegmentGroup', segmentInformation); // add a new param, creating a new object
    //     params = params.append('Cabin', Cabin);
    //     params = params.append('TripType', this.TripType);
    //     params = params.append('DomOrInt', this.DomOrInt);
    //     params = params.append('BookingClass', bookingClass);
    //     params = params.append('DirectFlights', this.DirectFlights.toString());

    //let newparams = new HttpParams().set('PassengersGroup', new_s);

    this.httpClient
      .post(this.apiUrl + 'api/FlightFareRules', { headers: headers })
      .subscribe(
        //this.httpClient.post("api/Test",new_s, {headers: headers, params:params}).subscribe(
        (data: any) => {
          //console.log (data);
        }
      );
  }

  checkSession(referencingDetailField, fareDetailsField, flightIndex){
  this.loading=true;
    if (!this.cookieService.get('cosmicHits')) {
      console.log('No cookie');
      this.errorMessageService.seterrorMessage("Oops!! Your session Expired... Kindly restart searching your itinerary.")
      this.NoCooks=true;
      this.loading=false;
    }else{
      this.NoCooks=false;
      this.BookClicked(referencingDetailField, fareDetailsField, flightIndex);
    }
  }

  BookClicked(referencingDetailField, fareDetailsField, flightIndex) {
    console.log("BookedCXk");


    let Sectors = ''; //Origin-Destination|Origin-Destination
    let Segments = ''; //DepDate-TrueBoard-TrueOffPOint-marketingCompany-FlightNo-BookingClass-TotalPax
    this.FlightGroup = '';

    for (let i = 0; i < referencingDetailField.length - 1; i++) {
      let DepDate = '';
      let DepTime = '';
      let TrueFromLoctions = '';
      let TrueToLoctions = '';
      let marketingCompany = '';
      let FlightNo = '';
      let BookingClass = '';

      //recommendationField[0].paxFareProductField[0].fareDetailsField[0].groupOfFaresField[0].productInformationField.cabinProductField.rbdField (RBD)(Class) ="O"
      // //console.log("i="+ i + "No: "+ referencingDetailField[i].refNumberField);
      for (
        let n = 0;
        n <
        flightIndex[i].groupOfFlightsField[
          referencingDetailField[i].refNumberField - 1
        ].flightDetailsField.length;
        n++
      ) {
        //[0].groupOfFaresField[0].productInformationField.cabinProductField.rbdField
        if (BookingClass.length == 0) {
          //flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.locationField[0].locationIdField
          BookingClass =
            fareDetailsField[i].groupOfFaresField[n].productInformationField
              .cabinProductField.rbdField;
        } else {
          BookingClass =
            BookingClass +
            '-' +
            fareDetailsField[i].groupOfFaresField[n].productInformationField
              .cabinProductField.rbdField;
        }
        let F_BookingClass =
          fareDetailsField[i].groupOfFaresField[n].productInformationField
            .cabinProductField.rbdField;

        if (DepDate.length == 0) {
          DepDate =
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField.productDateTimeField
              .dateOfDepartureField;
        } else {
          DepDate =
            DepDate +
            '-' +
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField.productDateTimeField
              .dateOfDepartureField;
        }
        let F_depDate =
          flightIndex[i].groupOfFlightsField[
            referencingDetailField[i].refNumberField - 1
          ].flightDetailsField[n].flightInformationField.productDateTimeField
            .dateOfDepartureField;
        //flightIndexField[0].groupOfFlightsField[5].flightDetailsField[0].flightInformationField.productDateTimeField.timeOfDepartureField
        let F_depTime =
          flightIndex[i].groupOfFlightsField[
            referencingDetailField[i].refNumberField - 1
          ].flightDetailsField[n].flightInformationField.productDateTimeField
            .timeOfDepartureField;

        //flightIndexField[0].groupOfFlightsField[58].flightDetailsField[0].flightInformationField.locationField[1]
        if (TrueFromLoctions.length == 0) {
          //flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.locationField[0].locationIdField
          TrueFromLoctions =
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField.locationField[0]
              .locationIdField;
        } else {
          TrueFromLoctions =
            TrueFromLoctions +
            '-' +
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField.locationField[0]
              .locationIdField;
        }
        let F_TruLocF =
          flightIndex[i].groupOfFlightsField[
            referencingDetailField[i].refNumberField - 1
          ].flightDetailsField[n].flightInformationField.locationField[0]
            .locationIdField;
        let F_TruLocT =
          flightIndex[i].groupOfFlightsField[
            referencingDetailField[i].refNumberField - 1
          ].flightDetailsField[n].flightInformationField.locationField[1]
            .locationIdField;

        if (TrueToLoctions.length == 0) {
          //flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.locationField[0].locationIdField
          TrueToLoctions =
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField.locationField[1]
              .locationIdField;
        } else {
          TrueToLoctions =
            TrueToLoctions +
            '-' +
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField.locationField[1]
              .locationIdField;
        }

        if (marketingCompany.length == 0) {
          //flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.locationField[0].locationIdField
          //flightIndexField[0].groupOfFlightsField[58].flightDetailsField[0].flightInformationField.companyIdField.marketingCarrierField
          marketingCompany =
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField.companyIdField
              .marketingCarrierField;
        } else {
          marketingCompany =
            marketingCompany +
            '-' +
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField.companyIdField
              .marketingCarrierField;
        }

        let F_MarketingC =
          flightIndex[i].groupOfFlightsField[
            referencingDetailField[i].refNumberField - 1
          ].flightDetailsField[n].flightInformationField.companyIdField
            .marketingCarrierField;
        let F_OperatingC =
          flightIndex[i].groupOfFlightsField[
            referencingDetailField[i].refNumberField - 1
          ].flightDetailsField[n].flightInformationField.companyIdField
            .operatingCarrierField;

        if (FlightNo.length == 0) {
          //flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.locationField[0].locationIdField
          //flightIndexField[0].groupOfFlightsField[58].flightDetailsField[0].flightInformationField.companyIdField.marketingCarrierField
          FlightNo =
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField
              .flightOrtrainNumberField;
        } else {
          FlightNo =
            FlightNo +
            '-' +
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField
              .flightOrtrainNumberField;
        }

        let F_FlightNo =
          flightIndex[i].groupOfFlightsField[
            referencingDetailField[i].refNumberField - 1
          ].flightDetailsField[n].flightInformationField
            .flightOrtrainNumberField;

        ////console.log ("FlightNo: " + flightIndex[i].groupOfFlightsField[referencingDetailField[i].refNumberField-1].flightDetailsField[n].flightInformationField.flightOrtrainNumberField);

        if (this.FlightGroup.length == 0) {
          this.FlightGroup =
            F_depDate +
            '-' +
            F_depTime +
            '-' +
            F_TruLocF +
            '-' +
            F_TruLocT +
            '-' +
            F_MarketingC +
            '-' +
            F_OperatingC +
            '-' +
            F_FlightNo +
            '-' +
            F_BookingClass +
            '-' +
            (n + 1).toString() +
            '-' +
            '0';
        } else {
          this.FlightGroup =
            this.FlightGroup +
            '~' +
            F_depDate +
            '-' +
            F_depTime +
            '-' +
            F_TruLocF +
            '-' +
            F_TruLocT +
            '-' +
            F_MarketingC +
            '-' +
            F_OperatingC +
            '-' +
            F_FlightNo +
            '-' +
            F_BookingClass +
            '-' +
            (n + 1).toString() +
            '-' +
            '0';
        }
        localStorage.setItem('BookingClass', F_BookingClass);
      }

      //console.log(this.FlightGroup);

      if (Segments.length == 0) {
        Segments =
          DepDate +
          '|' +
          TrueFromLoctions +
          '|' +
          TrueToLoctions +
          '|' +
          marketingCompany +
          '|' +
          FlightNo +
          '|' +
          BookingClass +
          '|' +
          this.NoofPAX;
      } else {
        Segments =
          Segments +
          '_' +
          DepDate +
          '|' +
          TrueFromLoctions +
          '|' +
          TrueToLoctions +
          '|' +
          marketingCompany +
          '|' +
          FlightNo +
          '|' +
          BookingClass +
          '|' +
          this.NoofPAX;
      }
      localStorage.setItem('Carrier', marketingCompany);

      localStorage.setItem('Cabin', this.Cabin);
    }
    localStorage.setItem('Flights', this.FlightGroup);
    //localStorage.setItem("BC", )
    localStorage.setItem('Sectors', this.SectorsItinerary);
    localStorage.setItem('Segments', Segments);
    localStorage.setItem('Adults', this.PaxAdults.toString());
    localStorage.setItem('Children', this.PaxChildren.toString());
    localStorage.setItem('Infants', this.PaxInfant.toString());

    ////console.log(this.SectorsItinerary);
    ////console.log (Segments);
    this.router.navigate(['FlightReview', 'Review', this.TrackerID]);
  }

  getFlights(schedule, deparr) {
  //  this.FilterFlightData();
    //05-12
    if (schedule == '05to12') {
      //Filter Departure
      if (deparr == 'Dep') {
      } else if (deparr == 'Dep') {
        //Filter Arrival
      }
      //console.log("MorningFlight");

      // this.ResponseFlightData=[];
    }
  }

  FilterFlightData() {


    this.loading=true;
    //this.ResponseFlightDataFull=[];
    //this.ResponseFlightResult=false;
    //console.log("RD1.>>", this .ResponseFlightData);


    //Filter data with max price first

      //this.ResponseFlightDataFull.push(this.ResponseFlightData[0]);
      let newdata:FlightSearch[];
      let flightindexdata: FlightIndexFieldEntity[];
      let recommendationdata: RecommendationFieldEntity[];
      let recommendationdataStops: RecommendationFieldEntity[];
      let recommendationdataDep: RecommendationFieldEntity[];

      let serviceFeesGrpdata: ServiceFeesGrpFieldEntity[];
      //declare newdata
      newdata=[{}];
      flightindexdata=[];
      recommendationdata=[];
      serviceFeesGrpdata=[];

      //PriceWise
      for(let i=0;i<this.ResponseFlightData.length;i++){

        //set flights object
        for(let flights=0; flights<this.ResponseFlightData[i].flightIndexField.length; flights++){
          flightindexdata.push(this.ResponseFlightData[i].flightIndexField[flights]);
        }



        //set ServiceGroup Object
        for(let sg=0; sg<this.ResponseFlightData[i].serviceFeesGrpField.length;sg++){
          serviceFeesGrpdata.push(this.ResponseFlightData[i].serviceFeesGrpField[sg]);
        }




        //Filter Recommendations according to SelectedMaxPrice
        // for (let recomindex=0;recomindex<this.ResponseFlightData[i].recommendationField.length;recomindex++){
          for (let recomindex=0;recomindex<2;recomindex++){

          let price = Number(this.ResponseFlightData[i].recommendationField[recomindex].recPriceInfoField[0].amountField)
          if(price<=this.selectedMaxPrice){
            recommendationdata.push(this.ResponseFlightData[i].recommendationField[recomindex]);
          }

        }

      }


      //Till here We got recommendationdata filtered for SelectedMaxPrice
      //lets set flight and service data
      newdata[0].flightIndexField= flightindexdata;
      newdata[0].serviceFeesGrpField=serviceFeesGrpdata;



      // Now filter reommendations
       recommendationdataStops=[];
      //1)  selectedNoofStopswise
      if(this.isArrayEmpty(this.selectedNoofStops)!="Y"){ //selectedNoofStops

        for(let index=0; index<this.selectedNoofStops.length;index++){
          let stoptosearch=Number(this.selectedNoofStops[index]);
          stoptosearch=stoptosearch+1;
          // for(let i=0;i<this.ResponseFlightData.length;i++)
          // {

            for(let recom=0; recom<recommendationdata.length;recom++){
              //recommendationField[14].segmentFlightRefField[0].referencingDetailField[0].refQualifierField
              let a:RecommendationFieldEntity;
              a={};
              a.itemNumberField = recommendationdata[recom].itemNumberField;
              a.PropertyChanged= recommendationdata[recom].PropertyChanged;
              a.fareFamilyRefField=recommendationdata[recom].fareFamilyRefField;
              a.itemNumberField= recommendationdata[recom].itemNumberField;
              a.miniRuleField= recommendationdata[recom].miniRuleField;
              a.paxFareProductField=recommendationdata[recom].paxFareProductField;
              a.recPriceInfoField= recommendationdata[recom].recPriceInfoField;
              a.recommandationSegmentsFareDetailsField = recommendationdata[recom].recommandationSegmentsFareDetailsField;
              a.specificRecDetailsField = recommendationdata[recom].specificRecDetailsField;
              a.warningMessageField = recommendationdata[recom].warningMessageField;

              let s:SegmentFlightRefFieldEntity[];
                s=[];
              for(let seg=0;seg<recommendationdata[recom].segmentFlightRefField.length;seg++){

                  for(let refdet=0; refdet<recommendationdata[recom].segmentFlightRefField[seg].referencingDetailField.length-1; refdet++){
                    let refno=Number(recommendationdata[recom].segmentFlightRefField[seg].referencingDetailField[refdet].refNumberField);
                    //flightIndexField[0].groupOfFlightsField[0].flightDetailsField

                    refno=refno-1;

                    if(this.ResponseFlightData[0].flightIndexField[refdet].groupOfFlightsField[refno].flightDetailsField.length==stoptosearch){
                      s.push(recommendationdata[recom].segmentFlightRefField[seg]);
                      //recommendationdataStops.push(recommendationdata[recom]);
                    }
                  }
              }
              a.segmentFlightRefField=s;
              recommendationdataStops.push(a);
            }

          // }

        }
      }else{
        recommendationdataStops=recommendationdata;

      }







       recommendationdataDep=[];
      //2)  selectedDepTimes

        // check if array is completely empty

        let arrayEmpty="Y";
         console.log("DepTimes11>>",this.selectedDepTimes);
        for(let index=0; index<this.selectedDepTimes.length;index++){
          if(this.selectedDepTimes[index]!=undefined){
            // console.log("IndexArray",index)
            // console.log("ArrayLength>>",this.selectedDepTimes[index].length);
              if(this.selectedDepTimes[index].length>0){
                arrayEmpty="N";
              }
          }
        }

        console.log("arrayEmpty>>", arrayEmpty);

      console.log("selectedDepTimes>>",this.selectedDepTimes);
        if(arrayEmpty=="N"){ // array is not completely empty




          for (let recom=0; recom<recommendationdataStops.length;recom++){

            let a:RecommendationFieldEntity;
                a={};
                a.itemNumberField = recommendationdataStops[recom].itemNumberField;
                a.PropertyChanged= recommendationdataStops[recom].PropertyChanged;
                a.fareFamilyRefField=recommendationdataStops[recom].fareFamilyRefField;
                a.itemNumberField= recommendationdataStops[recom].itemNumberField;
                a.miniRuleField= recommendationdataStops[recom].miniRuleField;
                a.paxFareProductField=recommendationdataStops[recom].paxFareProductField;
                a.recPriceInfoField= recommendationdataStops[recom].recPriceInfoField;
                a.recommandationSegmentsFareDetailsField = recommendationdataStops[recom].recommandationSegmentsFareDetailsField;
                a.specificRecDetailsField = recommendationdataStops[recom].specificRecDetailsField;
                a.warningMessageField = recommendationdataStops[recom].warningMessageField;

                let s:SegmentFlightRefFieldEntity[];
                  s=[];

                  //now lets filter Segments
                  for(let seg=0;seg<recommendationdataStops[recom].segmentFlightRefField.length;seg++){ //segments

                  let tobeadded="Y";

                  //  for(let refdet=0; refdet<recommendationdataStops[recom].segmentFlightRefField[seg]
                  //    .referencingDetailField.length-1;refdet++){  //refdet






                      for(let index=0; index<this.selectedDepTimes.length;index++)
                      {
                        // console.log("this.selectedDepTimesIndex>", index);
                        // console.log("DepTimesArray",this.selectedDepTimes[index] +"-"+index)
                        console.log("(this.selectedDepTimes[index].length---"+index, this.selectedDepTimes[index].length);

                        if(this.selectedDepTimes[index].length=0){
                          tobeadded="Y";

                        }else

                        if(this.selectedDepTimes[index]!=undefined){

                          //this.selectedDepTimes[index].length>0 &&
                        let refno = Number(recommendationdataStops[recom].segmentFlightRefField[seg]
                          .referencingDetailField[index].refNumberField); // since its dep time we will check only first flight of the segment


                          refno = refno-1;
                          //console.log("Index>>", index);
                          //console.log("DepTimeSSS><>", this.selectedDep+Times[index]);

                        //Get the Flight Departure Time
                        let flightindex=index;
                        //DepartureTime
                        let timeD = Number(this.ResponseFlightData[0].flightIndexField[flightindex].groupOfFlightsField[refno]
                        .flightDetailsField[0].flightInformationField.productDateTimeField.timeOfDepartureField); //0 is departure time

                        //Get Flight Numbe for debugging purpose
                        //flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.flightOrtrainNumberField
                        let FlightNumber=this.ResponseFlightData[0].flightIndexField[flightindex].groupOfFlightsField[refno].
                        flightDetailsField[0].flightInformationField.flightOrtrainNumberField

                          // let tobeadded=0;

                            for (let ii=0; ii<this.selectedDepTimes[index].length;ii++){
                              {
                                // console.log("IIndex>>", ii);
                                // console.log("DepTime><>", this.selectedDepTimes[index][ii]);
                              }
                          let splitted = this.selectedDepTimes[index][ii].split("_");
                          let splittedTimes=splitted[0].split("-");

                            let St= Number(splittedTimes[0])
                            St=St*100;
                            let Et= Number(splittedTimes[1])
                            Et=Et*100;
                            {
                              console.log("Recom>>", recom);
                              console.log("Seg>>", seg);
                              console.log("RefNo>>", refno);

                            console.log("FlightNo.>>", FlightNumber);
                            console.log("ST>>",St);
                            console.log("ET",Et);
                            console.log("TD", timeD)
                            }
                            if(timeD>=St && timeD<=Et){
                                   tobeadded="Y";
                              console.log("Adding");
                       //     s.push(recommendationdataStops[recom].segmentFlightRefField[seg]);
                            }else{
                              tobeadded="N";
                              console.log("Not Adding");
                            }



                          }

                        }


                      }


                    // } //refdet
                    console.log("tobeadded>>>>",tobeadded);


                      if(tobeadded=="Y"){

                        s.push(recommendationdataStops[recom].segmentFlightRefField[seg]);
                      }


                    }  //Recommendation.seg

                  a.segmentFlightRefField=s;
                  recommendationdataDep.push(a);


                } // For Recommendation






        }else{
          console.log("ZZZZZZZZZZZZZZZZZ");
          recommendationdataDep=recommendationdataStops;
        }





      // }else{
      //   console.log("CCCCCCCCCCCCCCCCCCCCCC");
      //   recommendationdataDep=recommendationdataStops;

      // }












      newdata[0].recommendationField= recommendationdataDep



//newdata[0].recommendationField= recommendationdataDep;


            this.ResponseFlightDataFull = newdata;
            this.ResponseFlightResult=true;
              console.log("RD2>>", this .ResponseFlightData);
              console.log("ND2>>", newdata);



   // }





    this.loading=false;

  }



  async onChangeCombinedCode(event:Event, arrayName:string){

    // this.ResponseFlightDataFull=[];
    // this.ResponseFlightDataFull.push(this.ResponseFlightData[0]);

    await this.SetArrays(event,arrayName).then(()=>{
        //this.FilterFlightData();
          this.NewFilterFlightData();
    })

  }


  async SetArrays(event:Event, arrayName:string){
    if(arrayName=="Price"){
      let selectedValue=(<any>event).value;
      this.selectedMaxPrice = selectedValue;
      this.MaxPriceArrayChanged=true;
    }


    let selectedValue=((<any>event).source).value;


      if((<any>event).checked==true)    {

        if(arrayName=="Airlines"){
          this.selectedAirlines.push(selectedValue);
          this.AirlinesArrayChanged=true;

        }
        if(arrayName=="NoofStops"){
          this.selectedNoofStops.push(selectedValue);
          this.NoofStopsArrayChanged=true;

        }
        if(arrayName=="Dep"){
          let splitted = selectedValue.split("_");
          let  i=Number(splitted[1]);
          this.selectedDepTimes[i].push(selectedValue);
          //console.log("ClickedDepTimes>>",this.selectedDepTimes);
          this.DepTimesArrayChanged=true;
        }
        if(arrayName=="Arr"){
          let splitted = selectedValue.split("_");
          let i=Number(splitted[1]);
          this.selectedArrTimes[i].push(selectedValue);
          console.log("ClickedArrTimes>>",this.selectedArrTimes);
          this.ArrTimesArrayChanged=true;
        }


      }else{

        if(arrayName=="Airlines"){
          this.selectedAirlines.forEach((element,index)=>{
            if(element==selectedValue) {
              delete this.selectedAirlines[index];
            }
          });
          this.AirlinesArrayChanged=true;
        }
        if(arrayName=="NoofStops"){

          this.selectedNoofStops.forEach((element,index)=>{
            if(element==selectedValue){
              delete this.selectedNoofStops[index];
            }
          });
          this.NoofStopsArrayChanged=true;
        }
        if(arrayName=="Dep"){

          // this.selectedDepTimes.forEach((element,index)=>{
          //   if(element==selectedValue) {
          //     //delete this.selectedDepTimes[index];
          //   }
          // });

          let splitted = selectedValue.split("_");
          let  i=Number(splitted[1]);
          this.selectedDepTimes[i]=[];
        }
        if(arrayName=="Arr"){
          // this.selectedArrTimes.forEach((element,index)=>{
          //   if(element==selectedValue)
          //   {
          //     delete this.selectedArrTimes[index];
          //   }
          // });
          let splitted = selectedValue.split("_");
          let  i=Number(splitted[1]);
          this.selectedArrTimes[i]=[];
          this.ArrTimesArrayChanged=true;
        }
        if(arrayName=="Price"){

          this.selectedPriceRange.forEach((element,index)=>{
            if(element==selectedValue) delete this.selectedPriceRange[index];

          });
          this.MaxPriceArrayChanged=true;
        }



      }
  }




NewFilterFlightData(){
  this.loading=true;
  //this.ResponseFlightDataFull = this.ResponseFlightData;

  if(this.MaxPriceArrayChanged=true){
    this.FilterDataMaxPrice(this.ResponseFlightData);
  }

  if(this.NoofStopsArrayChanged=true && this.isArrayEmpty(this.selectedNoofStops)=="N"){
    console.log("Filtering No of stops");
    this.FilterDataNoofStops(this.ResponseFlightData);
  }


  if(this.DepTimesArrayChanged=true && this.isArrayEmpty(this.selectedDepTimes)=="N"){
    let issubarraysEmpty="Y";
    for(let i=0; i<this.selectedDepTimes.length;i++){
      if(this.selectedDepTimes[i].length>0){
        issubarraysEmpty="N";
      }
    }
    if(issubarraysEmpty=="N"){
      console.log("Filtering DepTimes");
      this.FilterDataDepTimes(this.ResponseFlightDataFull);
    }
  }

  if(this.ArrTimesArrayChanged=true && this.isArrayEmpty(this.selectedArrTimes)=="N"){
    let issubarraysEmpty="Y";
    for(let i=0; i<this.selectedArrTimes.length;i++){
      if(this.selectedArrTimes[i].length>0){
        issubarraysEmpty="N";
      }
    }
    if(issubarraysEmpty=="N"){
      console.log("Filtering ArrTimes");
      this.FilterDataArrTimes(this.ResponseFlightDataFull);
    }
  }

  if(this.AirlinesArrayChanged=true && this.isArrayEmpty(this.selectedAirlines)=="N"){
    console.log("Filtering Airlines");
    this.FilterDataAirlines(this.ResponseFlightDataFull);
  }


this.loading=false;

  }


FilterDataMaxPrice(DataToFilter:FlightSearch[]){

  let newdata:FlightSearch[];
  let flightindexdata: FlightIndexFieldEntity[];
  let recommendationdata: RecommendationFieldEntity[];
  let recommendationdataStops: RecommendationFieldEntity[];
  let serviceFeesGrpdata: ServiceFeesGrpFieldEntity[];

  newdata=[{}];
  flightindexdata=[];
  recommendationdata=[];
  serviceFeesGrpdata=[];

  //PriceWise
  for(let i=0;i<DataToFilter.length;i++){

    //set flights object
    for(let flights=0; flights<DataToFilter[i].flightIndexField.length; flights++){
      flightindexdata.push(DataToFilter[i].flightIndexField[flights]);
    }



    //set ServiceGroup Object
    for(let sg=0; sg<DataToFilter[i].serviceFeesGrpField.length;sg++){
      serviceFeesGrpdata.push(DataToFilter[i].serviceFeesGrpField[sg]);
    }


    //Filter Recommendations according to SelectedMaxPrice
     for (let recomindex=0;recomindex<this.ResponseFlightData[i].recommendationField.length;recomindex++){

      let price = Number(DataToFilter[i].recommendationField[recomindex].recPriceInfoField[0].amountField)
      if(price<=this.selectedMaxPrice){
        recommendationdata.push(DataToFilter[i].recommendationField[recomindex]);
      }

    }

  }


  //Till here We got recommendationdata filtered for SelectedMaxPrice
  //lets set flight and service data
  newdata[0].flightIndexField= flightindexdata;
  newdata[0].serviceFeesGrpField=serviceFeesGrpdata;
  newdata[0].recommendationField=recommendationdata;
  this.ResponseFlightDataFull= newdata;

}

FilterDataNoofStops(DataToFilter:FlightSearch[]){

  console.log("DataToFilter>", DataToFilter);
   let newdata:FlightSearch[];
   let flightindexdata: FlightIndexFieldEntity[];
   let recommendationdata: RecommendationFieldEntity[];
   let recommendationdataStops: RecommendationFieldEntity[];
   let serviceFeesGrpdata: ServiceFeesGrpFieldEntity[];

      //declare newdata
      newdata=[{}];
      flightindexdata=[];
      recommendationdata=[];
      serviceFeesGrpdata=[];


      for(let i=0;i<DataToFilter.length;i++){

        //set flights object
        for(let flights=0; flights<DataToFilter[i].flightIndexField.length; flights++){
          flightindexdata.push(DataToFilter[i].flightIndexField[flights]);
        }

        //set ServiceGroup Object
        for(let sg=0; sg<DataToFilter[i].serviceFeesGrpField.length;sg++){
          serviceFeesGrpdata.push(DataToFilter[i].serviceFeesGrpField[sg]);
        }



      }




      // Now filter reommendations
       recommendationdataStops=[];
      //1)  selectedNoofStopswise
      recommendationdata=DataToFilter[0].recommendationField;

        for(let index=0; index<this.selectedNoofStops.length;index++){
          let stoptosearch=Number(this.selectedNoofStops[index]);
          stoptosearch=stoptosearch+1;
          console.log("Stops", stoptosearch);
          // for(let i=0;i<this.ResponseFlightData.length;i++)
          // {

            for(let recom=0; recom<recommendationdata.length;recom++){





              //recommendationField[14].segmentFlightRefField[0].referencingDetailField[0].refQualifierField
              let a:RecommendationFieldEntity;
              a={};
              a.itemNumberField = recommendationdata[recom].itemNumberField;
              a.PropertyChanged= recommendationdata[recom].PropertyChanged;
              a.fareFamilyRefField=recommendationdata[recom].fareFamilyRefField;
              a.itemNumberField= recommendationdata[recom].itemNumberField;
              a.miniRuleField= recommendationdata[recom].miniRuleField;
              a.paxFareProductField=recommendationdata[recom].paxFareProductField;
              a.recPriceInfoField= recommendationdata[recom].recPriceInfoField;
              a.recommandationSegmentsFareDetailsField = recommendationdata[recom].recommandationSegmentsFareDetailsField;
              a.specificRecDetailsField = recommendationdata[recom].specificRecDetailsField;
              a.warningMessageField = recommendationdata[recom].warningMessageField;

              let s:SegmentFlightRefFieldEntity[];
                s=[];
              for(let seg=0;seg<recommendationdata[recom].segmentFlightRefField.length;seg++){

                  for(let refdet=0; refdet<recommendationdata[recom].segmentFlightRefField[seg].referencingDetailField.length-1; refdet++){
                    let refno=Number(recommendationdata[recom].segmentFlightRefField[seg].referencingDetailField[refdet].refNumberField);
                    //flightIndexField[0].groupOfFlightsField[0].flightDetailsField

                    refno=refno-1;

                    if(this.ResponseFlightData[0].flightIndexField[refdet].groupOfFlightsField[refno].flightDetailsField.length==stoptosearch){
                      s.push(recommendationdata[recom].segmentFlightRefField[seg]);
                      recommendationdataStops.push(recommendationdata[recom]);
                    }
                  }
              }
              a.segmentFlightRefField=s;
              recommendationdataStops.push(a);
            }

          // }

        }


      //lets set flight and service data
      newdata[0].flightIndexField= flightindexdata;
      newdata[0].serviceFeesGrpField=serviceFeesGrpdata;
      newdata[0].recommendationField = recommendationdataStops;


      this.ResponseFlightDataFull=newdata;
      console.log("Newdata>>", newdata);
      this.loading=false;
      //this.FilterDataDepTimes(newdata);

}


FilterDataDepTimes(DataToFilter:FlightSearch[]){
  console.log("Deptimes", this.selectedDepTimes);
  let newdata:FlightSearch[];  let recommendationdataDepTimes: RecommendationFieldEntity[];  recommendationdataDepTimes=[];
       //declare newdata
     newdata=[{}];     newdata[0].flightIndexField=DataToFilter[0].flightIndexField;     newdata[0].serviceFeesGrpField=DataToFilter[0].serviceFeesGrpField;




     //recommendationdata = DataToFilter[0].recommendationField;    //
     for(let recom=0;recom < DataToFilter[0].recommendationField.length;recom++){
      // for(let recom=0;recom < 1;recom++){

        let s:SegmentFlightRefFieldEntity[];        s=[];

        for(let seg=0; seg<DataToFilter[0].recommendationField[recom].segmentFlightRefField.length;seg++){
          //for(let seg=0; seg<2;seg++){
        //refdet=selecteddeptimes.index        //for(let refdet=0; refdet<DataToFilter[0].recommendationField[recom].segmentFlightRefField[seg].referencingDetailField.length-1; refdet++){
            let segmenttoadd=0;
              for(let refdet=0; refdet<this.selectedDepTimes.length;refdet++) {  //actually number of segments
                let refNumber=Number(DataToFilter[0].recommendationField[recom].segmentFlightRefField[seg].referencingDetailField[refdet].refNumberField);
                  refNumber = refNumber-1;          //refNumber          //Get FlightDepTimes
                let DepTime=Number(DataToFilter[0].flightIndexField[refdet].groupOfFlightsField[refNumber].flightDetailsField[0].flightInformationField.productDateTimeField.timeOfDepartureField);

                if(this.selectedDepTimes[refdet].length>0){
                  segmenttoadd=0;
                  console.log("segmenttoadd-->",segmenttoadd);            console.log("Here>",this.selectedDepTimes[refdet])+"-"+seg;
                  let subindexadd=false;
                  for (let subindex=0; subindex<this.selectedDepTimes[refdet].length;subindex++){
                    console.log("FilterFor>>>", this.selectedDepTimes[refdet][subindex]);
                    let splitted=this.selectedDepTimes[refdet][subindex].split("_");              let splittedTimes=splitted[0].split("-");              let StartTime=Number(splittedTimes[0]);                StartTime=StartTime*100;
                    let EndTime=Number(splittedTimes[1]);                EndTime=EndTime*100;                console.log("S>>"+StartTime+"   E>>"+EndTime+"Dep>>>"+DepTime);
                      if(DepTime>=StartTime && DepTime<=EndTime){
                        subindexadd=true;
                        console.log("1");
                        break;
                      }
                      // else{
                      //   subindexadd=false;
                      //   console.log("2");
                      // }
                      // console.log("subindexadd->",subindexadd);
                  }

            console.log("subindexadd->",subindexadd);
            console.log("segmenttoadd",segmenttoadd);

            if(subindexadd==true){
              console.log("LLLLLLLL");
              segmenttoadd=segmenttoadd+1;
            }
            console.log("segmenttoadd",segmenttoadd);

          }else{
            console.log("NoFilter-"+refdet)
            segmenttoadd=segmenttoadd+1;
          }

        }
        console.log("segmenttoadd-->",segmenttoadd);
        console.log("this.selectedDepTimes.length",this.selectedDepTimes.length);
        if(segmenttoadd==this.selectedDepTimes.length){
          s.push(DataToFilter[0].recommendationField[recom].segmentFlightRefField[seg]);
        }

      } // segment filling ends here
      let a:RecommendationFieldEntity;
                a={};
                a.itemNumberField = DataToFilter[0].recommendationField[recom].itemNumberField;
                a.PropertyChanged= DataToFilter[0].recommendationField[recom].PropertyChanged;
                a.fareFamilyRefField=DataToFilter[0].recommendationField[recom].fareFamilyRefField;
                a.itemNumberField= DataToFilter[0].recommendationField[recom].itemNumberField;
                a.miniRuleField= DataToFilter[0].recommendationField[recom].miniRuleField;
                a.paxFareProductField=DataToFilter[0].recommendationField[recom].paxFareProductField;
                a.recPriceInfoField= DataToFilter[0].recommendationField[recom].recPriceInfoField;
                a.recommandationSegmentsFareDetailsField = DataToFilter[0].recommendationField[recom].recommandationSegmentsFareDetailsField;
                a.specificRecDetailsField = DataToFilter[0].recommendationField[recom].specificRecDetailsField;
                a.warningMessageField = DataToFilter[0].recommendationField[recom].warningMessageField;
                a.segmentFlightRefField=s;

      recommendationdataDepTimes.push(a);

    }

newdata[0].recommendationField= recommendationdataDepTimes;
console.log("DataToFilter", DataToFilter);
console.log("NewData>>",newdata);
this.ResponseFlightDataFull= newdata;


}

FilterDataArrTimes(DataToFilter:FlightSearch[]){
  console.log("Arrtimes", this.selectedArrTimes);
  let newdata:FlightSearch[];  let recommendationdataArrTimes: RecommendationFieldEntity[];  recommendationdataArrTimes=[];
       //declare newdata
     newdata=[{}];     newdata[0].flightIndexField=DataToFilter[0].flightIndexField;     newdata[0].serviceFeesGrpField=DataToFilter[0].serviceFeesGrpField;




     //recommendationdata = DataToFilter[0].recommendationField;    //
     for(let recom=0;recom < DataToFilter[0].recommendationField.length;recom++){
      // for(let recom=0;recom < 1;recom++){

        let s:SegmentFlightRefFieldEntity[];        s=[];

        for(let seg=0; seg<DataToFilter[0].recommendationField[recom].segmentFlightRefField.length;seg++){
          //for(let seg=0; seg<2;seg++){
        //refdet=selectedArrtimes.index        //for(let refdet=0; refdet<DataToFilter[0].recommendationField[recom].segmentFlightRefField[seg].referencingDetailField.length-1; refdet++){
            let segmenttoadd=0;
              for(let refdet=0; refdet<this.selectedArrTimes.length;refdet++) {  //actually number of segments
                let refNumber=Number(DataToFilter[0].recommendationField[recom].segmentFlightRefField[seg].referencingDetailField[refdet].refNumberField);
                  refNumber = refNumber-1;          //refNumber          //Get FlightArrTimes


                  let arrivalFlightIndex=DataToFilter[0].flightIndexField[refdet].groupOfFlightsField[refNumber].flightDetailsField.length;
                  arrivalFlightIndex=arrivalFlightIndex-1;
                let ArrTime=Number(DataToFilter[0].flightIndexField[refdet].groupOfFlightsField[refNumber].flightDetailsField[arrivalFlightIndex].flightInformationField.productDateTimeField.timeOfArrivalField);

                if(this.selectedArrTimes[refdet].length>0){
                  segmenttoadd=0;
                  console.log("segmenttoadd-->",segmenttoadd);            console.log("Here>",this.selectedArrTimes[refdet])+"-"+seg;
                  let subindexadd=false;
                  for (let subindex=0; subindex<this.selectedArrTimes[refdet].length;subindex++){
                    console.log("FilterFor>>>", this.selectedArrTimes[refdet][subindex]);
                    let splitted=this.selectedArrTimes[refdet][subindex].split("_");              let splittedTimes=splitted[0].split("-");              let StartTime=Number(splittedTimes[0]);                StartTime=StartTime*100;
                    let EndTime=Number(splittedTimes[1]);                EndTime=EndTime*100;                console.log("S>>"+StartTime+"   E>>"+EndTime+"Arr>>>"+ArrTime);
                      if(ArrTime>=StartTime && ArrTime<=EndTime){
                        subindexadd=true;
                        console.log("1");
                        break;
                      }
                      // else{
                      //   subindexadd=false;
                      //   console.log("2");
                      // }
                      // console.log("subindexadd->",subindexadd);
                  }

            console.log("subindexadd->",subindexadd);
            console.log("segmenttoadd",segmenttoadd);

            if(subindexadd==true){
              console.log("LLLLLLLL");
              segmenttoadd=segmenttoadd+1;
            }
            console.log("segmenttoadd",segmenttoadd);

          }else{
            console.log("NoFilter-"+refdet)
            segmenttoadd=segmenttoadd+1;
          }

        }
        console.log("segmenttoadd-->",segmenttoadd);
        console.log("this.selectedArrTimes.length",this.selectedArrTimes.length);
        if(segmenttoadd==this.selectedArrTimes.length){
          s.push(DataToFilter[0].recommendationField[recom].segmentFlightRefField[seg]);
        }

      } // segment filling ends here
      let a:RecommendationFieldEntity;
                a={};
                a.itemNumberField = DataToFilter[0].recommendationField[recom].itemNumberField;
                a.PropertyChanged= DataToFilter[0].recommendationField[recom].PropertyChanged;
                a.fareFamilyRefField=DataToFilter[0].recommendationField[recom].fareFamilyRefField;
                a.itemNumberField= DataToFilter[0].recommendationField[recom].itemNumberField;
                a.miniRuleField= DataToFilter[0].recommendationField[recom].miniRuleField;
                a.paxFareProductField=DataToFilter[0].recommendationField[recom].paxFareProductField;
                a.recPriceInfoField= DataToFilter[0].recommendationField[recom].recPriceInfoField;
                a.recommandationSegmentsFareDetailsField = DataToFilter[0].recommendationField[recom].recommandationSegmentsFareDetailsField;
                a.specificRecDetailsField = DataToFilter[0].recommendationField[recom].specificRecDetailsField;
                a.warningMessageField = DataToFilter[0].recommendationField[recom].warningMessageField;
                a.segmentFlightRefField=s;

      recommendationdataArrTimes.push(a);

    }

newdata[0].recommendationField= recommendationdataArrTimes;
console.log("DataToFilter", DataToFilter);
console.log("NewData>>",newdata);
this.ResponseFlightDataFull= newdata;

}




FilterDataAirlines(DataToFilter:FlightSearch[]){

  console.log("DataToFilter>", DataToFilter);
   let newdata:FlightSearch[];
   let flightindexdata: FlightIndexFieldEntity[];
   let recommendationdata: RecommendationFieldEntity[];
   let recommendationdataAirlines: RecommendationFieldEntity[];
   let serviceFeesGrpdata: ServiceFeesGrpFieldEntity[];

      //declare newdata
      newdata=[{}];
      recommendationdata=[];

      newdata[0].flightIndexField = DataToFilter[0].flightIndexField;
      newdata[0].serviceFeesGrpField=DataToFilter[0].serviceFeesGrpField;





      // Now filter reommendations
       recommendationdataAirlines=[];
      //1)  selectedNoofStopswise
      recommendationdata=DataToFilter[0].recommendationField;

        for(let index=0; index<this.selectedAirlines.length;index++){
          let Airlinetosearch=this.selectedAirlines[index];

          console.log("Airlinetosearch", Airlinetosearch);
          // for(let i=0;i<this.ResponseFlightData.length;i++)
          // {

            for(let recom=0; recom<recommendationdata.length;recom++){

              //recommendationField[14].segmentFlightRefField[0].referencingDetailField[0].refQualifierField
              let a:RecommendationFieldEntity;
              a={};
              a.itemNumberField = recommendationdata[recom].itemNumberField;
              a.PropertyChanged= recommendationdata[recom].PropertyChanged;
              a.fareFamilyRefField=recommendationdata[recom].fareFamilyRefField;
              a.itemNumberField= recommendationdata[recom].itemNumberField;
              a.miniRuleField= recommendationdata[recom].miniRuleField;
              a.paxFareProductField=recommendationdata[recom].paxFareProductField;
              a.recPriceInfoField= recommendationdata[recom].recPriceInfoField;
              a.recommandationSegmentsFareDetailsField = recommendationdata[recom].recommandationSegmentsFareDetailsField;
              a.specificRecDetailsField = recommendationdata[recom].specificRecDetailsField;
              a.warningMessageField = recommendationdata[recom].warningMessageField;

              let s:SegmentFlightRefFieldEntity[];
                s=[];
              for(let seg=0;seg<recommendationdata[recom].segmentFlightRefField.length;seg++){

                  for(let refdet=0; refdet<recommendationdata[recom].segmentFlightRefField[seg].referencingDetailField.length-1; refdet++){
                    let refno=Number(recommendationdata[recom].segmentFlightRefField[seg].referencingDetailField[refdet].refNumberField);
                    //flightIndexField[0].groupOfFlightsField[0].flightDetailsField

                    refno=refno-1;

                    if(DataToFilter[0].flightIndexField[refdet].groupOfFlightsField[refno].flightDetailsField[0].flightInformationField
                      .companyIdField.operatingCarrierField==this.selectedAirlines[index]){
                      s.push(recommendationdata[recom].segmentFlightRefField[seg]);
                      //recommendationdataAirlines.push(recommendationdata[recom]);
                    }
                  }
              }
              a.segmentFlightRefField=s;
              recommendationdataAirlines.push(a);
            }

          // }

        }


      //lets set flight and service data

      newdata[0].recommendationField = recommendationdataAirlines;


      this.ResponseFlightDataFull=newdata;
      console.log("Newdata>>", newdata);
      this.loading=false;
      //this.FilterDataDepTimes(newdata);


}



isArrayEmpty(a:any[]){

  if (a === undefined ){
  //  console.log("Undefined");
    return "Y";
  }else

  if ( a.length == 0){
//    console.log("Zero Length");
    return "Y";
  }else{

    for(let i=0;i<a.length;i++){
      //console.log("a>>", a[i]);
      //console.log("Length a>>", a[i].length);
      if(a[i]===undefined){
        //console.log(i+" - UnDefined");

      }else{
        //console.log(i+" - Defined");
        return "N";
      }
    }
return "Y";
  }






}

}
