<!-- <app-wrapper></app-wrapper> -->
<!-- [item]="currentItem" -->

<app-head-section></app-head-section>


<div class="container" style="display: block;">
    <div class="row">


        <div class="cols col-lg-3 col-md-6 col-sm-12 card"></div>
        <div class="cols col-lg-3 col-md-6 col-sm-12 ">
            <mat-card>

            </mat-card>
        </div>
        <div class="cols col-lg-3 col-md-6 col-sm-12">
            <mat-card>
                <div *ngIf="LoggedUser">

                </div>

            </mat-card>
        </div>
        <div class="cols col-lg-3 col-md-6 col-sm-12">
            <mat-card>

            </mat-card>
        </div>


    </div>
</div>