import { Pipe, PipeTransform } from '@angular/core';
import { FlightSearch } from '../interfaces/flight-search';

@Pipe({
  name: 'recommendationFilter'
})
export class RecommendationFilterPipe implements PipeTransform {

  transform(value: FlightSearch[], ArrTimes: any, DepTimes:any, Airlines:any,NoofStops:any,PriceRange:any): unknown {

    console.log("Value>>>", value);
    console.log("NoofStops>>", NoofStops);


    let FilterredArray:FlightSearch[];
    FilterredArray=[];
    FilterredArray.push(value[0]);
    //let FilterredArray[0].recommendationField=[];
    console.log("FilterredArray[0].recommendationField>>>",FilterredArray[0].recommendationField);

    for (let i=0; i<value.length; i++){
      //recommendationField
      //value[i].recommendationField.length
      for(let n=0; n<2; n++){
        console.log("value[i].recommendationField[n]>>",value[i].recommendationField[n]);
        FilterredArray[0].recommendationField.push(value[i].recommendationField[n]);
      }

    }

console.log("FilterredArray>>>",FilterredArray);
    return FilterredArray;


  }

}
