import { Component, OnInit } from '@angular/core';
import { WindowService } from '../../Services/window.service';
//import * as firebase from 'firebase';
//import { AngularFireAuth } from  "@angular/fire/auth";
//import firebase from 'firebase';
import  firebase from 'firebase';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';
import { PhoneNumber } from 'src/app/Classes/phone-number';
import { AuthService } from 'src/app/auth/auth.service';
import { LoadingService } from 'src/app/Services/loading.service';

@Component({
  selector: 'app-phone-login',
  templateUrl: './phone-login.component.html',
  styleUrls: ['./phone-login.component.css']
})
export class PhoneLoginComponent implements OnInit {

  windowRef: any;

  phoneNumber = new PhoneNumber()

  verificationCode: string;

  user: any;
  phoneLoginNumberStr:string;
  s:string;
  constructor(private win: WindowService, private HitAuth:AuthService,
    private loadingService:LoadingService) { }

  ngOnInit() {
    this.windowRef = this.win.windowRef
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container')
    this.windowRef.recaptchaVerifier.render()

    this.s =sessionStorage.getItem("PhoneLoginNumber");

    this.phoneLoginNumberStr=sessionStorage.getItem("PhoneLoginNumber");
    console.log("PhoneLoginNumber>>",this.phoneLoginNumberStr)
    this.phoneNumber.fullNo=this.phoneLoginNumberStr;
  }


  sendLoginCode() {

    const appVerifier = this.windowRef.recaptchaVerifier;

    const num = this.phoneNumber.e164;
    const newNum = this.phoneNumber.full;

    firebase.auth().signInWithPhoneNumber(newNum, appVerifier)
            .then(result => {
              console.log("Phoneresult>>",result);
                this.windowRef.confirmationResult = result;

            })
            .catch( error => console.log(error) );

  }

  verifyLoginCode() {
    this.windowRef.confirmationResult
                  .confirm(this.verificationCode)
                  .then( result => {
                    console.log("PhoneVerifyingresult>>",result);
                    this.user = result.user;
                    sessionStorage.setItem('userFromPhoneLogin',JSON.stringify(result.user));
                    sessionStorage.setItem('user',JSON.stringify(result.user));

                    this.HitAuth.registerUser();


    })
    .catch( error => console.log(error, "Incorrect code entered?"));
  }


}
