import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getFlightsFromFlightIndex'
})
export class GetFlightsFromFlightIndexPipe implements PipeTransform {

  transform(value: any, flightIndex: number, GroupNo:number): any {

    GroupNo= GroupNo-1;
    // console.log("F:"+flightIndex);
    // console.log("G:"+GroupNo);


    const FilteredArray:any[] =[];
    for(let i=0; i<=value.length-1; i++){
      if(i==flightIndex){
        // console.log("L="+value[i].groupOfFlightsField.length)
        for(let n=0; n<=value[i].groupOfFlightsField.length-1;n++){
          if (n===GroupNo){
            FilteredArray.push(value[i].groupOfFlightsField[n]);

          }
        }

      }
    }


    // console.log(FilteredArray);

    return FilteredArray;

  }

}
