import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { ErrorMessageService } from '../Services/error-message.service';
import { HitCookieService } from '../Services/hit-cookie.service';


// import { AngularFirestore } from '@angular/fire/firestore';
// import { Observable } from 'rxjs';


@Component({
  selector: 'app-wrapper',
  templateUrl: 'wrapper.component.html',

  styleUrls: ['./wrapper.component.css'],
})
export class WrapperComponent implements OnInit {

  cookieValue: string = '';


  constructor(
    private errorMessageService: ErrorMessageService,
    private cookieService: CookieService, private httpClient:HttpClient, public HitAuth:AuthService,
    public hitCookieService:HitCookieService) {}

  //public apiUrl:string ="https://cosmictours202020210214185330.azurewebsites.net/";
   //public apiUrl= "https://cosmictours2021.azurewebsites.net/";
  //public apiUrl = 'https://localhost:44317/';
  apiUrl = environment.apiUrl;

   currentItem = {"Name":"Imran ANSARI"};
  public userProfile={};
  public isLoading=false;
  NoCooksW:boolean=false;

  ngOnInit(): void {
  //  this.SetCookies();
  }

//   SetCookies() {
//      //console.log("Started");
//      if (!this.cookieService.get('cosmicHits')) {
//       console.log('No cookie');
//       let headersa = new HttpHeaders().append('Content-Type','application/json; charset=utf-8');

//             this.httpClient.post(this.apiUrl + "api/Hits", {headers: headersa}).subscribe(
//             (data:any)=> {
//               //console.log(data);
//               const dateNow = new Date();
//                     dateNow.setMinutes(dateNow.getMinutes() + 10);
//                     this.cookieService.set('cosmicHits', data, dateNow);
//                     this.hitCookieService.setHit_CID(data);

//                     this.cookieValue = this.cookieService.get('cosmicHits');
//                         //console.log("User is:" + this.cookieValue);
//             },
//             (err)=>{
//               this.errorMessageService.seterrorMessage("Oops!! Some erro occurred... Kindly retry after some time.")
//               this.NoCooksW=true;
//             });

//       this.cookieValue = this.cookieService.get('cosmicHits');
//     } else {
//       let data = this.cookieService.get('cosmicHits');
//       const dateNow = new Date();
//       dateNow.setMinutes(dateNow.getMinutes() + 10);
//       this.cookieService.set('cosmicHits', data, dateNow);
//       this.cookieValue = this.cookieService.get('cosmicHits');

//       console.log("Extending");
//  ///     this.cookieValue = this.cookieService.get('cosmicHits');
//       this.currentItem={"Name":this.cookieValue};
//     }
//   }
}
