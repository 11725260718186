import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'displayDateTime'
})
export class DisplayDateTimePipe implements PipeTransform {

  transform(value: any, ToTransform: string): string {
    // if(value.toString().length==3){
    //   value = "0"+value;
    // }

// console.log("ValueLength:"+value.toString().length);
// console.log("Value:"+value);
if(value!=null){
    if(ToTransform=="Date"){
      var d = value.toString().substr(0, 2);
      var m = value.toString().substr(2, 2);
      var y = value.toString().substr(4,2);
      var dd =d+"/"+ m+"/"+"20"+y;
      return dd;
      //return moment(dd).format(dateFormat);
    }else if(ToTransform=="Time"){
      var h;
      var mt="";


      if(value.toString().length==3){
        h = "0" + value.toString().substr(0, 1);
        mt = value.toString().substr(1, 2);

      }else{
         h = value.toString().substr(0, 2);
        mt = value.toString().substr(2, 2);

      }

      var tt =h + ":"+ mt;

      return tt;

    }else{
      return value;
    }
  }else{
    return "";
  }


  }

}
