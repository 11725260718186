import { Pipe, PipeTransform } from '@angular/core';
import { JsonPipe } from '@angular/common';

@Pipe({
  name: 'filterFlightProposal'
})
export class FilterFlightProposalPipe implements PipeTransform {

  transform(value: any, nunitQualifierField: string): any {

      const FilteredArray:any[] =[];

      for(let i=0; i<=value.length-1; i++){
        if(value[i].unitQualifierField!=null){
          let arefQualifier:string=value[i].unitQualifierField;
          arefQualifier = arefQualifier.toUpperCase();

            if(arefQualifier===nunitQualifierField){
              FilteredArray.push(value[i]);
              }
        }

      }

      return FilteredArray;

  }

}
