<ng-template #loadingmodal let-modal class="modal-dialog-centered">


    <div class="modal-body">
        <div>
            <div class="m-auto spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>

            <br>
            <div class="m-auto spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
                <h2>Loading</h2>Loading
            </div>
            <br><br><br><br><br><br><br><br><br>
        </div>
    </div>


</ng-template>