import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getFareToDisplay'
})
export class GetFareToDisplayPipe extends CurrencyPipe implements PipeTransform {

  transform(value: any, recPriceInfoField: any): string {
    let faretodisplayNumber:number=0;
//    for(let i=0; i<=value.length-1; i++){ // with tax
      for(let i=0; i<=1-1; i++){ // Fare without tax

      let amount:number=value[i].amountField;
      faretodisplayNumber= faretodisplayNumber+amount;

    }
     let faretodisplay:string= faretodisplayNumber.toString();

    return faretodisplay;
  }

}
