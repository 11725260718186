import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getGSTFromPNR'
})
export class GetGSTFromPNRPipe implements PipeTransform {

  transform(value: any, GSTType: string): string {

    let valueData=[];
    valueData.push(value);



    //Get Total Fare
    let TotalFare:number=0;

    for(let i=0; i<=valueData.length-1; i++){
       for (let n=0; n<valueData[i].fareDataField.monetaryInfoField.length;n++){
         let amount=valueData[i].fareDataField.monetaryInfoField[n].amountField;
         let amt=parseInt(amount,10);
         TotalFare=TotalFare+amt;
       }
    }

    let TotalTax:number=0;
    //tstDataField[0].fareDataField.taxFieldsField[0].taxAmountField

    for(let i=0; i<=valueData.length-1; i++){
      for (let n=0; n<valueData[i].fareDataField.taxFieldsField.length;n++){
        let amount=valueData[i].fareDataField.taxFieldsField[n].taxAmountField;
        let amt=parseInt(amount,10);
        TotalTax=TotalTax+amt;
      }

   }
   let TotalPayable:number=0;
   TotalPayable = TotalFare + TotalTax;

   let TotalGST: number=0;
   TotalGST = ((5)/100)* TotalPayable;
   let TotalCGST:Number =0;
   TotalCGST =  TotalGST/2;
   let TotalSGST: number=0;
   TotalSGST=  TotalGST/2;

   let PayableAmount: number=0;
   PayableAmount = ((100+5)/100)*TotalFare;

   if(GSTType=="TotalPayableFare"){
     return TotalPayable.toString();
   }else if(GSTType=="TotalGST"){
      return TotalGST.toString();

   }else if(GSTType=="TotalCGST"){
    return TotalCGST.toString();
   }else if(GSTType=="TotalSGST"){
    return TotalSGST.toString();
   }else if (GSTType="PayableAmount"){
     return PayableAmount.toString();
   }


    //return null;
  }

}
