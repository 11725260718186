import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-menus',
  template:`
 <div><span>Cosmic Travels</span></div>
        <a href="#" class=""><span class="material-icons">
          local_airport</span><br>Flight</a>
          <a href="#" class="">
                <span class="material-icons">local_hotel

                </span>
<br>Hotels</a>

        <a href="#" class=""><span class="material-icons">
                  directions_boat
                </span>
                  <br>Cruise</a>

                <a href="#" class=""><span class="material-icons">
                  rowing
                </span> <br>Holidays</a>



                <div class="btn btn-primary" >Enquire Now</div>
    `,
  styleUrls: ['./top-menus.component.css']
})
export class TopMenusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
