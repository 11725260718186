import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imgPath'
})
export class ImgPathPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): any {
    let path: any=[];
    let nm: string="";
     //console.log(value);
//[0].itineraryReservationInfoField.reservationField.companyIdField
console.log(value[0].itineraryReservationInfoField.reservationField.companyIdField);

      nm = "assets/airlines/"+value[0].itineraryReservationInfoField.reservationField.companyIdField+".png";

      //nm="{'img':"+nm +"}";


      path.push(nm);
  console.log(path);
    return path;
  }

}
