import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getTotalPayableFromFareInfo'
})
export class GetTotalPayableFromFareInfoPipe implements PipeTransform {

  transform(value: any, ...args: any): string {

    let TotalTaxAmount:number=0;
    let TotalPayableAmount:number=0;

    //fareListField[0].taxInformationField[0].amountDetailsField.fareDataMainInformationField.fareAmountField

    for(let i=0; i<=value.length-1; i++){
      for (let n=0; n<value[i].taxInformationField.length;n++){
        let amount=value[i].taxInformationField[n].amountDetailsField.fareDataMainInformationField.fareAmountField;
        let amt =parseInt(amount, 10);
        // console.log("TaxAmount:" + amount);
        TotalTaxAmount = TotalTaxAmount+amt;
      }

      let pamount= value[i].fareComponentDetailsGroupField[0].monetaryInformationField.monetaryDetailsField.amountField
      let pamt =parseInt(pamount,10);
      TotalPayableAmount = TotalPayableAmount+pamt;
    }

    TotalPayableAmount  = TotalPayableAmount+TotalTaxAmount;
    let TotalPayableAmounttoDisplay = TotalPayableAmount.toString();
    return TotalPayableAmounttoDisplay;
  }

}
