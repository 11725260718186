// Stores the currently-being-typechecked object for error messages.
let obj: any = null;
export class FareMasterPricerTravelBoardSearchReplyErProxy {
  public readonly replyStatusField: null;
  public readonly errorMessageField: ErrorMessageFieldProxy;
  public readonly conversionRateField: null;
  public readonly solutionFamilyField: null;
  public readonly familyInformationField: null;
  public readonly amountInfoForAllPaxField: null;
  public readonly amountInfoPerPaxField: null;
  public readonly feeDetailsField: null;
  public readonly bucketInfoField: null;
  public readonly themeIdTextField: null;
  public readonly dictionaryField: null;
  public readonly additionalInfoField: null;
  public readonly companyIdTextField: null;
  public readonly officeIdDetailsField: null;
  public readonly programDetailsField: null;
  public readonly milesAccrualField: null;
  public readonly initialEMDInfoField: null;
  public readonly emdRecommendationField: null;
  public readonly flightIndexField: null;
  public readonly recommendationField: null;
  public readonly otherSolutionsField: null;
  public readonly warningInfoField: null;
  public readonly globalInformationField: null;
  public readonly serviceFeesGrpField: null;
  public readonly multiDimensionRefField: null;
  public readonly valueField: null;
  public readonly mnrGrpField: null;
  public readonly offersGroupField: null;
  public readonly virtualInterliningField: null;
  public readonly PropertyChanged: null;
  public static Parse(d: string): FareMasterPricerTravelBoardSearchReplyErProxy {
    return FareMasterPricerTravelBoardSearchReplyErProxy.Create(JSON.parse(d));
  }
  public static Create(d: any, field: string = 'root'): FareMasterPricerTravelBoardSearchReplyErProxy {
    if (!field) {
      obj = d;
      field = "root";
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d);
    } else if (typeof(d) !== 'object') {
      throwNotObject(field, d, false);
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false);
    }
    checkNull(d.replyStatusField, field + ".replyStatusField");
    if (d.replyStatusField === undefined) {
      d.replyStatusField = null;
    }
    d.errorMessageField = ErrorMessageFieldProxy.Create(d.errorMessageField, field + ".errorMessageField");
    checkNull(d.conversionRateField, field + ".conversionRateField");
    if (d.conversionRateField === undefined) {
      d.conversionRateField = null;
    }
    checkNull(d.solutionFamilyField, field + ".solutionFamilyField");
    if (d.solutionFamilyField === undefined) {
      d.solutionFamilyField = null;
    }
    checkNull(d.familyInformationField, field + ".familyInformationField");
    if (d.familyInformationField === undefined) {
      d.familyInformationField = null;
    }
    checkNull(d.amountInfoForAllPaxField, field + ".amountInfoForAllPaxField");
    if (d.amountInfoForAllPaxField === undefined) {
      d.amountInfoForAllPaxField = null;
    }
    checkNull(d.amountInfoPerPaxField, field + ".amountInfoPerPaxField");
    if (d.amountInfoPerPaxField === undefined) {
      d.amountInfoPerPaxField = null;
    }
    checkNull(d.feeDetailsField, field + ".feeDetailsField");
    if (d.feeDetailsField === undefined) {
      d.feeDetailsField = null;
    }
    checkNull(d.bucketInfoField, field + ".bucketInfoField");
    if (d.bucketInfoField === undefined) {
      d.bucketInfoField = null;
    }
    checkNull(d.themeIdTextField, field + ".themeIdTextField");
    if (d.themeIdTextField === undefined) {
      d.themeIdTextField = null;
    }
    checkNull(d.dictionaryField, field + ".dictionaryField");
    if (d.dictionaryField === undefined) {
      d.dictionaryField = null;
    }
    checkNull(d.additionalInfoField, field + ".additionalInfoField");
    if (d.additionalInfoField === undefined) {
      d.additionalInfoField = null;
    }
    checkNull(d.companyIdTextField, field + ".companyIdTextField");
    if (d.companyIdTextField === undefined) {
      d.companyIdTextField = null;
    }
    checkNull(d.officeIdDetailsField, field + ".officeIdDetailsField");
    if (d.officeIdDetailsField === undefined) {
      d.officeIdDetailsField = null;
    }
    checkNull(d.programDetailsField, field + ".programDetailsField");
    if (d.programDetailsField === undefined) {
      d.programDetailsField = null;
    }
    checkNull(d.milesAccrualField, field + ".milesAccrualField");
    if (d.milesAccrualField === undefined) {
      d.milesAccrualField = null;
    }
    checkNull(d.initialEMDInfoField, field + ".initialEMDInfoField");
    if (d.initialEMDInfoField === undefined) {
      d.initialEMDInfoField = null;
    }
    checkNull(d.emdRecommendationField, field + ".emdRecommendationField");
    if (d.emdRecommendationField === undefined) {
      d.emdRecommendationField = null;
    }
    checkNull(d.flightIndexField, field + ".flightIndexField");
    if (d.flightIndexField === undefined) {
      d.flightIndexField = null;
    }
    checkNull(d.recommendationField, field + ".recommendationField");
    if (d.recommendationField === undefined) {
      d.recommendationField = null;
    }
    checkNull(d.otherSolutionsField, field + ".otherSolutionsField");
    if (d.otherSolutionsField === undefined) {
      d.otherSolutionsField = null;
    }
    checkNull(d.warningInfoField, field + ".warningInfoField");
    if (d.warningInfoField === undefined) {
      d.warningInfoField = null;
    }
    checkNull(d.globalInformationField, field + ".globalInformationField");
    if (d.globalInformationField === undefined) {
      d.globalInformationField = null;
    }
    checkNull(d.serviceFeesGrpField, field + ".serviceFeesGrpField");
    if (d.serviceFeesGrpField === undefined) {
      d.serviceFeesGrpField = null;
    }
    checkNull(d.multiDimensionRefField, field + ".multiDimensionRefField");
    if (d.multiDimensionRefField === undefined) {
      d.multiDimensionRefField = null;
    }
    checkNull(d.valueField, field + ".valueField");
    if (d.valueField === undefined) {
      d.valueField = null;
    }
    checkNull(d.mnrGrpField, field + ".mnrGrpField");
    if (d.mnrGrpField === undefined) {
      d.mnrGrpField = null;
    }
    checkNull(d.offersGroupField, field + ".offersGroupField");
    if (d.offersGroupField === undefined) {
      d.offersGroupField = null;
    }
    checkNull(d.virtualInterliningField, field + ".virtualInterliningField");
    if (d.virtualInterliningField === undefined) {
      d.virtualInterliningField = null;
    }
    checkNull(d.PropertyChanged, field + ".PropertyChanged");
    if (d.PropertyChanged === undefined) {
      d.PropertyChanged = null;
    }
    return new FareMasterPricerTravelBoardSearchReplyErProxy(d);
  }
  private constructor(d: any) {
    this.replyStatusField = d.replyStatusField;
    this.errorMessageField = d.errorMessageField;
    this.conversionRateField = d.conversionRateField;
    this.solutionFamilyField = d.solutionFamilyField;
    this.familyInformationField = d.familyInformationField;
    this.amountInfoForAllPaxField = d.amountInfoForAllPaxField;
    this.amountInfoPerPaxField = d.amountInfoPerPaxField;
    this.feeDetailsField = d.feeDetailsField;
    this.bucketInfoField = d.bucketInfoField;
    this.themeIdTextField = d.themeIdTextField;
    this.dictionaryField = d.dictionaryField;
    this.additionalInfoField = d.additionalInfoField;
    this.companyIdTextField = d.companyIdTextField;
    this.officeIdDetailsField = d.officeIdDetailsField;
    this.programDetailsField = d.programDetailsField;
    this.milesAccrualField = d.milesAccrualField;
    this.initialEMDInfoField = d.initialEMDInfoField;
    this.emdRecommendationField = d.emdRecommendationField;
    this.flightIndexField = d.flightIndexField;
    this.recommendationField = d.recommendationField;
    this.otherSolutionsField = d.otherSolutionsField;
    this.warningInfoField = d.warningInfoField;
    this.globalInformationField = d.globalInformationField;
    this.serviceFeesGrpField = d.serviceFeesGrpField;
    this.multiDimensionRefField = d.multiDimensionRefField;
    this.valueField = d.valueField;
    this.mnrGrpField = d.mnrGrpField;
    this.offersGroupField = d.offersGroupField;
    this.virtualInterliningField = d.virtualInterliningField;
    this.PropertyChanged = d.PropertyChanged;
  }
}

export class ErrorMessageFieldProxy {
  public readonly applicationErrorField: ApplicationErrorFieldProxy;
  public readonly errorMessageTextField: ErrorMessageTextFieldProxy;
  public readonly PropertyChanged: null;
  public static Parse(d: string): ErrorMessageFieldProxy {
    return ErrorMessageFieldProxy.Create(JSON.parse(d));
  }
  public static Create(d: any, field: string = 'root'): ErrorMessageFieldProxy {
    if (!field) {
      obj = d;
      field = "root";
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d);
    } else if (typeof(d) !== 'object') {
      throwNotObject(field, d, false);
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false);
    }
    d.applicationErrorField = ApplicationErrorFieldProxy.Create(d.applicationErrorField, field + ".applicationErrorField");
    d.errorMessageTextField = ErrorMessageTextFieldProxy.Create(d.errorMessageTextField, field + ".errorMessageTextField");
    checkNull(d.PropertyChanged, field + ".PropertyChanged");
    if (d.PropertyChanged === undefined) {
      d.PropertyChanged = null;
    }
    return new ErrorMessageFieldProxy(d);
  }
  private constructor(d: any) {
    this.applicationErrorField = d.applicationErrorField;
    this.errorMessageTextField = d.errorMessageTextField;
    this.PropertyChanged = d.PropertyChanged;
  }
}

export class ApplicationErrorFieldProxy {
  public readonly applicationErrorDetailField: ApplicationErrorDetailFieldProxy;
  public readonly PropertyChanged: null;
  public static Parse(d: string): ApplicationErrorFieldProxy {
    return ApplicationErrorFieldProxy.Create(JSON.parse(d));
  }
  public static Create(d: any, field: string = 'root'): ApplicationErrorFieldProxy {
    if (!field) {
      obj = d;
      field = "root";
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d);
    } else if (typeof(d) !== 'object') {
      throwNotObject(field, d, false);
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false);
    }
    d.applicationErrorDetailField = ApplicationErrorDetailFieldProxy.Create(d.applicationErrorDetailField, field + ".applicationErrorDetailField");
    checkNull(d.PropertyChanged, field + ".PropertyChanged");
    if (d.PropertyChanged === undefined) {
      d.PropertyChanged = null;
    }
    return new ApplicationErrorFieldProxy(d);
  }
  private constructor(d: any) {
    this.applicationErrorDetailField = d.applicationErrorDetailField;
    this.PropertyChanged = d.PropertyChanged;
  }
}

export class ApplicationErrorDetailFieldProxy {
  public readonly errorField: string;
  public readonly PropertyChanged: null;
  public static Parse(d: string): ApplicationErrorDetailFieldProxy {
    return ApplicationErrorDetailFieldProxy.Create(JSON.parse(d));
  }
  public static Create(d: any, field: string = 'root'): ApplicationErrorDetailFieldProxy {
    if (!field) {
      obj = d;
      field = "root";
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d);
    } else if (typeof(d) !== 'object') {
      throwNotObject(field, d, false);
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false);
    }
    checkString(d.errorField, false, field + ".errorField");
    checkNull(d.PropertyChanged, field + ".PropertyChanged");
    if (d.PropertyChanged === undefined) {
      d.PropertyChanged = null;
    }
    return new ApplicationErrorDetailFieldProxy(d);
  }
  private constructor(d: any) {
    this.errorField = d.errorField;
    this.PropertyChanged = d.PropertyChanged;
  }
}

export class ErrorMessageTextFieldProxy {
  public readonly freeTextQualificationField: FreeTextQualificationFieldProxy;
  public readonly descriptionField: string[] | null;
  public readonly PropertyChanged: null;
  public static Parse(d: string): ErrorMessageTextFieldProxy {
    return ErrorMessageTextFieldProxy.Create(JSON.parse(d));
  }
  public static Create(d: any, field: string = 'root'): ErrorMessageTextFieldProxy {
    if (!field) {
      obj = d;
      field = "root";
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d);
    } else if (typeof(d) !== 'object') {
      throwNotObject(field, d, false);
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false);
    }
    d.freeTextQualificationField = FreeTextQualificationFieldProxy.Create(d.freeTextQualificationField, field + ".freeTextQualificationField");
    checkArray(d.descriptionField, field + ".descriptionField");
    if (d.descriptionField) {
      for (let i = 0; i < d.descriptionField.length; i++) {
        checkString(d.descriptionField[i], false, field + ".descriptionField" + "[" + i + "]");
      }
    }
    if (d.descriptionField === undefined) {
      d.descriptionField = null;
    }
    checkNull(d.PropertyChanged, field + ".PropertyChanged");
    if (d.PropertyChanged === undefined) {
      d.PropertyChanged = null;
    }
    return new ErrorMessageTextFieldProxy(d);
  }
  private constructor(d: any) {
    this.freeTextQualificationField = d.freeTextQualificationField;
    this.descriptionField = d.descriptionField;
    this.PropertyChanged = d.PropertyChanged;
  }
}

export class FreeTextQualificationFieldProxy {
  public readonly textSubjectQualifierField: string;
  public readonly informationTypeField: null;
  public readonly languageField: null;
  public readonly PropertyChanged: null;
  public static Parse(d: string): FreeTextQualificationFieldProxy {
    return FreeTextQualificationFieldProxy.Create(JSON.parse(d));
  }
  public static Create(d: any, field: string = 'root'): FreeTextQualificationFieldProxy {
    if (!field) {
      obj = d;
      field = "root";
    }
    if (d === null || d === undefined) {
      throwNull2NonNull(field, d);
    } else if (typeof(d) !== 'object') {
      throwNotObject(field, d, false);
    } else if (Array.isArray(d)) {
      throwIsArray(field, d, false);
    }
    checkString(d.textSubjectQualifierField, false, field + ".textSubjectQualifierField");
    checkNull(d.informationTypeField, field + ".informationTypeField");
    if (d.informationTypeField === undefined) {
      d.informationTypeField = null;
    }
    checkNull(d.languageField, field + ".languageField");
    if (d.languageField === undefined) {
      d.languageField = null;
    }
    checkNull(d.PropertyChanged, field + ".PropertyChanged");
    if (d.PropertyChanged === undefined) {
      d.PropertyChanged = null;
    }
    return new FreeTextQualificationFieldProxy(d);
  }
  private constructor(d: any) {
    this.textSubjectQualifierField = d.textSubjectQualifierField;
    this.informationTypeField = d.informationTypeField;
    this.languageField = d.languageField;
    this.PropertyChanged = d.PropertyChanged;
  }
}

function throwNull2NonNull(field: string, d: any): never {
  return errorHelper(field, d, "non-nullable object", false);
}
function throwNotObject(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, "object", nullable);
}
function throwIsArray(field: string, d: any, nullable: boolean): never {
  return errorHelper(field, d, "object", nullable);
}
function checkArray(d: any, field: string): void {
  if (!Array.isArray(d) && d !== null && d !== undefined) {
    errorHelper(field, d, "array", true);
  }
}
function checkString(d: any, nullable: boolean, field: string): void {
  if (typeof(d) !== 'string' && (!nullable || (nullable && d !== null && d !== undefined))) {
    errorHelper(field, d, "string", nullable);
  }
}
function checkNull(d: any, field: string): void {
  if (d !== null && d !== undefined) {
    errorHelper(field, d, "null or undefined", false);
  }
}
function errorHelper(field: string, d: any, type: string, nullable: boolean): never {
  if (nullable) {
    type += ", null, or undefined";
  }
  throw new TypeError('Expected ' + type + " at " + field + " but found:\n" + JSON.stringify(d) + "\n\nFull object:\n" + JSON.stringify(obj));
}
