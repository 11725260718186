import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getNoofStops'
})
export class GetNoofStopsPipe implements PipeTransform {

  transform(value: any, ): unknown {

    let gotStops= new Set();
    // gotStops.add("okkk");
    for (let i=0;i<value.length;i++){ //flightindex

      //flightIndexField[0].groupOfFlightsField[36].flightDetailsField
      for (let n=0; n<value[i].groupOfFlightsField.length;n++){

        let j = value[i].groupOfFlightsField[n].flightDetailsField.length;

        j=j-1;

        gotStops.add(j);
      }
    }

    return gotStops;
  }

}
