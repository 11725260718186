import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-head-section',
  template: `<div class="container-fluid" style="display: block;">
  <app-head-menus></app-head-menus>
  <app-search-section [NNewItem]="NItem" ></app-search-section>
</div>`,
  styleUrls: ['./head-section.component.css']
})
export class HeadSectionComponent implements OnInit {

@Input() item='';
NItem="Here";

  constructor() { }

  ngOnInit(): void {
  }

}
