import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusText'
})
export class StatusTextPipe implements PipeTransform {

  transform(value: string, status: string): string {
    let str = "Not Confirmed";
    if (status=="HK"){
      str="Confirmed";
    }
    return str;
  }

}
