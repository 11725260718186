import {
  AfterViewInit,
  Component,
  OnInit,
  Input,
  ViewChildren,
  ViewChild,
  Renderer2,
  NgModule,
  HostListener,
  ComponentFactoryResolver,
} from '@angular/core';
import * as moment from 'moment';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

import {
  FormsModule,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MultiCity } from '../multi-city';
import {
  FareMasterPricerTravelBoardSearchReplyProxy,
  GroupOfFlightsFieldEntityProxy,
} from '../fare-master-pricer-travel-board-search-reply';
import { FareMasterPricerTravelBoardSearchReplyErProxy } from '../fare-master-pricer-travel-board-search-reply-er';
import { JsonPipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ITS_JUST_ANGULAR } from '@angular/core/src/r3_symbols';
import { environment } from 'src/environments/environment';
//import { AllAirports } from '../Objs/all-airports';

//import { Console } from 'console';

// import {Observable} from 'rxjs';
// import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-search-section',
  templateUrl: './search-section.component.html',
  styleUrls: ['./search-section.component.css'],
})
export class SearchSectionComponent implements OnInit, AfterViewInit {
  @Input() NNewItem = '';
  obj: any = null;
  //constructor(private http:HttpClient){}

  //public apiUrl:string ="https://cosmictours202020210214185330.azurewebsites.net/";
  //public apiUrl = 'https://localhost:44317/';
  //public apiUrl="https://cosmictours2021.azurewebsites.net/";
  apiUrl = environment.apiUrl;

  // public DepartDateSelected=false;
  public DepartDateSelectedDate = moment().add(62, 'day');
  // public ReturnDateSelectedDate=moment().add(1,'day');

  public loading = false;
  public ReviewComponent = false;
  ReviewItineraryDetails = [];
  public BookingFailedComponent = false;
  public TrackerID = '';
  public DisplayedMonth = 0;
  ResponseFlightData = [];
  public ResponseFlight = false;
  ResponseFlightDataEr = [];
  public ResponseFlightEr = false;
  public SectorsItinerary = '';
  public NoofPAX = 1;
  public NoofTravellers = 1;

  public Session_Hits = '';

  ResponseFlightDataNew = [];
  public ResponseFlightNew = false;
  ResponseFlightDataErNew = [];
  public ResponseFlightErNew = false;
  public AdultsLists;
  public ChildrenList;
  public INfantsList;
  public AdtPax = true;
  public InfPax = true;
  public ChPax = false;
  InfantDOB = '';
  public PNR_locator = '';
  public cookieValue = '';

  ReviewOff() {
    if (this.ReviewComponent == false) {
      this.ReviewComponent = true;
    } else {
      this.ReviewComponent = false;
    }
  }
  reloadComponent() {
    let currentUrl = this.router.url;
    //  //console.log("URL: "+currentUrl);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  public ResponseFlightDetailsDataEr = [];
  public ResponseFlightDetailsData = [];
  public ResponseFlightDetails = false;

  public ResponseFlightDetailsEr = false;
  public FlightInfoNoPNR = [];

  public paxFareProductField = [];
  public FlightIndex = [];
  public TripType = '';

  public currentIndex = 0;

  // Calendar
  public dateO = moment().add(10, 'day');
  public date = moment();
  selectedFDate = this.date.format('YYYY MMM DD'); //Form
  selectedFormattedFDay = this.date.format('YYYY MM DD');

  selectedFDay = this.date.format('DD');
  selectedFDayYear = this.date.format('MMMM YYYY');
  selectedFWeekDay = this.date.format('dddd');

  public nextdate = moment().add(1, 'month'); /// Important

  selectedRDate = this.nextdate.format('YYYY MMM DD'); ////form
  selectedFormattedRDay = this.nextdate.format('YYYY MM DD');
  selectedRDay = this.nextdate.format('DD');
  selectedRDayYear = this.nextdate.format('MMMM YYYY');
  selectedRWeekDay = this.nextdate.format('dddd');

  //For Calendar View
  public DaysHeadArr;
  public DaysFArr;
  public DaysSArr;
  public SearchForm: FormGroup;

  public GroupBooking = false;
  public PaxAdults = 1; //Form
  public PaxChildren = 0; //Form
  public PaxInfant = 0; //Form

  public Cabin = 'Economy'; //Form

  public FromCity = 'Select Citya'; //Form
  public FromAirport = ''; //Form

  public ToCity = 'Select City'; //Form
  public ToAirport = ''; //Form

  public DirectFlights: boolean = false;
  public DomOrInt = 'D';

  public NoofPax: number = 1; //Form
  // public NoofTravellers:number=1;
  // public NoofTravellers:number=1;

  MultiCityArray = [
    {
      FromCityCode: 'DEL',
      ToCityCode: 'BOM',
      DepartDate: this.selectedFormattedFDay,
      ReturnDate: this.selectedFormattedRDay,
      DepDorI: '',
      RetDorI: '',
    },
  ];

  //FlightInfoDataParams=[{"PassengersGroup":"","SegmentGroup":"", "Cabin":"","TripType":"","DomOrInt":"","DirectFlights":false}];
  FlightInfoDataParams = [
    {
      PassengersGroups: '',
      DepDates: '',
      DepTimes: '',
      Sectors: '',
      MarCarriers: '',
      OprCarriers: '',
      FlightNos: '',
      Cabin: '',
      flightIndicators: '',
      itemNumbers: '',
      TripType: '',
      DomOrInt: '',
      DirectFlights: false,
    },
  ];

  //Calendar

  popularAirports = [
    {
      Code: 'AMD',
      Name: 'Sardar Vallabhbhai Patel International Airport',
      Alias: 'Sardar Vallabhbhai Patel International Airport',
      Country: 'India',
      CityName: 'Ahmedabad',
      CityCode: 'AMD',
      Type: 'P',
      LogoPath: 'in',
    },
    {
      Code: 'LON',
      Name: 'London All Airports',
      Alias: 'London All Airports',
      Country: 'United Kingdom',
      CityName: 'London',
      CityCode: 'LON',
      Type: 'P',
      LogoPath: 'gb',
    },
    {
      Code: 'BLR',
      Name: 'Bengaluru International Airport',
      Alias: 'Bengaluru International Airport',
      Country: 'India',
      CityName: 'Bangalore',
      CityCode: 'BLR',
      Type: 'P',
      LogoPath: 'in',
    },
    {
      Code: 'DEL',
      Name: 'Indira Gandhi International',
      Alias: 'Indira Gandhi International',
      Country: 'India',
      CityName: 'New Delhi',
      CityCode: 'DEL',
      Type: 'P',
      LogoPath: 'in',
    },
    {
      Code: 'CCU',
      Name: 'Netaji Subhas Chandra',
      Alias: 'Netaji Subhas Chandra',
      Country: 'India',
      CityName: 'Kolkata',
      CityCode: 'CCU',
      Type: 'P',
      LogoPath: 'in',
    },
    {
      Code: 'MAA',
      Name: 'Chennai',
      Alias: 'Chennai',
      Country: 'India',
      CityName: 'Chennai',
      CityCode: 'MAA',
      Type: 'P',
      LogoPath: 'in',
    },
    {
      Code: 'BOM',
      Name: 'Chhatrapati Shivaji',
      Alias: 'Chhatrapati Shivaji',
      Country: 'India',
      CityName: 'Mumbai',
      CityCode: 'BOM',
      Type: 'P',
      LogoPath: 'in',
    },
    {
      Code: 'KBL',
      Name: 'Khwaja Rawash',
      Alias: 'Khwaja Rawash',
      Country: 'Afghanistan',
      CityName: 'Kabul',
      CityCode: 'KBL',
      Type: 'P',
      LogoPath: 'af',
    },
    {
      Code: 'ALG',
      Name: 'Houari Boumedienne Airport',
      Alias: 'Houari Boumedienne Airport',
      Country: 'Algeria',
      CityName: 'Algiers',
      CityCode: 'ALG',
      Type: 'P',
      LogoPath: 'dz',
    },
    {
      Code: 'TSV',
      Name: 'Townsville',
      Alias: 'Townsville',
      Country: 'Australia',
      CityName: 'Townsville',
      CityCode: 'TSV',
      Type: 'P',
      LogoPath: 'au',
    },
    {
      Code: 'SYD',
      Name: 'Kingsford Smith',
      Alias: 'Kingsford Smith',
      Country: 'Australia',
      CityName: 'Sydney',
      CityCode: 'SYD',
      Type: 'P',
      LogoPath: 'au',
    },
    {
      Code: 'CTN',
      Name: 'Cooktown',
      Alias: 'Cooktown',
      Country: 'Australia',
      CityName: 'Cooktown',
      CityCode: 'CTN',
      Type: 'P',
      LogoPath: 'au',
    },
    {
      Code: 'MEL',
      Name: 'Tullamarine',
      Alias: 'Tullamarine',
      Country: 'Australia',
      CityName: 'Melbourne',
      CityCode: 'MEL',
      Type: 'P',
      LogoPath: 'au',
    },
    {
      Code: 'AVV',
      Name: 'Avalon Airport',
      Alias: 'Avalon Airport',
      Country: 'Australia',
      CityName: 'Avalon',
      CityCode: 'AVV',
      Type: 'P',
      LogoPath: 'au',
    },
    {
      Code: 'DRW',
      Name: 'Darwin',
      Alias: 'Darwin',
      Country: 'Australia',
      CityName: 'Darwin',
      CityCode: 'DRW',
      Type: 'P',
      LogoPath: 'au',
    },
    {
      Code: 'JSR',
      Name: 'Jessore',
      Alias: 'Jessore',
      Country: 'Bangladesh',
      CityName: 'Jessore',
      CityCode: 'JSR',
      Type: 'P',
      LogoPath: 'bd',
    },
    {
      Code: 'CGP',
      Name: 'Patenga',
      Alias: 'Patenga',
      Country: 'Bangladesh',
      CityName: 'Chittagong',
      CityCode: 'CGP',
      Type: 'P',
      LogoPath: 'bd',
    },
    {
      Code: 'BRU',
      Name: 'National',
      Alias: 'National',
      Country: 'Belgium',
      CityName: 'Brussels',
      CityCode: 'BRU',
      Type: 'P',
      LogoPath: 'be',
    },
    {
      Code: 'YWJ',
      Name: 'Deline',
      Alias: 'Deline',
      Country: 'Canada',
      CityName: 'Deline',
      CityCode: 'YWJ',
      Type: 'P',
      LogoPath: 'ca',
    },
    {
      Code: 'YEG',
      Name: 'International',
      Alias: 'International',
      Country: 'Canada',
      CityName: 'Edmonton',
      CityCode: 'YEG',
      Type: 'P',
      LogoPath: 'ca',
    },
    {
      Code: 'YVR',
      Name: 'Vancouver International',
      Alias: 'Vancouver International',
      Country: 'Canada',
      CityName: 'Vancouver',
      CityCode: 'YVR',
      Type: 'P',
      LogoPath: 'ca',
    },
    {
      Code: 'YWG',
      Name: 'Winnipeg',
      Alias: 'Winnipeg',
      Country: 'Canada',
      CityName: 'Winnipeg',
      CityCode: 'YWG',
      Type: 'P',
      LogoPath: 'ca',
    },
    {
      Code: 'YWB',
      Name: 'Kangiqsujuaq',
      Alias: 'Kangiqsujuaq',
      Country: 'Canada',
      CityName: 'Kangiqsujuaq',
      CityCode: 'YWB',
      Type: 'P',
      LogoPath: 'ca',
    },
    {
      Code: 'YWH',
      Name: 'Victoria Inner Harbor',
      Alias: 'Victoria Inner Harbor',
      Country: 'Canada',
      CityName: 'Victoria',
      CityCode: 'YWH',
      Type: 'P',
      LogoPath: 'ca',
    },
    {
      Code: 'YWF',
      Name: 'Dwtown Waterfront H-P',
      Alias: 'Dwtown Waterfront H-P',
      Country: 'Canada',
      CityName: 'Halifax',
      CityCode: 'YWF',
      Type: 'P',
      LogoPath: 'ca',
    },
    {
      Code: 'CDG',
      Name: 'Charles De Gaulle',
      Alias: 'Charles De Gaulle',
      Country: 'France',
      CityName: 'Paris',
      CityCode: 'CDG',
      Type: 'P',
      LogoPath: 'fr',
    },
    {
      Code: 'HKG',
      Name: 'Hong Kong International',
      Alias: '',
      Country: 'Hong Kong, PR China',
      CityName: 'Hong Kong',
      CityCode: 'HKG',
      Type: 'P',
      LogoPath: 'hk',
    },
    {
      Code: 'FCO',
      Name: 'Fiumicino',
      Alias: 'Fiumicino',
      Country: 'Italy',
      CityName: 'Rome',
      CityCode: 'FCO',
      Type: 'P',
      LogoPath: 'it',
    },
    {
      Code: 'VCE',
      Name: 'Marco Polo',
      Alias: 'Marco Polo',
      Country: 'Italy',
      CityName: 'Venice',
      CityCode: 'VCE',
      Type: 'P',
      LogoPath: 'it',
    },
    {
      Code: 'KTP',
      Name: 'Tinson',
      Alias: 'Tinson',
      Country: 'Jamaica',
      CityName: 'Kingston',
      CityCode: 'KTP',
      Type: 'P',
      LogoPath: 'jm',
    },
    {
      Code: 'POT',
      Name: 'Ken Jones',
      Alias: 'Ken Jones',
      Country: 'Jamaica',
      CityName: 'Port Antonio',
      CityCode: 'POT',
      Type: 'P',
      LogoPath: 'jm',
    },
    {
      Code: 'KTX',
      Name: 'Koutiala',
      Alias: 'Koutiala',
      Country: 'Mali',
      CityName: 'Koutiala',
      CityCode: 'KTX',
      Type: 'P',
      LogoPath: 'ml',
    },
    {
      Code: 'MEX',
      Name: 'Benito Juarez International',
      Alias: 'Benito Juarez International',
      Country: 'Mexico',
      CityName: 'Mexico City',
      CityCode: 'MEX',
      Type: 'P',
      LogoPath: 'mx',
    },
    {
      Code: 'AMS',
      Name: 'Amsterdam-Schiphol',
      Alias: 'Amsterdam-Schiphol',
      Country: 'Netherlands',
      CityName: 'Amsterdam',
      CityCode: 'AMS',
      Type: 'P',
      LogoPath: 'nl',
    },
    {
      Code: 'MST',
      Name: 'Maastricht-Aachen',
      Alias: 'Maastricht-Aachen',
      Country: 'Netherlands',
      CityName: 'Maastricht',
      CityCode: 'MST',
      Type: 'P',
      LogoPath: 'nl',
    },
    {
      Code: 'QRH',
      Name: 'ROTTERDAM',
      Alias: 'ROTTERDAM',
      Country: 'NETHERLANDS',
      CityName: 'ROTTERDAM',
      CityCode: 'QRH',
      Type: 'P',
      LogoPath: 'nl',
    },
    {
      Code: 'WLG',
      Name: 'International',
      Alias: 'International',
      Country: 'New Zealand',
      CityName: 'Wellington',
      CityCode: 'WLG',
      Type: 'P',
      LogoPath: 'nz',
    },
    {
      Code: 'ALR',
      Name: 'Alexandra',
      Alias: 'Alexandra',
      Country: 'New Zealand',
      CityName: 'Alexandra',
      CityCode: 'ALR',
      Type: 'P',
      LogoPath: 'nz',
    },
    {
      Code: 'ISB',
      Name: 'Islamabad',
      Alias: 'Islamabad',
      Country: 'Pakistan',
      CityName: 'Islamabad',
      CityCode: 'ISB',
      Type: 'P',
      LogoPath: 'pk',
    },
    {
      Code: 'KHI',
      Name: 'Quaid-E-Azam International',
      Alias: 'Quaid-E-Azam International',
      Country: 'Pakistan',
      CityName: 'Karachi',
      CityCode: 'KHI',
      Type: 'P',
      LogoPath: 'pk',
    },
    {
      Code: 'LHE',
      Name: 'Lahore',
      Alias: 'Lahore',
      Country: 'Pakistan',
      CityName: 'Lahore',
      CityCode: 'LHE',
      Type: 'P',
      LogoPath: 'pk',
    },
    {
      Code: 'VKO',
      Name: 'Vnukovo',
      Alias: 'Vnukovo',
      Country: 'Russia',
      CityName: 'Moscow',
      CityCode: 'VKO',
      Type: 'P',
      LogoPath: 'ru',
    },
    {
      Code: 'DME',
      Name: 'Domodedovo',
      Alias: 'Domodedovo',
      Country: 'Russia',
      CityName: 'Moscow',
      CityCode: 'DME',
      Type: 'P',
      LogoPath: 'ru',
    },
    {
      Code: 'LED',
      Name: 'Pulkovo',
      Alias: 'Pulkovo',
      Country: 'Russia',
      CityName: 'St Petersburg',
      CityCode: 'LED',
      Type: 'P',
      LogoPath: 'ru',
    },
    {
      Code: 'DMM',
      Name: 'King Fahad International',
      Alias: 'King Fahad International',
      Country: 'Saudi Arabia',
      CityName: 'Dammam',
      CityCode: 'DMM',
      Type: 'P',
      LogoPath: 'sa',
    },
    {
      Code: 'ELQ',
      Name: 'Gassim',
      Alias: 'Gassim',
      Country: 'Saudi Arabia',
      CityName: 'Gassim',
      CityCode: 'ELQ',
      Type: 'P',
      LogoPath: 'sa',
    },
    {
      Code: 'RAH',
      Name: 'Rafha',
      Alias: 'Rafha',
      Country: 'Saudi Arabia',
      CityName: 'Rafha',
      CityCode: 'RAH',
      Type: 'P',
      LogoPath: 'sa',
    },
    {
      Code: 'JED',
      Name: 'King Abdulaziz International',
      Alias: 'King Abdulaziz International',
      Country: 'Saudi Arabia',
      CityName: 'Jeddah',
      CityCode: 'JED',
      Type: 'P',
      LogoPath: 'sa',
    },
    {
      Code: 'GIZ',
      Name: 'Gizan',
      Alias: 'Gizan',
      Country: 'Saudi Arabia',
      CityName: 'Gizan',
      CityCode: 'GIZ',
      Type: 'P',
      LogoPath: 'sa',
    },
    {
      Code: 'MED',
      Name: 'Mohammad Bin Abdulaziz',
      Alias: 'Mohammad Bin Abdulaziz',
      Country: 'Saudi Arabia',
      CityName: 'Madinah',
      CityCode: 'MED',
      Type: 'P',
      LogoPath: 'sa',
    },
    {
      Code: 'SIN',
      Name: 'Changi',
      Alias: 'Changi',
      Country: 'Singapore',
      CityName: 'Singapore',
      CityCode: 'SIN',
      Type: 'P',
      LogoPath: 'sg',
    },
    {
      Code: 'CPT',
      Name: 'Cape Town Internationa',
      Alias: 'Cape Town Internationa',
      Country: 'South Africa',
      CityName: 'Cape Town',
      CityCode: 'CPT',
      Type: 'P',
      LogoPath: 'za',
    },
    {
      Code: 'JNB',
      Name: 'Johannesburg International',
      Alias: 'Johannesburg International',
      Country: 'South Africa',
      CityName: 'Johannesburg',
      CityCode: 'JNB',
      Type: 'P',
      LogoPath: 'za',
    },
    {
      Code: 'ZVH',
      Name: 'AL AIN',
      Alias: 'AL AIN',
      Country: 'UNITED ARAB EMIRATES',
      CityName: 'AL AIN',
      CityCode: 'ZVH',
      Type: 'P',
      LogoPath: 'ae',
    },
    {
      Code: 'SHJ',
      Name: 'Sharjah',
      Alias: 'Sharjah',
      Country: 'United Arab Emirates',
      CityName: 'Sharjah',
      CityCode: 'SHJ',
      Type: 'P',
      LogoPath: 'ae',
    },
    {
      Code: 'DXB',
      Name: 'Dubai',
      Alias: 'Dubai',
      Country: 'United Arab Emirates',
      CityName: 'Dubai',
      CityCode: 'DXB',
      Type: 'P',
      LogoPath: 'ae',
    },
    {
      Code: 'AUH',
      Name: 'Abu Dhabi International',
      Alias: 'Abu Dhabi International',
      Country: 'United Arab Emirates',
      CityName: 'Abu Dhabi',
      CityCode: 'AUH',
      Type: 'P',
      LogoPath: 'ae',
    },
    {
      Code: 'RKT',
      Name: 'Ras Al Khaimah',
      Alias: 'Ras Al Khaimah',
      Country: 'United Arab Emirates',
      CityName: 'Ras Al Khaimah',
      CityCode: 'RKT',
      Type: 'P',
      LogoPath: 'ae',
    },
    {
      Code: 'ATW',
      Name: 'Outagamie County',
      Alias: 'Outagamie County',
      Country: 'USA',
      CityName: 'Appleton, WI',
      CityCode: 'ATW',
      Type: 'P',
      LogoPath: 'us',
    },
    {
      Code: 'BBF',
      Name: 'Burlington',
      Alias: 'Burlington',
      Country: 'USA',
      CityName: 'Burlington, MA',
      CityCode: 'BBF',
      Type: 'P',
      LogoPath: 'us',
    },
    {
      Code: 'AGC',
      Name: 'Allegheny County Airport',
      Alias: 'Allegheny County Airport',
      Country: 'USA',
      CityName: 'Pittsburgh, PA',
      CityCode: 'AGC',
      Type: 'P',
      LogoPath: 'us',
    },
  ];

  // popularAirports = AllAirports;

  AirportSearch: string = '';

  //path:'FlightSearch/:Sectors/:DepDates/:Travellers/:Cabin/:TripType/:DomOrInt/:DirectFlights/:UTT',
  constructor(
    private cookieService: CookieService,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private httpClient: HttpClient,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe((params) => {


      // this.route.params.subscribe((params) => {
      //   if (params['Task'] == 'Search') {
      //     console.log("Route: "+this.route);
      //     this.getVisitID(
      //       params['Task'],
      //       params['Sectors'],
      //       params['DepDates'],
      //       params['Travellers'],
      //       params['Cabin'],
      //       params['TripType'],
      //       params['DomOrInt'],
      //       params['DirectFlights'],
      //       params['UTT']
      //     );
      //   }
      // });

      //this.setSearchForm("");

 //     console.log("LL<><>><>",this.MultiCityArray);



      if (params['Task'] == 'Search') {
        //      //console.log("Route: "+this.route);
        //     this.searchFromURL(params['Task'],params['Sectors'],params['DepDates'],params['Travellers'],
        //   params['Cabin'],params['TripType'],params['DomOrInt'],params['DirectFlights'],params['UTT'])
        //console.log("params['TripType']>>",params['TripType']);
        if(params['TripType']=="O"){
          this.SimpleSearch="One Way"
        }else if(params['TripType']=="M"){
          this.SimpleSearch="Multi City"
        }else{
          this.SimpleSearch="Round Trip"
        }





      }
      if (params['Task'] == 'Review') {
        // //console.log("Review Flight....");
        //this.AirSell(params['TrackerID']);
        // //console.log("Review Flight");
        // this.searchFromURL(params['Task'],params['Sectors'],params['DepDates'],params['Travellers'],
        // params['Cabin'],params['TripType'],params['DomOrInt'],params['DirectFlights'],params['UTT'])
      }
    });

    this.initSearchForm();
  }

  public initSearchForm() {
    return (this.SearchForm = this.fb.group({
      txtDep: [null, Validators.required],
      txtReturn: [null, Validators.required],
    }));
  }

  SimpleSearch = 'One Way';
  // SimpleSearch="Multi City";

  DateSelect = 'Depart';
  @ViewChild('DepartAirportDiv') DepartAirportDiv;
  @ViewChild('ToAirportDiv') ToAirportDiv;
  @ViewChild('airportSelector') AirportSelector;
  @ViewChild('errorSameDestination') ErrorSameDestination;
  @ViewChild('CalendarView') CalendarView;
  @ViewChild('DepartDateDiv') DepartDateDiv;
  @ViewChild('ReplyTxt') ReplyTxt;
  @ViewChild('RightBars') RightBars;
  @ViewChild('txtPNR') txtPNR;

  @ViewChild('ReturnDateDiv') ReturnDateDiv;
  @ViewChild('PaxDetDiv') PaxDetDiv;
  @ViewChild('PaxView') PaxView;

  @ViewChild('btnSimpleSearch') btnSimpleSearch;
  @ViewChild('btnMultiSearch') btnMultiSearch;

  @ViewChildren('MultiDepartAirportDiv') MultiDepartAirportDiv;
  @ViewChildren('MultiToAirportDiv') MultiToAirportDiv;
  @ViewChildren('MultiDepartDateDiv') MultiDepartDateDiv;

  @ViewChildren('SimpleDepartAirportDiv') SimpleDepartAirportDiv;
  @ViewChildren('SimpleToAirportDiv') SimpleToAirportDiv;
  @ViewChildren('SimpleDepartDateDiv') SimpleDepartDateDiv;
  @ViewChildren('SimpleReturnDateDiv') SimpleReturnDateDiv;
  @ViewChildren('SimplePaxDetDiv') SimplePaxDetDiv;
  @ViewChildren('MultiPaxDetDiv') MultiPaxDetDiv;

  @ViewChildren('errorSelectCityF') errorSelectCityF;
  @ViewChildren('errorSelectCityT') errorSelectCityT;

  //for FlightInfoData

  @ViewChildren('InfoDepDate') InfoDepDate;
  @ViewChildren('InfoDepTime') InfoDepTime;
  @ViewChildren('InfoFrom') InfoFrom;
  @ViewChildren('InfoTo') InfoTo;
  @ViewChildren('InfoMarketCarrier') InfoMarketCarrier;
  @ViewChildren('InfoOperatingCarrier') InfoOperatingCarrier;
  @ViewChildren('InfoFlightNumber') InfoFlightNumber;
  @ViewChildren('InfoFlightIndicator') InfoFlightIndicator;
  @ViewChildren('InfoItemNumber') InfoItemNumber;

  flightDetailsField = [];
  flightInformationField = [];

  GetFlightsInfo(T, i, h) {
    ////console.log("i: "+i+ " | " + "h: "+h + " | " + "T: " +T);

    //PassengerGroup
    //SegmentGroups
    //departureDate,departureTime,F_trueLocationId,T_trueLocationId,marketingCompany,operatingCompany,flightNumber,bookingClass,flightIndicator,itemNumber
    var Segments = '';

    for (let n = i; n <= h; n++) {
      var DepDate = this.InfoDepDate.toArray()[n];
      var DepDateV = DepDate.nativeElement.innerHTML;
      if (Segments.length == 0) {
        Segments = Segments + '|' + DepDateV;
      } else {
        Segments = Segments + '|' + DepDateV;
      }
    }
    ////console.log("Segments: " + Segments)

    var DepDate = this.InfoDepDate.toArray()[i];
    var InfoItemNumber = this.InfoItemNumber.toArray()[i];
    var InfoFlightNumber = this.InfoFlightNumber.toArray()[i];

    // this.renderer.setStyle(this.ErrorSameDestination.nativeElement,'display','none');

    var InfoFlightNumnerV = InfoFlightNumber.nativeElement.innerHTML;

    ////console.log("DepDate is " + DepDateV + "|" +"InfoFlightNumner is " + InfoFlightNumnerV );
  }

  NNGetFlightsInfo(T, i, h) {
    let isValid = 'Valid';
    let Sectors = '';
    let DepDates = '';

    //akbartravels.com/b2cplus/flight/display/
    //BLR-MAA/2020-07-31/1_0_0/D/ON/E/true/
    //819cefc0-681a-4472-a7e4-993e8c534737%7Cab71df6e-e169-4579-b038-adfd3b25965a%7C20200626180747

    //Getting Travellers/PassengersGroups
    const Travellers =
      this.PaxAdults + '-' + this.PaxChildren + '-' + this.PaxInfant;

    //Getting Sectors
    this.MultiCityArray.forEach((item, i) => {
      if (this.SimpleSearch == 'Round Trip') {
        Sectors =
          this.MultiCityArray[i].FromCityCode +
          '-' +
          this.MultiCityArray[i].ToCityCode +
          '_' +
          this.MultiCityArray[i].ToCityCode +
          '-' +
          this.MultiCityArray[i].FromCityCode;

        // DepDates= this.MultiCityArray[i].DepartDate + "_" + this.MultiCityArray[i].ReturnDate;
      } else {
        if (Sectors.length == 0) {
          Sectors =
            this.MultiCityArray[i].FromCityCode +
            '-' +
            this.MultiCityArray[i].ToCityCode;
        } else {
          Sectors =
            Sectors +
            '_' +
            this.MultiCityArray[i].FromCityCode +
            '-' +
            this.MultiCityArray[i].ToCityCode;
        }
      }
    });

    let Cabin = '';
    if (this.Cabin === 'Economy') {
      Cabin = 'E';
    } else if (this.Cabin === 'Premium Economy') {
      Cabin = 'PE';
    } else if (this.Cabin === 'Business') {
      Cabin = 'B';
    }

    //let DepartureDate =
    let TripType = '';
    if (this.SimpleSearch == 'One Way') {
      TripType = 'O';
      // RetDate="";
    } else if (this.SimpleSearch == 'Round Trip') {
      TripType = 'R';
    } else if (this.SimpleSearch == 'Multi City') {
      TripType = 'M';
      //   RetDate="";
    }
    this.TripType = TripType;

    if (isValid === 'Valid') {
      this.FlightInfoDataParams[0].PassengersGroups = Travellers;

      this.FlightInfoDataParams[0].Cabin = Cabin;
      this.FlightInfoDataParams[0].TripType = TripType;
      this.FlightInfoDataParams[0].DomOrInt = this.DomOrInt;
      this.FlightInfoDataParams[0].DirectFlights = this.DirectFlights;
      //this.FlightDataParams[0].SegmentGroups="";

      //'api/default.aspx", {}).toPromise().then(  data=> {

      //window.alert('{FlightDataParams:['+JSON.stringify(this.FlightDataParams[0])+']}');
      let s =
        '{"FlightDataParams":{"Sectors":"' +
        Sectors +
        '","DepDates":"' +
        DepDates +
        '","Travellers":"' +
        Travellers +
        '","Cabin":"' +
        Cabin +
        '","TripType":"' +
        TripType +
        '","DomOrInt":"' +
        this.DomOrInt +
        '","DirectFlights":"' +
        this.DirectFlights +
        '"}}';

      //  window.alert(s);
      let headers = new HttpHeaders().append(
        'Content-Type',
        'application/json; charset=utf-8'
      );

      let params = new HttpParams().set('Sectors', Sectors); // create params object
      params = params.append('DepDates', DepDates); // add a new param, creating a new object
      params = params.append('Travellers', Travellers);
      params = params.append('Cabin', Cabin);
      params = params.append('TripType', TripType);
      params = params.append('DomOrInt', this.DomOrInt);
      params = params.append('DirectFlights', this.DirectFlights.toString());

      this.httpClient
        .post('api/FlightInfo', s, { headers: headers, params: params })
        .subscribe((data: any) => {
          ////console.log("Info data is: "+data);

          let err = 'N';
        });

      ////console.log("Response is " + this.obj);
      ////console.log("FlightIndex is " + this.FlightIndex);
    }
  }

  ngOnInit(): void {
    this.DaysHeadArr = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    this.DaysFArr = this.createCalendar(this.date);
    this.DaysSArr = this.createCalendar(this.nextdate);
    this.selectedRDate = ''; //form
    this.selectedRDay = '';
    this.selectedRDayYear = '';
    this.selectedRWeekDay = '';
    this.cookieValue = this.cookieService.get('cosmicHits');
    this.getMultiCityArray();

  }

  getMultiCityArray(){
    let array = localStorage.getItem('SearchParams');
//    console.log("Array>>", array);
    if(array!=null){
      this.MultiCityArray=[];
      this.MultiCityArray=JSON.parse(array);
    }
    if(localStorage.getItem('Adults')!=null){
      this.PaxAdults=parseInt(localStorage.getItem('Adults'));
      this.NoofTravellers=this.PaxAdults;
    }
    if(localStorage.getItem('Children')!=null){
      this.PaxChildren=parseInt(localStorage.getItem('Children'));
      this.NoofTravellers=this.NoofTravellers+this.PaxChildren;
    }
    if(localStorage.getItem('Infants')!=null){
      this.PaxInfant=parseInt(localStorage.getItem('Infants'));
      this.NoofTravellers=this.NoofTravellers+this.PaxInfant;
    }




  }

  // Calendar
  createCalendar(month) {
    ////console.log(month);
    //console.log("D"+this.DepartDateSelectedDate);
    let firstDayFMonth = moment(month).startOf('M');

    //moment('2010-10-20').isAfter('2010-01-01', 'year'); // false
    //moment('2010-10-20').isAfter('2009-12-31', 'year'); // true

    //let lastDay = moment().a()+361;
    let lasdate = moment().add(361, 'day');

    ////console.log("Last: " + moment(lasdate).date() +"-"+ moment(lasdate).month() + "-"+ moment(lasdate).year());

    let CurDate = moment();
    let CurMonth = moment().month();
    let selectedM = moment(month).month();
    ////console.log('FirstDay:');
    ////console.log(firstDayFMonth);
    ////console.log('Curdate:');
    ////console.log(CurDate);

    if (moment(CurDate).isAfter(firstDayFMonth) == true) {
      firstDayFMonth = CurDate;
      // if(this.DepartDateSelected==false){
      //   firstDayFMonth=CurDate;
      // }else{
      //   firstDayFMonth=this.DepartDateSelectedDate;
      // }
    }

    let daysF;

    if (selectedM < CurMonth) {
      daysF = Array.apply(null, { length: lasdate.date() })
        .map(Number.call, Number)
        .map((n) => {
          return moment(firstDayFMonth).add(n, 'd');
        });
    } else {
      daysF = Array.apply(null, {
        length: month.daysInMonth() - firstDayFMonth.date() + 1,
      })
        .map(Number.call, Number)
        .map((n) => {
          return moment(firstDayFMonth).add(n, 'd');
        });
    }

    if (selectedM == CurMonth) {
      for (let n = 0; n < moment().date(); n++) {
        daysF.unshift(null);
      }
    } else if (selectedM < CurMonth) {
    } else {
      for (let n = 0; n < firstDayFMonth.weekday(); n++) {
        daysF.unshift(null);
      }
    }

    return daysF;
  }

  GoPrevious() {
    if (this.DisplayedMonth <= 0) {
      return;
    }
    this.date.add(-1, 'M');
    this.DaysFArr = this.createCalendar(this.date);
    this.nextdate.add(-1, 'M');
    this.DaysSArr = this.createCalendar(this.nextdate);
    this.DisplayedMonth--;
  }
  GoNext() {
    if (this.DisplayedMonth <= 9) {
      this.date.add(1, 'M');
      this.DaysFArr = this.createCalendar(this.date);
      this.nextdate.add(1, 'M');
      this.DaysSArr = this.createCalendar(this.nextdate);
      this.DisplayedMonth++;
      ////console.log("DD="+this.DisplayedMonth);
    }
  }

  TodayCheck(day) {
    if (!day) {
      return false;
    }
    ////console.log("L-"+day.format('L'));
    ////console.log("Date-"+day.format('DDMMYY'));
    // return moment().format('L')===day.format('L');
    return moment().format('DDMMYY') === day.format('DDMMYY');
  }

  public selectedDate(day, str) {
    //console.log("F: selectedDate");

    let dayFormatted = day.format('DD MMM YYYY');

    if (this.DateSelect === 'Depart') {
      // if(!this.SearchForm.get('txtDep').value){

      this.selectedFDate = day.format('DD MMM YYYY');
      this.selectedFDay = day.format('DD');
      this.selectedFDayYear = day.format('MMM YYYY');
      this.selectedFWeekDay = day.format('dddd');
      this.SearchForm.get('txtDep').patchValue(dayFormatted);
      //}
    } else if (this.DateSelect === 'To') {
      //if(!this.SearchForm.get('txtReturn').value){
      this.SearchForm.get('txtReturn').patchValue(dayFormatted);
      this.selectedRDate = day.format('DD MMM YYYY');
      this.selectedRDay = day.format('DD');
      this.selectedRDayYear = day.format('MMM YYYY');
      this.selectedRWeekDay = day.format('dddd');
      //}
    }
    this.hideControls('a');
  }

  public selectedDateA(day, str) {
    //console.log("F=selectedDateA");
    //console.log(moment(day));
    //console.log(this.DateSelect);

    ////console.log("moment(day).format('DDMMYY'):"+moment(day).format("DDMMYY"));
    if (this.SimpleSearch === 'Multi City') {
      // if(this.DateSelect==="Depart"){
      ////console.log("FormattedDate:"+day);
      this.MultiCityArray[this.currentIndex].DepartDate =
        moment(day).format('YYYY/MM/DD');
      this.CheckDepartDatesMulti();
      // }else if(this.DateSelect==="Return"){
      //this.MultiCityArray[this.currentIndex].DepartDate=day;
      //}
    } else if (this.SimpleSearch === 'One Way') {
      //if(this.DateSelect==="Depart"){
      ////console.log("FormattedDate:"+day);
      this.MultiCityArray[0].DepartDate = moment(day).format('YYYY/MM/DD');
      //}else if(this.DateSelect==="Return"){
      //this.MultiCityArray[this.currentIndex].DepartDate=day;

      //}
    } else if (this.SimpleSearch === 'Round Trip') {
      //  //console.log("FormattedDate:"+day);
      if (this.DateSelect === 'Depart') {
        this.MultiCityArray[0].DepartDate = moment(day).format('YYYY/MM/DD');
        //check if departdate is ahead of returndate

        // this.DepartDateSelected=true;
        // //console.log(this.DepartDateSelected);
        // this.DepartDateSelectedDate = moment(day);
      } else if (this.DateSelect === 'To') {
        this.MultiCityArray[0].ReturnDate = moment(day).format('YYYY/MM/DD');
      }

      let dDate = moment(this.MultiCityArray[0].DepartDate);
      let rDate = moment(this.MultiCityArray[0].ReturnDate);

      if (moment(dDate).isAfter(rDate) == true) {
        // console.log("Error");
        let ndate = moment(this.MultiCityArray[0].DepartDate).add(1, 'day');
        this.MultiCityArray[0].ReturnDate = moment(ndate).format('YYYY/MM/DD');
      } else {
        // console.log("OK");
      }
    }

    this.hideControls('a');
  }
  // Calendar

  ngAfterViewInit() {}

  setDateDiv(dd, el) {
    //console.log("F=setDateDiv");

    let a;
    let b;
    if (el == 'DepartDateDiv' || el == 'ReturnDateDiv') {
      a = this.DepartDateDiv;
      b = this.ReturnDateDiv;
    }

    const DTop = a.nativeElement.getBoundingClientRect().top + 25;
    const DLeft = a.nativeElement.getBoundingClientRect().left;
    //   const RTop = b.nativeElement.getBoundingClientRect().top + 25;
    //  const RLeft = b.nativeElement.getBoundingClientRect().left;
    this.renderer.setStyle(
      this.ErrorSameDestination.nativeElement,
      'display',
      'none'
    );
    if (dd === 'D') {
      this.DateSelect = 'Depart';
      this.renderer.setStyle(
        this.CalendarView.nativeElement,
        'top',
        DTop + 'px'
      );
      this.renderer.setStyle(
        this.CalendarView.nativeElement,
        'left',
        DLeft + 'px'
      );
      this.renderer.setStyle(
        this.CalendarView.nativeElement,
        'display',
        'block'
      );
    } else if (dd === 'T') {
      if (this.SimpleSearch == 'Round Trip') {
        this.DateSelect = 'To';
        // this.renderer.setStyle(this.CalendarView.nativeElement,'left',RLeft+'px');
        // this.renderer.setStyle(this.CalendarView.nativeElement,'top',RTop+'px');
        // this.renderer.setStyle(this.ErrorSameDestination.nativeElement,'top','325px');
        // this.renderer.setStyle(this.ErrorSameDestination.nativeElement,'left',RLeft+'px');

        // this.renderer.setStyle(this.CalendarView.nativeElement,'display','block');
      }
    }
  }

  setDateDivA(dd, Arr, i) {
    //console.log("F=setDateDivA---" + dd);
    this.currentIndex = i;
    let a;
    let b;
    if (Arr == 'M') {
      a = this.MultiDepartDateDiv.toArray()[i];
    } else if (Arr == 'S') {
      if (dd === 'D') {
        a = this.SimpleDepartDateDiv.toArray()[i];
      } else if (dd === 'T') {
        a = this.SimpleReturnDateDiv.toArray()[i];
      }
    }

    const DTop = a.nativeElement.getBoundingClientRect().top + 25;
    const DLeft = a.nativeElement.getBoundingClientRect().left;
    //   const RTop = b.nativeElement.getBoundingClientRect().top + 25;
    //  const RLeft = b.nativeElement.getBoundingClientRect().left;
    this.renderer.setStyle(
      this.ErrorSameDestination.nativeElement,
      'display',
      'none'
    );
    if (dd === 'D') {
      this.DateSelect = 'Depart';

      this.createCalendar(this.DepartDateSelectedDate);
      //console.log("Depart:");
      //console.log(this.DepartDateSelectedDate.toString());

      // //console.log("Return:"+ this.ReturnDateSelectedDate.toString())

      this.renderer.setStyle(
        this.CalendarView.nativeElement,
        'top',
        DTop + 'px'
      );
      this.renderer.setStyle(
        this.CalendarView.nativeElement,
        'left',
        DLeft + 'px'
      );
      this.renderer.setStyle(
        this.CalendarView.nativeElement,
        'display',
        'block'
      );
    } else if (dd === 'T') {
      if (this.SimpleSearch === 'Round Trip') {
        this.DateSelect = 'To';
        // this.createCalendar(this.ReturnDateSelectedDate);
        this.renderer.setStyle(
          this.CalendarView.nativeElement,
          'left',
          DLeft + 'px'
        );
        this.renderer.setStyle(
          this.CalendarView.nativeElement,
          'top',
          DTop + 'px'
        );
        // this.renderer.setStyle(this.ErrorSameDestination.nativeElement,'top','325px');
        // this.renderer.setStyle(this.ErrorSameDestination.nativeElement,'left',RLeft+'px');

        this.renderer.setStyle(
          this.CalendarView.nativeElement,
          'display',
          'block'
        );
      }
    }
  }

  setAirportDivA(dd, Arr, i) {
    this.currentIndex = i;
    let a;
    let b;
    if (Arr == 'M') {
      a = this.MultiDepartAirportDiv.toArray()[i];
      b = this.MultiToAirportDiv.toArray()[i];
    } else if (Arr == 'S') {
      a = this.SimpleDepartAirportDiv.toArray()[i];
      b = this.SimpleToAirportDiv.toArray()[i];
    }

    const DTop = a.nativeElement.getBoundingClientRect().top + 25;
    const DLeft = a.nativeElement.getBoundingClientRect().left;

    const RTop = b.nativeElement.getBoundingClientRect().top + 25;
    const RLeft = b.nativeElement.getBoundingClientRect().left;

    this.renderer.setStyle(
      this.ErrorSameDestination.nativeElement,
      'display',
      'none'
    );
    if (dd === 'D') {
      this.DateSelect = 'Depart';
      this.renderer.setStyle(
        this.AirportSelector.nativeElement,
        'top',
        DTop + 'px'
      );
      this.renderer.setStyle(
        this.AirportSelector.nativeElement,
        'left',
        DLeft + 'px'
      );
      this.renderer.setStyle(
        this.AirportSelector.nativeElement,
        'display',
        'block'
      );
    } else if (dd === 'T') {
      this.DateSelect = 'To';
      this.renderer.setStyle(
        this.AirportSelector.nativeElement,
        'left',
        RLeft + 'px'
      );
      this.renderer.setStyle(
        this.AirportSelector.nativeElement,
        'top',
        RTop + 'px'
      );
      this.renderer.setStyle(
        this.ErrorSameDestination.nativeElement,
        'top',
        '325px'
      );
      this.renderer.setStyle(
        this.ErrorSameDestination.nativeElement,
        'left',
        RLeft + 'px'
      );

      this.renderer.setStyle(
        this.AirportSelector.nativeElement,
        'display',
        'block'
      );
    }
  }

  setAirportDiv(dd, el) {
    let a;
    let b;
    if (el == 'DepartAirportDiv' || el == 'ToAirportDiv') {
      a = this.DepartAirportDiv;
      b = this.ToAirportDiv;
    }

    //window.alert(a.nativeElement.getBoundingClientRect().top + 25 +"Px");

    // const DTop = this.DepartAirportDiv.nativeElement.getBoundingClientRect().top + 25;
    // const DLeft = this.DepartAirportDiv.nativeElement.getBoundingClientRect().left;
    const DTop = a.nativeElement.getBoundingClientRect().top + 25;
    const DLeft = a.nativeElement.getBoundingClientRect().left;

    const RTop = b.nativeElement.getBoundingClientRect().top + 25;
    const RLeft = b.nativeElement.getBoundingClientRect().left;

    this.renderer.setStyle(
      this.ErrorSameDestination.nativeElement,
      'display',
      'none'
    );
    if (dd === 'D') {
      this.DateSelect = 'Depart';
      this.renderer.setStyle(
        this.AirportSelector.nativeElement,
        'top',
        DTop + 'px'
      );
      this.renderer.setStyle(
        this.AirportSelector.nativeElement,
        'left',
        DLeft + 'px'
      );
      this.renderer.setStyle(
        this.AirportSelector.nativeElement,
        'display',
        'block'
      );
    } else if (dd === 'T') {
      this.DateSelect = 'To';
      this.renderer.setStyle(
        this.AirportSelector.nativeElement,
        'left',
        RLeft + 'px'
      );
      this.renderer.setStyle(
        this.AirportSelector.nativeElement,
        'top',
        RTop + 'px'
      );
      this.renderer.setStyle(
        this.ErrorSameDestination.nativeElement,
        'top',
        '325px'
      );
      this.renderer.setStyle(
        this.ErrorSameDestination.nativeElement,
        'left',
        RLeft + 'px'
      );

      this.renderer.setStyle(
        this.AirportSelector.nativeElement,
        'display',
        'block'
      );
    }
  }

  setPaxDiv() {
    const RTop = this.PaxDetDiv.nativeElement.getBoundingClientRect().top + 25;
    const RLeft = this.PaxDetDiv.nativeElement.getBoundingClientRect().left;

    this.renderer.setStyle(
      this.ErrorSameDestination.nativeElement,
      'display',
      'none'
    );
    this.renderer.setStyle(this.PaxView.nativeElement, 'left', RLeft + 'px');
    this.renderer.setStyle(this.PaxView.nativeElement, 'top', RTop + 'px');
    this.renderer.setStyle(this.PaxView.nativeElement, 'display', 'block');
  }

  setPaxDivA(Arr, i) {
    this.currentIndex = i;
    let a;
    let b;
    if (Arr == 'M') {
      a = this.MultiPaxDetDiv.toArray()[i];
    } else if (Arr == 'S') {
      a = this.SimplePaxDetDiv.toArray()[i];
    }
    const RTop = a.nativeElement.getBoundingClientRect().top + 25;
    const RLeft = a.nativeElement.getBoundingClientRect().left;

    this.renderer.setStyle(
      this.ErrorSameDestination.nativeElement,
      'display',
      'none'
    );
    this.renderer.setStyle(this.PaxView.nativeElement, 'left', RLeft + 'px');
    this.renderer.setStyle(this.PaxView.nativeElement, 'top', RTop + 'px');
    this.renderer.setStyle(this.PaxView.nativeElement, 'display', 'block');
  }

  hideControls(ctrl) {
    this.renderer.setStyle(
      this.AirportSelector.nativeElement,
      'display',
      'none'
    );
    this.renderer.setStyle(this.CalendarView.nativeElement, 'display', 'none');
    this.renderer.setStyle(this.PaxView.nativeElement, 'display', 'none');
    if (ctrl === 'Date') {
      //this.renderer.setStyle(this.CalendarView.nativeElement,'display','block');
    }
    if (ctrl === 'Airport') {
      //this.renderer.setStyle(this.AirportSelector.nativeElement,'display','block');
    }
  }

  setSimpleForm(s) {
    this.SimpleSearch = s;
    if (s == 'Round Trip') {
      this.selectedRDate = this.nextdate.format('DD MMM YYYY'); //form
      this.selectedRDay = this.nextdate.format('DD');
      this.selectedRDayYear = this.nextdate.format('MMMM YYYY');
      this.selectedRWeekDay = this.nextdate.format('dddd');

      if (this.MultiCityArray.length > 1) {
        this.MultiCityArray.splice(1);
      }
    } else if (s == 'One Way') {
      this.selectedRDate = ''; //form
      this.selectedRDay = '';
      this.selectedRDayYear = '';
      this.selectedRWeekDay = '';
      if (this.MultiCityArray.length > 1) {
        this.MultiCityArray.splice(1);
      }
    } else {
      if (this.MultiCityArray.length == 1) {
        let m = new MultiCity('Select City', 'Select City', '', '', '', '');
        this.MultiCityArray.push(m);
      }
    }
  }

  setAirportToInputA(CityCode, Country) {
    if (CityCode === 'Select City') {
      // return;
      window.alert('P');
    }
    this.renderer.setStyle(
      this.ErrorSameDestination.nativeElement,
      'display',
      'none'
    );

    if (this.DateSelect === 'Depart') {
      if (Country === 'India') {
        this.MultiCityArray[this.currentIndex].DepDorI = 'D';
      } else {
        this.MultiCityArray[this.currentIndex].DepDorI = 'I';
      }

      if (
        this.SimpleSearch === 'One Way' ||
        this.SimpleSearch == 'Round Trip'
      ) {
        this.MultiCityArray[this.currentIndex].FromCityCode = CityCode;

        if (CityCode != 'Select City') {
          if (
            this.MultiCityArray[this.currentIndex].FromCityCode ===
            this.MultiCityArray[this.currentIndex].ToCityCode
          ) {
            this.renderer.setStyle(
              this.ErrorSameDestination.nativeElement,
              'display',
              'grid'
            );
            this.renderer.setStyle(
              this.btnSimpleSearch.nativeElement,
              'display',
              'none'
            );
            this.btnSimpleSearch.disabled = true;
          } else {
            this.renderer.setStyle(
              this.ErrorSameDestination.nativeElement,
              'display',
              'none'
            );
            this.renderer.setStyle(
              this.btnSimpleSearch.nativeElement,
              'display',
              'block'
            );
            this.btnSimpleSearch.disabled = false;
          }
        }
      } else if (this.SimpleSearch == 'Multi City') {
        this.MultiCityArray[this.currentIndex].FromCityCode = CityCode;

        let same = 'N';
        for (let n = 0; n < this.MultiCityArray.length - 0; n++) {
          if (this.MultiCityArray[n].FromCityCode != 'Select City') {
            if (
              this.MultiCityArray[n].FromCityCode ===
              this.MultiCityArray[n].ToCityCode
            ) {
              if (same === 'N') {
                same = 'Y';
              }
            }
          }
        }

        if (same === 'Y') {
          this.renderer.setStyle(
            this.ErrorSameDestination.nativeElement,
            'display',
            'grid'
          );
          this.renderer.setStyle(
            this.btnMultiSearch.nativeElement,
            'display',
            'none'
          );
          this.btnMultiSearch.disabled = true;
        } else {
          this.renderer.setStyle(
            this.ErrorSameDestination.nativeElement,
            'display',
            'none'
          );
          this.renderer.setStyle(
            this.btnMultiSearch.nativeElement,
            'display',
            'block'
          );
          this.btnMultiSearch.disabled = false;
        }
      }
    } else if (this.DateSelect === 'To') {
      if (Country === 'India') {
        this.MultiCityArray[this.currentIndex].RetDorI = 'D';
      } else {
        this.MultiCityArray[this.currentIndex].RetDorI = 'I';
      }

      if (
        this.SimpleSearch === 'One Way' ||
        this.SimpleSearch == 'Round Trip'
      ) {
        this.MultiCityArray[this.currentIndex].ToCityCode = CityCode;
        if (
          this.MultiCityArray[this.currentIndex].FromCityCode ===
          this.MultiCityArray[this.currentIndex].ToCityCode
        ) {
          this.renderer.setStyle(
            this.ErrorSameDestination.nativeElement,
            'display',
            'grid'
          );
          this.renderer.setStyle(
            this.btnSimpleSearch.nativeElement,
            'display',
            'none'
          );
          this.btnSimpleSearch.disabled = true;
        } else {
          this.renderer.setStyle(
            this.ErrorSameDestination.nativeElement,
            'display',
            'none'
          );
          this.renderer.setStyle(
            this.btnSimpleSearch.nativeElement,
            'display',
            'block'
          );
          this.btnSimpleSearch.disabled = false;
        }
      } else if (this.SimpleSearch == 'Multi City') {
        this.MultiCityArray[this.currentIndex].ToCityCode = CityCode;

        let same = 'N';
        for (let n = 0; n < this.MultiCityArray.length - 0; n++) {
          if (this.MultiCityArray[n].FromCityCode != 'Select City') {
            if (
              this.MultiCityArray[n].FromCityCode ===
              this.MultiCityArray[n].ToCityCode
            ) {
              if (same === 'N') {
                same = 'Y';
              }
            }
          }
        }

        if (same === 'Y') {
          this.renderer.setStyle(
            this.ErrorSameDestination.nativeElement,
            'display',
            'grid'
          );
          this.renderer.setStyle(
            this.btnMultiSearch.nativeElement,
            'display',
            'none'
          );
          this.btnMultiSearch.disabled = true;
        } else {
          this.renderer.setStyle(
            this.ErrorSameDestination.nativeElement,
            'display',
            'none'
          );
          this.renderer.setStyle(
            this.btnMultiSearch.nativeElement,
            'display',
            'block'
          );
          this.btnMultiSearch.disabled = false;
        }
      }
    }

    this.hideControls('a');
  }

  setAirportToInput(Code, Name, Country, CityName, LogoPath) {
    this.renderer.setStyle(
      this.ErrorSameDestination.nativeElement,
      'display',
      'none'
    );
    if (this.DateSelect === 'Depart') {
      this.FromAirport = Name;
      this.FromCity = CityName;
      this.DateSelect = 'Return';
      this.setDateDiv('T', 'ReturnDateDiv');
    } else if (this.DateSelect === 'To') {
      this.ToAirport = Name;
      this.ToCity = CityName;
      this.renderer.setStyle(
        this.AirportSelector.nativeElement,
        'display',
        'none'
      );
    }
    if (this.FromAirport === this.ToAirport) {
      this.renderer.setStyle(
        this.ErrorSameDestination.nativeElement,
        'display',
        'grid'
      );
    }
    this.hideControls('a');
  }

  AssignPax(T, a) {
    if (a === 'Add') {
      if (this.NoofPax < 9) {
        if (T == 'Adults') {
          this.PaxAdults++;
          this.NoofTravellers++;
        } else if (T == 'Children') {
          this.PaxChildren++;
          this.NoofTravellers++;
        } else if (T == 'Infants') {
          //this.NoofTravellers++;
        }

        this.NoofPax = this.PaxAdults + this.PaxChildren;
        if (this.NoofPax == 9) {
          this.GroupBooking = true;
        } else {
          this.GroupBooking = false;
        }
      } else {
        this.GroupBooking = true;
      }

      if (T === 'Infants') {
        if (this.PaxInfant < this.PaxAdults) {
          this.PaxInfant++;
          this.NoofTravellers++;
        }
      }
    } else if (a === 'Sub') {
      if (T == 'Adults') {
        if (this.PaxAdults > 1) {
          this.PaxAdults--;
          if (this.PaxInfant > this.PaxAdults) {
            this.PaxInfant = this.PaxAdults;
          }
        }
      } else if (T == 'Children') {
        if (this.PaxChildren > 0) {
          this.PaxChildren--;
        }
      } else if (T == 'Infants') {
        if (this.PaxInfant > 0) {
          this.PaxInfant--;
        }
      }
      this.NoofPax = this.PaxAdults + this.PaxChildren;
      if (this.NoofPax == 9) {
        this.GroupBooking = true;
      } else {
        this.GroupBooking = false;
      }
      this.NoofTravellers--;
    }
  }

  changeVal(i) {
    this.MultiCityArray[i].FromCityCode = 'New City:---' + i;
  }

  AddCity() {
    if (this.MultiCityArray.length < 6) {
      //"FromCityCode":"AMD","ToCityCode":"BOM","DepartDate":"13 Jul 2020"
      let m = new MultiCity('Select City', 'Select City', '', '', '', '');
      this.MultiCityArray.push(m);
    }
  }

  RemoveMultiCity(i) {
    this.MultiCityArray.forEach((item, index) => {
      //  window.alert(index);
      if (index === i) this.MultiCityArray.splice(index, 1);
    });
  }

  SmartsearchFromURL(
    Sectors: string,
    DepDates: string,
    Travellers: string,
    Cabin: string,
    TripType: string,
    DomOrInt: string,
    DirectFlights: string,
    UTT: string
  ) {
    //console.log("Smart");

    //   let s = '{"FlightDataParams":{"Sectors":"'+ Sectors + '","DepDates":"'+ DepDates + '","Travellers":"'+ Travellers
    //  + '","Cabin":"' + Cabin + '","TripType":"' + TripType + '","DomOrInt":"' + this.DomOrInt + '","DirectFlights":"' + this.DirectFlights +'"}}';

    this.DomOrInt = DomOrInt;

    var ll = Sectors.split('_');
    var Datess = DepDates.split('_');
    ////console.log(Datess[0]);

    if (TripType == 'O') {
      this.SimpleSearch = 'One Way';
      this.MultiCityArray.length = 1;
      var cities = ll[0].split('-');
      this.MultiCityArray[0].FromCityCode = cities[0];
      this.MultiCityArray[0].ToCityCode = cities[1];

      var FDate =
        '20' +
        Datess[0].substring(4, 6) +
        '-' +
        Datess[0].substring(2, 4) +
        '-' +
        Datess[0].substring(0, 2);
      this.MultiCityArray[0].DepartDate = FDate;
      this.MultiCityArray[0].ReturnDate = '';
      this.MultiCityArray[0].DepDorI = DomOrInt;
      this.MultiCityArray[0].RetDorI = DomOrInt;
      ////console.log(this.MultiCityArray);

      // this.SearchFlights('S');
    } else if (TripType == 'R') {
      this.SimpleSearch = 'Round Trip';

      this.MultiCityArray.length = 1;
      var cities = ll[0].split('-');
      this.MultiCityArray[0].FromCityCode = cities[0];
      this.MultiCityArray[0].ToCityCode = cities[1];
      var FDate =
        '20' +
        Datess[0].substring(4, 6) +
        '-' +
        Datess[0].substring(2, 4) +
        '-' +
        Datess[0].substring(0, 2);
      this.MultiCityArray[0].DepartDate = FDate;
      var TDate =
        '20' +
        Datess[1].substring(4, 6) +
        '-' +
        Datess[1].substring(2, 4) +
        '-' +
        Datess[1].substring(0, 2);
      this.MultiCityArray[0].ReturnDate = TDate;
      this.MultiCityArray[0].DepDorI = DomOrInt;
      this.MultiCityArray[0].RetDorI = DomOrInt;
      ////console.log(this.MultiCityArray);
      //    this.SearchFlights('S');
    } else if (TripType == 'M') {
      this.SimpleSearch = 'Multi City';

      ////console.log(ll.length);
      this.MultiCityArray.length = 0;

      for (var i = 0; i < ll.length; i++) {
        var cities = ll[i].split('-');

        var FDate =
          '20' +
          Datess[i].substring(4, 6) +
          '-' +
          Datess[i].substring(2, 4) +
          '-' +
          Datess[i].substring(0, 2);

        let m = new MultiCity(
          cities[0],
          cities[1],
          FDate,
          '',
          DomOrInt,
          DomOrInt
        );
        this.MultiCityArray.push(m);
      }
      ////console.log(this.MultiCityArray);
    }

    let s =
      '{"FlightDataParams":{"Sectors":"' +
      Sectors +
      '","DepDates":"' +
      DepDates +
      '","Travellers":"' +
      Travellers +
      '","Cabin":"' +
      Cabin +
      '","TripType":"' +
      TripType +
      '","DomOrInt":"' +
      this.DomOrInt +
      '","DirectFlights":"' +
      this.DirectFlights +
      '"}}';

    //  window.alert(s);
    let headers = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=utf-8'
    );

    let params = new HttpParams().set('Sectors', Sectors); // create params object
    params = params.append('DepDates', DepDates); // add a new param, creating a new object
    params = params.append('Travellers', Travellers);
    params = params.append('Cabin', Cabin);
    params = params.append('TripType', TripType);
    params = params.append('DomOrInt', this.DomOrInt);
    params = params.append('DirectFlights', this.DirectFlights.toString());

    this.httpClient
      .post(this.apiUrl + 'api/SmartFlightSearch', s, {
        headers: headers,
        params: params,
      })
      .subscribe((data: any) => {
        //console.log(data);

        let err = 'N';

        for (let key in data) {
          // //console.log("key="+key);
          if (data.hasOwnProperty(key) && key === 'errorMessageField') {
            for (let skey in data[key]) {
              if (
                data[key].hasOwnProperty(skey) &&
                skey === 'applicationErrorField'
              ) {
                err = 'F';
              }
            }
          }
        }
        //             window.alert("err: "+err);

        this.ResponseFlightDataNew = [];
        this.ResponseFlightDataErNew = [];
        this.ResponseFlightNew = true;
        this.ResponseFlightErNew = false;

        if (err === 'F') {
          this.ResponseFlightErNew = true;
          this.obj = FareMasterPricerTravelBoardSearchReplyErProxy.Create(data);
          this.ResponseFlightDataErNew = [];
          this.ResponseFlightDataErNew.push(this.obj);
        } else if (err === 'N') {
          this.ResponseFlightNew = true;
          //  this.obj =  FareMasterPricerTravelBoardSearchReplyProxy.Create(data);
          //  this.ResponseFlightData=[];
          //   this.ResponseFlightData.push(this.obj);
          this.ResponseFlightDataNew.push(data);

          //  for (let key in data){
          //   if(data.hasOwnProperty(key) && key==="flightIndexField" )
          //   {
          //     this.FlightIndex.push(data.flightIndexField);

          //   }else if (data.hasOwnProperty(key) && key==="paxFareProductField"){
          //       this.paxFareProductField.push(data[key])
          //   }

          //  }

          //  for (let key in this.FlightIndex){
          //   //alert(key);
          //   //&& key==="locationIdField"
          //   if(data.hasOwnProperty(key)  )
          //   {

          //   }
          //  }
        }
      });

    //  //console.log("Response is " + this.obj);
    //console.log("FlightIndex is " + this.FlightIndex);
  }

  searchFromURL(
    Task: string,
    Sectors: string,
    DepDates: string,
    Travellers: string,
    Cabin: string,
    TripType: string,
    DomOrInt: string,
    DirectFlights: string,
    TrackerID: string
  ) {
    //console.log("T: " + Task);

    //   let s = '{"FlightDataParams":{"Sectors":"'+ Sectors + '","DepDates":"'+ DepDates + '","Travellers":"'+ Travellers
    //  + '","Cabin":"' + Cabin + '","TripType":"' + TripType + '","DomOrInt":"' + this.DomOrInt + '","DirectFlights":"' + this.DirectFlights +'"}}';
    let Adults: number = 0;

    let Children: number = 0;
    let Infants: number = 0;

    var paxss = Travellers.split('-');
    Adults = +paxss[0];
    Children = +paxss[1];
    Infants = +paxss[2];
    this.NoofPAX = Adults + Children;
    this.PaxAdults = Adults;
    this.PaxChildren = Children;
    this.PaxInfant = Infants;
    this.NoofTravellers = Adults + Children + Infants;

    this.DomOrInt = DomOrInt;
    this.TrackerID = TrackerID;
    this.SectorsItinerary = Sectors;
    var ll = Sectors.split('_');
    var Datess = DepDates.split('_');
    ////console.log(Datess[0]);

    if (TripType == 'O') {
      this.SimpleSearch = 'One Way';
      this.MultiCityArray.length = 1;
      var cities = ll[0].split('-');
      this.MultiCityArray[0].FromCityCode = cities[0];
      this.MultiCityArray[0].ToCityCode = cities[1];

      var FDate =
        '20' +
        Datess[0].substring(4, 6) +
        '-' +
        Datess[0].substring(2, 4) +
        '-' +
        Datess[0].substring(0, 2);
      this.MultiCityArray[0].DepartDate = FDate;
      this.MultiCityArray[0].ReturnDate = '';
      this.MultiCityArray[0].DepDorI = DomOrInt;
      this.MultiCityArray[0].RetDorI = DomOrInt;
      ////console.log(this.MultiCityArray);

      // this.SearchFlights('S');
    } else if (TripType == 'R') {
      this.SimpleSearch = 'Round Trip';

      this.MultiCityArray.length = 1;
      var cities = ll[0].split('-');
      this.MultiCityArray[0].FromCityCode = cities[0];
      this.MultiCityArray[0].ToCityCode = cities[1];
      var FDate =
        '20' +
        Datess[0].substring(4, 6) +
        '-' +
        Datess[0].substring(2, 4) +
        '-' +
        Datess[0].substring(0, 2);
      this.MultiCityArray[0].DepartDate = FDate;
      var TDate =
        '20' +
        Datess[1].substring(4, 6) +
        '-' +
        Datess[1].substring(2, 4) +
        '-' +
        Datess[1].substring(0, 2);
      this.MultiCityArray[0].ReturnDate = TDate;
      this.MultiCityArray[0].DepDorI = DomOrInt;
      this.MultiCityArray[0].RetDorI = DomOrInt;
      ////console.log(this.MultiCityArray);
      //    this.SearchFlights('S');
    } else if (TripType == 'M') {
      this.SimpleSearch = 'Multi City';

      ////console.log(ll.length);
      this.MultiCityArray.length = 0;

      for (var i = 0; i < ll.length; i++) {
        var cities = ll[i].split('-');

        var FDate =
          '20' +
          Datess[i].substring(4, 6) +
          '-' +
          Datess[i].substring(2, 4) +
          '-' +
          Datess[i].substring(0, 2);

        let m = new MultiCity(
          cities[0],
          cities[1],
          FDate,
          '',
          DomOrInt,
          DomOrInt
        );
        this.MultiCityArray.push(m);
      }
      ////console.log(this.MultiCityArray);
    }

    let ma = '{"Tracker":"' + this.TrackerID + '","Merchantid":"7123"}';
    //GetTracker here and then Navigate
    let headersa = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=utf-8'
    );

    this.httpClient
      .post(this.apiUrl + 'api/HitsTracker', ma, { headers: headersa })
      .subscribe((data: any) => {
        if (this.TrackerID != 'll') {
          this.TrackerID = data;
          // console.log("Match no found still moving");
          // console.log(this.TrackerID);

          ///FromURL
          let s =
            '{"FlightDataParams":{"Sectors":"' +
            Sectors +
            '","DepDates":"' +
            DepDates +
            '","Travellers":"' +
            Travellers +
            '","Cabin":"' +
            Cabin +
            '","TripType":"' +
            TripType +
            '","DomOrInt":"' +
            this.DomOrInt +
            '","DirectFlights":"' +
            this.DirectFlights +
            '"}}';

          //  window.alert(s);
          let headers = new HttpHeaders().append(
            'Content-Type',
            'application/json; charset=utf-8'
          );

          let params = new HttpParams().set('Sectors', Sectors); // create params object
          params = params.append('DepDates', DepDates); // add a new param, creating a new object
          params = params.append('Travellers', Travellers);
          params = params.append('Cabin', Cabin);
          params = params.append('TripType', TripType);
          params = params.append('DomOrInt', this.DomOrInt);
          params = params.append(
            'DirectFlights',
            this.DirectFlights.toString()
          );

          //    this.httpClient.post(this.apiUrl + "api/FlightSearch",s , {headers: headers,  params:params}).subscribe(
          this.httpClient
            .post(this.apiUrl + 'api/AirFareFeatured', s, {
              headers: headers,
              params: params,
            })
            .subscribe((data: any) => {
              //console.log(data);

              let err = 'N';

              for (let key in data) {
                // //console.log("key="+key);
                if (data.hasOwnProperty(key) && key === 'errorMessageField') {
                  for (let skey in data[key]) {
                    if (
                      data[key].hasOwnProperty(skey) &&
                      skey === 'applicationErrorField'
                    ) {
                      err = 'F';
                    }
                  }
                }
              }
              //             window.alert("err: "+err);

              this.ResponseFlightData = [];
              this.ResponseFlightDataEr = [];
              this.ResponseFlight = true;
              this.ResponseFlightEr = false;

              if (err === 'F') {
                this.ResponseFlightEr = true;
                this.obj =
                  FareMasterPricerTravelBoardSearchReplyErProxy.Create(data);
                this.ResponseFlightDataEr = [];
                this.ResponseFlightDataEr.push(this.obj);
              } else if (err === 'N') {
                this.ResponseFlight = true;

                this.ResponseFlightData.push(data);
              }
            });
        } else {
          //this.onSearchFlights();
          //navigate with new TrackerID
          //console.log ("LLLLLLL");
        }
      });

    //  //console.log("Response is " + this.obj);
    //console.log("FlightIndex is " + this.FlightIndex);
  }
  getRandomString() {
    var randomChars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < 10; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    return result;
  }

  onSearchFlights() {
    this.loading = true;
    console.log('Task');
    ////console.log(this.MultiCityArray);
    let isValid = 'Valid';
    let Sectors = '';
    let DepDates = '';
    localStorage.setItem('SearchParams',JSON.stringify(this.MultiCityArray));


    //Getting Sectors
    this.MultiCityArray.forEach((item, i) => {
      //set first DepDate to localstorage
      let FirstDepartDate = this.MultiCityArray[0].DepartDate;
      localStorage.setItem('depDate', FirstDepartDate.toString());

      if (this.SimpleSearch == 'Round Trip') {
        Sectors =
          this.MultiCityArray[i].FromCityCode +
          '-' +
          this.MultiCityArray[i].ToCityCode +
          '_' +
          this.MultiCityArray[i].ToCityCode +
          '-' +
          this.MultiCityArray[i].FromCityCode;

        // DepDates= this.MultiCityArray[i].DepartDate + "_" + this.MultiCityArray[i].ReturnDate;
      } else {
        if (Sectors.length == 0) {
          Sectors =
            this.MultiCityArray[i].FromCityCode +
            '-' +
            this.MultiCityArray[i].ToCityCode;
        } else {
          Sectors =
            Sectors +
            '_' +
            this.MultiCityArray[i].FromCityCode +
            '-' +
            this.MultiCityArray[i].ToCityCode;
        }
      }

      ////console.log("Sectors="+Sectors);
      ////console.log("this.MultiCityArray[i].DepartDate:"+this.MultiCityArray[i].DepartDate);

      //2020-11-18
      var DepDate =
        this.MultiCityArray[i].DepartDate.substring(8, 10) +
        '' +
        this.MultiCityArray[i].DepartDate.substring(5, 7) +
        '' +
        this.MultiCityArray[i].DepartDate.substring(2, 4);
      var RetDate =
        this.MultiCityArray[i].ReturnDate.substring(8, 10) +
        '' +
        this.MultiCityArray[i].ReturnDate.substring(5, 7) +
        '' +
        this.MultiCityArray[i].ReturnDate.substring(2, 4);

      let DepDateR = moment(this.MultiCityArray[i].ReturnDate, 'MM-DD-YYYY');
      // let RetDate = DepDateR.format("DDMMYY") ;
      //console.log("DepDate:"+DepDate+"-" + "RetDate:"+RetDate);

      if (this.SimpleSearch == 'Round Trip') {
        this.checkOurAirInventory();
        DepDates = DepDate + '_' + RetDate;
        // DepDates= this.MultiCityArray[i].DepartDate + "_" + this.MultiCityArray[i].ReturnDate;
      } else {
        if (DepDates.length == 0) {
          //DepDates= this.MultiCityArray[i].DepartDate;
          DepDates = DepDate;
        } else {
          //DepDates=DepDates+"_"+this.MultiCityArray[i].DepartDate;
          DepDates = DepDates + '_' + DepDate;
        }
      }
      ////console.log("DepDates="+DepDates);

      if (
        this.MultiCityArray[i].DepDorI === 'I' ||
        this.MultiCityArray[i].RetDorI === 'I'
      ) {
        this.DomOrInt = 'I';
      }
      if (this.MultiCityArray[i].FromCityCode === 'Select City') {
        this.renderer.setStyle(
          this.errorSelectCityF.toArray()[i].nativeElement,
          'display',
          'block'
        );
      } else {
        this.renderer.setStyle(
          this.errorSelectCityF.toArray()[i].nativeElement,
          'display',
          'none'
        );
      }

      if (this.MultiCityArray[i].ToCityCode === 'Select City') {
        this.renderer.setStyle(
          this.errorSelectCityT.toArray()[i].nativeElement,
          'display',
          'block'
        );
        isValid = 'Invalid';
      } else {
        this.renderer.setStyle(
          this.errorSelectCityT.toArray()[i].nativeElement,
          'display',
          'none'
        );
      }
    });

    console.log('L');

    const Travellers =
      this.PaxAdults + '-' + this.PaxChildren + '-' + this.PaxInfant;

    let Cabin = '';
    if (this.Cabin === 'Economy') {
      Cabin = 'E';
    } else if (this.Cabin === 'Premium Economy') {
      Cabin = 'PE';
    } else if (this.Cabin === 'Business') {
      Cabin = 'B';
    }

    //let DepartureDate =
    let TripType = '';
    if (this.SimpleSearch == 'One Way') {
      TripType = 'O';
      // RetDate="";
    } else if (this.SimpleSearch == 'Round Trip') {
      TripType = 'R';
    } else if (this.SimpleSearch == 'Multi City') {
      TripType = 'M';
      //   RetDate="";
    }

    this.TripType = TripType;

    if (isValid === 'Valid') {
      var randomChars =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var id = '';
      for (var i = 0; i < 10; i++) {
        id += randomChars.charAt(
          Math.floor(Math.random() * randomChars.length)
        );
      }

      let m = '{"Tracker":"' + id + '","Merchantid":"7123"}';
      //GetTracker here and then Navigate
      let headers = new HttpHeaders().append(
        'Content-Type',
        'application/json; charset=utf-8'
      );

      let params = new HttpParams().set('Sectors', Sectors); // create params object
      params = params.append('Test', 'TestDate');

      this.httpClient
        .post(this.apiUrl + 'api/HitsTracker', m, { headers: headers })
        .subscribe((data: any) => {
          this.loading = false;
          this.TrackerID = data;
          //console.log("This.TrackerID="+this.TrackerID);
          //console.log("Data="+data);

          //path:'FlightSearch/:Sectors/:DepDates/:Travellers/:Cabin/:TripType/:DomOrInt/:DirectFlights/:UTT',
          let s =
            '{"FlightDataParams":{"Task": "Search", "Sectors":"' +
            Sectors +
            '","DepDates":"' +
            DepDates +
            '","Travellers":"' +
            Travellers +
            '","Cabin":"' +
            Cabin +
            '","TripType":"' +
            TripType +
            '","DomOrInt":"' +
            this.DomOrInt +
            '","DirectFlights":"' +
            this.DirectFlights +
            '","Tracker":"' +
            this.TrackerID +
            '"}}';

          this.router.navigate([
            'FlightSearch',
            'Search',
            Sectors,
            DepDates,
            Travellers,
            Cabin,
            TripType,
            this.DomOrInt,
            this.DirectFlights,
            this.TrackerID,
          ]);
        });
    } else {
      //console.log("Invalid");
    }
  }

  checkOurAirInventory() {
    console.log('chekcing our air inventory');
  }

  sSearchFlights(T) {
    let isValid = 'Valid';
    let Sectors = '';
    let DepDates = '';

    //akbartravels.com/b2cplus/flight/display/
    //BLR-MAA/2020-07-31/1_0_0/D/ON/E/true/
    //819cefc0-681a-4472-a7e4-993e8c534737%7Cab71df6e-e169-4579-b038-adfd3b25965a%7C20200626180747

    //Getting Sectors
    this.MultiCityArray.forEach((item, i) => {
      // //console.log("i="+i);
      if (this.SimpleSearch == 'Round Trip') {
        Sectors =
          this.MultiCityArray[i].FromCityCode +
          '-' +
          this.MultiCityArray[i].ToCityCode +
          '_' +
          this.MultiCityArray[i].ToCityCode +
          '-' +
          this.MultiCityArray[i].FromCityCode;

        // DepDates= this.MultiCityArray[i].DepartDate + "_" + this.MultiCityArray[i].ReturnDate;
      } else {
        if (Sectors.length == 0) {
          Sectors =
            this.MultiCityArray[i].FromCityCode +
            '-' +
            this.MultiCityArray[i].ToCityCode;
        } else {
          Sectors =
            Sectors +
            '_' +
            this.MultiCityArray[i].FromCityCode +
            '-' +
            this.MultiCityArray[i].ToCityCode;
        }
      }

      let DepDateF = moment(this.MultiCityArray[i].DepartDate, 'MM-DD-YYYY');
      let DepDate = DepDateF.format('DDMMYY');

      let DepDateR = moment(this.MultiCityArray[i].ReturnDate, 'MM-DD-YYYY');
      let RetDate = DepDateR.format('DDMMYY');

      if (this.SimpleSearch == 'Round Trip') {
        DepDates = DepDate + '_' + RetDate;
        // DepDates= this.MultiCityArray[i].DepartDate + "_" + this.MultiCityArray[i].ReturnDate;
      } else {
        if (DepDates.length == 0) {
          //DepDates= this.MultiCityArray[i].DepartDate;
          DepDates = DepDate;
        } else {
          //DepDates=DepDates+"_"+this.MultiCityArray[i].DepartDate;
          DepDates = DepDates + '_' + DepDate;
        }
      }

      if (
        this.MultiCityArray[i].DepDorI === 'I' ||
        this.MultiCityArray[i].RetDorI === 'I'
      ) {
        this.DomOrInt = 'I';
      }
      if (this.MultiCityArray[i].FromCityCode === 'Select City') {
        this.renderer.setStyle(
          this.errorSelectCityF.toArray()[i].nativeElement,
          'display',
          'block'
        );
      } else {
        this.renderer.setStyle(
          this.errorSelectCityF.toArray()[i].nativeElement,
          'display',
          'none'
        );
      }

      if (this.MultiCityArray[i].ToCityCode === 'Select City') {
        this.renderer.setStyle(
          this.errorSelectCityT.toArray()[i].nativeElement,
          'display',
          'block'
        );
        isValid = 'Invalid';
      } else {
        this.renderer.setStyle(
          this.errorSelectCityT.toArray()[i].nativeElement,
          'display',
          'none'
        );
      }
    });

    //array: [];
    //  const MultiSearchArray = JSON.stringify(this.MultiCityArray);
    //  const SimpeSearchArray = JSON.stringify(this.MultiCityArray);

    //const FCity=this.MultiCityArray[0].FromCityCode;
    //const TCity = this.MultiCityArray[0].ToCityCode;

    //let DepDateF = new Date(this.MultiCityArray[0].DepartDate);
    // var DepDateF = moment(this.MultiCityArray[0].DepartDate, 'MM-DD-YYYY');
    // const DepDate = DepDateF.format("DDMMYY") ;

    // var RetDateF = moment(this.MultiCityArray[0].ReturnDate, 'MM-DD-YYYY');
    // let RetDate = RetDateF.format("DDMMYY") ;

    const Travellers =
      this.PaxAdults + '-' + this.PaxChildren + '-' + this.PaxInfant;

    let Cabin = '';
    if (this.Cabin === 'Economy') {
      Cabin = 'E';
    } else if (this.Cabin === 'Premium Economy') {
      Cabin = 'PE';
    } else if (this.Cabin === 'Business') {
      Cabin = 'B';
    }

    //let DepartureDate =
    let TripType = '';
    if (this.SimpleSearch == 'One Way') {
      TripType = 'O';
      // RetDate="";
    } else if (this.SimpleSearch == 'Round Trip') {
      TripType = 'R';
    } else if (this.SimpleSearch == 'Multi City') {
      TripType = 'M';
      //   RetDate="";
    }

    this.TripType = TripType;

    if (isValid === 'Valid') {
      let s =
        '{"FlightDataParams":{"Sectors":"' +
        Sectors +
        '","DepDates":"' +
        DepDates +
        '","Travellers":"' +
        Travellers +
        '","Cabin":"' +
        Cabin +
        '","TripType":"' +
        TripType +
        '","DomOrInt":"' +
        this.DomOrInt +
        '","DirectFlights":"' +
        this.DirectFlights +
        '"}}';

      //  window.alert(s);
      let headers = new HttpHeaders().append(
        'Content-Type',
        'application/json; charset=utf-8'
      );

      let params = new HttpParams().set('Sectors', Sectors); // create params object
      params = params.append('DepDates', DepDates); // add a new param, creating a new object
      params = params.append('Travellers', Travellers);
      params = params.append('Cabin', Cabin);
      params = params.append('TripType', TripType);
      params = params.append('DomOrInt', this.DomOrInt);
      params = params.append('DirectFlights', this.DirectFlights.toString());

      this.httpClient
        .post(this.apiUrl + 'api/AirFareFeatured', s, {
          headers: headers,
          params: params,
        })
        .subscribe((data: any) => {
          //console.log(data);

          let err = 'N';

          for (let key in data) {
            // //console.log("key="+key);
            if (data.hasOwnProperty(key) && key === 'errorMessageField') {
              for (let skey in data[key]) {
                if (
                  data[key].hasOwnProperty(skey) &&
                  skey === 'applicationErrorField'
                ) {
                  err = 'F';
                }
              }
            }
          }
          //             window.alert("err: "+err);

          this.ResponseFlightData = [];
          this.ResponseFlightDataEr = [];
          this.ResponseFlight = true;
          this.ResponseFlightEr = false;

          if (err === 'F') {
            this.ResponseFlightEr = true;
            this.obj =
              FareMasterPricerTravelBoardSearchReplyErProxy.Create(data);
            this.ResponseFlightDataEr = [];
            this.ResponseFlightDataEr.push(this.obj);
          } else if (err === 'N') {
            this.ResponseFlight = true;
            //  this.obj =  FareMasterPricerTravelBoardSearchReplyProxy.Create(data);
            //  this.ResponseFlightData=[];
            //   this.ResponseFlightData.push(this.obj);
            this.ResponseFlightData.push(data);

            for (let key in data) {
              if (data.hasOwnProperty(key) && key === 'flightIndexField') {
                this.FlightIndex.push(data.flightIndexField);
              } else if (
                data.hasOwnProperty(key) &&
                key === 'paxFareProductField'
              ) {
                this.paxFareProductField.push(data[key]);
              }
            }

            for (let key in this.FlightIndex) {
              //alert(key);
              //&& key==="locationIdField"
              if (data.hasOwnProperty(key)) {
              }
            }
          }
        });

      //  //console.log("Response is " + this.obj);
      //   //console.log("FlightIndex is " + this.FlightIndex);
    }
  }

  BookClicked(referencingDetailField, fareDetailsField, flightIndex) {
    // //console.log(this.SectorsItinerary);
    // //console.log(flightIndex);
    // //console.log(fareDetailsField);
    // //console.log(referencingDetailField);
    // localStorage.setItem('fareDetailsField', JSON.stringify(fareDetailsField));
    // //console.log(JSON.parse(localStorage.getItem('fareDetailsField')));

    // sessionStorage.setItem('fareDetailsField', JSON.stringify(fareDetailsField));
    // //console.log(JSON.parse(sessionStorage.getItem('fareDetailsField')));
    let Sectors = ''; //Origin-Destination|Origin-Destination
    let Segments = ''; //DepDate-TrueBoard-TrueOffPOint-marketingCompany-FlightNo-BookingClass-TotalPax

    for (let i = 0; i < referencingDetailField.length - 1; i++) {
      let DepDate = '';
      let TrueFromLoctions = '';
      let TrueToLoctions = '';
      let marketingCompany = '';
      let FlightNo = '';
      let BookingClass = '';

      //recommendationField[0].paxFareProductField[0].fareDetailsField[0].groupOfFaresField[0].productInformationField.cabinProductField.rbdField (RBD)(Class) ="O"
      // //console.log("i="+ i + "No: "+ referencingDetailField[i].refNumberField);
      for (
        let n = 0;
        n <
        flightIndex[i].groupOfFlightsField[
          referencingDetailField[i].refNumberField - 1
        ].flightDetailsField.length;
        n++
      ) {
        //[0].groupOfFaresField[0].productInformationField.cabinProductField.rbdField
        if (BookingClass.length == 0) {
          //flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.locationField[0].locationIdField
          BookingClass =
            fareDetailsField[i].groupOfFaresField[n].productInformationField
              .cabinProductField.rbdField;
        } else {
          BookingClass =
            BookingClass +
            '-' +
            fareDetailsField[i].groupOfFaresField[n].productInformationField
              .cabinProductField.rbdField;
        }

        if (DepDate.length == 0) {
          DepDate =
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField.productDateTimeField
              .dateOfDepartureField;
        } else {
          DepDate =
            DepDate +
            '-' +
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField.productDateTimeField
              .dateOfDepartureField;
        }

        //flightIndexField[0].groupOfFlightsField[58].flightDetailsField[0].flightInformationField.locationField[1]
        if (TrueFromLoctions.length == 0) {
          //flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.locationField[0].locationIdField
          TrueFromLoctions =
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField.locationField[0]
              .locationIdField;
        } else {
          TrueFromLoctions =
            TrueFromLoctions +
            '-' +
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField.locationField[0]
              .locationIdField;
        }

        if (TrueToLoctions.length == 0) {
          //flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.locationField[0].locationIdField
          TrueToLoctions =
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField.locationField[1]
              .locationIdField;
        } else {
          TrueToLoctions =
            TrueToLoctions +
            '-' +
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField.locationField[1]
              .locationIdField;
        }

        if (marketingCompany.length == 0) {
          //flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.locationField[0].locationIdField
          //flightIndexField[0].groupOfFlightsField[58].flightDetailsField[0].flightInformationField.companyIdField.marketingCarrierField
          marketingCompany =
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField.companyIdField
              .marketingCarrierField;
        } else {
          marketingCompany =
            marketingCompany +
            '-' +
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField.companyIdField
              .marketingCarrierField;
        }

        if (FlightNo.length == 0) {
          //flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.locationField[0].locationIdField
          //flightIndexField[0].groupOfFlightsField[58].flightDetailsField[0].flightInformationField.companyIdField.marketingCarrierField
          FlightNo =
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField
              .flightOrtrainNumberField;
        } else {
          FlightNo =
            FlightNo +
            '-' +
            flightIndex[i].groupOfFlightsField[
              referencingDetailField[i].refNumberField - 1
            ].flightDetailsField[n].flightInformationField
              .flightOrtrainNumberField;
        }

        ////console.log ("FlightNo: " + flightIndex[i].groupOfFlightsField[referencingDetailField[i].refNumberField-1].flightDetailsField[n].flightInformationField.flightOrtrainNumberField);
      }
      if (Segments.length == 0) {
        Segments =
          DepDate +
          '|' +
          TrueFromLoctions +
          '|' +
          TrueToLoctions +
          '|' +
          marketingCompany +
          '|' +
          FlightNo +
          '|' +
          BookingClass +
          '|' +
          this.NoofPAX;
      } else {
        Segments =
          Segments +
          '_' +
          DepDate +
          '|' +
          TrueFromLoctions +
          '|' +
          TrueToLoctions +
          '|' +
          marketingCompany +
          '|' +
          FlightNo +
          '|' +
          BookingClass +
          '|' +
          this.NoofPAX;
      }
    }
    localStorage.setItem('Sectors', this.SectorsItinerary);
    localStorage.setItem('Segments', Segments);
    localStorage.setItem('Adults', this.PaxAdults.toString());
    localStorage.setItem('Children', this.PaxChildren.toString());
    localStorage.setItem('Infants', this.PaxInfant.toString());

    ////console.log(this.SectorsItinerary);
    ////console.log (Segments);
    this.router.navigate(['Flight', 'Review', this.TrackerID]);
  }

  AirSells(TrackerID) {
    let Adults = localStorage.getItem('Adults');
    let Children = localStorage.getItem('Children');
    let Infants = localStorage.getItem('Infants');

    this.PaxAdults = +Adults;
    this.PaxChildren = +Children;
    this.PaxInfant = +Infants;

    if (this.PaxAdults > 0) {
      this.AdtPax = true;
    } else {
      this.AdtPax = false;
    }

    if (this.PaxChildren > 0) {
      this.ChPax = true;
    } else {
      this.ChPax = false;
    }

    if (this.PaxInfant > 0) {
      this.InfPax = true;
    } else {
      this.InfPax = false;
    }

    this.NoofPAX = this.PaxAdults + this.PaxChildren;
    this.NoofTravellers = this.PaxAdults + this.PaxChildren + this.PaxInfant;

    this.AdultsLists = new Array(this.PaxAdults);
    this.ChildrenList = new Array(this.PaxChildren);
    this.INfantsList = new Array(this.PaxInfant);

    this.TrackerID = TrackerID;
    this.ResponseFlight = false;

    let Sectors = localStorage.getItem('Sectors');
    let Segments = localStorage.getItem('Segments');

    let s = '{"Sectors":"' + Sectors + '","Segments":"' + Segments + '"}';

    //  window.alert(s);
    //let headers = new HttpHeaders().append('Content-Type','application/json; charset=utf-8');
    let params = new HttpParams().set('TrackerID', this.TrackerID); // create params object
    const httOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true,
      observe: 'response' as 'response',
      params: params,
    };

    this.httpClient
      .post(this.apiUrl + 'api/CheckFlight', s, httOptions)
      .subscribe((data: any) => {
        //console.log(data.body);
        // let headers = data.headers;
        // //console.log(data.headers.get('ASP.NET_SessionId'))
        // //console.log(data.headers);
        // //console.log(data.Cookies);

        ////console.log(data.headers.get('authorization'));

        if (data.body == 'No') {
          this.ReviewComponent = false;
          this.BookingFailedComponent = true;
        } else {
          localStorage.setItem('Tracker', data.body);

          this.ReviewComponent = true;
          this.BookingFailedComponent = false;

          //   let Nheaders = new HttpHeaders({'Authorization':'Bearer ' + localStorage.getItem('Tracker')});
          //     Nheaders = Nheaders.append('Content-Type','application/json; charset=utf-8');

          //   s = '{"Sectors":"'+ Sectors + '","Segments":"'+ Segments + '","Token":"' + localStorage.getItem('Tracker') +'"}';

          //   let Nparams = new HttpParams().set('TrackerID', this.TrackerID); // create params object
          //   this.httpClient.post(this.apiUrl + "api/FlightReview",s, {headers: Nheaders, params: params}).subscribe(
          //     (data1:any)=> {
          //       //console.log(data1);
          //     // this.ReviewItineraryDetails= data.itineraryDetailsField;
          //     //itineraryDetailsField[0].segmentInformationField[0].actionDetailsField.statusCodeField[0]
          //  var itineraryDetailsCount = data1.itineraryDetailsField.length;
          //  let Status="OK";
          //   for(let i=0; i<itineraryDetailsCount; i++){
          //     var SegmentCount = data1.itineraryDetailsField[i].segmentInformationField.length;
          //       this.ReviewItineraryDetails.push(data1.itineraryDetailsField[i]);
          //     for(let n=0; n<SegmentCount; n++){
          //       if(data1.itineraryDetailsField[i].segmentInformationField[n].actionDetailsField.statusCodeField[0]!="OK"){
          //         Status="Non saleable"
          //       }
          //     }
          //   }
          //       //console.log(this.ReviewItineraryDetails);
          //       if(Status=="OK"){
          //         this.ReviewComponent=true;
          //         this.BookingFailedComponent=false;
          //       }else{
          //         this.ReviewComponent=false;
          //         this.BookingFailedComponent=true;
          //       }
          // });
        }
      });
  }

  AddPNR() {
    let new_s = '{"Token":"' + localStorage.getItem('Tracker') + '"}';
    let new_ss = localStorage.getItem('Tracker');
    //console.log (new_s);

    let newparams = new HttpParams().set('PassengersGroup', new_s);
    // let headers = new HttpHeaders().append('Content-Type','application/json; charset=utf-8');

    let headers = new HttpHeaders({
      Authorization: 'Bearer ' + localStorage.getItem('Tracker'),
    });
    headers = headers.append('Content-Type', 'application/json; charset=utf-8');

    let params = new HttpParams().set('TrackerID', this.TrackerID); // create params object
    const httOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      withCredentials: true,
      observe: 'response' as 'response',
      //params: params
    };

    // this.httpClient.post(this.apiUrl + "api/CheckFlight",s, httOptions).subscribe(

    this.httpClient
      .post(this.apiUrl + 'api/BookPNR', new_s, {
        headers: headers,
        params: params,
      })
      .subscribe(
        //this.httpClient.post(this.apiUrl + "api/cc/"+this.TrackerID,new_s, {headers:headers}).subscribe(

        (data: any) => {
          //console.log(data);
          let err = 'N';
        }
      );
  }

  GetFlightsInfoNew(GroupofFlight, linenumber) {
    ////console.log(GroupofFlight.flightDetailsField);
    // //console.log("New here");
    var locator = '';

    var segmentInformation = '';
    var countGroupofFlight = Object.keys(
      GroupofFlight.flightDetailsField
    ).length;
    const Travellers =
      this.PaxAdults + '-' + this.PaxChildren + '-' + this.PaxInfant;
    var bookingClass = '';
    let Cabin = '';
    if (this.Cabin === 'Economy') {
      Cabin = 'E';
      bookingClass = 'Y';
    } else if (this.Cabin === 'Premium Economy') {
      Cabin = 'PE';
      bookingClass = 'F';
    } else if (this.Cabin === 'Business') {
      Cabin = 'B';
      bookingClass = 'C';
    }

    for (let i = 0; i <= countGroupofFlight - 1; i++) {
      var countflightInformationField = Object.keys(
        GroupofFlight.flightDetailsField[i].flightInformationField
      ).length;
      //[0].flightInformationField.productDateTimeField.dateOfDepartureField
      var departureDate =
        GroupofFlight.flightDetailsField[i].flightInformationField
          .productDateTimeField.dateOfDepartureField;

      //[0].flightInformationField.productDateTimeField.timeOfDepartureField
      var departureTime =
        GroupofFlight.flightDetailsField[i].flightInformationField
          .productDateTimeField.timeOfDepartureField;

      //[0].flightInformationField.locationField[0].locationIdField
      var trueLocationIdF =
        GroupofFlight.flightDetailsField[i].flightInformationField
          .locationField[0].locationIdField;
      //[0].flightInformationField.locationField[1].locationIdField
      var trueLocationIdT =
        GroupofFlight.flightDetailsField[i].flightInformationField
          .locationField[1].locationIdField;

      //[0].flightInformationField.companyIdField.marketingCarrierField
      var marketingCompany =
        GroupofFlight.flightDetailsField[i].flightInformationField
          .companyIdField.marketingCarrierField;

      //[0].flightInformationField.companyIdField.operatingCarrierField
      var operatingCompany =
        GroupofFlight.flightDetailsField[i].flightInformationField
          .companyIdField.operatingCarrierField;

      //[0].flightInformationField.flightOrtrainNumberField
      //flightIndexField[0].groupOfFlightsField[0].flightDetailsField[0].flightInformationField.flightOrtrainNumberField
      var flightNumber =
        GroupofFlight.flightDetailsField[i].flightInformationField
          .flightOrtrainNumberField;
      //i+1;

      var flightIndicator = i + 1;
      //GroupofFlight.flightDetailsField[i].flightInformationField.companyIdField.marketingCarrierField;
      var itemNumber = linenumber;

      //departureDate;departureTime;trueLocationIdF;trueLocationIdT;marketingCompany;operatingCompany;flightNumber;bookingClass;flightIndicator;itemNumber
      if (segmentInformation.length == 0) {
        segmentInformation =
          departureDate +
          '-' +
          departureTime +
          '-' +
          trueLocationIdF +
          '-' +
          trueLocationIdT +
          '-' +
          marketingCompany +
          '-' +
          operatingCompany +
          '-' +
          flightNumber +
          '-' +
          bookingClass +
          '-' +
          flightIndicator +
          '-' +
          itemNumber;
      } else {
        segmentInformation =
          segmentInformation +
          '~' +
          departureDate +
          '-' +
          departureTime +
          '-' +
          trueLocationIdF +
          '-' +
          trueLocationIdT +
          '-' +
          marketingCompany +
          '-' +
          operatingCompany +
          '-' +
          flightNumber +
          '-' +
          bookingClass +
          '-' +
          flightIndicator +
          '-' +
          itemNumber;
      }
    }
    // //console.log(Cabin);
    // //console.log(Travellers);
    // //console.log(segmentInformation);

    //send to server

    let PassengersGroup =
      '{"FlightInfoDataParams":{"PassengersGroup":"' +
      Travellers +
      '","SegmentGroup":"' +
      segmentInformation +
      '","Cabin":"' +
      Cabin +
      '","TripType":"' +
      this.TripType +
      '","DomOrInt":"' +
      this.DomOrInt +
      '","DirectFlights":"' +
      this.DirectFlights +
      '"}}';

    let new_s =
      Travellers +
      '/' +
      segmentInformation +
      '/' +
      this.Cabin +
      '/' +
      this.TripType +
      '/' +
      this.DomOrInt +
      '/' +
      this.DirectFlights;

    let headers = new HttpHeaders().append(
      'Content-Type',
      'application/json; charset=utf-8'
    );

    let params = new HttpParams().set('PassengersGroup', Travellers); // create params object
    params = params.append('SegmentGroup', segmentInformation); // add a new param, creating a new object
    params = params.append('Cabin', Cabin);
    params = params.append('TripType', this.TripType);
    params = params.append('DomOrInt', this.DomOrInt);
    params = params.append('DirectFlights', this.DirectFlights.toString());

    let newparams = new HttpParams().set('PassengersGroup', new_s);

    this.httpClient
      .post(this.apiUrl + 'api/FlightInfo', new_s, {
        headers: headers,
        params: params,
      })
      .subscribe(
        //this.httpClient.post("api/Test",new_s, {headers: headers, params:params}).subscribe(
        (data: any) => {
          //console.log(data);

          let err = 'N';

          for (let key in data) {
            // //console.log("key="+key);
            if (data.hasOwnProperty(key) && key === 'errorGroupField') {
              for (let skey in data[key]) {
                if (
                  data[key].hasOwnProperty(skey) &&
                  skey === 'errorWarningDescription'
                ) {
                  err = 'F';
                }
              }
            }
          }
          //             window.alert("err: "+err);

          this.ResponseFlightDetailsData = [];
          this.ResponseFlightDetailsDataEr = [];
          this.ResponseFlightDetails = true;
          this.ResponseFlightDetailsEr = false;

          if (err === 'F') {
            this.ResponseFlightEr = true;
            this.obj =
              FareMasterPricerTravelBoardSearchReplyErProxy.Create(data);
            this.ResponseFlightDataEr = [];
            this.ResponseFlightDataEr.push(this.obj);
          } else if (err === 'N') {
            this.ResponseFlightDetails = true;

            this.ResponseFlightDetailsData.push(data);

            for (let key in data) {
              if (data.hasOwnProperty(key) && key === 'mainGroupField') {
                this.FlightInfoNoPNR.length = 0;
                //console.log("MainFound");
                this.FlightInfoNoPNR.push(data.mainGroupField);
              } else if (
                data.hasOwnProperty(key) &&
                key === 'paxFareProductField'
              ) {
                //    this.paxFareProductField.push(data[key])
              }
            }

            this.renderer.setStyle(
              this.RightBars.nativeElement,
              'right',
              '0px'
            );
            //console.log("MainGroup: " + this.FlightInfoNoPNR);
          }
        }
      );
  }

  getShaCode() {
    return 'HH';
  }
  setPNR(val) {
    //console.log(val);
    this.PNR_locator = val;
  }

  CheckDepartDatesMulti() {
    console.log('OK Checking Yaar');

    let err = false;

    if (this.MultiCityArray[0].DepartDate.length > 0) {
      console.log('LL');

      this.MultiCityArray.forEach((item, i) => {
        if (i > 0) {
          if (err == true) {
            this.MultiCityArray[i].DepartDate = '';
          } else {
            let sdate = moment(
              this.MultiCityArray[i - 1].DepartDate,
              'YYYY/MM/DD'
            ).toDate();
            let mdate = moment(
              this.MultiCityArray[i].DepartDate,
              'YYYY/MM/DD'
            ).toDate();
            if (moment(sdate).isAfter(mdate) == true) {
              console.log(this.MultiCityArray[i].DepartDate);
              console.log(mdate);
              err = true;
              this.MultiCityArray[i].DepartDate = '';
            }
          }
        }
      });
    }
  }
}
