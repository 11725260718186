import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterRecommendations'
})
export class FilterRecommendationsPipe implements PipeTransform {

  transform(value: any, FlightsData:any, FilterType: string, FilterValue:string): unknown {


    //console.log("FT>>>", FilterType);
    //console.log("FV>>>", FilterValue);
    if(FilterType=="Full"){
      console.log("FilteredData>>", value);
      return value;
    }

    const FilteredArray:any[] =[];
    if(FilterType=="Dep"){
      for(let i=0; i<=2; i++){




        FilteredArray.push(value[i]);
      }

    }


    console.log('filteredArray>>>', FilteredArray)
    return FilteredArray;



  }

}
