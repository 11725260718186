import * as moment from 'moment';

export class MultiCity {
  FromCityCode:string ="";
  ToCityCode:string ="";
  DepartDate:string = "";
  ReturnDate:string="";
  DepDorI="";
  RetDorI="";



  constructor(FromCityCode,ToCityCode,DepartDate,ReturnDate,DepDorI,RetDorI) {
    this.FromCityCode=FromCityCode;
    this.ToCityCode=ToCityCode;
    this.DepartDate=DepartDate;
    this.ReturnDate=ReturnDate;
    this.DepDorI=DepDorI;
    this.RetDorI=RetDorI;

  }



  // selectedFDate6=this.date.format("DD MMM YYYY");  //Form
  // selectedFDay6=this.date.format("DD");
  // selectedFDayYear6=this.date.format("MMMM YYYY");
  // selectedFWeekDay6=this.date.format("dddd");

  getDateFormat(str,ret){
    //const Mydate= moment(str,'YYYY/MM/DD').toDate();
    let dateString = str;
    let aMydate = new Date(dateString);
    let Mydate=moment(str);

    let RetF="";
    if(ret==="D"){
      RetF=Mydate.format("DD");
    }else if(ret==="MY"){
      RetF=Mydate.format("MMMM YYYY");
    }else if(ret==="DN"){
      RetF=Mydate.format("dddd");
    }else if(ret==="FD"){
      RetF=Mydate.format("DD MM YYYY");
    }

    return RetF;
  }
}

