import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {
  errorMessage:string = '';
  errorMessageUpated:EventEmitter<string> = new EventEmitter();

  geterrorMessage(){
    return this.errorMessage;
  }

  seterrorMessage(val){
    this.errorMessage=val;
    this.errorMessageUpated.emit(this.errorMessage);
  }
  constructor() { }
}
