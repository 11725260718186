import { Injectable,EventEmitter } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class HitCookieService {

  constructor(public cookieService:CookieService) { }
  Hit_CID:string;
  Hit_CVisitID:string;

  Hit_CIDUpated:EventEmitter<string> = new EventEmitter();

  getHit_CID(){
    this.Hit_CID=this.cookieService.get("Hit_CID");
    return this.Hit_CID;
  }

  setHit_CID(val){
    this.cookieService.set("Hit_CID", val);
    this.Hit_CID=val;
    this.Hit_CIDUpated.emit(this.Hit_CID);
  }



  Hit_CVisitIDUpated:EventEmitter<string> = new EventEmitter();

  getHit_CVisitID(){
    this.Hit_CVisitID=this.cookieService.get("Hit_CVisitID");
    return this.Hit_CVisitID;
  }

  setHit_CVisitID(val){
    this.cookieService.set("Hit_CVisitID", val);
    this.Hit_CVisitID=val;
    this.Hit_CVisitIDUpated.emit(this.Hit_CVisitID);
  }



}
