import { Injectable,EventEmitter } from '@angular/core';
import { Router } from  "@angular/router";
// import { auth } from  'firebase/app/dist/';
import { AngularFireAuth } from  "@angular/fire/auth";
//import { User } from  'firebase';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import {LoadingService } from '../Services/loading.service';
import {User} from './user';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class AuthService {
    authUpdated:EventEmitter<any> = new EventEmitter();
    userUpdated:EventEmitter<User>=new EventEmitter();
    hitTokenUpated:EventEmitter<string> = new EventEmitter();
    emailUpdated:EventEmitter<string> = new EventEmitter();
    phonenumberUpdated:EventEmitter<string> = new EventEmitter();
    user: User;
    hitToken:string;
    email:string;
    phonenumber:string;

    public hitloginStatus:boolean=false;
      cosmicHits: any;
      cosmicVisits: string;
      //public apiUrl= "https://cosmictours2021.azurewebsites.net/";
      apiUrl = environment.apiUrl;

      getLoginState(){
        return this.hitloginStatus;
       //return true;
          }

    setLoginState(val){
        this.hitloginStatus=val;
        this.authUpdated.emit(this.hitloginStatus);
    }

    gethitToken(){
      return this.hitToken;
    }

    sethitToken(val){
      this.hitToken=val;
      this.hitTokenUpated.emit(this.hitToken);
    }

    getemail(){
      return this.email;
    }

    setemail(val){
      this.email=val;
      this.emailUpdated.emit(this.email);
    }

    getphonenumber(){
      return this.phonenumber;
    }

    setphonenumber(val){
      this.phonenumber=val;
      this.phonenumberUpdated.emit(this.phonenumber);
    }


    getLoggedUser(){
      return this.user;
    }

    setLoggedUser(val){
      this.user=val;
      localStorage.setItem("User",val);
      this.userUpdated.emit(this.user);

    }



  constructor(public  afAuth:  AngularFireAuth, private httpClient:HttpClient, private cookieService:CookieService, public loadingservice:LoadingService) {

    this.afAuth.authState.subscribe(user => {
      if (user!=null){
        sessionStorage.setItem('userFromAuth',JSON.stringify(user));
        sessionStorage.setItem('user',JSON.stringify(user));
        this.checkHitCookie();
        //this.registerUser();
        console.log("Got From Firebase>>>", user);
        this.user = user;
        this.setLoginState(true);
        this.setLoggedUser(user);
        console.log("UUUUU>>>>", user);
        console.log("UUUUUID>>>>", user.uid);

        ///Encode here uid
        //user.uid
        this.sethitToken(user.uid)

      } else {
        //localStorage.setItem('user', null);
        this.setLoginState(false);
        this.setLoggedUser(null);
      }
    })

   }
  checkHitCookie() {

    if(this.cookieService.get('cosmicHits')){
      this.registerUser();
    }else{
    let headersa = new HttpHeaders().append('Content-Type','application/json; charset=utf-8');
    this.httpClient.post(this.apiUrl + "api/Hits", {headers: headersa}).subscribe(
    (data:any)=> {
      //console.log(data);
      const dateNow = new Date();
            dateNow.setMinutes(dateNow.getMinutes() + (60*24));
            //dateNow.setDate(dateNow.getFullYear()+100);
            this.cookieService.set('cosmicHits', data, dateNow);
            this.registerUser();

    },
    (err)=>{
      // this.errorMessageService.seterrorMessage("Oops!! Your session Expired... Kindly restart searching your itinerary.")
      // this.NoCooks=true;
    });
  }
  }

   public registerUser() {
     this.loadingservice.setloading(true);
    this.cosmicHits = this.cookieService.get('cosmicHits');
    this.cosmicVisits = this.cookieService.get('cosmicVisits');

    console.log('Registration Initiated');
    let data = sessionStorage.getItem('user');
    let obj = JSON.parse(data);
    console.log(obj);
    // displayname:string,
    // providerid:string, photourl:string, Hit:string
    if (obj.uid != undefined) {
      //stsTokenManager.refreshToken
      let s =
        '{"FUid":"' +
        obj.uid +
        '","email":"' +
        obj.email +
        '","emailVerified":"' +
        obj.emailVerified +
        '","password":"' +
        '' +
        '","accessToken":"' +
        obj.stsTokenManager.accessToken +
        '","refreshToken":"' +
        obj.stsTokenManager.refreshToken +
        '","displayName":"' +
        obj.displayName +
        '","phoneNumber":"' +
        obj.phoneNumber +
        '","providerId":"' +
        obj.providerId +
        '","photourl":"' +
        obj.photourl +
        '","Hit":"' +
        this.cosmicHits +
        '","cosmicVisits":"' +
        this.cosmicVisits +
        '"}';

      //let headers = new HttpHeaders().append('Content-Type','application/json; charset=utf-8');
      //let params = new HttpParams().set('TrackerID', ""); // create params object

      let headersa = new HttpHeaders().append(
        'Content-Type',
        'application/json; charset=utf-8'
      );

      const httOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        withCredentials: true,
        observe: 'response' as 'response',
        // params: params
      };

      this.httpClient
        .post(this.apiUrl + 'api/Auth', s, { headers: headersa, observe: 'response'})
        .subscribe((data: any) => {
          console.log('RegisterResponse', data);
          console.log('RegisterHeader', data.headers);
          console.log('RegisterHeader', data.headers.get('server'));

          this.loadingservice.setloading(false);

          if (data.body == 'No') {
          } else {
            console.log("Check here");
          }
        },(err)=>{console});
    }
  }


  }
