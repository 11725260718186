<div>
    <div>
        <!-- {{NNewItem}} -->
        <mat-radio-group [(ngModel)]="SimpleSearch" class="radios">
            <mat-radio-button checked value="One Way" (click)="setSimpleForm('One Way')">
                One Way</mat-radio-button>
            <mat-radio-button value="Round Trip" (click)="setSimpleForm('Round Trip')">
                Round Trip</mat-radio-button>
            <mat-radio-button value="Multi City" (click)="setSimpleForm('Multi City')">
                Multi City</mat-radio-button>
        </mat-radio-group>
    </div>

    <div>
        <hr class="hrstyle" />
    </div>

    <!-- ONEWAY and RETURN Form -->
    <form class="form" id="FormSearch" *ngIf="SimpleSearch === 'One Way' || SimpleSearch === 'Round Trip'">
        <div class="simplecityDiv" *ngFor="
        let simplecity of MultiCityArray;
        let i = index;
        let first = first;
        let last = last
      " [ngClass]="{ first: first, last: last }">
            <div #SimpleDepartAirportDiv (click)="setAirportDivA('D', 'S', i)" class="formElementMulti" *ngFor="
          let airport of popularAirports
            | filterAirportByCode: simplecity.FromCityCode
        ">
                <div>
                    <span class="lblS">
            <i class="fa fa-plane" aria-hidden="true"></i>
            From <i class="fa fa-arrow-down" aria-hidden="true"></i
          ></span>
                    <span class="AirportS">{{ airport.CityName }}</span>
                    <span class="ACodeS">[{{ simplecity.FromCityCode }}]
            {{
              (airport.Name | slice: 0:25) +
                (airport.Name.length > 25 ? "..." : "")
            }}</span
          >
        </div>
        <div #errorSelectCityF class="errSelectCity">
          <span>Select City</span>
                </div>
            </div>

            <!-- ToAirport ReturnDateDiv -->
            <!-- <div #SimpleToAirportDiv class="formElementMulti" id="ReturnDateDiv" (mousemove)="hideControls('Airport')" (click)="setAirportDivA('T','S',i)">-->
            <div #SimpleToAirportDiv (click)="setAirportDivA('T', 'S', i)" class="formElementMulti" *ngFor="
          let airport of popularAirports
            | filterAirportByCode: simplecity.ToCityCode">
                <div>
                    <span class="lblS">
            <i class="fa fa-plane" aria-hidden="true"></i>
            To <i class="fa fa-arrow-down" aria-hidden="true"></i
          ></span>
                    <span class="AirportS">{{ airport.CityName }}</span>
                    <span class="ACodeS">[{{ simplecity.ToCityCode }}]
            {{
              (airport.Name | slice: 0:25) +
                (airport.Name.length > 25 ? "..." : "")
            }}</span
          >
        </div>

        <div #errorSelectCityT class="errSelectCity">
          <span>Select City</span>
                </div>
            </div>

            <!-- DepartDate  (click)="setDateDiv('D','DepartDateDiv')" -->
            <div #SimpleDepartDateDiv class="formElementMulti" (mouseover)="hideControls('Date')" (click)="setDateDivA('D', 'S', i)">
                <div>
                    <span class="lblS">
            <i class="fa fa-calendar" aria-hidden="true"></i>
            Departure on <i class="fa fa-arrow-down" aria-hidden="true"></i
          ></span>
                    <span class="AirportS">
            <!-- {{simplecity.DepartDate}} -->
            {{ simplecity.DepartDate | date: "dd" }}
            <small>{{ simplecity.DepartDate | date: "MMM yyyy" }}</small></span
          >
          <span class="ACodeS">{{ simplecity.DepartDate | date: "EEEE" }}</span>
                </div>
            </div>

            <!-- ReturnDate  (click)="setDateDiv('T','ReturnDateDiv')-->
            <div #SimpleReturnDateDiv class="formElementMulti" (mouseover)="hideControls('Date')" (click)="setDateDivA('T', 'S', i)">
                <div>
                    <span class="lblS">
            <i class="fa fa-calendar" aria-hidden="true"></i>
            Return <i class="fa fa-arrow-down" aria-hidden="true"></i
          ></span>
                    <span class="AirportS" *ngIf="SimpleSearch === 'Round Trip'">{{ simplecity.ReturnDate | date: "dd" }}
            <small>{{ simplecity.ReturnDate | date: "MMM yyyy" }}</small></span
          >
          <span class="ACodeS" *ngIf="SimpleSearch === 'Round Trip'">{{
            simplecity.ReturnDate | date: "EEEE"
          }}</span>
                    <span class="ACodeS" *ngIf="SimpleSearch === 'One Way'">Book Round Trip</span
          >
        </div>
      </div>

      <div
        #SimplePaxDetDiv
        class="formElementMulti"
        (mouseover)="hideControls('Pax')"
        (click)="setPaxDivA('S', i)"
      >
        <div>
          <span class="lblS">
            <i class="fa fa-users" aria-hidden="true"></i>
            Travellers & class
            <i class="fa fa-arrow-down" aria-hidden="true"></i
          ></span>
                    <span class="AirportS">0{{ NoofTravellers }} <small>Travellers</small></span
          >
          <span class="ACodeS">{{ Cabin }}</span>
                </div>
            </div>

            <div style="border: none">
                <!-- SearchFlights('S') -->
                <div style="display:grid;">
                    <div class="m-auto spinner-border text-primary" role="status" *ngIf="loading">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <button #btnSimpleSearch (click)="onSearchFlights()" class="btnn">
          Search
        </button>
                <!-- <button #btnFlightInfo (click)="GetFlightsInfo('S')" class="btnn">Search</button> -->
            </div>
        </div>
    </form>

    <!-- MultiCityForm -->
    <form class="form" id="FormSearchMultiCity" *ngIf="SimpleSearch == 'Multi City'">
        <div class="multicityDiv" *ngFor="
        let multicity of MultiCityArray;
        let i = index;
        let first = first;
        let last = last
      " [ngClass]="{ first: first, last: last }">
            <div #MultiDepartAirportDiv (click)="setAirportDivA('D', 'M', i)" class="formElementMulti" *ngFor="
          let airport of popularAirports
            | filterAirportByCode: multicity.FromCityCode
        ">
                <div>
                    <span class="lblS">
            <i class="fa fa-plane" aria-hidden="true"></i>
            From <i class="fa fa-arrow-down" aria-hidden="true"></i
          ></span>
                    <span class="AirportS">{{ airport.CityName }}</span>
                    <span class="ACodeS">[{{ multicity.FromCityCode }}]
            {{
              (airport.Name | slice: 0:30) +
                (airport.Name.length > 30 ? "..." : "")
            }}</span
          >
        </div>
        <div #errorSelectCityF class="errSelectCity">
          <span>Select City</span>
                </div>
            </div>

            <div #MultiToAirportDiv (click)="setAirportDivA('T', 'M', i)" class="formElementMulti" *ngFor="
          let airport of popularAirports
            | filterAirportByCode: multicity.ToCityCode
        ">
                <div>
                    <span class="lblS">
            <i class="fa fa-plane" aria-hidden="true"></i>
            To <i class="fa fa-arrow-down" aria-hidden="true"></i
          ></span>
                    <span class="AirportS">{{ airport.CityName }}</span>
                    <span class="ACodeS">[{{ multicity.ToCityCode }}]
            {{
              (airport.Name | slice: 0:30) +
                (airport.Name.length > 30 ? "..." : "")
            }}</span
          >
        </div>
        <div #errorSelectCityT class="errSelectCity">
          <span>Select City</span>
                </div>
            </div>

            <div #MultiDepartDateDiv class="formElementMulti" (mouseover)="hideControls('Date')" (click)="setDateDivA('D', 'M', i)">
                <span class="lblS">
          <i class="fa fa-calendar" aria-hidden="true"></i>

          Departure on <i class="fa fa-arrow-down" aria-hidden="true"></i
        ></span>
                <span class="AirportS">
          {{ multicity.DepartDate | date: "dd" }}
          <small>{{ multicity.DepartDate | date: "MMM yyyy" }}</small></span
        >
        <span class="ACodeS">{{ multicity.DepartDate | date: "EEEE" }}</span>
            </div>

            <div class="formElementMultiMerged">
                <div #MultiPaxDetDiv class="formElementMulti" (mouseover)="hideControls('Pax')" (click)="setPaxDivA('M', i)" *ngIf="first">
                    <span class="lblS">
            <i class="fa fa-users" aria-hidden="true"></i>
            Travellers & class
            <i class="fa fa-arrow-down" aria-hidden="true"></i
          ></span>
                    <span class="AirportS">0{{ NoofPax }} <small>Travellers</small></span>
                    <span class="ACodeS">{{ Cabin }}</span>
                </div>

                <div #MultiButtontDiv (mouseover)="hideControls('Pax')" *ngIf="last">
                    <div style="
              border: none;
              display: grid;
              grid-gap: 2px;
              grid-template-columns: 1fr 1fr;
            ">
                        <!-- SearchFlights('M') -->
                        <button #btnMultiSearch (click)="onSearchFlights()" class="btnn">
              Search
            </button>
                        <!-- <button mat-raised-button color="primary" class="btnn" > Search</button> -->
                        <button mat-raised-button color="primary" class="btnn" (click)="AddCity()">
              Add City
            </button>
                    </div>
                </div>
            </div>
            <div (click)="RemoveMultiCity(i)" class="formElementMultiMerged">
                <i class="fa fa-times" aria-hidden="true"></i>
            </div>

            <!-- <div class="formElementMulti"> <button mat-button (click)="changeVal(i)">Change</button> </div> -->
        </div>
    </form>

    <div>
        <hr class="hrstyle" />
    </div>

    <mat-checkbox [(ngModel)]="DirectFlights">Direct Flights</mat-checkbox>

    <!-- <div><input (change)="setPNR(txtPNR.value)" #txtPNR name="pnttxt"><button (click)="GetPNR()"></button></div> -->
    <!-- AirportSelector -->
    <div #airportSelector id="airportSelector" class="AirportSelector">
        {{ DateSelect }}
        <div id="a" style="display: grid; grid-gap: 5px; grid-template-columns: 10% 90%">
            <i class="fa fa-search searchIcon" aria-hidden="true"></i>
            <mat-form-field>
                <input [(ngModel)]="AirportSearch" matInput type="text" class="searchBox" placeholder="From" />
            </mat-form-field>
        </div>
        <!-- <hr> -->

        <mat-list #shoes class="custom-scroll-bar">
            <!-- | filterAirports:AirportSearch" -->

            <!-- <mat-list-item *ngFor="let airport of popularAirports | filterAirports:AirportSearch" class="AirportListView"
      (click)="setAirportToInput(airport.Code,airport.Name,airport.Country,airport.CityName,airport.LogoPath)" > -->
            <mat-list-item *ngFor="let airport of popularAirports | filterAirports: AirportSearch" class="AirportListView" (click)="setAirportToInputA(airport.Code, airport.Country)">
                <div id="b">
                    <div id="c" class="badge primary">{{ airport.Code }}</div>
                    {{ airport.CityName }}
                    <br />
                    <small>{{
            (airport.Name | slice: 0:30) +
              (airport.Name.length > 30 ? "..." : "")
          }}</small>

                    <small>
            [{{
              (airport.Country | slice: 0:30) +
                (airport.Country.length > 30 ? "..." : "")
            }}]</small
          >
        </div>
      </mat-list-item>
    </mat-list>
  </div>
  <!-- AirportSelector -->

  <!-- Error -->
  <div #errorSameDestination class="errN">
    <span>From and To Airports cannot be same!</span>
  </div>
  <!-- Error -->

  <!--Calendar View DatePicker Range-->

  <div #CalendarView id="CalendarView" class="Calendarwrapper">
    <div class="CalendarTop">
      <div class="CalendarDInput">
        <div class="style1">Departure:</div>
        <div class="style2">{{ selectedFDate }}</div>
      </div>

      <div class="CalendarRInput">
        <div class="style1">Return:</div>
        <div class="style2">{{ selectedRDate }}</div>
      </div>
    </div>
    <div class="calendarsContainer">
      <div class="calendarView">
        <div class="yearViewL">
          <span class="chv" (click)="GoPrevious()"> << </span>
          <span class="style2"
            >{{ date.format("MMMM") }} {{ date.format("YYYY") }}</span
          >
        </div>

        <div class="daysView">
          <div class="DayHeader" *ngFor="let dayHead of DaysHeadArr">
            {{ dayHead }}
          </div>
          <div *ngFor="let day of DaysFArr">
            <div
              class="Days"
              (click)="selectedDateA(day, 'Dep')"
              [ngClass]="{ inactive: !day, today: TodayCheck(day) }"
            >
              {{ day?.date() }}
            </div>
          </div>
        </div>
      </div>

      <div class="calendarView">
        <div class="yearViewR">
          <span class="style2"
            >{{ nextdate.format("MMMM") }} {{ nextdate.format("YYYY") }}</span
          >

          <span class="chv" (click)="GoNext()"> >> </span>
        </div>

        <div class="daysView">
          <div class="DayHeader" *ngFor="let dayHead of DaysHeadArr">
            {{ dayHead }}
          </div>
          <div *ngFor="let sday of DaysSArr">
            <div
              class="Days"
              (click)="selectedDateA(sday, 'Return')"
              [ngClass]="{ inactive: !sday, today: TodayCheck(sday) }"
            >
              {{ sday?.date() }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Calendar View DatePicker Range-->

  <div #PaxView id="PaxView" class="PaxWrapper">
    <div class="Pax">
      <span><strong>Adults</strong></span> <span>{{ PaxAdults }}</span>
      <div class="spinBtns">
        <span (click)="AssignPax('Adults', 'Sub')" class="MinusBtn">-</span>
        <span (click)="AssignPax('Adults', 'Add')" class="PlusBtn">+</span>
      </div>
    </div>

    <div class="Pax">
      <span><strong>Children</strong><br /><small>2-12 Years</small></span>
                    <span>{{ PaxChildren }}</span>
                    <div class="spinBtns">
                        <span (click)="AssignPax('Children', 'Sub')" class="MinusBtn">-</span>
                        <span (click)="AssignPax('Children', 'Add')" class="PlusBtn">+</span>
                    </div>
                </div>

                <div class="Pax">
                    <span><strong>Infant</strong><br /><small>0-23 Moths</small></span>
                    <span>{{ PaxInfant }}</span>
                    <div class="spinBtns">
                        <span (click)="AssignPax('Infants', 'Sub')" class="MinusBtn">-</span>
                        <span (click)="AssignPax('Infants', 'Add')" class="PlusBtn">+</span>
                    </div>
                </div>

                <div *ngIf="GroupBooking" class="PaxGroup">
                    <span>More than 9 Travellers! Get special Rates click here</span>
                </div>

                <div class="Pax">
                    <mat-radio-group [(ngModel)]="Cabin">
                        <mat-radio-button value="Economy">Economy</mat-radio-button>
                        <mat-radio-button value="Premium Economy">Premium Economy</mat-radio-button>
                        <mat-radio-button value="Business">Business</mat-radio-button>
                    </mat-radio-group>
                </div>
    </div>
</div>

<!-- <div *ngFor="let MultiCity of MultiCityArray">
  {{MultiCity.FromCityCode}} | {{MultiCity.ToCityCode}} | {{MultiCity.DepartDate}} | {{MultiCity.ReturnDate}} | {{MultiCity.DepDorI}} | {{MultiCity.RetDorI}}
</div> -->

<div #RightBars id="RightBarstyle">
    <div *ngIf="ResponseFlightDetails">
        <h2>Flight Details</h2>
    </div>
</div>



<!-- <div id="FlightResultsNew" *ngIf="ResponseFlightNew">

  {{ResponseFlightNew}}


<div class="sideBar">Side Bar New</div>
<div class="contentBar">
  <div  *ngFor="let responseEr of ResponseFlightDataEr">Error
    {{responseEr.errorMessageField.errorMessageTextField.descriptionField}}
  </div>


</div>


</div> -->