<div>
    <div class="cont">
        <span></span>
        <span>Customer Care No</span>
        <span>Email</span>
        <span></span>
        <!-- <span *ngIf="LoginStatus==false" class="btn btn-danger">Login</span> -->
        <!-- <button *ngIf="isSignedIn == false" type="button" class="btn btn-danger" data-toggle="modal" data-target="#LoginModalCenter">
      Login
    </button> -->


        <div>
            <img src="../../../assets/images/play_store.png" />
        </div>
        <div>
            <img src="../../../assets/images/app_store.png" />
        </div>



        <button *ngIf="(afAuth.authState | async) === null" type="button" class="btn btn-danger" data-toggle="modal" data-target="#LoginModalCenter">
      Login
    </button>

        <button class="btn btn-danger" *ngIf="(afAuth.authState | async)" (click)="GoogleSignOut()">Sign Out</button>
        <div *ngIf="(afAuth.authState | async)">

            <img class="profileImg" *ngIf="LoggedUser" src="{{LoggedUser.photoURL}}" />

        </div>

        <!-- <div *ngIf="afAuth.authState | async as user">

            Welcome {{user.displayName}};

        </div> -->


        <!-- <div style="padding:10px;" *ngIf="isSignedIn == true">

            <span class="welcome">Hello!! Guest</span>

        </div> -->
    </div>
</div>

<div *ngIf="(afAuth.authState | async)">
    <app-user-profile></app-user-profile>
</div>


<div class="modal fade" id="LoginModalCenter" tabindex="-1" role="dialog" aria-labelledby="LoginModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <!-- <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLongTitle">Login</h2>
      </div> -->
            <div class="modal-body">
                <app-login-modal></app-login-modal>
            </div>
        </div>
    </div>
</div>