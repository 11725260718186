import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-head-menus',
  templateUrl: './head-menus.component.html',
  styleUrls: ['./head-menus.component.css']
})
export class HeadMenusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
