// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  APIEndpoint: 'https://cosmictours2021.azurewebsites.net/',
  //apiUrl:"https://localhost:44317/",
  apiUrl:"https://cosmictours2021.azurewebsites.net/",

  firebaseConfig: {
    apiKey: "AIzaSyBWqlNuotq-fkFIiLCay3ixcZEHY-NATb4",
    authDomain: "cosmictours-708bb.firebaseapp.com",
    projectId: "cosmictours-708bb",
    storageBucket: "cosmictours-708bb.appspot.com",
    messagingSenderId: "102944536643",
    appId: "1:102944536643:web:c36900b555af46836d3cf6",
    measurementId: "G-DQ3JTWCJ14"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
